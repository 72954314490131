import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RolesPagesService {

  constructor(private http: HttpClient) { }

  public getRolesPages(roleId: any): Observable<any> {
    // const params: HttpParams = new HttpParams({fromObject: role})
    return this.http.get(`${environment.URL.APIPRIVATE}/v1/rolesPages/${roleId}`)
  }

  public postRolesPages(parameters: any) {
    // const params: HttpParams = new HttpParams({fromObject: parameters})
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/rolesPages`, parameters)
  }

}
