import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseJavaApi } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class F4EstoqueProdutoService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly endpoint = 'f4-estoque-produto';

  public getEstoqueProduto(parameters: any): Observable<ResponseJavaApi<any>>  {
    parameters.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.endpoint}`, parameters) as Observable<any>
  }
}
