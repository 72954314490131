import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListaTransportadoraService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'lista-transportadora';

  public getListaTransportadora(parameters: any): Observable<any> {
    parameters.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, parameters) as Observable<any>;
  }

}
