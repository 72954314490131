import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  PoBreadcrumb,
  PoBreadcrumbItem,
  PoNotificationService,
  PoPageAction,
  PoTableColumn,
} from '@po-ui/ng-components';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { PoService } from 'src/app/service/po/po.service';
import { UtilService } from 'src/app/service/util/util.service';

@Component({
  selector: 'app-purchase-order-edit',
  templateUrl: './purchase-order-edit.component.html',
  styleUrls: ['./purchase-order-edit.component.css'],
})
export class PurchaseOrderEditComponent implements OnInit {
  constructor(
    private poService: PoService,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private notificationService: PoNotificationService,
    private location: Location,
    private excelService: ExcelService,
    private router: Router
  ) { }

  public loading = false as boolean;
  public header = {
    C7_EMISSAO: '',
    C7_FORNECE: '',
    C7_LOJA: '',
    C7_NUM: '',
    C7_XNUMFOR: '',
    RECNO: 0,
    tabela: 'SC7',
    A2_NOME: '',
    TOTAL_AMOUNT: ''
  };

  page = {
    title: 'Purchase Order Edit' as string,
    breadcrumb: {
      items: [
        { label: 'Purchase' },
        { label: 'Purchase Order' },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [
      { label: 'Save', action: () => this.gravaPo() },
      { label: 'Back', action: () => this.location.back() },
      { label: 'Excel', action: () => { this.exportExcel() } }
    ] as Array<PoPageAction>,
  };

  table = {
    columns: [
      { C7_ITEM: 1, width: "5%", property: "C7_ITEM", label: "Item" },
      { width: "10%", property: "C7_PRODUTO", label: "Product" },
      { width: "9%", property: "C7_DESCRI", label: "Description" },
      { width: "9%", property: "C7_QUANT", label: "Qty" },
      { format: "USD", width: "9%", property: "C7_PRECO", label: "Unit Price", type: "cellTemplate", },
      { format: "USD", width: "9%", property: "C7_TOTAL", label: "Total Amount", type: "currency", },
      { width: "9%", property: "C7_XCONTR", label: "Container" },
      { width: "9%", property: "ZR6_NUM", label: "Wire Transf." },
      { width: "9%", property: "C7_LOCAL", label: "Location", type: "cellTemplate" },
      { width: "9%", property: "C7_SO", label: "SO Origin", type: "cellTemplate" },
      { width: "9%", property: "C7_OBS", label: "Observation", type: "cellTemplate" },
      { width: "9%", property: "C7_QUJE", label: "Qty. Delivered" },


      { visible: false, width: "10%", property: "C7_XOBS", label: "Notes" },
      { visible: false, width: "1%", property: "tabela", label: "table" },
      { visible: false, width: "1%", property: "recno", label: "recno" },
      { visible: false, width: "1%", property: "mensagem", label: "Status" },
      { visible: false, width: "1%", property: "alterar", label: "alterar" }
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: 0,
  };

  ngOnInit(): void {
    if (this.router.url.indexOf('view') !== -1) {
      this.page.title = 'Purchase Order View';
      this.page.breadcrumb.items.push({ label: 'Purchase Order View' });
      this.page.actions[0].disabled = true;
    } else {
      this.page.title = 'Purchase Order Edit';
      this.page.breadcrumb.items.push({ label: 'Purchase Order Edit' });
      this.page.actions[0].disabled = false;
    }
    this.route.paramMap
      .subscribe((param: ParamMap) => {
        this.getPo(param.get('RECNO'));
      });
  }

  private getPo(recno: any) {
    this.loading = true;
    const obj = {
      procedure: false,
      metodo: 'listar_dados',
      data: {
        recno: parseInt(recno),
      },
    };
    this.poService.getPo(obj).subscribe({
      next: (element: any) => {
        element.data.map((data: any) => {
          if (data.columns) {
            // this.table.columns = data.columns;
            this.table.height = this.utilService.calcularHeight(innerHeight, 0.60)
          }
          if (data.header) {
            this.header = data.header[0];
          }
          if (data.items) {
            this.table.items = data.items;
            this.table.items.forEach((item) => {
              item.disabled = !item.alterar;
              if(item.C7_QUJE <= 0 ) {
                item.disableLocal = false
              } else {
                item.disableLocal = true
              }
            });
            this.calcularTotalizador();
          }
        });
        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.loading = false;
        this.notificationService.error('Error while loading data');
      }
    });
  }

  calculaValorTotal(row: any) {
    if (row.C7_PRECO < 0) {
      row.C7_PRECO = 0;
      this.notificationService.warning(`The minimum unit price allowed is : 0`)
    }
    row.C7_TOTAL = row.C7_QUANT * row.C7_PRECO;
    this.calcularTotalizador();
  }

  calcularTotalizador() {
    let reducedAmount = this.utilService.reduceArrayByProperty(this.table.items, 'C7_TOTAL');
    reducedAmount = reducedAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
    this.header.TOTAL_AMOUNT = reducedAmount;
  }

  private gravaPo() {
    let valid = true as boolean
    this.table.items.map(element => {
      if (element.C7_PRECO <= 0) {
        valid = false
      }
    })
    const itens = this.table.items.filter((item) => item.alterar === true);
    if (itens.length <= 0) {
      valid = false;
    }
    this.loading = true;
    const obj = {
      procedure: false,
      metodo: 'salvar_po',
      data: {
        cabec: this.table.items[0],
        itens
      },
      operation: '4'
    }
    if (valid) {
      this.poService.getPo(obj)
        .subscribe({
          next: (element) => {
          if (element !== null) {
            this.notificationService.success(element.mensagem)
            this.location.back();
          } else {
            this.notificationService.error('Error, please try again!')
          }
          this.loading = false
        }, error: (error) => {
          console.error(error);
          this.loading = false
          this.notificationService.error('Erro')
        }
      })
    } else {
      this.loading = false;
      this.notificationService.warning('Unable to change, please check item status')
    }
  }

  private exportExcel() {
    this.loading = true
    const arr = this.table.items.map((element: any) => {
      return { ITEM: element.C7_ITEM, SKU: element.C7_PRODUTO, DESCRIPTION: element.C7_DESCRI, QTY: element.C7_QUANT, UNITPRICE: element.C7_PRECO, TOTAL: element.C7_TOTAL, NOTES: element.C7_XOBS, SO_ORIGIN: element.C7_SO }
    })
    this.excelService.exportAsExcelFile(arr, this.page.title)
    setTimeout((a: any) => {
      this.loading = false
    }, 1000)
  }
}
