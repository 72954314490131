import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  PoBreadcrumb,
  PoBreadcrumbItem,
  PoDialogService,
  PoNotificationService,
  PoPageAction,
  PoPageDefault,
  PoTableColumn,
} from '@po-ui/ng-components';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { TransferEstoqueService } from 'src/app/service/transfer-estoque/transfer-estoque.service';

@Component({
  selector: 'app-view-cancel-transfer',
  templateUrl: './view-cancel-transfer.component.html',
  styleUrls: ['./view-cancel-transfer.component.css'],
})
export class ViewCancelTransferComponent implements OnInit {
  constructor(
    private transferService: TransferEstoqueService,
    // private sessionService: SessionService,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private notificationService: PoNotificationService,
    private excelService: ExcelService,
    private dialog: PoDialogService,
  ) {}

  public loading = false as boolean;
  private recno = '' as any;
  private estornar = false as boolean;
  private visualizar = false as boolean;
  public item = {
    ARMAZEM_DESTINO: '',
    ARMAZEM_ORIGEM: '',
    RECNO: 0,
    ZR0_DATA: '',
    ZR0_HORA: '',
    ZR0_LOCALD: '',
    ZR0_LOCALO: '',
    ZR0_NUM: '',
    ZR0_STATUS: '',
    ZR0_USERNA: '',
    dataHora: '',
  };

  title = '' as string;
  page = {
    breadcrumb: {
      items: [
        { label: 'Stock' },
        { label: 'Stock Transfer  ' },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
  } as PoPageDefault;

  actions = [
    {
      label: 'Back',
      action: () => {
        this.location.back();
      },
    },
  ] as Array<PoPageAction>;

  table = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
  };

  ngOnInit(): void {
    const title = this.router.url.indexOf('reverse');
    if (title !== -1) {
      this.title = 'Reverse Transfer';
      this.actions.push({
        label: 'Reverse Transfer',
        action: () => {
          this.estornarTransfer();
        },
      });
      this.page.breadcrumb?.items.push(
        {label: 'View Transfer'}
      )
    } else {
      this.title = 'View Transfer';
      this.actions.push({
          label: 'Excel',
          action: () => {
            this.exportExcel();
          },
      })
      this.page.breadcrumb?.items.push(
        {label: 'View Transfer'}
      )
    }
    this.route.paramMap.subscribe((param: ParamMap) => {
      this.recno = param.get('RECNO');
      this.getTranser();
    });
  }

  private getTranser() {
    this.loading = true;
    const obj = {
      procedure: false,
      metodo: 'listar_transferencia',
      data: {
        recno: this.recno,
      },
    };
    this.transferService.getTransferEstoque(obj).subscribe({
      next: (element) => {
        element.data.map((item) => {
          if (item['columns']) {
            this.table.columns = item['columns'];
          }
          if (item['items']) {
            this.table.items = item['items'];
          }
          if (item['header']) {
            this.item = item['header'].pop();
            this.item.dataHora = `${this.item.ZR0_DATA}-${this.item.ZR0_HORA}`;
          }
        });
        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.loading = false;
      }
    });
  }

  private estornarTransfer() {
    this.dialog.confirm({
      title: 'Reverse Transfer',
      message: 'Are you sure you want to reverse this Transfer?',
      literals: { confirm: 'Yes', cancel: 'No' },
      confirm: () => {
        this.loading = true;
        const obj = {
          metodo: 'estornar_transferencia',
          procedure: false,
          data: {
            cabec: {
              ZR0_DATA: this.item.ZR0_DATA,
              ZR0_LOCALO: this.item.ZR0_LOCALO,
              ZR0_LOCALD: this.item.ZR0_LOCALD,
              ZR0_HORA: this.item.ZR0_HORA,
              ZR0_USERNA: this.item.ZR0_USERNA,
              recno: this.item.RECNO,
              tabela: 'ZR0',
            },
            itens: this.table.items,
            operation: '5',
          },
        };
        this.transferService.getTransferEstoque(obj).subscribe({
         next: (element) => {
            this.notificationService.success(
              'Transfer canceled successfully'
            );
            this.location.back()
            this.loading = false;
          },
         error: (error) => {
            console.error(error);
            this.notificationService.error('Error while cancelling transfer');
            this.loading = false;
          }
        });
      },
      cancel: () => {},
    });
  }

  private exportExcel() {
    this.loading = true;
    const items = this.table.items.map((a) => {
      return {
        Status: a.status,
        Item: a.ZR1_ITEM,
        Product: a.ZR1_PRODUT,
        Description: a.B1_DESC,
        QtyTransf: a.ZR1_QTDTRA,
        Document: a.ZR1_DOCSD3,
        Message: a.ZR1_MENSAG,
      };
    });
    this.excelService.exportAsExcelFile(items, `From_${this.item.ARMAZEM_ORIGEM}_To_${this.item.ARMAZEM_DESTINO}-View_Transfer`);
    setTimeout((a: any) => {
      this.loading = false;
    }, 1000);
  }
}
