import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface quotesInterface {
  mensagem: string;
  status: boolean;
  response: Array<dataQuotesInterface>;
}

export interface quotesDetailInterface {
  mensagem: string;
  status: boolean;
  response: detailQuote;
}

export interface dataQuotesInterface {
  idLicenca: number;
  apelido: string;
  idEvento: number;
  totalPedido: number;
  idPedido: number;
  dataEmissao: string;
  cnpjCpf: string;
  razaoSocial: string;
  codCliente: string;
  codRepresentante: string;
}

export interface detailQuote extends dataQuotesInterface {
  itens: Array<itensDeatilsInterface>;
}

export interface itensDeatilsInterface {
  corteRedondo: number;
  largura: number;
  totalItem: number;
  percDesconto: number;
  valorTabela: number;
  comprimento: number;
  qtdVenda: number;
  valorIPI: number;
  descricao: string;
  codProduto: string;
  valorUnitario: number;
}

export interface quoteDeatilObj {
  quoteId: number;
}

@Injectable({
  providedIn: 'root',
})
export class GoSalesService {
  constructor(private http: HttpClient) {}

  private endPoint: string = 'manter-quotes';
  private endPointQuoteId: string = 'detalhes-quote';
  private url = environment.URL.APIPRIVATE;

  public post(data: Object = {}): Observable<quotesInterface> {
    return this.http.post<quotesInterface>(
      `${this.url}/v1/${this.endPoint}`,
      data
    );
  }

  public getQuoteDetail(
    data: quoteDeatilObj
  ): Observable<quotesDetailInterface> {
    return this.http.post<quotesDetailInterface>(
      `${this.url}/v1/${this.endPointQuoteId}`,
      data
    );
  }
}
