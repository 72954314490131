<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb">
    <form [formGroup]="userEditForm">

        <!-- <div class="po-row">
            <div class="po-md-1">
                <po-avatar [p-src]="controls.avatar.value" p-size="md"></po-avatar>
            </div>
            <div class="po-md-11">
                <div class="po-row">
                    <po-input class="po-md-3" p-required="true" p-disabled="true" formControlName="id" p-label="ID">
                    </po-input>
                    <po-input class="po-md-3" p-required="true" p-disabled="true" formControlName="id_protheus" p-label="Id Protheus">
                    </po-input>
                    <po-input class="po-md-3" p-required="true" p-disabled="true" formControlName="username" p-label="Username" p-disabled="true">
                    </po-input> -->
        <!-- [p-readonly]="isDisabled" -->
        <!-- <po-password class="po-md-4" p-required="true" formControlName="password" p-label="Password">
                    </po-password> -->
        <!-- <po-switch class="po-md-2" p-label="Sales Rep." [p-disabled]="isDisabled" formControlName="is_representante" p-label-on="Sim" p-label-off="Não"></po-switch> -->
        <!-- </div> -->
        <!-- <div class="po-row"> -->
        <!-- [p-readonly]="isDisabled" -->
        <!-- <po-input class="po-md-3" p-required="true" formControlName="name" p-label="Name" p-error-pattern="Only letters"></po-input> -->
        <!-- <po-select class="po-md-3" [p-required]="true" *ngIf="roleOptions" [p-readonly]="isDisabled" formControlName="role_id" p-label="Role" [p-options]="roleOptions">
                    </po-select> -->
        <!-- <po-combo *ngIf="roleOptions" [p-disabled]="isDisabled" formControlName="role_id" p-label="Role" [p-options]="roleOptions"> </po-combo>
                    <po-switch [p-disabled]="isDisabled" p-required="true" formControlName="active" p-label="Active" class="po-md-3" p-label-on="Yes" p-label-off="No">
                    </po-switch>
                </div>
            </div>
        </div> -->
        <div class="po-row">
            <div class="po-md-12">
                <po-input class="po-md-2" p-required="true" p-disabled="true" formControlName="id" p-label="ID">
                </po-input>
                <!-- <po-input class="po-md-5" p-required="true" p-disabled="true" formControlName="id_protheus"
                        p-label="ID Protheus">
                    </po-input> -->
                <po-input class="po-md-4" p-required="true" p-disabled="true" formControlName="username"
                    p-label="Username" p-disabled="true">
                </po-input>
                <po-input class="po-md-4" p-required="true" formControlName="name" p-label="Name"
                    p-error-pattern="Only letters"></po-input>
                <po-switch [p-disabled]="isDisabled" p-required="true" formControlName="active" p-label="Active"
                    class="po-md-2" p-label-on="Yes" p-label-off="No">
                </po-switch>
                <po-email class="po-md-10" p-label="Email" p-help="" formControlName="email"></po-email>
                <po-select class="po-md-2" [p-required]="true" *ngIf="roleOptions" [p-readonly]="isDisabled"
                    formControlName="role_id" p-label="Role" [p-options]="roleOptions">
                </po-select>
            </div>
        </div>
        <po-divider class="po-md-12"></po-divider>
        <div class="po-row">
            <po-info class="po-md-3" p-label="Creation Date" p-value="{{controls.created.value | date: 'MM/dd/yyyy'}}">
            </po-info>
            <po-info class="po-md-3" p-label="Modification Date"
                p-value="{{controls.modified.value | date: 'MM/dd/yyyy'}}"></po-info>
        </div>
    </form>
</po-page-default>


<po-loading-overlay *ngIf="loading" [p-screen-lock]="true" p-text="Loading...">
</po-loading-overlay>