<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb">

    <form [formGroup]="filtersForm" class="po-row" (keyup.enter)="applyFilters()">
        <po-input class="po-md-3" p-label="Username" formControlName="username"></po-input>
        <po-input class="po-md-3" p-label="Name" formControlName="name"></po-input>
        <po-select class="po-md-2" p-label="Sales Rep" formControlName="is_representante" [p-options]="representanteOptions"></po-select>
        <po-select class="po-md-2" p-label="Active" formControlName="active" [p-options]="activeOptions"></po-select>
        <po-button class="po-mt-4 po-md-2" p-label="Search" p-icon="po-icon-search" (p-click)="applyFilters()" p-type="primary"></po-button>
    </form>

    <po-table [p-hide-columns-manager]="true" [p-sort]="true" [p-striped]="true" [p-loading]="table.loading" [p-columns]="table.columns" [p-items]="table.items" [p-actions]="tableActions" [p-height]="table.height">

    </po-table>
</po-page-default>

<po-modal #alterarSenhaModal id="modalUser" p-size="sm" p-title="Change Password" [p-hide-close]="true" [p-primary-action]="primaryAction" [p-secondary-action]="secondaryAction">
    <div [formGroup]="alterarSenhaForm">
        <po-password p-label="New Password" [p-no-autocomplete]="true" class="po-md-12" p-pattern="^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$" formControlName="new" p-required="true"></po-password>
        <po-password p-label="Confirm Password" class="po-md-12" [p-no-autocomplete]="true" formControlName="confirm" p-required="true">
        </po-password>
    </div>
</po-modal>

<po-loading-overlay *ngIf="loading" p-text="Loading..."></po-loading-overlay>