import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RelatorioPerformanceService {
  constructor(private http: HttpClient) {}

  private readonly ip = 'relatorio-performance';

  public getPeformance(parameters: any) {
    const params = new HttpParams({ fromObject: parameters });
    return this.http.get(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, {
      params,
    });
  }
}
