import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  PoBreadcrumbItem,
  PoComboFilterMode,
  PoDialogService,
  PoInfoOrientation,
  PoModalAction,
  PoModalComponent,
  PoNotificationService,
  PoPageAction,
  PoSelectOption,
  PoTableAction,
  PoTableColumn,
  PoTableLiterals,
} from '@po-ui/ng-components';
import { ListaStatusCodeService } from 'src/app/service/lista-status-code/lista-status-code.service';
import { merge } from 'rxjs';
import { RelacaoPedidosV2Service } from 'src/app/service/relacao-pedidos-v2/relacao-pedidos-v2.service';
import { UtilService } from 'src/app/service/util/util.service';
import { ListaTransportadoraService } from 'src/app/service/lista-transportadora/lista-transportadora.service';
import { ProdutosService } from 'src/app/service/produtos/produtos.service';
import { RepresentantesService } from 'src/app/service/representantes/representantes.service';
import { ListaCondicaoPagamentoService } from 'src/app/service/lista-condicao-pagamento/lista-condicao-pagamento.service';
import { ListaTabelaPrecosService } from 'src/app/service/lista-tabela-precos/lista-tabela-precos.service';
import { EnvioPadraoBackend } from 'src/app/service/envio-padrao-backend';
import { ListaWarehouseProdutosService } from 'src/app/service/lista-warehouse-produtos/lista-warehouse-produtos.service';
import { F4EstoqueProdutoService } from 'src/app/service/f4-estoque-produto/f4-estoque-produto.service';
import { RelacaoClientesService } from 'src/app/service/relacao-clientes/relacao-clientes.service';
import { ListaTipoOperacaoService } from 'src/app/service/lista-tipo-operacao/lista-tipo-operacao.service';
import { RelatorioPedidosCarteiraService } from 'src/app/service/relatorio-pedidos-carteira/relatorio-pedidos-carteira.service';
import { ConsultaReservasService } from 'src/app/service/consulta-reservas/consulta-reservas.service';
import { ConsignacoesAbertoService } from 'src/app/service/consignacoes-aberto/consignacoes-aberto.service';
import { ImportacaoBuscaProdutosService } from 'src/app/service/importacao-busca-produtos/importacao-busca-produtos.service';
import { GravadadosService } from 'src/app/service/gravadados/gravadados.service';
import { ListaInvoiceService } from 'src/app/service/lista-invoice/lista-invoice.service';
import { ListaTESService } from 'src/app/service/listaTES/lista-tes.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sales-order-edit',
  templateUrl: './sales-order-edit.component.html',
  styleUrls: ['./sales-order-edit.component.css'],
})
export class SalesOrderEditComponent implements OnInit {
  constructor(
    private location: Location,
    private fb: UntypedFormBuilder,
    private relacaoPedidosServiceV2: RelacaoPedidosV2Service,
    private activatedRoute: ActivatedRoute,
    private notificationService: PoNotificationService,
    private transportadoraService: ListaTransportadoraService,
    private utilService: UtilService,
    private listaStatusCode: ListaStatusCodeService,
    private dialog: PoDialogService,
    private productsService: ProdutosService,
    private listaWarehouseProdutosService: ListaWarehouseProdutosService,
    private relacaoClientesService: RelacaoClientesService,
    private listaTipoOperacaoService: ListaTipoOperacaoService,
    private gravadadosService: GravadadosService,
    private representanteService: RepresentantesService,
    private condicaoPagamentoService: ListaCondicaoPagamentoService,
    private pedidosCarteiraService: RelatorioPedidosCarteiraService,
    private listaTabelaPrecoService: ListaTabelaPrecosService,
    private consultaReservasService: ConsultaReservasService,
    private consignacoesAbertoService: ConsignacoesAbertoService,
    private importacaoBuscaProdutosService: ImportacaoBuscaProdutosService,
    private f4EstoqueProdutoService: F4EstoqueProdutoService,
    private listaInvoiceService: ListaInvoiceService,
    private listaTESService: ListaTESService,
    private router: Router
  ) { }

  public loading = true as boolean;

  public disableF = true as boolean;

  public disabelEdit = false as boolean;

  public grandTotal = 0 as number;

  public totalItems = 0 as any;

  private houveAlteracao = false as boolean;

  private produtoSelecionado: any;

  public tipoOperacaoList: Array<PoSelectOption> = [];

  public quantidadeInvoice = 0 as number;

  public poFilterMode = {
    contains: PoComboFilterMode.contains,
    endsWith: PoComboFilterMode.endsWith,
    startsWith: PoComboFilterMode.startsWith,
  };

  public poOrientation = {
    vertical: PoInfoOrientation.Vertical,
    horizontal: PoInfoOrientation.Horizontal,
  };

  public page = {
    title: 'Sale Order' as string,
    breadcrumb: {
      items: [
        { label: 'Commercial' },
        { label: 'Sales Order' },
        { label: 'Sale Order Edit' },
      ] as Array<PoBreadcrumbItem>,
    },
    actions: [
      { label: 'Save', action: () => this.saveDispatch() },
      { label: 'Cancel', type: 'danger', action: () => this.router.navigate(['commercial/sales-order']) },
    ] as Array<PoPageAction>,
  };

  public form = this.fb.group({
    C5_NUM: [''],
    customer: [''],
    C5_CLIENTE: [''],
    C5_LOJACLI: [''],
    C5_CLIENT: [''],
    C5_LOJAENT: [''],
    C5_EMISSAO: [''],
    C5_TPFRETE: [''],
    C5_NOTA: [''],
    C5_FRETE: [''],
    C5_OPER: [''],
    C5_XPO: [''],
    C5_TABELA: [''],
    C5_VEND1: [''],
    C5_VEND2: [''],
    F2_EMISSAO: [''],
    C5_CONDPAG: [''], //CODIGO CONDICAO PAGAMENTO
    TOTAL: [''],
    C5_XOBSCOM: [''],
    C5_XOBSGC1: [''],
    C5_XOBSGC2: [''],
    C5_XFIN: [''],
    C5_XOBSFEI: [''],
    C5_XLOG: [''],
    C5_XSHIPTO: [''],
    C5_XENDENT: [''],
    C5_XSHPZIP: [''],
    C5_XSHPEMA: [''],
    C5_XENDEN2: [''],
    C5_XSHPUF: [''],
    C5_XDESPAC: [''],
    FreighForwarder: [''],
    C5_XCOLENA: [''],
    C5_XCOLEDA: [''],
    C5_XTRACNU: [''],
    C5_VOLUME1: [''],
    C5_ESPECI1: [''],
    C5_PBRUTO: [''],
    C5_PESOL: [''],
    C5_XNOTE: [''],
    recno: [''],
    C5_TOTAL: [''],
    C5_DESPESA: [''],
    A1_CEP: [''],
    A1_EMAIL: [''],
    A1_END: [''],
    A1_EST: [''],
    A1_MUN: [''],
    A1_NOME: [''],
    A1_TEL: [''],
    valorRecebido: [''],
    totalDue: [''],
    C5_XCODSTA: [''],
    C5_FILIAL: [''],
    C5_XPEDAPP: ['']
  });

  public select = {
    status: [
      { label: '1 - To Deliver', value: '1' },
      { label: '2 - Scheduled', value: '2' },
      { label: '3 - Delivered', value: '3' },
    ] as Array<PoSelectOption>,
    transportadoras: [] as Array<PoSelectOption>,
    statusCode: [] as Array<PoSelectOption>,
    customer: [] as Array<PoSelectOption>,
    representante: [] as Array<PoSelectOption>,
    condicaoPagamento: [] as Array<PoSelectOption>,
    tabelaPreco: [] as Array<PoSelectOption>,
    freightType: [
      { label: 'CIF', value: 'C' },
      { label: 'FOB', value: 'F' },
      { label: 'NO FREIGHT', value: 'S' },
    ] as Array<PoSelectOption>,
    invoice: [] as Array<PoSelectOption>,
    tes: [] as Array<PoSelectOption>,
  };

  public table = {
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
    columns: [
      {
        label: 'Item',
        property: 'C6_ITEM',
        width: '100px',
        type: 'cellTemplate',
      },
      {
        label: 'Product',
        property: 'C6_PRODUTO',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'Description',
        property: 'C6_DESCRI',
        width: '300px',
        type: 'cellTemplate',
      },
      {
        label: 'Quantity',
        property: 'C6_QTDVEN',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'Price',
        property: 'C6_PRCVEN',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'Total',
        property: 'C6_VALOR',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'in Stock',
        property: 'QTDESTOQUE',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'Reserved',
        property: 'QTDRES',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'Qty Invoiced',
        property: 'C6_QTDENT',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'Warehouse',
        property: 'C6_LOCAL',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'Notes',
        property: 'C6_XOBS',
        width: '150px',
        type: 'cellTemplate',
      },
      {
        label: 'Outf. Type',
        property: 'C6_TES',
        width: '150px',
        type: 'cellTemplate',
      },
      { label: '', property: 'AUTDELETA', width: '150px', visible: false },
      { label: 'recno', property: 'recno', width: '150px', visible: false },
    ] as Array<PoTableColumn>,
    actions: [
      { label: 'Edit', icon: 'po-icon-edit', action: this.editItem.bind(this) },
      {
        label: 'Delete',
        icon: 'po-icon-delete',
        type: 'danger',
        separator: true,
        action: this.confirmDeleteItem.bind(this),
        disabled: this.disabledDeleteAction.bind(this),
      },
      {
        label: 'Undo',
        icon: 'po-icon po-icon-refresh',
        type: 'default',
        separator: true,
        action: this.confirmDeleteItem.bind(this),
        disabled: this.disabledUndoAction.bind(this),
      },
    ] as Array<PoTableAction>,
  };

  // ********************************************************************************************************************
  //                                                      MODAL
  // ********************************************************************************************************************

  public modalForm = this.createModalForm();
  public disabledFieldsModalForm: any = false;
  public tipoModal = 1;
  public warehouseList = [];
  public warehouseSelect = [] as Array<PoSelectOption>;

  @ViewChild('modal', { static: true }) poModal!: PoModalComponent;

  primaryActionModal: PoModalAction = {
    label: 'Ok',
    action: () => {
      this.verifyFormAndSave(this.modalForm);
    },
  };

  secondaryActionModal: PoModalAction = {
    label: 'Close',
    danger: true,
    action: () => {
      this.poModal.close();
    },
  };

  public titlePopup = '';
  public formValue = {};
  public isFirstRequest = true;

  @ViewChild('modalSearch', { static: true }) poModalSearch!: PoModalComponent;
  @ViewChild('modalActionsF4Estoque', { static: true }) modalActionsF4Estoque!: PoModalComponent;
  @ViewChild('modalClientes', { static: true }) modalClientes!: PoModalComponent;

  public confirmModalSearch: PoModalAction = {
    action: () => {
      this.setListItem();
    },
    label: 'Ok',
  };

  public closeModalSearch: PoModalAction = {
    action: () => {
      this.poModalSearch.close();
    },
    label: 'Close',
    danger: true,
  };

  public tableSearchPopup = {
    columns: [
      { property: 'codigo', label: 'Product', width: '20%' },
      { property: 'descricao', label: 'Description', width: '50%' },
      {
        property: 'precoTabela',
        label: 'Unit Price',
        width: '30%',
        type: 'currency',
        format: 'USD',
      },
    ] as PoTableColumn[],
    items: [],
    height: 250,
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals,
  };

  public tableF4EstoqueProduto = {
    columns: [
      { label: 'Warehouse', property: 'B2_LOCAL', width: '10%' },
      { label: 'Description', property: 'NNR_DESCRI', width: '20%' },
      { label: 'Stock', property: 'B2_QATU', width: '10%' },
      { label: 'Qty Sales Order', property: 'B2_QPEDVEN', width: '17.5%' },
      { label: 'Qty Reserved', property: 'B2_RESERVA', width: '17.5%' },
      { label: 'Qty Consigned', property: 'B2_QNPT', width: '17.5%' },
      { label: 'Qty Purchase Orders', property: 'B2_SALPEDI', width: '17.5%' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.2),
    actions: [
      {
        label: 'Sales Orders',
        action: this.createColumnsF4Estoque.bind(this, 'Sales Orders'),
        icon: '',
      },
      {
        label: 'Reserved',
        action: this.createColumnsF4Estoque.bind(this, 'Reserved'),
        icon: '',
      },
      {
        label: 'Consigned',
        action: this.createColumnsF4Estoque.bind(this, 'Consigned'),
        icon: '',
      },
      {
        label: 'Purchase Order',
        action: this.createColumnsF4Estoque.bind(this, 'Purchase Order'),
        icon: '',
      },
    ] as Array<PoTableAction>,
  };

  public talbeActionF4Estoque = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.4),
    literals: { noData: 'No data found' } as PoTableLiterals,
  };

  public modalClientesProperties = {
    codigoCliente: '',
    table: {
      columns: [
        { label: 'Code', property: 'codigo', width: '20%' },
        { label: 'Trading name', property: 'nomeFantasia', width: '70%' },
        { label: 'Unit', property: 'loja', width: '10%' },
      ] as Array<PoTableColumn>,
      items: [] as Array<any>,
      actions: [
        { label: 'Select', action: this.setValueCliente.bind(this), icon: 'po-icon-ok' }
      ] as Array<PoTableAction>,
      height: this.utilService.calcularHeight(innerHeight, 0.4)
    },
    closeAction: { label: 'Close', action: () => this.modalClientes.close() } as PoModalAction,
    pagination: {
      page: 1,
      limit: 30,
      total: 0
    }
  }

  // public produtoSelecionado = new ModalProdutos();


  ngOnInit(): void {
    Promise.all([
      this.getParamsRoute(),
      this.getTransportadoras(),
      this.getRepresentantes(),
      this.getListaCondicaoPagamento(),
      this.getListaTabelaPreco(),
      this.getListaTipoOperacao(),
      this.getListaStatusCode(),
      this.getListaTes()
    ]).then((ret) => {
      setTimeout(() => {
        this.loading = false
      }, 3000);
    });

    merge(
      this.form.controls['C5_CLIENTE'].valueChanges,
      this.form.controls['C5_VEND1'].valueChanges,
      this.form.controls['C5_VEND2'].valueChanges,
      this.form.controls['C5_OPER'].valueChanges
    ).subscribe(() => (this.houveAlteracao = true));

    if (window.innerWidth > 1366) {
      this.table.columns = [
        {
          label: 'Item',
          property: 'C6_ITEM',
          width: '6%',
          type: 'cellTemplate',
        },
        {
          label: 'Product',
          property: 'C6_PRODUTO',
          width: '14%',
          type: 'cellTemplate',
        },
        {
          label: 'Description',
          property: 'C6_DESCRI',
          width: '23%',
          type: 'cellTemplate',
        },
        {
          label: 'Quantity',
          property: 'C6_QTDVEN',
          width: '8%',
          type: 'cellTemplate',
        },
        {
          label: 'Price',
          property: 'C6_PRCVEN',
          width: '8%',
          type: 'cellTemplate',
        },
        {
          label: 'Total',
          property: 'C6_VALOR',
          width: '8%',
          type: 'cellTemplate',
        },
        {
          label: 'in Stock',
          property: 'QTDESTOQUE',
          width: '7%',
          type: 'cellTemplate',
        },
        {
          label: 'Reserved',
          property: 'QTDRES',
          width: '7%',
          type: 'cellTemplate',
        },
        {
          label: 'Qty Invoiced',
          property: 'C6_QTDENT',
          width: '7%',
          type: 'cellTemplate',
        },
        {
          label: 'Warehouse',
          property: 'C6_LOCAL',
          width: '7%',
          type: 'cellTemplate',
        },
        {
          label: 'Notes',
          property: 'C6_XOBS',
          width: '15%',
          type: 'cellTemplate',
        },
        {
          label: 'Outf. Type',
          property: 'C6_TES',
          width: '8%',
          type: 'cellTemplate',
        },
        { label: '', property: 'AUTDELETA', width: '0%', visible: false },
        { label: 'recno', property: 'recno', width: '0%', visible: false },
      ];
    }
  }

  private get controls() {
    return this.form.controls;
  }

  private getParamsRoute() {
    this.activatedRoute.paramMap.subscribe((param: ParamMap) => {
      setTimeout(() => {
        this.getOrder(param.get('RECNO'));
      }, 500);
    });
  }

  private getOrder(recno: any) {
    const envio = {
      procedure: true,
      data: {
        recno,
      },
      metodo: '',
    } as EnvioPadraoBackend;
    this.relacaoPedidosServiceV2.getPedidosItem(envio).subscribe({
      next: (a: any) => {
        const data = a.data.data.pop();
        this.form.patchValue(data, { emitEvent: false, onlySelf: true });
        // setando o valor concatenado pois o value do select esta desta maneira
        this.controls.customer.setValue(
          `${data.C5_CLIENTE}-${data.C5_LOJACLI}-${data.A1_NOME}`, { emitEvent: false }
        );
        // this.controls.PriceList.setValue(
        //   `${data.C5_TABELA}-${data.DA0_DESCRI}`
        // );
        this.tableSearchPopup.fieldValue.C5_TABELA = data.C5_TABELA;
        // this.controls.SalesRep.setValue(`${data.C5_VEND1}-${data.A3_NREDUZ}`);
        // this.controls.SalesRep2.setValue(`${data.C5_VEND2}-${data.A3_NREDUZ2}`);
        // this.controls.PaymentTerm.setValue(
        //   `${data.C5_CONDPAG}-${data.E4_DESCRI}`
        // );
        this.controls.C5_XDESPAC.setValue(data.C5_XDESPAC[0]);
        this.controls.C5_XCOLEDA.setValue(
          data.C5_XCOLEDA === '1900-01-01' ? '' : data.C5_XCOLEDA
        );
        this.controls.F2_EMISSAO.setValue(
          data.F2_EMISSAO === '1900-01-01'
            ? ''
            : this.utilService.multiFormataData(data.F2_EMISSAO, 'mm/dd/yyyy')
        );

        this.controls.C5_EMISSAO.setValue(
          this.utilService.multiFormataData(data.C5_EMISSAO, 'mm/dd/yyyy')
        );
        this.controls.FreighForwarder.setValue(data.C5_TRANSP);

        this.disabelEdit = data.C5_XDESPAC[0] === '3' ? true : false;
        this.controls.C5_TOTAL.setValue(
          data.F2_VALIMP1 > 0 ? data.F2_VALIMP1 : data.C5_XPRVSTX
        );
        this.disableF = data.C5_NOTA.length >= 2 ? true : false;
        if (this.disableF) {
          this.getListaInvoice(data.C5_NUM);
        }

        const invoiceSelected = { label: data.C5_NOTA, value: data.C5_NOTA } as PoSelectOption
        this.select.invoice = this.utilService.spreadArrayToProperty(this.select.invoice,invoiceSelected);

        this.table.actions = data.C5_NOTA.length >= 2 ? [] : this.table.actions;
        this.page.title = `Sale Order - ${data.C5_NUM}`;
        data.SC6.forEach((element: any) => {
          element.AUTDELETA = 'N';
        });
        this.table.items = data.SC6;
        this.calculaTotal();
      },
      error: (error) => {
        console.error('error --->', error);
        this.notificationService.error('Error in loading data');
        this.loading = false;
      }
   } );
  }

  private getListaInvoice(SALES_ORDER: string) {
    const envio = {
      procedure: true,
      data: {
        SALES_ORDER
      },
      metodo: ''
    } as EnvioPadraoBackend
    this.listaInvoiceService.getListaInvoice(envio)
      .subscribe((element: any) => {
        this.quantidadeInvoice = element.data.length;
        this.select.invoice = element.data.map((a: any) => {
          return { label: a.D2_DOC, value: a.D2_DOC } as PoSelectOption
        })
      })
  }

  private getTransportadoras() {
    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.transportadoraService.getListaTransportadora(envio)
      .subscribe({
        next: (element: any) => {
          this.select.transportadoras = element.data.data.map((t: any) => {
            return {
              label: `${t.codigo}-${t.nome}`,
              value: t.codigo,
            } as PoSelectOption;
          });
        },
        error: (error) => {
          console.error(error);
          this.location.back();
          this.notificationService.error('Error in loading Freight Forwarder');
        }
      });
  }

  private getListaStatusCode() {
    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.listaStatusCode.getLista(envio).subscribe({
      next: (element: any) => {
        element.data.map((sta: any) => {
          const statusCode = { label: sta.description, value: sta.code,} as PoSelectOption
          this.select.statusCode = this.utilService.spreadArrayToProperty(this.select.statusCode,statusCode);
        });
      },
      error: (error) => {
        this.notificationService.error('Server is not responding');
        this.loading = false;
      }
    });
  }

  private getListaWarehouseProdutos(cb: any) {
    this.loading = true;
    const envio = {
      procedure: true,
      data: {
        C6_PRODUTO: this.modalForm.controls.C6_PRODUTO.value,
      },
      metodo: '',
    } as EnvioPadraoBackend;
    this.listaWarehouseProdutosService.getListaWarehouse(envio).subscribe({
      next: (element: any) => {
        if (element.status) {

          this.warehouseList = element.data;
          this.warehouseSelect = this.warehouseList.map((t: any) => {
            return {
              label: `${t.B2_LOCAL} - ${t.NNR_DESCRI}`,
              value: t.B2_LOCAL,
            } as PoSelectOption;
          });
          if (this.tipoModal === 1) {
            const warehouseDefault = this.warehouseList[0] as any;
            this.modalForm.controls.C6_LOCAL.setValue(warehouseDefault.B2_LOCAL);
            this.modalForm.controls.QTDRES.setValue(warehouseDefault.B2_RESERVA);
            this.modalForm.controls.QTDESTOQUE.setValue(warehouseDefault.QTDDISP);
            this.calcTotalItem();
          }
          cb();
        } else {
          return;
        }
        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.notificationService.error('Error in loading');
        this.loading = false;
      }
    });
  }

  public getRelacaoClientes() {
    this.loading = true
    const envio = {
      procedure: true,
      data: {
        page: this.modalClientesProperties.pagination.page,
        limit: this.modalClientesProperties.pagination.limit,
        codigoCliente: this.modalClientesProperties.codigoCliente,
      },
      metodo: '',
    } as EnvioPadraoBackend;
    this.relacaoClientesService
      .getRelacaoClientes(envio)
      .subscribe((element: any) => {
        this.modalClientesProperties.table.items = element.data.data;
        this.utilService.emitTotalItems.emit(element.data.total)
        this.loading = false;
      })
  }

  private getListaTipoOperacao() {
    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.listaTipoOperacaoService
      .getListaTipoOperacao(envio)
      .subscribe((element: any) => {
        this.tipoOperacaoList = element.data.map((t: any) => {
          return {
            label: `${t.tipoOperacao} - ${t.descricao}`,
            value: t.tipoOperacao,
          } as PoSelectOption;
        });
      });
  }

  private getOpenSalesOrders(codigoProduto: string, local: any) {
    this.loading = true
    const envio = {
      procedure: true,
      data: {
        geraFinanceiro: 'T',
        codigoProduto,
        local,
      },
      metodo: ''
    } as EnvioPadraoBackend
    this.pedidosCarteiraService.getPedidosCarteira(envio)
      .subscribe({
        next: (element: any) => {
        if (element.status) {
          this.talbeActionF4Estoque.items = element.data.sort((a: any) => a.quantidade - a.quantidade);
          this.loading = false;
        } else {
          this.talbeActionF4Estoque.items = []
          this.notificationService.warning(element.mensagem);
          this.loading = false;
        }
      }, error: (err) => {
        this.notificationService.error(err);
        this.loading = false;
      }
    })
  }

  private getReservas(codigoProduto: any, local: any) {
    this.loading = true;
    const envio = {
      procedure: true,
      data: {
        codigoProduto,
        somenteReservas: '3',
        page: 1,
        limit: 9999,
        local,
      },
      metodo: ''
    } as EnvioPadraoBackend
    this.consultaReservasService.getReservas(envio)
      .subscribe({
        next: (element: any) => {
        if (element.status) {
          this.talbeActionF4Estoque.items = element.data.data;
          this.loading = false;
        } else {
          this.talbeActionF4Estoque.items = []
          this.notificationService.warning(element.mensagem);
          this.loading = false;
        }
      }, error: (err: HttpErrorResponse) => {
        this.notificationService.error(err);
        this.loading = false;
      }})
  }

  private getConsignacoesAberto(filtro: any, local: any) {
    this.loading = true;
    const envio = {
      procedure: true,
      data: {
        page: 1,
        limit: 9999,
        filtro,
        local,
      },
      metodo: ''
    } as EnvioPadraoBackend
    this.consignacoesAbertoService.getConsignacoesAberto(envio)
      .subscribe((element: any) => {
        this.talbeActionF4Estoque.items = element.data.data
        this.loading = false;
      })
  }

  private getImportacaoBuscaProduto(codigoProduto: any) {
    this.loading = true;
    const envio = {
      procedure: true,
      data: {
        descricao: '',
        codigoProduto,
        importacao: '',
      },
      metodo: ''
    } as EnvioPadraoBackend
    this.importacaoBuscaProdutosService.getImportacaoBuscaProdutos(envio)
      .subscribe((element: any) => {
        this.talbeActionF4Estoque.items = element.data
        this.loading = false;
      })
  }

  private getF4EstoqueProduto(C6_PRODUTO: any) {
    this.loading = true;
    const envio = {
      procedure: true,
      data: {
        C6_PRODUTO,
        C6_FILIAL: this.controls.C5_FILIAL.value,
      },
      metodo: '',
    } as EnvioPadraoBackend;
    const obj = {};
    this.f4EstoqueProdutoService
      .getEstoqueProduto(envio)
      .subscribe((element: any) => {
        this.tableF4EstoqueProduto.items = element.data;
        this.loading = false;
      });
  }

  private getRepresentantes() {
    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.representanteService
      .getAllRepresentantes(envio)
      .subscribe((element: any) => {
        this.select.representante = element.data.data.map((item: any) => {
          return {
            label: `${item.codigo}-${item.nomeFantasia}`,
            value: item.codigo,
          } as PoSelectOption;
        });
      });
  }

  private getListaCondicaoPagamento() {
    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.condicaoPagamentoService
      .getListaCondicaoPagamento(envio)
      .subscribe((element: any) => {
        this.select.condicaoPagamento = element.data.map((item: any) => {
          return {
            label: `${item.codigo}-${item.descricao}`,
            value: item.codigo,
          } as PoSelectOption;
        });
      });
  }

  private getListaTabelaPreco() {
    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.listaTabelaPrecoService
      .getListaTabelaPrecos(envio)
      .subscribe((element: any) => {
        this.select.tabelaPreco = element.data.map((item: any) => {
          return {
            label: `${item.codigoTabela}-${item.descricaoTabela}`,
            value: item.codigoTabela,
          } as PoSelectOption;
        });
      });
  }

  public calculaTotal() {
    const nonDeletedItems = this.table.items.filter(
      (item) => item.AUTDELETA === 'N'
    );
    this.totalItems = nonDeletedItems.reduce(
      (total, element) => element.C6_QTDVEN + total,
      0
    );
    const somaTotalProdutos = nonDeletedItems.reduce(
      (total, element) => element.C6_VALOR + total,
      0
    );
    const salesTax = this.controls.C5_TOTAL.value;
    const freight = this.controls.C5_FRETE.value ?? 0;
    const packingFee = this.controls.C5_DESPESA.value ?? 0;
    const sum = freight + salesTax + somaTotalProdutos + packingFee;
    this.controls.TOTAL.setValue(sum);
    this.grandTotal = sum;
    this.controls.totalDue.setValue(sum - this.controls.valorRecebido.value);
  }

  private verifyFormAndSave(form: any) {
    const invalidForm = this.utilService.findInvalidControls(form.controls);
    if (invalidForm.length > 0) {
      invalidForm.map((data) => {
        const labels = [
          {
            C6_PRODUTO: 'Product',
            C6_DESCRI: 'Description',
            C6_QTDVEN: 'Qty',
            C6_PRCVEN: 'Price',
            C6_VALOR: 'Total',
            C6_LOCAL: 'Warehouse',
            QTDESTOQUE: 'Stock',
            C6_TES: 'Outflow Type'
          },
        ];
        labels.forEach((item: any) => {
          Object.keys(item).forEach((valor) => {
            if (valor === data) {
              return this.notificationService.warning(
                `Field: ${item[data]} is invalid, please check and try again!`
              );
            }
          });
        });
      });
    } else {
      if (this.tipoModal === 1) {
        this.table.items = this.utilService.spreadArrayToProperty(this.table.items,form.value);
        form.reset();
      } else {
        const filtered = this.table.items.filter(
          (valor) => valor.C6_ITEM === form.controls.C6_ITEM.value
        );
        const formValue = this.modalForm.value;
        filtered.map((item) => {
          item.AUTDELETA = formValue.AUTDELETA;
          item.C6_DESCRI = formValue.C6_DESCRI;
          item.C6_ITEM = formValue.C6_ITEM;
          item.C6_LOCAL = formValue.C6_LOCAL;
          item.C6_PRCVEN = formValue.C6_PRCVEN;
          item.C6_PRODUTO = formValue.C6_PRODUTO;
          item.C6_QTDVEN = formValue.C6_QTDVEN;
          item.C6_VALOR = formValue.C6_VALOR;
          item.C6_XOBS = formValue.C6_XOBS;
          item.QTDESTOQUE = formValue.QTDESTOQUE;
          item.QTDRES = formValue.QTDRES;
          item.recno = formValue.recno ?? 0;
          item.C6_TES = formValue.C6_TES;
        });
      }
      this.houveAlteracao = true;
      this.poModal.close();
    }
    this.calculaTotal();
  }

  public editItem(item: any) {
    this.disabledFieldsModalForm = true;
    this.poModal.title = 'Edit';
    this.modalForm.patchValue(item);
    this.tipoModal = 2;
    this.getListaWarehouseProdutos(() => {
      this.getF4EstoqueProduto(item.C6_PRODUTO);
    });
    this.poModal.open();
  }

  public confirmDeleteItem(item: any) {
    const action = item.AUTDELETA === 'N' ? 'delete' : 'undo';
    if (action === 'undo') {
      this.deleteItem(item, action);
    } else {
      this.dialog.confirm({
        confirm: () => this.deleteItem(item, action),
        title: 'Attention',
        message: 'Are you sure you want to delete this item?',
        literals: { cancel: 'No', confirm: 'Yes' },
      });
    }
  }

  public disabledUndoAction(item: any) {
    if (item.AUTDELETA === 'N') {
      return true;
    } else {
      return false;
    }
  }

  public disabledDeleteAction(item: any) {
    if (item.AUTDELETA === 'S') {
      return true;
    } else {
      return false;
    }
  }

  public deleteItem(item: any, action: string) {
    this.loading = true;
    setTimeout(() => {
      if (action === 'delete') {
        item.AUTDELETA = 'S';
        this.notificationService.success(
          'Item deleted successfully, when you click save this action will be performed.'
        );
      } else {
        item.AUTDELETA = 'N';
        this.notificationService.success(
          'Exclusion of the successfully undone article.'
        );
      }
      this.calculaTotal();
      this.houveAlteracao = true;
      this.loading = false;
    }, 300);
  }

  public calcTotalItem() {
    const controls = this.modalForm.controls;
    // if (controls.C6_QTDVEN.value > controls.QTDESTOQUE.value) {
    //   return this.notificationService.warning(`The maximum quantity allowed is: : ${controls.QTDESTOQUE.value} `);
    // } else {
    const totalItem = controls.C6_QTDVEN.value * controls.C6_PRCVEN.value;
    controls.C6_VALOR.setValue(totalItem);
    // }
  }

  public openModal() {
    this.poModal.title = 'Add';
    this.modalForm.reset();
    this.createModalForm();
    this.retornaSequencial(
      this.table.items,
      'C6_ITEM',
      this.modalForm.controls
    );
    this.modalForm.controls.recno.setValue(0);
    this.disabledFieldsModalForm = false;
    this.tipoModal = 1;
    this.tableF4EstoqueProduto.items = [];
    this.poModal.open();
  }

  public retornaSequencial(tableItems: any, property: any, form: any) {
    if (this.tipoModal === 1 && tableItems.length <= 0) {
      const sequencia = 1;
      const formatado = sequencia.toString().padStart(2, '0');
      form[property].setValue(formatado);
    } else {
      const table = tableItems;
      table.sort((a: any, b: any) => {
        if (a[property] < b[property]) {
          return -1;
        } else if (a[property] > b[property]) {
          return 1;
        } else {
          return 0;
        }
      });

      const ultimoItem = table[table.length - 1][property];
      const sequencia = parseInt(ultimoItem, 10) + 1;
      const formatado = sequencia.toString().padStart(2, '0');
      form.C6_ITEM.setValue(formatado);
    }
  }

  public createModalForm(): UntypedFormGroup {
    return this.fb.group({
      C6_ITEM: [0, []],
      C6_PRODUTO: ['', [Validators.required]],
      C6_DESCRI: ['', [Validators.required]],
      C6_QTDVEN: [0, [Validators.required]],
      C6_PRCVEN: [0, [Validators.required]],
      C6_QTDENT: [0, []],
      C6_VALOR: [0, [Validators.required]],
      QTDESTOQUE: [0, []],
      QTDRES: [0, []],
      C6_LOCAL: ['', [Validators.required]],
      C6_XOBS: ['', []],
      AUTDELETA: ['N', [Validators.required]],
      recno: [0, []],
      C6_TES: ['', Validators.required]
    });
  }

  public searchList() {
    this.searchListPromise((status: any, error: any, mensagem: any) => {
      if (error === true) {
        this.notificationService.error(mensagem);
      } else if (status === false) {
        this.notificationService.warning(mensagem);
      }
    });
  }

  public searchListPromise(callback?: any) {
    this.tableSearchPopup.loading = true;
    const envio = {
      procedure: true,
      data: this.tableSearchPopup.fieldValue ?? {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.productsService.getProdutos(envio).subscribe({
      next: (data: any) => {
        if (data !== null) {
          this.tableSearchPopup.items = data.data.data;
        }
        this.tableSearchPopup.loading = false;
        if (typeof callback === 'function') {
          callback(true, false, 'succefuly');
        }
      },
      error: (error: any) => {
        this.tableSearchPopup.loading = false;
        if (typeof callback === 'function') {
          callback(false, true, error);
        }
      }
    });
  }

  public openModalList(field: any) {
    this.loading = true;
    this.formValue = {};
    this.tableSearchPopup.field = field;
    this.tableSearchPopup.items = [];
    this.titlePopup = `Search Product`;
    this.searchListPromise((status: any, error: any, mensagem: any) => {
      if (status) {
        this.loading = false;
        this.poModalSearch.open();
      } else {
        if (error === true) {
          this.notificationService.error(mensagem);
        } else if (status === false) {
          this.notificationService.warning(mensagem);
        }
        this.loading = false;
      }
    });
  }

  public selectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
  }

  public unselectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
  }

  public setListItem() {
    if (this.produtoSelecionado['$selected']) {
      this.modalForm.controls.C6_PRODUTO.setValue(
        this.produtoSelecionado['codigo']
      );
      this.modalForm.controls.C6_DESCRI.setValue(
        this.produtoSelecionado['descricao']
      );
      this.modalForm.controls.C6_PRCVEN.setValue(
        this.produtoSelecionado['precoTabela']
      );
      this.modalForm.controls.AUTDELETA.setValue('N');
      this.modalForm.controls.C6_LOCAL.setValue(
        this.produtoSelecionado['warehouse']
      );
      this.tableSearchPopup.fieldValue = {};
      this.getListaWarehouseProdutos(() => {
        this.getF4EstoqueProduto(this.produtoSelecionado['codigo']);
      });
      this.poModalSearch.close();
    } else {
      this.notificationService.warning('Please select a option');
    }
  }

  public selecionarItemLista(param: any) {
    this.modalForm.controls.grupo.setValue(param.grupo);
    this.poModalSearch.close();
  }

  public limparField(controls: any) {
    controls.C6_PRODUTO.setValue('');
    controls.C6_DESCRI.setValue('');
    controls.C6_QTDVEN.setValue(0);
    controls.C6_PRCVEN.setValue(0);
    controls.QTDESTOQUE.setValue(0);
    controls.C6_LOCAL.setValue('01');
    this.calcTotalItem();
  }

  public selectProd(event: any) {
    return (this.produtoSelecionado = event);
  }

  public unselectedProd() {
    // return (this.produtoSelecionado = new ModalProdutos());
  }

  public warehouseChange(event: any) {
    if (event) {
      const warehouseSelected = this.warehouseList.filter(
        (item: any) => item.B2_LOCAL === event
      )[0] as any;
      this.modalForm.controls.C6_LOCAL.setValue(warehouseSelected.B2_LOCAL);
      this.modalForm.controls.QTDRES.setValue(warehouseSelected.B2_RESERVA);
      this.modalForm.controls.QTDESTOQUE.setValue(warehouseSelected.QTDDISP);
    }
  }

  private createColumnsF4Estoque(table: any, line: any) {
    this.modalActionsF4Estoque.title = table;
    this.talbeActionF4Estoque.columns = [];
    this.talbeActionF4Estoque.items = [];
    if (table === 'Sales Orders') {
      this.talbeActionF4Estoque.columns = [
        { label: 'Order', property: 'numeroPedido', width: '10%' },
        { label: 'Trading Name', property: 'nomeFantasia', width: '25%' },
        { label: 'Sales Rep.', property: 'nomeVendedor', width: '25%' },
        { label: 'Quantity', property: 'quantidade', width: '15%' },
        { label: 'Oper Type', property: 'operType', width: '25%' },
      ];
      this.getOpenSalesOrders(line.B2_COD, line.B2_LOCAL);
    } else if (table === 'Reserved') {
      this.talbeActionF4Estoque.columns = [
        { label: 'Type', property: 'tipoReserva', width: '25%' },
        {
          label: 'Reservation Number',
          property: 'numeroReserva',
          width: '25%',
        },
        { label: 'Quantity', property: 'quantidadeReservada', width: '25%' },
        {
          label: 'Emission',
          property: 'emissao',
          width: '25%',
          type: 'date',
          format: 'MM/dd/yyyy',
        },
      ];
      this.getReservas(line.B2_COD, line.B2_LOCAL);
    } else if (table === 'Consigned') {
      this.talbeActionF4Estoque.columns = [
        { label: 'Customer', property: 'codigoCliente', width: '15%' },
        { label: 'Trading Name', property: 'nomeCliente', width: '30%' },
        { label: 'Order', property: 'numeroPedido', width: '10%' },
        { label: 'Invoice', property: 'numeroNF', width: '20%' },
        { label: 'Qty. Shipped', property: 'quantidadeEnviada', width: '15%' },
        { label: 'Balance', property: 'saldoAberto', width: '15%' },
      ];
      this.getConsignacoesAberto(line.B2_COD, line.B2_LOCAL);
    } else if (table === 'Purchase Order') {
      this.talbeActionF4Estoque.columns = [
        { label: 'Import', property: 'poNumber', width: '20%' },

        { label: 'Warehouse', property: 'warehouse', width: '20%' },
        { label: 'Sales Order', property: 'C7_SO', width: '20%' },

        { label: 'Quantity', property: 'qty', width: '20%' },
        {
          label: 'ETA',
          property: 'etaa',
          width: '20%',
          type: 'date',
          format: 'MM/dd/yyyy',
        },
      ];
      this.getImportacaoBuscaProduto(line.B2_COD);
    }
    this.modalActionsF4Estoque.open();
    this.modalActionsF4Estoque.primaryAction!.label = 'Close';
  }

  public returnBooleanForPoUi(bool: Boolean): string {
    let retorno: string;
    if (bool) {
      retorno = 'true';
    } else {
      retorno = 'false';
    }
    return retorno;
  }

  public openModalClientes() {
    this.modalClientes.open();
    this.getRelacaoClientes();
  }

  public setValueCliente(cliente: any) {
    this.controls.customer.setValue(`${cliente.codigo}-${cliente.loja}-${cliente.nomeFantasia}`);
    this.controls.C5_CLIENTE.setValue(cliente.codigo);
    this.controls.C5_LOJACLI.setValue(cliente.loja);
    this.controls.C5_CLIENT.setValue(cliente.codigo);
    this.controls.C5_LOJAENT.setValue(cliente.loja);
    this.modalClientes.close();
  }

  public clearCustomer() {
    this.controls.customer.setValue('Please select a new Customer');
    this.controls.C5_CLIENTE.setValue('');
    this.controls.C5_LOJACLI.setValue('');
    this.controls.C5_CLIENT.setValue('');
    this.controls.C5_LOJAENT.setValue('');
  }

  public pageChangeModalClientes(page: number) {
    this.modalClientesProperties.pagination.page = page;
    this.getRelacaoClientes();
  }

  private saveDispatch() {
    const cabecForm = this.controls;
    const itens = this.table.items.filter(
      (item) =>
        (item.recno !== 0 && item.AUTDELETA === 'N') ||
        (item.recno === 0 && item.AUTDELETA === 'N') ||
        (item.recno !== 0 && item.AUTDELETA === 'S')
    );
    itens.forEach((item) => {
      item.tabela = 'SC6';
    });
    const obj = {
      cabec: {
        C5_NUM: cabecForm.C5_NUM.value,
        C5_FRETE: cabecForm.C5_FRETE.value,
        C5_CLIENTE: cabecForm.C5_CLIENTE.value,
        C5_LOJACLI: cabecForm.C5_LOJACLI.value,
        C5_CLIENT: cabecForm.C5_CLIENT.value,
        C5_LOJAENT: cabecForm.C5_LOJAENT.value,
        C5_TPFRETE: cabecForm.C5_TPFRETE.value,
        C5_TABELA: cabecForm.C5_TABELA.value,
        C5_VEND1: cabecForm.C5_VEND1.value,
        C5_VEND2: cabecForm.C5_VEND2.value,
        C5_CONDPAG: cabecForm.C5_CONDPAG.value,
        C5_XSHIPTO: cabecForm.C5_XSHIPTO.value,
        C5_XENDENT: cabecForm.C5_XENDENT.value,
        C5_DESPESA: cabecForm.C5_DESPESA.value,
        C5_XSHPZIP: cabecForm.C5_XSHPZIP.value,
        C5_XSHPEMA: cabecForm.C5_XSHPEMA.value,
        C5_XENDEN2: cabecForm.C5_XENDEN2.value,
        C5_XSHPUF: cabecForm.C5_XSHPUF.value,
        C5_XDESPAC: cabecForm.C5_XDESPAC.value,
        C5_TRANSP: cabecForm.FreighForwarder.value,
        C5_XCOLEDA: cabecForm.C5_XCOLEDA.value,
        C5_XCOLENA: cabecForm.C5_XCOLENA.value,
        C5_XNOTE: cabecForm.C5_XNOTE.value,
        C5_XTRACNU: cabecForm.C5_XTRACNU.value,
        C5_VOLUME1: cabecForm.C5_VOLUME1.value,
        C5_ESPECI1: cabecForm.C5_ESPECI1.value,
        C5_PBRUTO: cabecForm.C5_PBRUTO.value,
        C5_PESOL: cabecForm.C5_PESOL.value,
        C5_XOBSCOM: cabecForm.C5_XOBSCOM.value,
        C5_XFIN: cabecForm.C5_XFIN.value,
        C5_XOBSFEI: cabecForm.C5_XOBSFEI.value,
        C5_XOBSGC1: cabecForm.C5_XOBSGC1.value,
        C5_XOBSGC2: cabecForm.C5_XOBSGC2.value,
        C5_XCODSTA: cabecForm.C5_XCODSTA.value,
        C5_XLOG: cabecForm.C5_XLOG.value,
        C5_XPO: cabecForm.C5_XPO.value,
        recno: cabecForm.recno.value,
        C5_OPER: cabecForm.C5_OPER.value,
        tabela: 'SC5',
        ITEM_ALTERADO: this.houveAlteracao,
      },
      itens,
    };
    const envio = {
      procedure: false,
      data: obj,
      metodo: '',
    } as EnvioPadraoBackend
    if (this.controls.C5_VEND1.value !== this.controls.C5_VEND2.value) {
      this.loading = true;
      this.gravadadosService.gravadados(envio).subscribe({
        next: (element: any) => {
          if (Object.keys(element).indexOf('error') !== -1) {
            this.notificationService.warning('Error, please try again');
          } else {
            if (element.gravado === 0) {
              this.notificationService.error(element.mensagem);
            } else {
              this.notificationService.success(element.mensagem);
              this.getOrder(this.form.controls['recno'].value)
              // this.location.back();
            }
          }
          this.loading = false;
        },
        error: (error) => {
          console.error(error);
          this.notificationService.error('Error while saving'),
            (this.loading = false);
        }
      });
    } else {
      this.notificationService.warning('Please select different Sales Rep.');
    }
  }

  private getListaTes() {
    this.loading = true;
    this.listaTESService.getLista({ procedure: true, data: { tipo: 'S' } })
      .subscribe({
        next: (element) => {
          this.select.tes = element.data.map((item) => {
            return { label: `${item.F4_CODIGO} - ${item.F4_TEXTO} - ${item.F4_ESTOQUE} - ${item.F4_DUPLIC} - ${item.TAX}%`, value: item.F4_CODIGO } as PoSelectOption
          })
          this.loading = false
        },
        error: (error: HttpErrorResponse) => {
          console.error(error.error);
          this.loading = false
        }
      })
  }
}
