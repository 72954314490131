import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { F3ClientesService } from 'src/app/service/f3-clientes/f3-clientes.service';

@Injectable({
  providedIn: 'root'
})
export class ListaClientesResolver implements Resolve<Observable<any>> {
  constructor (private f3Cliente: F3ClientesService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<Observable<any>>{
    return this.f3Cliente.getClientes({limit: 9999});
  }
}
