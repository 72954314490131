import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { ListaFeirasService } from 'src/app/service/lista-feiras/lista-feiras.service';

@Injectable({
  providedIn: 'root'
})
export class ListaFeirasResolver implements Resolve<Observable<any>> {
  constructor (private listaFeirasService: ListaFeirasService) {}
  resolve(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<any> | Promise<Observable<any>>{
    return this.listaFeirasService.getListaFeiras();
  }
}
