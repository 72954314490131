import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface listaTESReturn {
  data: Array<{
    F4_CODIGO: string,
    F4_DUPLIC: string,
    F4_ESTOQUE: string,
    F4_TEXTO: string,
    TAX: number
  }>,
  mensagem: string,
  status: boolean
}

@Injectable({
  providedIn: 'root'
})
export class ListaTESService {

  constructor(
    private http:HttpClient
  ) { }

  private readonly apiRoot = `${environment.URL.APIPRIVATE}/v1/lista-tes`;

  /**
   * 
   * @param parameters tipo: 'S' saída, 'E' entrada
   * @returns 
   */
  public getLista(parameters: {
    procedure: boolean,
    homologacao?: boolean,
    data: {
      tipo: 'S' | 'E'
    }
  }): Observable<listaTESReturn> {
    parameters.homologacao = !environment.production;
    return this.http.post(this.apiRoot, parameters) as Observable<listaTESReturn>
  }
}
