import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PoNotificationService,
  PoPageAction,
  PoTableColumn,
} from '@po-ui/ng-components';
import {
  GoSalesService,
  quotesDetailInterface,
  quotesInterface,
} from 'src/app/service/goSales/go-sales.service';
import { UtilService } from 'src/app/service/util/util.service';

@Component({
  selector: 'app-generic-quotes',
  templateUrl: './generic-quotes.component.html',
  styleUrls: ['./generic-quotes.component.css'],
})
export class GenericQuotesComponent implements OnInit {
  constructor(
    private notificationService: PoNotificationService,
    private goSalesService: GoSalesService,
    private fb: FormBuilder,
    private utilService: UtilService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  public isLoading: boolean = false;
  public idGlobal: number = 0;

  public form: FormGroup = this.fb.group({
    apelido: [''],
    cnpjCpf: [''],
    codCliente: [''],
    codRepresentante: [''],
    dataEmissao: [''],
    idLicenca: [''],
    idPedido: [''],
    razaoSocial: [''],
    total: [''],
  });

  public page = {
    title: 'Quote Details',
    breadcrumb: {
      items: [
        {
          label: 'Home',
        },
        {
          label: 'goSales',
        },
        {
          label: 'Quotes',
        },
        {
          label: 'Quote Details',
        },
      ] as Array<any>,
    },
    actions: [
      {
        label: 'Back',
        icon: 'po-icon-exit',
        action: () => {
          this.voltarListagem();
        },
      },
    ] as Array<PoPageAction>,
  };

  public table = {
    columns: [
      { label: 'Code', property: 'codProduto', width: '10%' },
      { label: 'Description', property: 'descricao', width: '25%' },
      { label: 'Quantity', property: 'qtdVenda', width: '10%' },
      { label: 'Price List Value', property: 'valorTabela', width: '15%' },
      { label: 'Unit Value', property: 'valorUnitario', width: '10%' },
      { label: '% Discount', property: 'percDesconto', width: '10%' },
      { label: 'Amount', property: 'totalItem', width: '10%' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.5),
  };

  async ngOnInit() {
    this.activatedRoute.paramMap.subscribe((params) => {
      if (params.has('id')) {
        this.idGlobal = parseInt(params.get('id') ?? '');
        this.obter(this.idGlobal);
      }
    });
  }

  /**
   * @param quoteId id do pedido
   * @description Busca um pedido pelo id
   */
  public obter(quoteId: number) {
    try {
      this.isLoading = true;

      const obj = {
        quoteId,
      };

      this.goSalesService.getQuoteDetail(obj).subscribe({
        next: (data: quotesDetailInterface) => {
          try {
            if (data.status) {
              this.form.patchValue(data.response);
              this.table.items = data.response.itens ?? [];
              if (this.table.items.length > 0) {
                this.setAmount();
              }
            } else {
              this.notificationService.warning(data.mensagem);
            }
            this.isLoading = false;
          } catch (error: any) {
            this.notificationService.error(error);
            this.isLoading = false;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.notificationService.error(error.error);
          this.isLoading = false;
        },
      });
    } catch (error: any) {
      this.notificationService.error(error);
    }
  }

  /**
   * @function voltarListagem()
   * @description irá direcionar o usuário a listagem
   */
  private voltarListagem() {
    try {
      this.router.navigate([`../../`], {
        relativeTo: this.activatedRoute,
      });
    } catch (error: any) {
      this.notificationService.error(error);
    }
  }

  /**
   * @description Método responsável por realizar a soma dos valores dos itens.
   */
  public setAmount() {
    try {
      const total = this.table.items
        .reduce((acc, item) => acc + item.valorUnitario, 0)
        .toFixed(2);

      this.form.controls['total'].patchValue(total);
    } catch (error: any) {
      this.notificationService.error(error);
    }
  }
}
