<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb">
    <div class="po-row" [formGroup]="form" (keyup.enter)="getCustomers(this.form.value)">
        <div class="po-md-9">
            <po-input class="po-md-4" p-label="Code" formControlName="codigoCliente"></po-input>
            <po-input class="po-md-4" p-label="Name" formControlName="razaoSocial"></po-input>
            <po-input class="po-md-4" p-label="Short Name" formControlName="nomeFantasia"></po-input>
            <po-combo class="po-md-4" p-label="Rep" formControlName="codRepresentante" [p-filter-mode]="filterMode"
                [p-options]="select.representante">
            </po-combo>

            <po-combo class="po-md-4" p-label="Country" formControlName="pais" [p-filter-mode]="filterMode" [p-options]="listaCountries"></po-combo>
            <po-input class="po-md-4" p-label="State" formControlName="uf"></po-input>

        </div>
        <div class="po-md-3">
            <po-select class="po-md-6" p-label="Status" [p-options]="select.bloqueado" formControlName="bloqueado">
            </po-select>
            <po-button class="po-md-6 po-mt-4" p-type="danger" p-label="Clear" p-icon="po-icon-delete" (p-click)="clearSearch()"
                ></po-button>
                <po-button class="po-md-12 po-mt-4" p-type="primary" p-label="Search" p-icon="po-icon-search"
            (p-click)="getCustomers(this.form.value)"></po-button>
        </div>
    </div>
    <po-table id="hideSecondScrollTable" [p-selectable]="true" [p-single-select]="true" [p-striped]="true" [p-sort]="true"
        class="po-md-12" [p-hide-text-overflow]="true" [p-columns]="table.columns" [p-items]="table.items"
        [p-height]="table.height">
    </po-table>
    <app-pagination class="po-md-12" (totalItems)="pagination.total" [itemsPerPage]="pagination.limit"
        (currentPage)="pagination.page" (pageChanged)="pageChange($event)"></app-pagination>
</po-page-default>

<po-loading-overlay p-text="Loading" [p-screen-lock]="true" *ngIf="loading"></po-loading-overlay>
