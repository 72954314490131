import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PoBreadcrumbItem, PoPageAction, PoPageDefault, PoSelectOption } from '@po-ui/ng-components';
import { PoNotificationService } from '@po-ui/ng-components';
import { __assign } from 'tslib';
import { PoBreadcrumb } from '@po-ui/ng-components';
import { RoleService } from 'src/app/service/role/role.service';
import { UserService } from 'src/app/service/user/user.service';
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.css']
})
export class UserEditComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private notification: PoNotificationService,
    private roleService: RoleService,
  ) { }


  page = {
    title: 'Users',
    breadcrumb: {
      items: [
        { label: 'Home' },
        { label: 'Settings' },
        { label: 'Users' }
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [
      { label: 'Save', icon: 'po-icon po-icon-ok', action: () => { this.updateUser(); } },
      { label: 'Cancel', action: () => { this.router.navigate([this.relativeLink]); } }
    ] as Array<PoPageAction>,
  };


  get controls() {
    return this.userEditForm.controls;
  }

  public isDisabled: boolean = false;
  public loading = false;
  private relativeLink = '/settings/users';
  public roleOptions: any;
  public selects = {
    marcas: [] as PoSelectOption[]
  };


  userEditForm: UntypedFormGroup = this.fb.group({
    // adm: [false],
    active: ['', [Validators.required]],
    created: ['', [Validators.required]],
    id: [, [Validators.required]],
    modified: ['', [Validators.required]],
    name: ['', [Validators.required]],
    role_id: ['', [Validators.required]],
    username: ['', [Validators.required]],
    avatar: [''],
    id_marca: ['', []],
    is_representante: ['', [Validators.required]],
    id_protheus: ['', [Validators.required]],
    email: [''],
    password: ['', []]
  });

  ngOnInit() {
    this.loading = true;
    this.route.paramMap
      .subscribe((params: ParamMap) => {
        if (this.router.url.indexOf('view') !== -1) {
          this.page.title = 'View user';
          this.page.breadcrumb.items.push({ label: 'View User' });
          this.page.actions[0].disabled = true;
          this.isDisabled = true;
        } else {
          this.page.title = 'Edit User';
          this.page.breadcrumb.items.push({ label: 'Edit User' });
          this.isDisabled = false;
        }

        const userId = parseInt(params.get('user_id') as string);
        this.getRoles();
        this.getUser(userId);
      });

  }

  private getRoles() {
    this.roleService
      .getAllRoles()
      .subscribe({
        next: (data: any) => {
          if (data) {
            const retorno = data.data
              .map((item: { name: any; id: any; }) => {
                return { label: item.name, value: item.id };
              });
            this.roleOptions = retorno;
          }
      }, error: (err: any) => {
        console.error(err);
      }
    });
  }

  private getUser(userId: any) {
    this.userService
      .getUser(userId)
      .subscribe({
        next: (data: any) => {
        const value = data.data.response;
        value.password = '';
        this.userEditForm.patchValue(value);
        this.loading = false;
      },
       error: (error: any) => {
          this.notification.error('An error has occured');
          this.loading = false;
          this.router.navigate([this.relativeLink]);
        }
      });
  }

  private updateUser() {
    if (this.userEditForm.valid) {
      this.loading = true;
      this.userService.addUser(this.userEditForm.value).subscribe({
        next: (element: any) => {
        this.notification.success(`User - ${this.controls.username.value} successfully updated`);
        this.router.navigate([this.relativeLink]);
        this.loading = false;
      }, error: (error: any) => {
        this.loading = false
        this.notification.error('Error while updating user')
      }
    })
    }
  }

}
