import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PurchaseRoutingModule } from './purchase-routing.module';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { PoAccordionModule, PoButtonModule, PoContainerModule, PoDividerModule, PoDynamicModule, PoFieldModule, PoInfoModule, PoLoadingModule, PoModalModule, PoPageModule, PoTableModule, PoTabsModule } from '@po-ui/ng-components';
import { PurchaseOrderEditComponent } from './purchase-order/purchase-order-edit/purchase-order-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalComponentsModule } from '../global-components/global-components.module';
import { WireTransferComponent } from './wire-transfer/wire-transfer.component';
import { WireTransferEditComponent } from './wire-transfer/wire-transfer-edit/wire-transfer-edit.component';


@NgModule({
  declarations: [
    PurchaseOrderComponent,
    PurchaseOrderEditComponent,
    WireTransferComponent,
    WireTransferEditComponent
  ],
  imports: [
    CommonModule,
    PurchaseRoutingModule,
    PoPageModule,
    PoTableModule,
    PoFieldModule,
    PoLoadingModule,
    PoContainerModule,
    PoInfoModule,
    FormsModule,
    GlobalComponentsModule,
    ReactiveFormsModule,
    PoTabsModule,
    PoDynamicModule,
    PoDividerModule,
    PoButtonModule,
    PoModalModule,
    PoAccordionModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PurchaseModule { }
