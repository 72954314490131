import { Component, OnInit, Input, Output } from '@angular/core';
import { PoButtonGroupItem } from '@po-ui/ng-components';
import { EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UtilService } from 'src/app/service/util/util.service';



export interface Pagination {
  currentPage: number,
  totalItems: number,
  itemsPerPage: number
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private utilService: UtilService
  ) { }

  /** Total number of pages */
  totalPages = 0 as number;

  /** Buttons */
  previousButton: PoButtonGroupItem = {
    icon: 'po-icon po-icon-arrow-left', label: 'Previous',
    action: () => { this.changePage(this.currentPage - 1); }
  };

  nextButton: PoButtonGroupItem = {
    icon: 'po-icon po-icon-arrow-right', label: 'Next',
    action: () => { this.changePage(this.currentPage + 1); }
  };

  firstButton: PoButtonGroupItem = {
    icon: 'po-icon po-icon-first-page', label: 'First',
    action: () => { this.changePage(this.currentPage = 1); }
  };

  lastButton: PoButtonGroupItem = {
    icon: 'po-icon po-icon-last-page', label: 'Last',
    action: () => { this.changePage(this.currentPage = this.totalPages); }
  };

  buttons: PoButtonGroupItem[] = [
    this.firstButton, this.previousButton
  ];

  newButtons: PoButtonGroupItem[] = [
    this.nextButton, this.lastButton
  ];

  /** Props */

  /** Total number of items */
  private countTotalItems = 1;

  // get total number of items
  get totalItems(): number {
    return this.countTotalItems;
  }

  // set total number of items
  @Input()
  set totalItems(items: number) {
    this.countTotalItems = items;
    this.totalPages = this.calculatePages();
  }

  /** Items per page */
  private countItemsPerPage = 0 as number;

  get itemsPerPage(): number {
    return this.countItemsPerPage;
  }

  @Input()
  set itemsPerPage(item) {
    this.countItemsPerPage = item;
    this.totalPages = this.calculatePages();
  }

  /** Current page */
  @Input() currentPage: number = 1;

  /** Event emitted on page change */
  @Output() pageChanged: EventEmitter<number> = new EventEmitter<number>();

  /**
   *
   * disabled button changes pages
   *
   */
  @Input()
  set disabled(value: boolean) {
    this.nextButton.disabled = value;
    this.previousButton.disabled = value;

    this.firstButton.disabled = value;
    this.lastButton.disabled = value;

    // verifica as páginas
    if ((this.totalPages && this.currentPage === 1) || value === false) {
      this.isFirstPage(this.currentPage);
      this.isLastPage(this.currentPage);
    }
  }

  changePage(item: any) {
    this.isFirstPage(item);
    this.isLastPage(item);
    this.currentPage = item;
    this.pageChanged.next(this.currentPage);
  }

  isFirstPage(item: any) {
    if (item === 1) {
      this.previousButton.disabled = true;
      this.firstButton.disabled = true;
    } else {
      this.previousButton.disabled = false;
      this.firstButton.disabled = false;
    }
  }

  isLastPage(item: any) {
    if (item === this.totalPages) {
      this.nextButton.disabled = true;
      this.lastButton.disabled = true;
    } else {
      this.nextButton.disabled = false;
      this.lastButton.disabled = false;
    }
  }

  calculatePages() {
    if (this.itemsPerPage >= this.totalItems) {
      return 1;
    } else {
      return Math.ceil((this.totalItems / this.itemsPerPage));
    }

  }


  ngOnInit() {
    this.isFirstPage(this.currentPage);
    this.isLastPage(this.currentPage);
    this.totalPages = this.calculatePages();

    this.utilService.emitTotalItems
      .subscribe((data: any) => {
        this.totalItems = data;
        if (this.totalPages && this.currentPage === 1) {
          this.isFirstPage(this.currentPage);
          this.isLastPage(this.currentPage);
        }
      });


  }

  paginaForm: UntypedFormGroup = this.fb.group({
    pagina: ['',]
  });

  // goTo(item) {
  //   if (this.paginaForm.controls.pagina.value <= this.totalPages) {
  //     this.currentPage = this.paginaForm.controls.pagina.value;
  //     this.isLastPage(item)
  //   }
  //   else {
  //     this.notificationService.warning('Valor inserido não atende as restrições');
  //     return false
  //   }
  // }




}
