<po-page-default p-title="Add Role" [p-actions]="actions" [p-breadcrumb]="breadcrumb">
    <po-divider p-label="Info"></po-divider>

    <form [formGroup]="addRoleForm">
        <div class="po-row">
            <po-input p-required="true" class="po-md-6" formControlName="name" p-label="Name" p-error-pattern="Only letters and without spaces!"></po-input>
            <po-select class="po-md-3" formControlName="active" p-label="Active" [p-options]="activateOptions"></po-select>
        </div>
    </form>



</po-page-default>