<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb">
  <form [formGroup]="form">
    <po-tabs>
      <po-tab p-label="Information" [p-active]="true">
        <div class="po-row">
          <po-container class="po-md-12" [p-no-border]="true">
            <po-select [p-disabled]="blockAllFields || disableCreate"  formControlName="A1_MSBLQL" [p-options]="select.A1_MSBLQL"
              class="po-md-3" p-label="Status *" p-pattern="^.{1,1}$" p-clean ></po-select>
            <po-input formControlName="A1_COD" class="po-md-3" p-label="Code" p-disabled="true"
              p-error-pattern="The field check number must be a string with a maximum length of '6'.">
            </po-input>
            <po-input p-disabled="true" formControlName="A1_LOJA" class="po-md-3" p-label="Unit" p-pattern="^.{1,4}$"
              p-error-pattern="The field check number must be a string with a maximum length of '2'.">
            </po-input>
            <po-select [p-disabled]="blockAllFields" formControlName="A1_PESSOA" [p-options]="select.A1_PESSOA"
              class="po-md-3" p-pattern="^.{1,1}$" p-label="Indiv/Comp *" p-clean ></po-select>
          </po-container>
          <po-container class="po-md-12" [p-no-border]="true">
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_NOME" class="po-md-3" p-label="Name *"
              p-pattern="^.{1,40}$"
              p-error-pattern="The field check number must be a string with a maximum length of '40'." p-clean
              >
            </po-input>
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_NREDUZ" class="po-md-3" p-label="Short Nm. *"
              p-pattern="^.{1,20}$"
              p-error-pattern="The field check number must be a string with a maximum length of '20'." p-clean
              ></po-input>
            <po-combo [p-disabled]="blockAllFields" formControlName="A1_VEND"
              class="po-md-3" p-label="Sales Rep" p-pattern="^.{1,3}$" [p-options]="listarSalesRep" [p-filter-mode]="filterMode"
              p-error-pattern="The field check number must be a string with a maximum length of '3'." [p-clean]="true"
            ></po-combo>
            <po-datepicker formControlName="A1_ULTCOM" class="po-md-3" p-format="mm/dd/yyyy" p-label="Last Purchase" p-disabled="true">
            </po-datepicker>
          </po-container>
          <po-container class="po-md-12" [p-no-border]="true">
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_CGC" class="po-md-4" p-label="Tax ID"
              p-pattern="^.{1,14}$"
              p-error-pattern="The field check number must be a string with a maximum length of '14'." p-clean
              >
            </po-input>
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_TEL" class="po-md-4" p-label="Phone *" p-mask="999999999999999"
              p-pattern="^.{1,15}$"
              p-error-pattern="The field check number must be a string with a maximum length of '15'." p-clean
              >
            </po-input>
            <po-email [p-disabled]="blockAllFieldsTwo" formControlName="A1_EMAIL" class="po-md-4" p-label="E-mail *"
              p-pattern="^.{1,250}$"
              p-error-pattern="The field check number must be a string with a maximum length of '250'." p-clean
              >
            </po-email>
          </po-container>
        </div>
      </po-tab>
      <po-tab p-label="Others">
        <div class="po-row">
          <po-container class="po-md-12" [p-no-border]="true">
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_END" class="po-md-4" p-label="Address *"
              p-pattern="^.{1,40}$"
              p-error-pattern="The field check number must be a string with a maximum length of '40'." p-clean
              >
            </po-input>
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_COMPLEM" class="po-md-4" p-label="Adress Add"
              p-pattern="^.{1,50}$"
              p-error-pattern="The field check number must be a string with a maximum length of '50'." p-clean
              >
            </po-input>
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_MUN" class="po-md-4" p-label="City *"
              p-pattern="^.{1,50}"
              p-error-pattern="The field check number must be a string with a maximum length of '50'." p-clean
              >
            </po-input>
          </po-container>
          <po-container class="po-md-12" [p-no-border]="true">
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_EST" class="po-md-4" p-label="State *"
              p-pattern="^.{1,2}$"
              p-error-pattern="The field check number must be a string with a maximum length of '2'." p-clean
              >
            </po-input>
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_CEP" class="po-md-4" p-label="ZIP Code *"
              p-pattern="^.{1,8}$"
              p-error-pattern="The field check number must be a string with a maximum length of '8'." p-clean
              >
            </po-input>
            <po-combo [p-disabled]="blockAllFields" formControlName="A1_PAIS"
              class="po-md-4" p-label="Country *" p-pattern="^.{1,3}$" [p-options]="listaCountries" [p-filter-mode]="filterMode"
              p-error-pattern="The field check number must be a string with a maximum length of '3'." [p-clean]="true"
              >
            </po-combo>
          </po-container>
          <po-container class="po-md-12" [p-no-border]="true">
            <po-input [p-disabled]="blockAllFieldsTwo" formControlName="A1_CONTATO" class="po-md-4" p-label="Contact"
              p-clean></po-input>
            <po-select [p-disabled]="blockAllFields" formControlName="A1_TABELA" [p-options]="select.A1_TABELA"
              class="po-md-4" p-label="Price List *" p-pattern="^.{1,3}$"
              p-error-pattern="The field check number must be a string with a maximum length of '3'." p-clean
              >
            </po-select>
            <po-select [p-disabled]="blockAllFields" formControlName="A1_XCATCRM" [p-options]="select.A1_XCATCRM"
              class="po-md-4" p-label="CRM" p-clean>
            </po-select>
          </po-container>
        </div>
      </po-tab>
    </po-tabs>
  </form>
  <po-tabs class="po-md-12 po-mt-2" *ngIf="showTableTabs">
    <po-tab p-label="Sales Representative" [p-active]="true">
      <div class="po-row">
        <po-button [p-disabled]="blockAllFields" (p-click)="openSalesRepModal()" p-label="Add" p-type="primary"
          class="po-md-2"></po-button>
        <po-table class="po-md-12 po-mt-1" [p-sort]="true" [p-striped]="true" p-container="box"
          [p-columns]="tableSalesRep.columns" [p-items]="tableSalesRep.items" [p-height]="tableSalesRep.height"
          [p-actions]="tableSalesRep.actions"
          >
        </po-table>
      </div>
    </po-tab>
    <po-tab p-label="Contacts">
      <po-button [p-disabled]="blockAllFields" p-label="Add" p-type="primary" class="po-md-2"
        (p-click)="modalContacts.open()"></po-button>
      <po-table class="po-md-12 po-mt-1" p-container="box" [p-sort]="true" [p-striped]="true" p-container="box"
        [p-columns]="tableContacts.columns" [p-items]="tableContacts.items" [p-actions]="tableContacts.actions"  [p-height]="tableContacts.height">
      </po-table>
    </po-tab>
    <po-tab p-label="Resale Certificate">
      <po-button [p-disabled]="blockAllFields" p-label="Add" p-type="primary" class="po-md-2"
        (p-click)="modalResaleCertificate.open()"></po-button>
      <po-table class="po-md-12 po-mt-1" p-container="box" [p-sort]="true" [p-striped]="true" p-container="box" [p-hide-text-overflow]="true"
        [p-columns]="tableResaleCertificate.columns" [p-items]="tableResaleCertificate.items"
        [p-height]="tableResaleCertificate.height" [p-actions]="tableResaleCertificate.actions">
      </po-table>
    </po-tab>
    <po-tab p-label="Ocurrences">
      <po-button [p-disabled]="blockAllFields" p-label="Add" p-type="primary" class="po-md-2"
        (p-click)="modalOcurrences.open()"></po-button>
      <po-table class="po-md-12 po-mt-2" p-container="box" [p-sort]="true" [p-striped]="true" p-container="box"  [p-hide-text-overflow]="true"
        [p-columns]="tableOcurrences.columns" [p-items]="tableOcurrences.items" [p-height]="tableOcurrences.height">
      </po-table>
    </po-tab>
  </po-tabs>
</po-page-default>


<!--
  MODAL DE REPRESENTANTES
 -->
<po-modal #modalSalesRep p-title="Sales Rep." p-size="sm">
  <div class="po-row" (keyup.enter)="getRepresentantes(filterSalesRep)">
    <po-input class="po-md-10" p-placeholder="Filter" [(ngModel)]="filterSalesRep"></po-input>
    <po-button p-icon="po-icon-search" p-type="primary" class="po-md-2 po-mt-1"
      (p-click)="getRepresentantes(filterSalesRep)"></po-button>
  </div>
  <po-table class="po-md-12" [p-height]="300" [p-striped]="true" p-container="box" [p-loading]="tableSalesRep.loading"
    [p-columns]="tableSalesRep.columns" [p-items]="tableSalesRep.itemsModal" [p-actions]="tableSalesRep.actionModal"
    [p-height]="tableSalesRep.height"></po-table>
  <po-modal-footer></po-modal-footer>
</po-modal>

<!--
    MODAL DE OCORRENCIAS
   -->

<po-modal #modalOcurrences p-title="Ocurrence" p-size="sm">
  <div class="po-row">
    <!-- <po-select p-placeholder="Type" class="po-md-12" [p-options]="select.tipoOcorrencias"
      [(ngModel)]="modelOcurrences.type"></po-select> -->
    <po-textarea p-placeholder="Ocurrence" class="po-md-12" [p-rows]="6" [(ngModel)]="modelOcurrences.ocurrence"
    p-pattern="^.{1,30}$" p-error-pattern="The field check number must be a string with a maximum length of '30'.">
    </po-textarea>
  </div>
  <po-modal-footer [p-disabled-align]="true">
    <div class="po-row">
      <div class="po-md-10"></div>
      <po-button p-label="Save" p-type="primary" class="po-md-2" (p-click)="criarOcorrencia()"></po-button>
    </div>
  </po-modal-footer>
</po-modal>

<!--
  MODAL RESALE CERTIFICATE
 -->
<po-modal #modalResaleCertificate p-title="Resale Certificate">
  <div class="po-row">
    <div class="po-row">
      <po-input p-required="true" p-label="Resale Certificate Number *" class="po-md-6"
        [(ngModel)]="rCertificate.ZA2_NRCERT"></po-input>
      <po-datepicker p-required="true" p-label="Expiration Date *" class="po-md-6" [(ngModel)]="rCertificate.ZA2_EXPDT" p-locale="en" p-format="mm/dd/yyyy">
      </po-datepicker>
      <po-input p-label="Note" class="po-md-12" [(ngModel)]="rCertificate.ZA2_NOTA"></po-input>
    </div>
    <div *ngIf="!rCertificate.previewFile">
      <po-info p-value="Supported file in PDF/JPG/JPEG/PNG format" id="info-upload"></po-info>
      <po-info
        p-value="The file name cannot contain periods (.) before the extension (.pdf/.jpg/.jpeg/.png) - Example: 'file.name.extension'"
        class="info-upload"></po-info>
    </div>
    <div *ngIf="!rCertificate.previewFile" class="po-row">
      <button type="button" (click)="clickCatalogo.click()" class="btn-upload-catalogo">
        <span class="po-icon po-icon-upload"></span>
        <p class="po-font-text-small">Select a file</p>
      </button>
      <input #clickCatalogo hidden type="file" accept="image/jpeg,image/gif,image/png,application/pdf,image/x-eps"
        (change)="handleFileCertificate($event)">
    </div>
    <div *ngIf="rCertificate.previewFile" class="po-row">
      <div class="po-md-12 po-row">
        <po-info class="po-md-8 po-mt-4" [p-value]="rCertificate.fileName" class="info-upload-file">
        </po-info>
      </div>
    </div>
  </div>
  <po-modal-footer [p-disabled-align]="false">
    <po-button [p-disabled]="!rCertificate.previewFile" p-label="Upload" class="po-md-4 po-sm-12" p-type="primary"
      (p-click)="uploadFile(this.rCertificate.file, this.rCertificate.tipoFile, 0)"></po-button>
    <po-button *ngIf="rCertificate.previewFile" class="po-md-4" p-type="danger" p-icon="po-icon-delete"
      p-label="Delete File" (p-click)="resetRCertificate()">
    </po-button>
  </po-modal-footer>
</po-modal>

<po-modal #modalContacts>
  <div class="po-row">
    <po-input p-label="Contact Name" class="po-md-12" [(ngModel)]="modelContacts.U5_CONTAT"></po-input>
    <po-input p-label="Phone" class="po-md-6" [(ngModel)]="modelContacts.U5_FONE"></po-input>
    <po-email p-label="E-mail" class="po-md-6" [(ngModel)]="modelContacts.U5_EMAIL"></po-email>
  </div>
  <po-modal-footer [p-disabled-align]="false">
    <po-button p-label="Save" class="po-md-4 po-sm-12" p-type="primary" (p-click)="postContato(0)"></po-button>
  </po-modal-footer>
</po-modal>

<po-loading-overlay *ngIf="loading" p-text="Loading" [p-screen-lock]="true"></po-loading-overlay>
