<po-page-default
  [p-title]="page.title"
  [p-breadcrumb]="page.breadcrumb"
  [p-actions]="page.actions"
>
  <div class="po-row" [formGroup]="form">
    <po-datepicker
      p-label="Start Date *"
      class="po-md-3"
      p-locale="en"
      p-format="MM/dd/yyyy"
      p-clean="true"
      p-required="true"
      formControlName="start"
    ></po-datepicker>
    <po-datepicker
      p-label="End Date *"
      class="po-md-3"
      p-locale="en"
      p-format="MM/dd/yyyy"
      p-clean="true"
      p-required="true"
      formControlName="end"
    ></po-datepicker>
    <po-combo
      [p-options]="select.categorias"
      class="po-md-3"
      p-label="Category"
      [p-optional]="true"
      formControlName="categoria"
    ></po-combo>
    <po-combo
      [p-options]="select.estados"
      class="po-md-3"
      p-label="State"
      [p-optional]="true"
      formControlName="uf"
    ></po-combo>
    <po-combo
      [p-options]="select.representantes"
      class="po-md-3"
      p-label="Sales Rep."
      [p-optional]="true"
      formControlName="codigoRepresentante"
    ></po-combo>
    <po-select
      class="po-md-2"
      p-label="Top:"
      [p-optional]="false"
      [p-options]="select.top"
      formControlName="quantidadeItens"
    ></po-select>
    <po-button
      class="po-md-2 po-mt-4"
      p-label="Search"
      p-icon="po-icon-search"
      p-type="primary"
      (p-click)="montaColuna()"
    ></po-button>
  </div>
  <div class="po-row">
    <po-table
      id="hideSecondScrollTable"
      [p-sort]="true"
      [p-striped]="true"
      class="po-md-12"
      [p-columns]="table.columns"
      [p-items]="table.items"
      [p-height]="table.height"
    ></po-table>
    <div class="po-row">
      <hr
        class="po-md-10"
        style="
          height: 2px;
          border-width: 0;
          color: #b6bdbf;
          background-color: #b6bdbf;
        "
      />
      <span
        class="po-md-1"
        style="
          font-size: 13px;
          text-align: left;
          color: #4a5c60;
          padding-top: 5px;
        "
        >TOTAL:</span
      >
      <span
        class="po-md-1"
        style="text-align: right; color: #4a5c60; padding-top: 5px"
        >{{ totalPedidos | number : "1.2-2" }}</span
      >
    </div>
  </div>
  <po-loading-overlay
    *ngIf="loading"
    [p-screen-lock]="true"
    p-text="Loading..."
  ></po-loading-overlay>
</po-page-default>
