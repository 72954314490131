<po-page-default [p-title]="page.title" [p-breadcrumb]="page.breadcrumb" [p-actions]="page.actions"
  [p-literals]="page.literals">

  <form [formGroup]="form" (keyup.enter)="getPedidosAberto()">
    <div class="po-row">
      <po-select class="po-sm-12 po-md-2 po-lg-2" formControlName="somenteAbertos" p-label="Order Status"
        [p-options]="selects.somenteAbertos">
      </po-select>
      <po-select class="po-md-2 po-sm-12" p-label="Filters" formControlName="filtros" [p-options]="selects.filtros">
      </po-select>
      <po-input *ngIf="tipoForm === 1" class="po-md-2 po-sm-12" p-label="Search for:" formControlName="pesquisa">
      </po-input>
      <po-datepicker *ngIf="tipoForm === 2" class="po-md-2" p-label="Date" formControlName="pesquisa">
      </po-datepicker>
      <po-combo *ngIf="tipoForm === 3" class="po-md-2" p-label="Sales Rep" formControlName="pesquisa"
        [p-options]="selects.representantes"></po-combo>
      <div class="positionRelative po-md-2">
        <po-input name="input" p-label="Product" p-placeholder="" class="paddingInput"
          formControlName="codigoProduto"></po-input>
        <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" (p-click)="modalF3Produto.open()">
        </po-button>
        <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger"
          (p-click)="this.controls.codigoProduto.setValue('')"></po-button>
      </div>
      <po-select p-label="Status Code" class="po-md-2" [p-options]="selects.statusCode"
        formControlName="C5_XCODSTA"></po-select>
      <po-button class="po-md-1 po-mt-4" p-icon="po-icon-search" p-type="primary"
        (p-click)="getPedidosAberto()"></po-button>
      <po-button class="po-md-1 po-mt-4" (click)="clearFilters()" p-icon="po-icon po-icon-clear-content"
        p-type="danger"></po-button>
    </div>
  </form>

  <po-table id="hideSecondScrollTable" class="po-md-12 po-mt-1" [p-literals]="table.customLiterals"
    [p-items]="table.items" [p-columns]="table.columns" [p-height]="table.height" [p-selectable]="true"
    [p-single-select]="true" [p-sort]="true" [p-striped]="true" (p-selected)="selectedPedido($event)"
    (p-unselected)="unselectedPedido()">
  </po-table>

  <app-pagination [totalItems]="pagination.totalItems" [itemsPerPage]="pagination.itemsPerPage"
    [currentPage]="pagination.currentPage" (pageChanged)="onPageChange($event)" [disabled]="loading">
  </app-pagination>
</po-page-default>


<po-modal #modalVisualizarImpressao id="modalImpressao" p-size="auto" [p-hide-close]="true" p-click-out="false"
  [p-primary-action]="confirmarImpressao" [p-secondary-action]="close" p-title="">
  <div class="po-row">
    <po-email class="po-md-12" p-label="From" [(ngModel)]="emailData.cFrom"></po-email>
    <po-input class="po-md-12" p-label="To" [(ngModel)]="emailData.cEmailTo"></po-input>
    <po-input class="po-md-12" p-label="CC" [(ngModel)]="emailData.cEmailCc"></po-input>
    <po-input class="po-md-12" p-label="BCC" [(ngModel)]="emailData.cEmailBCC"></po-input>
    <po-input class="po-md-12" p-label="Subject" [(ngModel)]="emailData.cAssunto"></po-input>
    <po-rich-text class="po-md-12" [(ngModel)]="emailData.cOptionalMensagem" [p-height]="262"
      name="optionalMessage"></po-rich-text>
    <po-switch class="po-md-3" [p-disabled]="hidePaymentLink" p-label="Send Payment Link" p-label-off="No"
      p-label-on="Yes" [(ngModel)]="emailData.paytrace" (p-change)="generateHtmlBody()"></po-switch>
    <po-decimal class="po-md-3" [p-disabled]="convertBoo(hidePaymentLink)" p-label="Amount" p-locale="en"
      [p-decimals-length]=2 [(ngModel)]="emailData.amount" (p-change-model)="generateHtmlBody()">
    </po-decimal>
    <po-button class="po-md-3 po-mt-4" [p-disabled]="hidePaymentLink" p-label="Submit Payment" p-type="primary"
      p-icon="po-icon po-icon-finance" (p-click)="clickButton()"></po-button>
    <po-button class="po-md-3 po-mt-4" p-label="Send E-Mail" p-type="default" p-icon="po-icon po-icon-mail"
      (p-click)="sendEmail()"></po-button>
    <div #submitPayment></div>
  </div>
</po-modal>
<po-modal #modalInvoice [p-primary-action]="modalInvoiceFirstAction" p-size="sm">
  <po-select [p-options]="this.selects.invoices" p-label="Select your Invoice" [ngModel]="optionInvoice"
    (p-change)="optionInvoice = $event"></po-select>
</po-modal>

<po-modal #modalF3Produto p-size="sm" [p-primary-action]="closeModalProduto">
  <div class="po-row">
    <po-input class="po-md-10" p-label="Filter" p-placeholder="Enter the filter before search " p-clean
      [p-optional]="true" (p-change-model)="tableProdutos.filtro = $event"></po-input>
    <po-button class="po-md-2 po-mt-4" p-type="primary" p-icon="po-icon-search"
      (p-click)="getListaProdutos(tableProdutos.filtro)"></po-button>
  </div>
  <div class="po-row">
    <po-table class="po-md-12" [p-height]="tableProdutos.height" [p-columns]="tableProdutos.columns"
      [p-items]="tableProdutos.items" [p-actions]="tableProdutos.actions"></po-table>
    <app-pagination class="po-md-12" [totalItems]="tableProdutos.total" [itemsPerPage]="tableProdutos.limit"
      [currentPage]="tableProdutos.page" (pageChanged)="onPageChangeProdutos($event)"
      [disabled]="loading"></app-pagination>
  </div>
</po-modal>

<!-- INVOICE MODAL -->
<po-modal #invoiceModal p-size="xl" class="limitModal" [p-primary-action]="saveInvoiceModal"
  [p-secondary-action]="cancelInvoiceModal" [p-hide-close]="true">
  <form [formGroup]="invoiceForm">
    <div class="po-row po-mt-4" style="height: 50%;">
      <po-select p-label="Customer" class="po-md-2" [p-disabled]="true" formControlName="C5_CLIENTE"
        [p-options]="selects.customer">
      </po-select>
      <po-select p-label="Freight Type" [p-disabled]="true" class="po-md-2" formControlName="C5_TPFRETE"
        [p-options]="selects.freightType">
      </po-select>
      <po-select p-label="Oper Type" class="po-md-2" formControlName="C5_OPER" [p-disabled]="true"
        [p-options]="tipoOperacaoList"></po-select>
      <po-input p-label="PO Number" class="po-md-2" formControlName="C5_XPO" p-disabled={{true}}
        [p-maxlength]="20"></po-input>
      <po-select p-label="Price List" class="po-md-2" formControlName="C5_TABELA" [p-disabled]="true"
        [p-options]="selects.tabelaPreco">
      </po-select>
      <po-combo p-label="Sales Rep" [p-clean]="true" [p-filter-mode]="filterMode" [p-disabled]="true" class="po-md-2"
        formControlName="C5_VEND1" [p-options]="selects.representantes">
      </po-combo>
      <po-select p-label="Payment Term" class="po-md-2" [p-disabled]="true" formControlName="C5_CONDPAG"
        [p-options]="selects.condicaoPagamento">
      </po-select>
      <po-decimal p-label="Sales Tax" class="po-md-1" formControlName="C5_TOTAL" p-locale="en" p-disabled={{true}}>
      </po-decimal>
      <po-decimal p-label="Freight" class="po-md-1" formControlName="C5_FRETE" p-disabled={{true}}
        p-locale="en"></po-decimal>
      <po-decimal p-label="Packing Fee" class="po-md-2" formControlName="C5_DESPESA" p-disabled={{true}} p-locale="en"
        [p-min]="0"></po-decimal>
      <po-input p-label="Collector Name" class="po-md-2" formControlName="C5_XCOLENA" p-disabled={{true}}></po-input>
      <po-datepicker p-label="Collect Date" class="po-md-2" formControlName="C5_XCOLEDA" p-locale="en"
        p-format="mm/dd/yyyy" p-disabled={{true}}></po-datepicker>
      <po-decimal p-label="Total" class="po-md-1" formControlName="TOTAL" p-locale="en" p-disabled={{true}}>
      </po-decimal>
      <po-decimal p-label="Paid" class="po-md-1" formControlName="valorRecebido" p-locale="en"
        p-disabled={{true}}></po-decimal>
      <po-decimal p-label="Total Due" class="po-md-2" formControlName="totalDue" p-locale="en"
        p-disabled={{true}}></po-decimal>

      <po-divider class="po-md-12" p-label="Invoice"></po-divider>

      <po-select p-label="Serie" class="po-md-2" formControlName="F2_SERIE" [p-options]="selects.series"></po-select>
      <po-input p-label="DOC" class="po-md-2" formControlName="F2_DOC"></po-input>

      <po-datepicker p-label="Emission" class="po-md-3" formControlName="F2_EMISSAO" p-locale="en"
        p-format="mm/dd/yyyy"></po-datepicker>

    </div>
  </form>
    <po-table class="po-md-12" [p-height]="invoiceModalTable.height" [p-items]="invoiceModalTable.items"
        [p-columns]="invoiceModalTable.columns" [p-sort]="true" [p-actions]="invoiceModalTable.actions"
        [p-hide-text-overflow]="true">

        <ng-template p-table-cell-template let-column="column" let-row="row">
            <div *ngIf="column.property === 'C6_QTDLIB'" style="cursor: pointer;">
                <po-decimal [p-decimals-length]="0" [(ngModel)]="row.C6_QTDLIB" (ngModelChange)="validarQtyLib(row)"  p-disabled={{desabilitaInputQtyInvoice(row)}} >

                </po-decimal>
                <!--<span class="po-icon po-icon-download custom-icon-download"
                    (click)="getPrinter(row,'RSCA415', 'salesorder')"></span>-->
            </div>
        </ng-template>

    </po-table>
</po-modal>

<!-- COMPENSATION MODAL -->

<po-modal #compensationModal class="po-md-12" p-title="{{ compensationModalTitle }}" p-size="md" class="limitModal"
  [p-hide-close]="true">
  <po-table class="po-md-12" [p-columns]="compensationModalTable.columns" [p-items]="compensationModalTable.items"
    [p-height]="compensationModalTable.height" [p-actions]="compensationModalTable.actions" p-container="box"
    [p-hide-columns-manager]="true" [p-sort]="true" [p-striped]="true" [p-hide-text-overflow]="true">
    <ng-template p-table-column-template [p-property]="'STATUS'" let-value>
      <div class="badge {{ defineStatusClass(value) }}">
        {{ value | uppercase }}
      </div>
    </ng-template>
  </po-table>
</po-modal>

<po-loading-overlay *ngIf="loading" [p-screen-lock]="true" p-text="Loading, please wait ..."></po-loading-overlay>
