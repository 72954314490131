<po-page-default [p-title]="page.title" [p-breadcrumb]="page.breadcrumb" [p-actions]="page.action">
    <div class="po-row">
        <po-info class="po-md-3" p-label="From" [p-label-size]="7"  [p-value]="armazens.origem">
        </po-info>
        <po-info class="po-md-3" p-label="To" [p-label-size]="7" [p-value]="armazens.destino">
        </po-info>
    </div>
    <div class="po-row po-mt-4">
        <po-button p-label="Download Model" p-icon="po-icon-download" class="po-md-2"
            (p-click)="getModeloPlanilha()"></po-button>
        <po-button p-label="Import Excel" p-icon="po-icon-folder" class="po-md-2" p-type="primary"
            (p-click)="uploadButton.click()"></po-button>
        <input #uploadButton type="file" name="fileUpload" accept=".xls,.xlsx" hidden="true"
            (change)="excelUpload($event)" />
    </div>
    <po-table class="po-md-12 po-mt-4" [p-columns]="table.columns" [p-items]="table.items"
        [p-literals]="table.literals" [p-height]="table.height"></po-table>
</po-page-default>
<po-loading-overlay *ngIf="loading" [p-screen-lock]="true" p-text="Loading.."></po-loading-overlay>