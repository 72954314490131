<div class="po-wrapper">
  <ng-container *ngIf="isLoggedIn$ | async as loggedIn">
    <po-toolbar
      *ngIf="loggedIn"
      p-title="PORTAL RSC {{ version }}"
      p-actions-icon="po-icon-user"
      [p-actions]="profileActions"
    ></po-toolbar>

    <po-menu
      *ngIf="loggedIn"
      [p-filter]="true"
      [p-menus]="menus"
      [p-collapsed]="collapsed"
      p-logo="../assets/images/logo-rsc.png"
      p-short-logo="../assets/images/short-logo-rsc.png"
    ></po-menu>
  </ng-container>

  <po-modal
    #modalUser
    id="modalUser"
    p-title="Profile"
    p-size="sm"
    [p-primary-action]="modalPrimaryAction"
    [p-secondary-action]="modalSecondaryAction"
    [p-hide-close]="true"
  >
    <div class="po-row">
      <div class="po-md-1"></div>
      <div class="po-md-3">
        <po-avatar class="po-md-2" p-size="lg" [p-src]="profile.avatar">
        </po-avatar>
      </div>
      <div class="po-md-5 po-mt-2 po-offset-lg-1">
        <p class="po-md-12"><strong>Name:</strong> {{ profile.title }}</p>
        <p class="po-md-12">
          <strong>Username:</strong> {{ profile.username }}
        </p>
        <p class="po-md-12"><strong>Role:</strong> {{ profile.subtitle }}</p>
      </div>
      <div class="po-row po-mt-2">
        <div class="po-md-1"></div>
        <div class="po-md-10" [formGroup]="formPassword">
          <po-password
            p-label="Current Password"
            formControlName="old"
          ></po-password>
          <po-password
            p-label="New Password"
            formControlName="new"
          ></po-password>
          <po-password
            p-label="Confirm Password"
            formControlName="confirm"
          ></po-password>
        </div>
        <div class="po-mt-4">
          <po-button
            id="buttonInfo"
            p-icon="po-icon-info"
            class="po-mt-4"
            [p-tooltip]="regexPasswordInfo"
            p-tooltip-position="right"
          ></po-button>
        </div>
      </div>
    </div>
  </po-modal>

  <router-outlet></router-outlet>
</div>

<po-loading-overlay [p-screen-lock]="true" p-text="Loading.." *ngIf="loading">
</po-loading-overlay>
