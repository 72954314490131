<po-page-default
  [p-title]="page.title"
  [p-actions]="page.actions"
  [p-breadcrumb]="page.breadcrumb"
>
  <form [formGroup]="form">
    <div class="po-row">
      <po-datepicker
        p-label="Start *"
        p-locale="en"
        p-format="MM/dd/yyyy"
        class="po-md-2"
        formControlName="start"
      ></po-datepicker>
      <po-datepicker
        p-label="End *"
        p-locale="en"
        p-format="MM/dd/yyyy"
        class="po-md-2"
        formControlName="end"
      ></po-datepicker>
      <po-combo
        p-label="Sales Rep."
        class="po-md-5"
        [p-options]="optionsRepresentantes"
        formControlName="codigoRepresentante"
      ></po-combo>
      <po-button
        p-type="primary"
        p-label="Search"
        class="po-md-3 po-mt-4"
        p-icon="po-icon-search"
        (p-click)="getRelatorio()"
      ></po-button>
    </div>
  </form>
  <div class="po-row">
    <po-table
      class="po-md-12"
      [p-columns]="table.columns"
      [p-items]="table.items"
      [p-height]="table.height"
      [p-striped]="true"
    ></po-table>
  </div>
  <po-loading-overlay
    p-text="Loading..."
    [p-screen-lock]="true"
    *ngIf="loading"
  ></po-loading-overlay>
</po-page-default>
