import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SendJavaApi } from '../util/util.service';

export interface WireTransferDetailEnvio {
  ZR7_PREFIX: string;
  ZR7_BILL: string;
  ZR7_PARCEL: string;
  ZR7_TIPO: string;
  ZR7_FORNEC: string;
  ZR7_LOJA: string;
}
export interface WireTransferDetailItemEnvio {
  prefixo: string;
  bill: string;
  ZR7_FORNEC: string;
  ZR7_LOJA: string;
}

export interface WireTransferUpFront {
  $showAction: boolean;
  SALDO: number;
  ZR7_BILL: string;
  ZR7_DESCON: number;
  ZR7_DESCRI: string;
  ZR7_EMISSA: string;
  ZR7_FORNEC: string;
  ZR7_HIST2: string;
  ZR7_HISTOR: string;
  ZR7_LOJA: string;
  ZR7_NATURE: string;
  ZR7_NOME: string;
  ZR7_PAGO: number;
  ZR7_PARCEL: string;
  ZR7_PREFIX: string;
  ZR7_TIPO: string;
  ZR7_VALOR: number;
  ZR7_VENCTO: string;
  alterar: boolean;
  mensagem: string;
  recno: number;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class WireTransferService {
  constructor(private http: HttpClient) {}

  private endpoint = 'wire';

  returnWire(param: SendJavaApi): Observable<any> {
    param.homologacao = !environment.production;
    return this.http.post(
      `${environment.URL.APIPRIVATE}/v1/${this.endpoint}`,
      param
    ) as Observable<any>;
  }
}
