import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PoBreadcrumb,
  PoBreadcrumbItem,
  PoComboFilterMode,
  PoNotificationService,
  PoPageAction,
  PoTableColumn,
} from '@po-ui/ng-components';
import { ListasService } from 'src/app/service/listas/listas.service';
import { LoadedPageService } from 'src/app/service/loadedPage/loaded-page.service';
import { PoService } from 'src/app/service/po/po.service';
import { UtilService } from 'src/app/service/util/util.service';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.css'],
})
export class PurchaseOrderComponent implements OnInit {
  constructor(
    private poService: PoService,
    private router: Router,
    private utilService: UtilService,
    private notificationService: PoNotificationService,
    private listasService: ListasService,
    private fb: UntypedFormBuilder,
    private loadedPageService: LoadedPageService,
    private activatedRoute: ActivatedRoute
  ) {}

  public loading = false as boolean;
  public item = { $selected: false, alterar: false } as any;
  public filterModeCombo = PoComboFilterMode.contains;
  public selectsSA2: Array<any> = [];

  public filtersForm = this.fb.group({
    C7_NUM: ['', []],
    C7_FORNECE: ['', []],
    C7_LOJA: ['', []],
    C7_DESCRI: ['', []],
    C7_EMISSAO: [],
    EMISSAO_ORIGINAL: [],
    FORNEC_ORIGINAL: ['', []],
    EMISSAO_FROM: [],
    EMISSAO_TO: [],
  });

  public page = {
    title: 'Purchase Order',
    breadcrum: {
      items: [
        { label: 'Purchase' },
        { label: 'Purchase Order' },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    action: [
      {
        label: 'Edit',
        icon: 'po-icon po-icon-edit',
        action: () => this.mudarRota('edit'),
      },
    ] as Array<PoPageAction>,
  };

  public table = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.42),
  };

  public pagination = {
    totalItems: 0,
    itemsPerPage: 50,
    currentPage: 1,
  };

  async ngOnInit() {
    await this.loadParameters();
    await this.returnListas('SA2');

    this.controls.EMISSAO_ORIGINAL.valueChanges.subscribe((data) => {
      if (data !== null) {
        this.controls.C7_EMISSAO.setValue([data.start, data.end]);
      }
    });
  }

  get controls() {
    return this.filtersForm.controls;
  }

  public getListaBrowser() {
    try {
      this.loading = true;
      const obj = {
        procedure: false,
        metodo: 'listar_browse',
        data: {
          limit: this.pagination.itemsPerPage,
          page: this.pagination.currentPage,
          filter: this.filtersForm.value,
        },
      };
      this.poService.getPo(obj).subscribe({
        next: (element) => {
          try {
            element.data.map((data: any) => {
              if (data.columns) {
                this.table.columns = data.columns;
              }
              if (data.items) {
                this.table.items = data.items;
              }
              if (data['total']) {
                this.pagination.totalItems = data['total'];
                this.utilService.emitTotalItems.emit(
                  this.pagination.totalItems
                );
              }
            });
          } catch (error) {
            this.notificationService.error('Error: ' + error);
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          console.error(error);
          this.notificationService.error(error.message);
        },
      });
    } catch (error) {
      this.notificationService.error('Error: ' + error);
    }
  }

  public selectedItem(event: any) {
    this.item = event;
  }
  public unselectedItem() {
    this.item = {};
  }

  /**
   * @returns os filtros aplicados anteriormente
   * @description caso o usuário tenha ido para uma página que contém o endereço 'solicitacoes-compras' mna url, ao voltar para a página de listagem, esses filtros anteriores serão aplicados novamente
   */
  loadParameters() {
    try {
      const value = this.loadedPageService.verifyRoute(this.router.url);
      if (value !== false) {
        this.filtersForm.patchValue(value.params);
      }

      this.getListaBrowser();
    } catch (error) {
      this.notificationService.error('Error: ' + error);
    }
  }

  /**
   * @param rota rota que deseja ser direcionado
   * @description irá direcionar o usuário a rota desejada
   */
  private mudarRota(rota: string) {
    try {
      const params = {
        params: {
          ...this.filtersForm.value,
        },
        route: this.router.url,
      };
      this.loadedPageService.changeRoute(params);

      const item = this.table.items
        .filter((a: any) => a.$selected === true)
        .pop();
      if (item) {
        this.router.navigate([`${rota}/`, item?.RECNO], {
          relativeTo: this.activatedRoute,
        });
      } else {
        return this.notificationService.warning(
          'Please, select a request to proceed'
        );
      }
    } catch (error) {
      this.notificationService.error('Error: ' + error);
    }
  }

  public onPageChange(event: any) {
    this.pagination.currentPage = event;
    this.getListaBrowser();
  }

  public supllierSelec(item: any) {
    this.controls.C7_FORNECE.setValue(item[0]);
    this.controls.C7_LOJA.setValue(item[1]);
  }

  returnListas(table: any, callback?: any): any {
    const params = {
      procedure: false,
      data: { table },
    };
    this.listasService.returnListas(params).subscribe({
      next: (data) => {
        if (data.status) {
          const retorno = data.data[0].items;
          if (table === 'SA2') {
            this.selectsSA2 = retorno.map((item: any) => {
              return {
                label: item.concatenado,
                value: item.valor,
              } as any;
            });
          }
        }
        if (typeof callback === 'function') {
          callback(true, false, 'succefuly');
        }
      },
      error: (err) => {
        console.error(err, 'Err -->');
      },
    });
  }

  limparFiltros() {
    this.filtersForm.reset();
    this.getListaBrowser();
  }
}
