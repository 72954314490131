import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvioPadraoBackend } from '../envio-padrao-backend';

@Injectable({
  providedIn: 'root'
})
export class CategoriasService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'categorias';

  public getCategorias(parameter: EnvioPadraoBackend) {
    parameter.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, parameter);
  }
}
