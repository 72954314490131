import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: "root",
})
export class ListasManterService {

  constructor(
    private http: HttpClient

  ) { }

  private apiUrl = `${environment.URL.APIPRIVATE}/v1/listas-manter`;

  public manter(dados: any): Observable<any> {
    return this.http.post(this.apiUrl, dados);
  }
}
