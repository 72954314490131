import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PoNotificationService, PoBreadcrumbItem, PoBreadcrumb, PoPageAction, PoTableColumn, PoPageDefaultLiterals } from '@po-ui/ng-components';
import { ContainerService } from 'src/app/service/container/container.service';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { UtilService } from 'src/app/service/util/util.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent implements OnInit, AfterViewInit, AfterViewChecked {

  constructor(
    private containerService: ContainerService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private notificationService: PoNotificationService,
    private excelService: ExcelService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder
  ) { }

  public loading = false as boolean;
  public item = { $selected: false } as any

  page = {
    title: 'Container',
    breadcrumb: {
      items: [
        { label: 'Stock' },
        { label: 'Container' },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    action: [
      {
        label: 'Add',
        action: () => this.addContainer(),
        icon: 'po-icon-plus'
      },
      {
        label: 'Edit',
        action: () => this.editContainer(),
        icon: 'po-icon-edit'
      },
      {
        label: 'View',
        action: () => this.viewContainer(),
        icon: 'po-icon-zoom-in'
      },
      {
        label: 'Delete',
        action: () => this.deleteContainer(),
        icon: 'po-icon-delete'
      },
      {
        label: 'Excel',
        action: () => this.exportExcel(),
        icon: 'po-icon-export'
      },
    ] as Array<PoPageAction>,
    literals:  {
      otherActions: 'Other Actions'
    } as PoPageDefaultLiterals
  };

  table = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.42)
  }

  pagination = {
    totalItems: 0,
    itemsPerPage: 50,
    currentPage: 1,
  };


  filtersForm = this.fb.group({
    ZR2_NUM: ['', []],
    ZR2_ETD: ['', []],
    ZR2_ETA: ['', []],
    ZR2_BOL: [],
    ETA_FROM: [, []],
    ETA_TO: [, []],
    ETD_FROM: [, []],
    ETD_TO: [, []],
    ZR3_NUMPC: ['', []]
  })

  ngOnInit(): void {
    this.getListaBrowser();
  }

  get controls() {
    return this.filtersForm.controls
  }

  ngAfterViewInit(): void {
    this.table.height = 0;
    this.table.height = this.utilService.calcularHeight(innerHeight, 0.42);
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.table.height = 0;
    this.table.height = this.utilService.calcularHeight(innerHeight, 0.42);
    this.cdr.detectChanges();
  }

  public getListaBrowser() {
    this.loading = true;
    delete this.filtersForm.value.ZR3_NUMPC
    const obj = {
      procedure: false,
      metodo: 'LISTAR_BROWSE',
      data: {
        limit: this.pagination.itemsPerPage,
        page: this.pagination.currentPage,
        filter: { ...this.filtersForm.value , ZR2_ETD: [this.controls.ETD_FROM.value, this.controls.ETD_TO.value], ZR2_ETA: [this.controls.ETA_FROM.value, this.controls.ETA_TO.value] },
        subquery: { ZR3_NUMPC: this.controls.ZR3_NUMPC.value }
      },
    }
    this.containerService.returnContainer(obj)
      .subscribe({
        next: (element: any) => {
        if (element != null) {
          element.data
            .map((data: any) => {
              if (data.columns) {
                this.table.columns = data.columns
              }
              if (data.items) {
                this.table.items = data.items;
                this.table.items.map((ret) => {
                  Object.entries(ret)
                    .forEach(([chave, valor]) => {
                      if (valor === '00/00/00') {
                        ret[chave] = null
                      } else {
                        ret[chave] = ret[chave]
                      }
                    })
                })
              }
              if (data['total']) {
                this.pagination.totalItems = data['total'];
                this.utilService.emitTotalItems.emit(this.pagination.totalItems);
              }
            })
        }
        this.loading = false;
      }, error: (error: any) => {
        this.loading = false
        console.error(error);
        this.notificationService.error('Error while loading data')
      }})
  }

  public selectedItem(event: any) {
    this.item = event
  }
  public unselectedItem(event: any) {
    this.item = {}

  }

  private addContainer() {
    this.router.navigate(['add'], { relativeTo: this.route })
  }

  private editContainer() {
    if (this.item['$selected'] && this.verifyStatus(this.item)) {
      this.router.navigate(['edit', this.item.RECNO], { relativeTo: this.route })
    } else if (this.item['$selected'] && !this.verifyStatus(this.item)) {
      this.notificationService.warning('Container Closed');
    } else {
      this.notificationService.warning('Please select an container!');
    }
  }

  private viewContainer() {
    if (this.item['$selected']) {
      this.router.navigate(['view', this.item.RECNO], { relativeTo: this.route })
    } else {
      this.notificationService.warning('Please select an container!');
    }
  }

  private deleteContainer() {
    if (this.item['$selected'] && this.verifyStatus(this.item)) {
      this.router.navigate(['delete', this.item.RECNO], { relativeTo: this.route })
    } else if (this.item['$selected'] && !this.verifyStatus(this.item)) {
      this.notificationService.warning('Container Closed');
    } else {
      this.notificationService.warning('Please select an container!');
    }
  }

  private verifyStatus(item: any): boolean {
    if (item.ZR2_STATUS !== '1') {
      return false
    } else {
      return true
    }
  }

  public onPageChange(event: any) {
    this.pagination.currentPage = event;
    this.getListaBrowser();
  }

  private exportExcel() {
    this.loading = true
    const arr = this.table.items
      .map((element: any) => {
        return {
          Status: element.ZR2_STATUS,
          Container: element.ZR2_NUM,
          ETD: element.ZR2_ETD,
          ETA: element.ZR2_ETA,
          "Received Warehouse": element.ZR2_DWAREH,
          "Received System": element.ZR2_DSYSTE,
          RECNO: element.RECNO
        }
      })
    this.excelService.exportAsExcelFile(arr, this.page.title)
    setTimeout((a: any) => {
      this.loading = false
    }, 1000)
  }

  limparFiltros() {
    this.filtersForm.reset();
    this.getListaBrowser();
  }
}

