import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PoBreadcrumb,
  PoComboFilterMode,
  PoModalComponent,
  PoNotificationService,
  PoPageAction,
  PoSelectOption,
  PoTableAction,
  PoTableColumn,
} from '@po-ui/ng-components';
import { isNull } from 'lodash';
import { ClienteEditService } from 'src/app/service/cliente-edit.service';
import { ContactCustomerService } from 'src/app/service/contact-customer.service';
import { CountryServices } from 'src/app/service/country/country.service';
import { EnvioPadraoBackend } from 'src/app/service/envio-padrao-backend';
import { GravaClienteService } from 'src/app/service/grava-cliente/grava-cliente.service';
import { LinkSalesRepService } from 'src/app/service/link-sales-rep.service';
import { ListaTabelaPrecosService } from 'src/app/service/lista-tabela-precos/lista-tabela-precos.service';
import { RepresentantesService } from 'src/app/service/representantes/representantes.service';
import {
  ResaleCertificateRecordEnvio,
  ResaleCertificateService,
} from 'src/app/service/resale-certificate.service';
import { SalesOcurrenceService } from 'src/app/service/sales-ocurrence.service';
import { UtilService } from 'src/app/service/util/util.service';

enum PageType {
  'create' = 1,
  'edit' = 2,
  'delete' = 3,
}

@Component({
  selector: 'app-generic-customer',
  templateUrl: './generic-customer.component.html',
  styleUrls: ['./generic-customer.component.css'],
})
export class GenericCustomerComponent implements OnInit {
  constructor(
    private utilService: UtilService,
    private fb: UntypedFormBuilder,
    private representantesService: RepresentantesService,
    private notification: PoNotificationService,
    private listaTabelaPrecoService: ListaTabelaPrecosService,
    private clientesPosicaoService: ClienteEditService,
    private resaleCertificateService: ResaleCertificateService,
    private salesOcurrenceService: SalesOcurrenceService,
    private countryServices: CountryServices,
    private contactCustomerService: ContactCustomerService,
    private linkSalesRepService: LinkSalesRepService,
    private gravaClienteService: GravaClienteService,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router
  ) {}

  @ViewChild('modalSalesRep', { static: true })
  modalSalesRep!: PoModalComponent;
  @ViewChild('modalOcurrences', { static: true })
  modalOcurrences!: PoModalComponent;
  @ViewChild('modalResaleCertificate', { static: true })
  modalResaleCertificate!: PoModalComponent;
  @ViewChild('modalContacts', { static: true })
  modalContacts!: PoModalComponent;

  public page = {
    title: 'Edit Customer',
    actions: [
      {
        label: 'Save',
        action: () => {
          this.postRecordCustomer();
        },
        icon: 'po-icon-save',
      },
      {
        label: 'Back',
        action: () => {
          this.router.navigate(['customers/']);
        },
      },
    ] as Array<PoPageAction>,
    breadcrumb: {
      items: [
        { label: 'Home' },
        { label: 'Relationship' },
        { label: 'Customers' },
      ],
    } as PoBreadcrumb,
  };

  public loading: boolean = false;
  public showTableTabs = false as boolean;
  public disableCreate = true as boolean;
  public disableDelete = false as boolean;
  public listaCountries = [] as Array<PoSelectOption>;
  public listarSalesRep = [] as Array<PoSelectOption>;
  public blockAllFields = false as boolean;
  public blockAllFieldsTwo = 'false' as string;
  public filterMode = PoComboFilterMode.contains;
  public filterSalesRep = '';

  public modelOcurrences = {
    type: 'COMMERCIAL',
    ocurrence: '',
  };
  public previewResaleFile = false;
  public rCertificate = {
    file: '' as any,
    fileName: '',
    previewFile: false,
    tipoFile: 1,
    ZA2_NOTA: '',
    ZA2_EXPDT: '',
    ZA2_NRCERT: '',
  };
  public modelContacts = {
    U5_CONTAT: '',
    U5_FONE: '',
    U5_EMAIL: '',
    A1_COD: '',
    A1_LOJA: '',
    RECNOSU5: 0, //recno da SU5,
    RECNOAC8: 0, //recno da SU5,
    DELET: 0,
  };

  public form = this.fb.group({
    A1_MSBLQL: ['2', [Validators.required, Validators.maxLength(1)]],
    A1_COD: ['', []],
    A1_LOJA: ['', []],
    A1_NOME: ['', [Validators.required, Validators.maxLength(40)]],
    A1_NREDUZ: ['', [Validators.required, Validators.maxLength(20)]],
    A1_PESSOA: ['J', [Validators.required, Validators.maxLength(1)]],
    A1_CGC: ['', [Validators.maxLength(14)]],
    A1_TEL: ['', [Validators.required, Validators.maxLength(15)]],
    A1_EMAIL: ['', [Validators.required, Validators.maxLength(250)]],
    A1_END: ['', [Validators.required, Validators.maxLength(40)]],
    A1_EST: ['', [Validators.required, Validators.maxLength(2)]],
    A1_COMPLEM: ['', [Validators.maxLength(50)]],
    A1_MUN: ['', [Validators.required, Validators.maxLength(50)]],
    A1_CEP: ['', [Validators.required, Validators.maxLength(8)]],
    A1_PAIS: ['', [Validators.required, Validators.maxLength(3)]],
    A1_VEND: ['', [Validators.required]],
    A1_CONTATO: [''],
    A1_TABELA: ['', [Validators.required, Validators.maxLength(3)]],
    A1_XCATCRM: [''],
    A1_ULTCOM: ['', []],
    RECNO: [0],
    DELET: [0],
  });

  public select = {
    A1_MSBLQL: [
      { label: 'ACTIVE', value: '2' },
      { label: 'INACTIVE', value: '1' },
    ] as Array<PoSelectOption>,
    A1_PESSOA: [
      { label: 'INDIVIDUAL', value: 'F' },
      { label: 'COMPANY', value: 'J' },
    ] as Array<PoSelectOption>,
    A1_PAIS: [] as Array<PoSelectOption>,
    A1_TABELA: [] as Array<PoSelectOption>,
    A1_XCATCRM: [
      { label: 'DIAMOND', value: '01' },
      { label: 'GOLD', value: '02' },
    ] as Array<PoSelectOption>,
    tipoOcorrencias: [
      { label: 'COMMERCIAL', value: 'COMMERCIAL' },
      { label: 'LOGISTIC', value: 'LOGISTIC' },
      { label: 'FINANCIAL', value: 'FINANCIAL' },
    ] as Array<PoSelectOption>,
  };

  public tableSalesRep = {
    columns: [
      { label: 'Code', property: 'codigoRepresentante', width: '30%' },
      { label: 'Name', property: 'nomeRepresentante', width: '70%' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    itemsModal: [] as Array<any>,
    actionModal: [
      {
        label: 'Link',
        icon: 'po-icon-ok',
        action: (item: any) => {
          if (
            this.tableSalesRep.items.find(
              (element) => element.codigoRepresentante === item.codigo
            )
          ) {
            this.notification.warning(
              'This representative is already inserted in the table'
            );
          } else {
            this.postSalesRepCustomer(item, false);
          }
        },
      },
    ] as Array<PoTableAction>,
    actions: [
      {
        label: 'Delete',
        action: (item: any) => {
          this.postSalesRepCustomer(item, true);
        },
        icon: 'po-icon-delete',
        type: 'danger',
      },
    ] as Array<PoTableAction>,
    loading: false,
    height: this.utilService.calcularHeight(innerHeight, 0.35),
  };

  public tableContacts = {
    columns: [
      { label: 'Code', property: 'U5_CODCONT', width: '10%' },
      { label: 'Name', property: 'U5_CONTAT', width: '25%' },
      { label: 'Telephone', property: 'U5_FONE', width: '25%' },
      { label: 'E-mail', property: 'U5_EMAIL', width: '25%' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    actions: [
      {
        label: 'Delete',
        icon: 'po-icon-delete',
        type: 'danger',
        action: (item: any) => {
          this.postContato(1, item.RECNOSU5, item.RECNOAC8);
        },
      },
    ] as Array<PoTableAction>,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
  };

  public tableResaleCertificate = {
    columns: [
      { label: 'Certificate', property: 'ZA2_NRCERT', width: '15%' },
      {
        label: 'Record Date',
        property: 'ZA2_DATA',
        type: 'date',
        format: 'MM/dd/yyyy',
        width: '10%',
      },
      { label: 'Record Hour', property: 'ZA2_HORA', width: '15%' },
      {
        label: 'Expiration Date',
        property: 'ZA2_EXPDT',
        type: 'date',
        format: 'MM/dd/yyyy',
        width: '15%',
      },
      { label: 'Note', property: 'ZA2_NOTA', width: '40%' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    actions: [
      {
        label: 'View',
        icon: 'po-icon po-icon-eye',
        action: (item: any) => {
          this.showResaleCertificate(item.ZA2_URL);
        },
      },
      {
        label: 'Delete',
        icon: 'po-icon-delete',
        type: 'danger',
        action: (item: any) => {
          this.uploadFile(null, 0, 1, item.RECNO);
        },
      },
    ] as Array<PoTableAction>,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
  };

  public tableOcurrences = {
    columns: [
      {
        label: 'Ocorrence Date',
        property: 'ZT_DATA',
        width: '25%',
        type: 'date',
        format: 'MM/dd/yyyy',
      },
      { label: 'Hour', property: 'ZT_HORA', width: '25%' },
      { label: 'Responsible', property: 'ZT_CODUSR', width: '25%' },
      // { label: 'Type', property: 'ZT_TIPO', width: '20%' }, // Campo não existe na base de produção
      { label: 'Description', property: 'descricao', width: '25%' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
  };

  ngOnInit(): void {
    this.callInitMethods(this.router.url);
  }

  private callInitMethods(route: string) {
    if (route.includes('create')) {
      this.showTableTabs = false;
      this.createPageWhenInit(PageType.create);
      this.getA1_TABELAList(true, () => {});
    } else if (route.includes('edit')) {
      this.showTableTabs = true;
      this.createPageWhenInit(PageType.edit);
      this.activatedRoute.paramMap.subscribe((element) => {
        this.getCustomer(
          {
            codigoCliente: element.get('codigo') as string,
            lojaCliente: element.get('loja') as string,
          },
          true,
          () => {
            this.getA1_TABELAList(true, () => {});
          }
        );
      });
    } else if (route.includes('delete')) {
      this.showTableTabs = true;
      this.blockAllFields = true;
      this.blockAllFieldsTwo = 'true';
      this.createPageWhenInit(PageType.delete);
      this.hideActionsTable();
      this.activatedRoute.paramMap.subscribe({
        next: (element: any) => {
          this.getCustomer(
            {
              codigoCliente: element.get('codigo') as string,
              lojaCliente: element.get('loja') as string,
            },
            true,
            () => {
              this.getA1_TABELAList(true, () => {});
            }
          );
        },
      });
    }
  }

  private createPageWhenInit(pageType: PageType) {
    if (pageType === PageType.create) {
      this.page.title = 'Create Customer';
      this.page.breadcrumb.items.push({ label: 'Create Customer' });
      this.page.actions[0].label = 'Create';
      this.disableCreate = true;
    } else if (pageType === PageType.edit) {
      this.page.title = 'Edit Customer';
      this.page.breadcrumb.items.push({ label: 'Edit Customer' });
      this.page.actions[0].label = 'Save';
      this.disableCreate = false;
    } else if (pageType === PageType.delete) {
      this.page.title = 'Delete Customer';
      this.page.breadcrumb.items.push({ label: 'Delete Customer' });
      this.page.actions[0].label = 'Delete';
      this.page.actions[0].icon = 'po-icon-delete';
      this.disableCreate = true;
      this.disableDelete = true;
    }
    const country = this.activatedRoute.snapshot.data['listCountries'].data;
    this.listaCountries = this.utilService.montarListaComResolver(
      country,
      'YA_PAIS_I',
      'YA_CODGI'
    );

    const salesRep =
      this.activatedRoute.snapshot.data['listRepresentante'].data['data'];
    this.listarSalesRep = this.utilService.montarListaComResolver(
      salesRep,
      'nomeFantasia',
      'codigo'
    );
    console.log(this.listarSalesRep, 'SALES');
  }

  public get controls() {
    return this.form.controls;
  }

  private getCustomer(
    params: { codigoCliente: string; lojaCliente: string },
    promise: boolean,
    callBack?: any
  ) {
    this.loading = true;
    const body: any = {
      data: {
        codigoCliente: params.codigoCliente,
        lojaCliente: params.lojaCliente,
      },
      procedure: true,
      metodo: '',
      // homologacao: false,
    };
    this.clientesPosicaoService.post(body).subscribe({
      next: (element: any) => {
        if (element.status) {
          this.form.patchValue(element.data);
          this.tableContacts.items = element.data.contatos.map((item: any) => {
            item.actions = ['delete', 'edit'];
            return item;
          });

          this.tableOcurrences.items = element.data.ocorrencias;
          this.tableResaleCertificate.items =
            element.data.resaleCertificate.map((item: any) => {
              item.actions = ['delete'];
              return item;
            });

          this.tableSalesRep.items = element.data.representantes;
          promise ? callBack() : null;
        } else {
          this.notification.warning(element.mensagem);
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error, '<---- ERRO CHAMADA GETCUSTOMER');
        this.loading = false;
        this.notification.error(
          'Sorry, error while getting customer, please contact a admin'
        );
      },
    });
  }

  private getA1_TABELAList(promise: boolean, callBack?: any) {
    this.loading = true;
    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.listaTabelaPrecoService.getListaTabelaPrecos(envio).subscribe({
      next: (element: any) => {
        if (element.status) {
          this.select.A1_TABELA = element.data.map((item: any) => {
            return {
              label: `${
                item.codigoTabela
              } - ${item.descricaoTabela.toUpperCase()}`,
              value: item.codigoTabela,
            } as PoSelectOption;
          });
          promise ? callBack() : null;
        } else {
          this.notification.warning(element.mensagem);
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
      },
    });
  }

  private hideActionsTable() {
    if (this.router.url.includes('delete')) {
      this.tableSalesRep.actions = [];
      this.tableResaleCertificate.actions = [];
      this.tableContacts.actions = [];
    }
  }

  public getRepresentantes(filter: string) {
    this.tableSalesRep.loading = true;
    const obj = {
      data: {
        page: 1,
        limit: 99999,
        filter,
        block: 0,
      },
      procedure: true,
      metodo: '',
      // homologacao: false,
    };
    this.representantesService.getRepresentantes(obj).subscribe({
      next: (element: any) => {
        if (element.status) {
          this.tableSalesRep.itemsModal = element.data.data.map((a: any) => {
            a.nomeRepresentante = a.nomeFantasia;
            a.codigoRepresentante = a.codigo;
            return a;
          });
        } else {
          this.notification.warning(element.mensagem);
        }
        this.tableSalesRep.loading = false;
        error: (error: HttpErrorResponse) => {
          console.error(error.error);
          this.tableSalesRep.loading = false;
          this.notification.error('Erro while getting data');
        };
      },
    });
  }

  public handleFileCertificate(file: any) {
    if (file) {
      this.rCertificate.file = file.target.files[0];
      this.rCertificate.fileName = file.target.files[0].name;
      this.rCertificate.previewFile = true;
      this.rCertificate.tipoFile =
        file.target.files[0].type == 'application/pdf' ? 2 : 1;
    }
  }

  public uploadFile(file: any, tipoFile: number, DELET: 0 | 1, RECNO?: any) {
    if (this.rCertificate.ZA2_NRCERT.length <= 1 && DELET != 1) {
      this.notification.warning(
        "Please fill in the 'Resale Certificate Number' field with at least 1"
      );
    } else if (this.rCertificate.ZA2_EXPDT.length <= 1 && DELET != 1) {
      this.notification.warning(
        "Please fill in the 'Expiration Date' field with at least 1"
      );
    } else {
      this.loading = true;
      const obj = {
        data: {
          ZA2_URL: '',
          ZA2_DATA: this.utilService.dataAtual(),
          ZA2_HORA: this.utilService.horaAtual(),
          ZA2_CODCLI: this.controls['A1_COD'].value,
          ZA2_LOJACL: this.controls['A1_LOJA'].value,
          ZA2_NOTA: this.rCertificate.ZA2_NOTA,
          ZA2_EXPDT: this.rCertificate.ZA2_EXPDT,
          ZA2_NRCERT: this.rCertificate.ZA2_NRCERT,
          RECNO: RECNO ?? 0,
          DELET,
        },
        procedure: false,
        homologacao: false,
        tipoFile,
        hasFile: !isNull(file),
      } as ResaleCertificateRecordEnvio;
      file = isNull(file) ? new File(['pdf'], 'NullFile') : file;
      this.resaleCertificateService
        .addNewResaleCertificate(file, obj)
        .subscribe({
          next: (element: any) => {
            if (element.gravado == 1) {
              this.notification.success(element.mensagem);
              const obj = {
                codigoCliente: this.controls['A1_COD'].value,
                lojaCliente: this.controls['A1_LOJA'].value,
              };

              this.resetRCertificate();
              this.modalResaleCertificate.close();
              this.getCustomer(obj, false);
            } else {
              this.loading = false;
              this.notification.error(element.mensagem);
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error(error.error);
            this.loading = false;
          },
        });
    }
  }

  public resetRCertificate() {
    this.rCertificate.ZA2_EXPDT = ' ';
    this.rCertificate.ZA2_NOTA = ' ';
    this.rCertificate.ZA2_NRCERT = ' ';
    this.rCertificate.file = null;
    this.rCertificate.fileName = '';
    this.rCertificate.previewFile = false;
    this.rCertificate.tipoFile = 0;
  }

  public criarOcorrencia() {
    if (this.modelOcurrences.ocurrence.length <= 5) {
      this.notification.warning(
        'Please fill in the occurrence field with at least 5'
      );
    } else {
      this.loading = true;
      const username = JSON.parse(
        localStorage.getItem('username') as string
      ).username;
      const obj: any = {
        data: {
          ZT_CLIENTE: this.controls['A1_COD'].value,
          ZT_LOJA: this.controls['A1_LOJA'].value,
          ZT_TIPO: this.modelOcurrences.type,
          ZT_DATA: this.utilService.dataAtual(),
          ZT_HORA: this.utilService.horaAtual(),
          ZT_CODUSR: username,
          // ZT_PEDIDO: '',
          DESCRICAO: this.modelOcurrences.ocurrence,
        },
        procedure: false,
        metodo: '',
        // homologacao: false,
      };
      this.salesOcurrenceService.recordOcurrenceSales(obj).subscribe({
        next: (element) => {
          if (element.gravado === 1) {
            this.notification.success(element.mensagem);
            const obj = {
              codigoCliente: this.controls['A1_COD'].value,
              lojaCliente: this.controls['A1_LOJA'].value,
            };
            this.modelOcurrences.ocurrence = ' ';
            this.modelOcurrences.type = 'COMMERCIAL';
            this.modalOcurrences.close();
            this.getCustomer(obj, false);
          } else {
            this.loading = false;
            this.notification.error(element.mensagem);
          }
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          this.notification.error(
            'Sorry, error in the server, while recording'
          );
          console.error(error.error);
        },
      });
    }
  }

  public resetContato() {
    this.modelContacts.DELET = 0;
    this.modelContacts.RECNOSU5 = 0;
    this.modelContacts.RECNOAC8 = 0;
    this.modelContacts.U5_CONTAT = '';
    this.modelContacts.U5_FONE = '';
    this.modelContacts.U5_EMAIL = '';
  }

  public postContato(DELET: 0 | 1, RECNOSU5?: number, RECNOAC8?: number) {
    if (this.modelContacts.U5_CONTAT.length <= 5 && DELET != 1) {
      this.notification.warning(
        "Please fill in the 'Contact Name' field with at least 5"
      );
    } else if (this.modelContacts.U5_FONE.length <= 1 && DELET != 1) {
      this.notification.warning(
        "Please fill in the 'Phone' field with at least 1"
      );
    } else if (this.modelContacts.U5_EMAIL.length <= 10 && DELET != 1) {
      this.notification.warning(
        "Please fill in the 'E-mail' field with at least 10"
      );
    } else {
      this.loading = true;

      this.modelContacts.A1_COD = this.controls['A1_COD'].value;
      this.modelContacts.A1_LOJA = this.controls['A1_LOJA'].value;
      this.modelContacts.DELET = DELET;
      this.modelContacts.RECNOSU5 = RECNOSU5 ?? this.modelContacts.RECNOSU5;
      this.modelContacts.RECNOAC8 = RECNOAC8 ?? this.modelContacts.RECNOAC8;
      // console.log(this.modelContacts);

      this.contactCustomerService
        .post({
          data: this.modelContacts,
          procedure: false,
          homologacao: false,
          metodo: '',
        })
        .subscribe({
          next: (element: any) => {
            if (element.gravado === 1) {
              this.notification.success(element.mensagem);
              this.resetContato();
              const obj = {
                codigoCliente: this.controls['A1_COD'].value,
                lojaCliente: this.controls['A1_LOJA'].value,
              };
              this.modalContacts.close();
              if (DELET !== 1) {
                this.createPageWhenInit(PageType.edit);
              } else {
                // this.location.back();
              }
              this.getCustomer(obj, false);
            } else {
              this.loading = false;
              this.notification.error(element.mensagem);
            }
          },
          error: (error: HttpErrorResponse) => {
            console.error(error.error);
            this.notification.error('Sorry, error while recording contact');
            this.loading = false;
          },
        });
    }
  }

  public postSalesRepCustomer(item: any, isSalesRepDeleted: boolean) {
    this.loading = true;
    const body = {
      data: {
        ZAC_VEND: item.codigoRepresentante,
        ZAC_CLIENT: this.controls['A1_COD'].value,
        ZAC_LOJA: this.controls['A1_LOJA'].value,
        RECNOZAC: item.RECNOZAC ?? 0,
        DELET: isSalesRepDeleted ? 1 : 0,
      },
      procedure: false,
      metodo: '',
      // homologacao: false,
    };
    this.linkSalesRepService.post(body).subscribe({
      next: (element: any) => {
        if (element.gravado === 1) {
          this.notification.success(element.mensagem);
          const obj = {
            codigoCliente: this.controls['A1_COD'].value,
            lojaCliente: this.controls['A1_LOJA'].value,
          };
          this.loading = false;
          this.modalSalesRep.close();
          this.getCustomer(obj, false);
        } else {
          this.loading = false;
          this.notification.error(element.mensagem);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        this.notification.error('Sorry, error while linking Sales Rep');
        console.error(error.error);
      },
    });
  }

  public openSalesRepModal() {
    this.tableSalesRep.itemsModal = [];
    this.filterSalesRep = '';
    this.modalSalesRep.open();
  }

  private postRecordCustomer() {
    if (this.router.url.includes('delete')) {
      this.form.controls.DELET.setValue(1);
    } else {
      this.form.controls.DELET.setValue(0);
    }

    if (this.form.invalid && this.form.controls.DELET.value === 0) {
      const invalidFields = this.utilService.findInvalidControls(
        this.form.controls
      );
      if (invalidFields.length > 0) {
        invalidFields.map((data) => {
          const labels = [
            {
              A1_MSBLQL: 'Status',
              A1_NOME: 'Name',
              A1_NREDUZ: 'Short Nm.',
              A1_PESSOA: 'Indiv/Comp',
              // A1_CGC: 'Tax ID',
              A1_TEL: 'Phone',
              A1_EMAIL: 'E-mail',
              A1_END: 'Address',
              A1_EST: 'State',
              A1_MUN: 'City',
              A1_CEP: 'ZIP Code',
              A1_PAIS: 'Country',
              A1_TABELA: 'Price List',
            },
          ];
          labels.forEach((item: any) => {
            Object.keys(item).forEach((valor) => {
              if (valor === data) {
                return this.notification.warning(
                  `Field "${item[data]}" is invalid, please check and try again!`
                );
              }
            });
          });
        });
      }
    } else {
      this.loading = true;
      const body: any = {
        data: this.form.value,
        procedure: false,
        metodo: '',
        // homologacao: false,
      };
      this.clientesPosicaoService.post(body).subscribe({
        next: (element: any) => {
          if (element.gravado === 1) {
            this.notification.success(element.mensagem);
            if (this.router.url.includes('create')) {
              this.router.navigate([
                'customers/edit',
                element.A1_COD,
                element.A1_LOJA,
              ]);
            } else {
              this.location.back();
            }
          } else {
            this.notification.error(element.mensagem);
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error.error);
          this.loading = false;
          this.notification.error('Sorry, errro while recording customer');
        },
      });
    }
  }

  showResaleCertificate(link: string) {
    this.loading = true;
    this.resaleCertificateService.getResaleCertificate(link).subscribe({
      next: (element: any) => {
        console.log(element);
        const url = window.URL.createObjectURL(element);
        window.open(url);
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
        this.notification.error('Error getting the file, please try again');
      },
    });
  }
}
