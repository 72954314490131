<po-page-default p-title="{{title | titlecase}}" [p-actions]="actions">

    <div *ngIf="isLoaded">

        <form [formGroup]="roleForm">

            <po-divider p-label="Info"></po-divider>
            <div class="po-row">
                <po-input p-disabled="true" formControlName="roleId" p-label="ID" class="po-lg-2"></po-input>
                <!-- [p-readonly]="isDisabled" -->
                <po-input p-required="true" formControlName="name" p-label="Name" class="po-lg-3">
                </po-input>
            </div>

            <div class="po-row">
                <po-switch [p-disabled]="isDisabled" formControlName="active" p-label="Active" class="po-lg-2" p-label-on="Yes" p-label-off="No">
                </po-switch>
                <po-info class="po-md-2" p-label="Created" [p-label-size]="7" [p-orientation]="orientation" p-url="" p-value="{{controls.created.value | date: 'dd/MM/yyyy'}}">
                </po-info>
                <po-info class="po-md-2" p-label="Modified" [p-label-size]="7" [p-orientation]="orientation" p-url="" p-value="{{controls.modified.value | date: 'dd/MM/yyyy'}}">
                </po-info>
                <!-- <span class="po-md-2"><strong>Created</strong></span>
                <span class="po-md-2"></span> -->
                <!-- <span class="po-md-2"><strong>Modified</strong></span>
                <span class="po-md-2">{{controls.modified.value | date: 'dd/MM/yyyy'}}</span> -->
            </div>

            <div class="po-row">
                <po-container class="po-md-6 po-mt-4" [p-height]="tableParents.height+100">
                    <po-table [p-sort]="true" [p-striped]="true" [p-columns]="tableParents.columns" [p-items]="tableParents.items" [p-selectable]="true" [p-single-select]="true" (p-selected)="selectRoute($event)" [p-height]="tableParents.height" [p-literals]="tableParents.literals"></po-table>
                </po-container>
                <po-container class="po-md-6 po-mt-4" [p-height]="tableChilds.height+100">
                    <po-table [p-columns]="tableChilds.columns" [p-items]="tableChilds.items" [p-sort]="true" [p-striped]="true" [p-single-select]="false" [p-selectable]="true" [p-literals]="tableChilds.literals"></po-table>
                </po-container>
                <!-- <po-table [p-columns]="tableChilds.columns" class="po-md-12 po-mt-4" [p-items]="jsonEx" p-sort="true" p-striped="true" p-single-select="false" p-selectable="true"></po-table> -->
            </div>

        </form>


    </div>

</po-page-default>


<po-loading-overlay *ngIf="loading" [p-screen-lock]="true" p-text="Loading...">
</po-loading-overlay>