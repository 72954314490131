import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PoComboFilterMode,
  PoModalAction,
  PoModalComponent,
  PoNotification,
  PoNotificationService,
  PoPageDefaultLiterals,
  PoSelectOption,
  PoTableAction,
  PoTableColumn,
  PoTableLiterals,
} from '@po-ui/ng-components';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { ListaStatusCodeService } from 'src/app/service/lista-status-code/lista-status-code.service';
import {
  ListaPedidos,
  RelacaoPedidosV2Service,
} from 'src/app/service/relacao-pedidos-v2/relacao-pedidos-v2.service';
import {
  SendEmail,
  SendEmailService,
} from 'src/app/service/send-email/send-email.service';
import { SessionService } from 'src/app/service/session/session.service';
import { UtilService } from 'src/app/service/util/util.service';
import * as _ from 'lodash';
import { LoadedPageService } from 'src/app/service/loadedPage/loaded-page.service';
import { RepresentantesService } from 'src/app/service/representantes/representantes.service';
import { PrintService } from 'src/app/service/print/print.service';
import { EnvioPadraoBackend } from 'src/app/service/envio-padrao-backend';
import { ListaInvoiceService } from 'src/app/service/lista-invoice/lista-invoice.service';
import { ProdutosService } from 'src/app/service/produtos/produtos.service';
import { ListaCondicaoPagamentoService } from 'src/app/service/lista-condicao-pagamento/lista-condicao-pagamento.service';
import { ListaTabelaPrecosService } from 'src/app/service/lista-tabela-precos/lista-tabela-precos.service';
import { PedidoItemService } from 'src/app/service/pedido-item/pedido-item.service';
import { ListasManterService } from 'src/app/service/listas-manter/listas-manter.service';
import { InvoiceSalesOrderService } from 'src/app/service/invoice-sales-order/invoice-sales-order.service';
import { Subject, of } from "rxjs";
import { debounceTime, switchMap } from 'rxjs/operators';

export interface ReturnValidateDocumentNumber {
  valid: boolean;
  nextDocumentNumber?: string;
}
@Component({
  selector: 'app-sales-order',
  templateUrl: './sales-order.component.html',
  styleUrls: ['./sales-order.component.css'],
})
export class SalesOrderComponent implements OnInit {
  constructor(
    private notificationService: PoNotificationService,
    private relacaoPedidosV2: RelacaoPedidosV2Service,
    private fb: UntypedFormBuilder,
    private router: Router,
    private activadeRoute: ActivatedRoute,
    private representantesService: RepresentantesService,
    private loadedPageService: LoadedPageService,
    private utilService: UtilService,
    private printService: PrintService,
    private excelService: ExcelService,
    private listaStatusCodeService: ListaStatusCodeService,
    private sendEmailService: SendEmailService,
    private sessionService: SessionService,
    private listaInvoiceService: ListaInvoiceService,
    private produtosService: ProdutosService,
    private condicaoPagamentoService: ListaCondicaoPagamentoService,
    private listaTabelaPrecoService: ListaTabelaPrecosService,
    private pedidoItemService: PedidoItemService,
    private invoiceSalesOrderService: InvoiceSalesOrderService,
    private listasManterService: ListasManterService
  ) {
    this.saldoSubject
      .pipe(
        debounceTime(300),
        switchMap(row => {

          const linha:any = row

          //QTDESTOQUE + QTDRES + C6_QTDENT + C6_QTDLIB + C6_QTDVEN
          const qtdeEstoque = (linha.QTDESTOQUE + linha.QTDRES )
          const qtdeDisponivelParaLiberar = ( linha.C6_QTDVEN - linha.C6_QTDENT )

          // if (qtdeEstoque <= 0 ){
          //   linha.C6_QTDLIB = 0
          //   this.notificationService.warning('Product out of stock');
          //   return of(linha);
          // }

          // if (qtdeEstoque < qtdeDisponivelParaLiberar ) {
          //   linha.C6_QTDLIB = 0
          //   this.notificationService.warning('It will not be possible to release this quantity, quantity in stock available: ' + qtdeEstoque );
          //   return of(linha);
          // }

          if(linha.C6_QTDLIB > qtdeEstoque) {
            linha.C6_QTDLIB = qtdeEstoque
            this.notificationService.warning('You have exceeded the maximum permitted stock quantity');
          }

          if(linha.C6_QTDLIB > linha.C6_QTDVEN) {
            linha.C6_QTDLIB = linha.C6_QTDVEN
            this.notificationService.warning('You have exceeded the maximum permitted fractional quantity');
          }

          if ( (linha.C6_QTDLIB + linha.C6_QTDENT ) > linha.C6_QTDVEN ){
            linha.C6_QTDLIB = (linha.C6_QTDVEN - linha.C6_QTDENT)
            this.notificationService.warning('Released quantity cannot be greater than sale quantity + previously released quantity');
            return of(linha);
          }

          return of(linha);
        })
      )
      .subscribe();
  }

  public pedido = new ListaPedidos();
  public loading = false as boolean;
  public tipoForm = 1 as number;
  public recno = 0 as number;
  public numeroPedido = '' as string;
  public htmlPromise = '' as string;
  public imageError = '' as string;
  public isImageSaved = false as boolean;
  public cardImageBase64 = null as any;
  filterMode: PoComboFilterMode = PoComboFilterMode.contains;

  public page = {
    title: 'Sales Order',
    breadcrumb: {
      items: [{ label: 'Commercial' }, { label: 'Sales Order' }],
    },
    actions: [
      { label: 'Edit', action: () => this.vincularDispacth() },
      { label: 'Excel', action: () => this.toExcel() },
      {
        label: 'Invoice',
        action: () => {
          this.openModalInvoice();
        },
        disabled: true,
      },
      {
        label: 'Sales Order',
        action: () => {
          this.openEmailModal('RSCA415', 'salesorder');
        },
      },
      {
        label: 'Generate Invoice',
        action: () => this.validateOpenInvoiceModal(),
        icon: 'po-icon po-icon-ok',
      },
    ],
    literals: {
      otherActions: 'Other Actions',
    } as PoPageDefaultLiterals,
  };

  public form = this.fb.group({
    filtros: ['numeroPedido'],
    somenteAbertos: [3, []],
    pesquisa: [''],
    page: [],
    limit: [''],
    produto: [''],
    RECNO: [''],
    C5_XCODSTA: [' '],
    codigoProduto: [''],
  }) as UntypedFormGroup;

  public pagination = {
    totalItems: 0,
    itemsPerPage: 50,
    currentPage: 1,
  };

  public selects = {
    filtros: [
      { label: 'Sales order No', value: 'numeroPedido' },
      { label: 'Customer Code', value: 'codigoCliente' },
      { label: 'Customer Name', value: 'razaoSocial' },
      { label: 'Ficticious Name', value: 'nomeFantasia' },
      { label: 'Status', value: 'C5_XDESPAC' },
      { label: 'Po Number', value: 'poNumber' },
      { label: 'Date', value: 'start' },
      { label: 'Sales Rep Code', value: 'codigoRepresentante' },
      { label: 'Sales Rep Name', value: 'nomeRepresentante' },
      { label: 'ID GoSales', value: 'idGoSales' },
    ],
    transportadoras: [] as Array<PoSelectOption>,
    customer: [] as Array<PoSelectOption>,
    representantes: [] as Array<PoSelectOption>,
    condicaoPagamento: [] as Array<PoSelectOption>,
    tabelaPreco: [] as Array<PoSelectOption>,
    freightType: [
      { label: 'CIF', value: 'C' },
      { label: 'FOB', value: 'F' },
      { label: 'NO FREIGHT', value: 'S' },
    ] as Array<PoSelectOption>,
    somenteAbertos: [
      { label: 'OPEN', value: 1 },
      { label: 'CLOSED', value: 2 },
      { label: 'ALL', value: 3 },
    ] as PoSelectOption[],
    statusCode: [{ label: 'ALL', value: ' ' }] as Array<PoSelectOption>,
    invoices: [] as Array<PoSelectOption>,
    series: [] as Array<any>,
  };
  public tipoOperacaoList: Array<PoSelectOption> = [];

  public table = {
    columns: [] as PoTableColumn[],
    items: [] as Array<any>,
    height: 0,
    total: '' as string,
    customLiterals: {
      noData: 'No data found',
      seeCompleteSubtitle: 'See complete Subtitle',
      completeSubtitle: 'Complete Subtitle',
    } as PoTableLiterals,
  };

  public tableProdutos = {
    columns: [
      { label: 'Code', property: 'codigo', width: '40%' },
      { label: 'Description', property: 'descricao', width: '60%' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    actions: [
      {
        label: 'Select',
        icon: 'po-icon-ok',
        action: (item: any) => {
          this.controls.codigoProduto.setValue(item.codigo);
          this.modalF3Produto.close();
        },
      },
    ] as Array<PoTableAction>,
    height: this.utilService.calcularHeight(innerHeight, 0.42),
    filtro: '',
    page: 1,
    total: 0,
    limit: 50,
  };

  private saldoSubject = new Subject<Number>();

  @ViewChild('modalF3Produto', { static: true })
  modalF3Produto!: PoModalComponent;
  public closeModalProduto: PoModalAction = {
    action: () => {
      this.modalF3Produto.close();
    },
    label: 'Close',
    danger: false,
  };

  @ViewChild('modalVisualizarImpressao', { static: true })
  modalVisualizarImpressao!: PoModalComponent;
  public confirmarImpressao: PoModalAction = {
    action: () => {
      this.getPrinter();
    },
    label: 'Donwload',
    danger: false,
  };

  public close: PoModalAction = {
    action: () => {
      this.modalVisualizarImpressao.close();
    },
    label: 'Close',
    danger: false,
  };

  @ViewChild('modalInvoice', { static: true }) modalInvoice!: PoModalComponent;
  public modalInvoiceFirstAction = {
    label: 'Confirm',
    action: () => {
      if (this.optionInvoice) {
        this.modalInvoice.close();
        this.openEmailModal('RSCA414', 'invoice');
      } else {
        this.notificationService.warning('Please Select a Invoice');
      }
    },
  } as PoModalAction;

  optionInvoice: any;

  emailData = new SendEmail();
  public hidePaymentLink = false;
  base64: any;

  // INVOICE MODAL UTILITIES
  @ViewChild('invoiceModal', { static: true }) invoiceModal!: PoModalComponent;
  @ViewChild('compensationModal', { static: true })
  compensationModal!: PoModalComponent;
  public saveInvoiceModal!: PoModalAction;
  public cancelInvoiceModal!: PoModalAction;

  // FORMS
  public invoiceForm = this.fb.group({
    C5_NUM: [''],
    C5_CLIENTE: [''],
    C5_EMISSAO: [''],
    C5_TPFRETE: [''],
    C5_NOTA: [''],
    C5_FRETE: [''],
    C5_OPER: [''],
    C5_XPO: [''],
    C5_TABELA: [''],
    C5_VEND1: [''],
    C5_VEND2: [''],
    F2_EMISSAO: [''],
    C5_CONDPAG: [''], //CODIGO CONDICAO PAGAMENTO
    TOTAL: [''],
    C5_XOBSCOM: [''],
    C5_XOBSGC1: [''],
    C5_XOBSGC2: [''],
    C5_XFIN: [''],
    C5_XOBSFEI: [''],
    C5_XLOG: [''],
    C5_XSHIPTO: [''],
    C5_XENDENT: [''],
    C5_XSHPZIP: [''],
    C5_XSHPEMA: [''],
    C5_XENDEN2: [''],
    C5_XSHPUF: [''],
    C5_XDESPAC: [''],
    FreighForwarder: [''],
    C5_XCOLENA: [''],
    C5_XCOLEDA: [''],
    C5_XTRACNU: [''],
    C5_VOLUME1: [''],
    C5_XPEDAPP: [''],
    C5_ESPECI1: [''],
    C5_PBRUTO: [''],
    C5_PESOL: [''],
    C5_XNOTE: [''],
    recno: [''],
    C5_TOTAL: [''],
    C5_DESPESA: [0],
    A1_CEP: [''],
    A1_EMAIL: [''],
    A1_END: [''],
    A1_EST: [''],
    A1_MUN: [''],
    A1_NOME: [''],
    A1_TEL: [''],
    valorRecebido: [''],
    totalDue: [''],
    C5_XCODSTA: [''],
    C5_FILIAL: [''],
    ZB6_DESCRI: [''],
    C5_XCODFEI: [''],
    ZT_CLIENTE: [''],
    ZT_LOJA: [''],
    ZT_PEDIDO: [''],
    ZT_DATA: [''],
    ZT_HORA: [''],
    ZT_CODUSR: [''],
    ZT_TIPO: [''],
    DESCRICAO: [''],
    F2_SERIE: [''],
    F2_DOC: [''],
  });

  public initialValuesInvoiceForm = this.invoiceForm.value;

  public compensationModalTitle: string = '';

  public invoiceModalTable = {
    columns: [
      {
        label: 'Item',
        property: 'C6_ITEM',
        width: '6%',
      },
      {
        label: 'Product',
        property: 'C6_PRODUTO',
        width: '9%',
      },
      {
        label: 'Description',
        property: 'C6_DESCRI',
        width: '18%',
      },
      {
        label: 'Quantity',
        property: 'C6_QTDVEN',
        width: '9%',
      },
      {
        label: 'Price',
        property: 'C6_PRCVEN',
        width: '9%',
      },
      {
        label: 'Total',
        property: 'C6_VALOR',
        width: '9%',
      },
      {
        label: 'in Stock',
        property: 'QTDESTOQUE',
        width: '7%',
      },
      {
        label: 'Reserved',
        property: 'QTDRES',
        width: '7%',
      },
      {
        label: 'Qty Invoiced',
        property: 'C6_QTDENT',
        width: '10%',
      },
      {
        label: 'Qty Release',
        property: 'C6_QTDLIB',
        type: 'cellTemplate',
        width: '10%',
      },
      {
        label: 'Warehouse',
        property: 'C6_LOCAL',
        width: '7%',
      },
      {
        label: 'Notes',
        property: 'C6_XOBS',
        width: '10%',
      },
      { label: '', property: 'AUTDELETA', width: '5%', visible: false },
      { label: 'recno', property: 'recno', width: '5%', visible: false },
    ] as PoTableColumn[],
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.5),
    actions: [] as Array<PoTableAction>,
  };

  public compensationModalTable = {
    columns: [
      {
        property: 'STATUS',
        label: 'Status',
        type: 'columnTemplate',
        width: '30%',
      },
      {
        label: 'Title',
        property: 'TITULO',
        width: '30%',
      },
      {
        label: 'Value',
        property: 'VALOR',
        width: '30%',
      },
    ] as PoTableColumn[],
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
    actions: [] as Array<PoTableAction>,
  };

  ngOnInit(): void {
    this.createColumns();
    //this.getListaStatusCode();
    this.getListaStatusCode(true,() => {
    });

    this.form.controls.filtros.valueChanges.subscribe((ret) => {
      const filtro = ret ?? '';
      if (filtro) {
        this.controls.pesquisa.setValue('');
        this.formChanges(filtro);
      }
    });
  }

  public validarQtyLib(row:any){
    this.saldoSubject.next(row);
  }

  public desabilitaInputQtyInvoice(row:any){
    return row.C6_QTDENT >= row.C6_QTDVEN
  }


  public get controls() {
    return this.form.controls;
  }

  public getPedidosAberto() {
    this.loading = true;
    const envio = {
      procedure: true,
      data: this.retornaFiltros(),
    };
    this.relacaoPedidosV2.getPedidos(envio).subscribe({
      next: (dados: any) => {
        this.table.items = dados.data.data
        .map((a: any) => {
          let statusCode: any = '';
          if (this.selects.statusCode.length > 0) {
            statusCode = this.selects.statusCode.filter(
              (element: any) => a.C5_XCODSTA === element.value
            );
            if (statusCode.length > 0) {
              statusCode = statusCode.pop().label;
            }
          }
          const itens = {
            A1_EMAIL: a.A1_EMAIL.toLocaleLowerCase(),
            numeroPedido: a.numeroPedido,
            dataEmissao: this.utilService.multiFormataData(
              a.dataEmissao,
              'mm/dd/yyyy'
            ),
            pedidoApp: a.C5_XPEDAPP,
            codigoCliente: a.codigoCliente,
            nomeCliente: a.nomeCliente,
            estado: a.estado,
            ponumber: a.ponumber,
            nomeRepresentante: a.nomeRepresentante,
            notaFiscal: a.notaFiscal,
            totalPedido: (
              a.totalPedido +
              a.C5_FRETE +
              a.C5_XPRVSTX +
              a.C5_DESPESA
            ).toFixed(2),
            descrisaoStatus: `${a.descricaoStatus} - ${a.codigoStatus}`,
            C5_XDESPAC: a.C5_XDESPAC ?? '',
            legenda: a.legenda,
            RECNO: a.recno,
            tipoOperacao: a.tipoOperacao,
            valorRecebido: a.valorRecebido,
            totalDue: (
              a.totalPedido +
              a.C5_FRETE +
              a.C5_XPRVSTX +
              a.C5_DESPESA -
              a.valorRecebido
            ).toFixed(2),
            C5_XCODSTA: statusCode,
            qtd: a.qtd,
          };
          return itens;
        });
        this.pagination.totalItems = dados.data.total;
        this.pagination.itemsPerPage = dados.data.limit;

        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.notificationService.error(error);
        this.loading = false;
      },
    });
  }

  public getRepresentanteInterno(brand?: any) {
    this.loading = true;
    const envio = {
      procedure: true,
      data: {},
    };
    this.representantesService.getRepresentantes(envio).subscribe({
      next: (dados) => {
        const retorno = dados.response.data.map((ret: any) => {
          return {
            label: `${ret.codigo} - ${ret.nomeFantasia}`,
            value: ret.codigo,
          } as PoSelectOption;
        });
        this.selects.representantes = retorno;
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
      },
    });
  }

  private retornaPedidoSelecionado(): any {
    const pedido = this.table.items
      .filter((item) => item.$selected === true)
      .pop();
    if (pedido) {
      return pedido;
    } else {
      return false;
    }
  }

  public vincularDispacth() {
    const pedido = this.retornaPedidoSelecionado();
    if (pedido) {
      const params = {
        route: this.router.url,
        page: this.pagination.currentPage,
        params: this.form.value,
      };
      this.loadedPageService.changeRoute(params);
      this.router.navigate(['edit-sales-order', pedido.RECNO], {
        relativeTo: this.activadeRoute,
      });
    } else {
      this.notificationService.warning('Select a Order!');
    }
  }

  public selectedPedido(pedido: any) {
    this.pedido = pedido;
    const disabledAction = pedido.qtd >= 1 ? false : true;
    // @ts-ignore: Object is possibly 'undefined'.
    this.page.actions[2].disabled = disabledAction;
    this.recno = pedido.recno;
    this.numeroPedido = pedido.numeroPedido;
    this.controls.produto.setValue(pedido.numeroPedido);
    this.controls.RECNO.setValue(pedido.RECNO);
  }

  public unselectedPedido() {
    this.controls.produto.setValue('');
    this.controls.RECNO.setValue('');
  }

  private createColumns() {
    this.table.columns = [
      {
        property: 'legenda',
        label: '⠀',
        type: 'subtitle',
        width: '50px',
        subtitles: [
          {
            value: 'color-10',
            color: 'color-10',
            label: 'Open Sales Orders',
            content: '',
          },
          {
            value: 'color-07',
            color: 'color-07',
            label: 'Sales Order closed',
            content: '',
          },
          {
            value: 'color-09',
            color: 'color-09',
            label: 'Sales Orders released',
            content: '',
          },
          {
            value: 'color-01',
            color: 'color-01',
            label: 'Sales Orders with Rule Lock',
            content: '',
          },
          {
            value: 'color-08',
            color: 'color-08',
            label: 'Sales Orders with Budget Blocking',
            content: '',
          },
          {
            value: 'color-05',
            color: 'color-05',
            label: 'Partially Invoiced',
            content: '',
          },
        ],
      },
      { label: 'Sales Order No.', property: 'numeroPedido', width: '140px' },
      { label: 'idGoSales', property: 'pedidoApp', width: '140px' },
      { label: 'Oper. Type', property: 'tipoOperacao', width: '100px' },
      {
        label: 'Date',
        property: 'dataEmissao',
        width: '120px',
      },
      { label: 'Customer Code', property: 'codigoCliente', width: '150px' },
      { label: 'Customer', property: 'nomeCliente', width: '350px' },
      { label: 'State', property: 'estado', width: '100px' },
      { label: 'Po No', property: 'ponumber', width: '120px' },
      { label: 'Sales Rep.', property: 'nomeRepresentante', width: '300px' },
      { label: 'Invoice', property: 'notaFiscal', width: '130px' },
      { label: 'Amount', property: 'totalPedido', width: '120px' },
      { label: 'Paid', property: 'valorRecebido', width: '120px' },
      { label: 'Total Due', property: 'totalDue', width: '120px' },
      { label: 'Dispatch Status', property: 'C5_XDESPAC', width: '140px' },
      { label: 'Status Code', property: 'C5_XCODSTA', width: '180px' },
      { label: 'RECNO', property: 'RECNO', width: '100px', visible: false },
      { label: 'qtdInvoice', property: 'qtd', width: '100px', visible: false },
    ];

    this.table.height = this.utilService.calcularHeight(innerHeight, 0.42);
  }

  public onPageChange(event: any) {
    this.controls.page.setValue(event);
    this.pagination.currentPage = this.controls.page.value;
    this.getPedidosAberto();
  }

  private formChanges(tipo: any) {
    if (tipo === 'start') {
      this.tipoForm = 2;
    } else if (tipo === 'numeroPedido' || tipo === 'codigoCliente') {
      this.tipoForm = 1;
    } else {
      this.tipoForm = 1;
    }
  }

  private retornaFiltros() {
    const obj = JSON.parse(
      `{"${this.controls.filtros.value}": "${
        this.controls.pesquisa.value == null
          ? ''
          : this.controls.pesquisa.value.toUpperCase()
      }"}`
    );
    const form: any = {
      somenteAbertos: this.controls.somenteAbertos.value,
      page: this.pagination.currentPage,
      limit: this.pagination.itemsPerPage,
      statusCode: this.controls.C5_XCODSTA.value,
      codigoProduto: this.controls.codigoProduto.value,
    };
    Object.keys(form).map((key) => {
      if (form[key] === '') {
        delete form[key];
      }
    });
    return Object.assign(obj, form);
  }

  // private loadParameters() {
  //   const value: any = this.loadedPageService.verifyRoute(this.router.url);
  //   if (value !== false) {
  //     this.form.setValue(Object.assign({}, value['params']));
  //     (value['params']);
  //     this.getPedidosAberto();
  //     this.pagination.currentPage = this.controls.page.value;
  //   } else {
  //     return;
  //   }
  // }

  fileChangeEvent(fileInput: any): false | undefined | any {
    this.imageError = '';
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const maxSize = 20971520;
      const allowedTypes = ['image/png', 'image/jpeg'];
      const maxHeight = 15200;
      const maxWidth = 25600;

      if (fileInput.target.files[0].size > maxSize) {
        this.imageError = 'Maximum size allowed is ' + maxSize / 1000 + 'Mb';

        return false;
      }

      if (!_.includes(allowedTypes, fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs): false | undefined | any => {
          // @ts-ignore: Object is possibly 'null'.
          const imgHeight = rs.currentTarget['height'];
          // @ts-ignore: Object is possibly 'null'.
          const imgWidth = rs.currentTarget['width'];


          if (imgHeight > maxHeight && imgWidth > maxWidth) {
            this.imageError =
              'Maximum dimentions allowed ' + maxHeight + '*' + maxWidth + 'px';
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.cardImageBase64 = imgBase64Path;
            this.isImageSaved = true;
            // this.previewImagePath = imgBase64Path;
          }
        };
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  public getPrinter() {
    const item = this.pedido;
    if (item) {
      this.loading = true;
      const envio = {
        procedure: false,
        data: {
          [this.pedido.opcao]:
            this.pedido.opcao === 'salesorder'
              ? item.numeroPedido
              : item.notaFiscal,
          funcao: this.pedido.funcao,
          serie: this.pedido.serieNotaFiscal.trim().replace(' ', ''),
        },
        metodo: '',
      } as EnvioPadraoBackend;
      const nome =
        this.pedido.opcao === 'salesorder'
          ? `Sales_Order_${item.numeroPedido}`
          : `Invoice_${item.notaFiscal}`;
      this.printService.getPrint(envio).subscribe({
        next: (element) => {
          if (element.size > 0) {
            const blob = new Blob([element], {
              type: 'application/pdf',
            });
            const url = window.URL.createObjectURL(blob);
            const el = document.createElement('a');
            el.href = url;
            el.download = nome;
            document.body.append(el);
            el.click();
          } else {
            this.notificationService.warning('Error while downloading');
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.notificationService.error(error.error);
          this.loading = false;
        },
      });
    } else {
      this.notificationService.warning('Please select a Order');
    }
  }

  private getListaStatusCode(exibeLoad:boolean, callback:any) {
    if (exibeLoad) {
      this.loading = true;
    }
    const obj = {
      procedure: true,
    };
    this.listaStatusCodeService.getLista(obj).subscribe({
      next: (element: any) => {
        element.data.map((sta: any) => {
          const statusCode = { label: sta.description, value: sta.code,} as PoSelectOption
          this.selects.statusCode = this.utilService.spreadArrayToProperty(this.selects.statusCode,statusCode);
        });
        const a = this.loadedPageService.verifyRoute(this.router.url);
        if (a) {
          this.loading = true;
          this.pagination.currentPage = a.page ?? 1;
          this.form.patchValue(a.params);
          this.getPedidosAberto();
          this.formChanges(a.params.filtros);
        }
        if (exibeLoad) {
          this.loading = false;
        }
        callback()
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        if (exibeLoad) {
          this.loading = false;
        }
        callback()
      },
    });
  }

  removeImage() {
    this.cardImageBase64 = null;
    this.isImageSaved = false;
  }

  public print(option?: any) {
    // const newWindow = window.window.open(null, '_blank')
    // newWindow.document.writeln(this.htmlPromise);
    // newWindow.document.title = this.generateFileName(option);
  }

  public sendEmail() {
    if (this.emailData.cFrom === '') {
      return this.notificationService.warning(
        `Field: from is invalid, please check and try again!`
      );
    } else if (this.emailData.cEmailTo === '') {
      return this.notificationService.warning(
        `Field: To is invalid, please check and try again!`
      );
    } else if (this.emailData.cAssunto === '') {
      return this.notificationService.warning(
        `Field: Subject is invalid, please check and try again!`
      );
    } else {
      this.emailData.cMensagem = this.emailData.cOptionalMensagem;
      this.emailData.chtml = this.base64;
      this.loading = true;
      const envio = {
        procedure: false,
        data: this.emailData,
        metodo: '',
      } as EnvioPadraoBackend;
      // (envio);
      // this.generateHtmlBody();
      this.sendEmailService.sendEmail(envio).subscribe({
        next: (data: any) => {
          if (Object.keys(data).indexOf('error') !== -1) {
            this.notificationService.warning(
              'Error sending e-mail, please try again'
            );
          } else {
            if (data.gravado === '0') {
              this.notificationService.warning(
                'Error sending e-mail, please try again'
              );
            } else {
              this.notificationService.success('Email sent with success');
              this.modalVisualizarImpressao.close();
              this.emailData = new SendEmail();
            }
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error, 'Error');
          this.notificationService.error(
            'Error sending e-mail, please try again'
          );
          this.loading = false;
        },
      });
    }
  }

  private openEmailModal(funcao: string, opcao: string) {
    this.pedido = this.retornaElementoSelecionado() as any;
    const invoice = JSON.parse(this.optionInvoice ?? '{}');
    this.pedido.notaFiscal = invoice.nota ?? this.pedido.notaFiscal;
    this.pedido.serieNotaFiscal = invoice.serie ?? this.pedido.serieNotaFiscal;
    this.pedido.serieNotaFiscal = this.pedido.serieNotaFiscal ?? '';
    if (this.pedido) {
      this.pedido.funcao = funcao;
      this.pedido.opcao = opcao;
      const a = {
        funcao: funcao,
        [opcao]:
          this.pedido.opcao === 'salesorder'
            ? this.pedido.numeroPedido
            : this.pedido.notaFiscal,
        serie: this.pedido.serieNotaFiscal,
      };
      Object.assign(this.emailData, a);
      let literalInvoice = '';
      if (opcao === 'invoice') {
        literalInvoice = `Invoice ${this.pedido.notaFiscal}`;
        this.emailData.amount = invoice.valor;
      } else {
        literalInvoice = `Sales Order ${this.pedido.numeroPedido}`;
        this.emailData.amount = this.pedido.totalPedido;
      }
      this.emailData.cFrom = this.sessionService.email$;
      this.emailData.cEmailTo = this.pedido.A1_EMAIL;
      this.emailData.paytrace = false;
      this.hidePaymentLink = this.emailData.amount === 0 ? true : false;
      this.emailData.cAssunto = `Roberta Schilling Collection - ${literalInvoice} `;
      this.generateHtmlBody();
      this.modalVisualizarImpressao.open();
      this.loading = false;
    } else {
      this.notificationService.warning('Select a order before');
    }
  }

  public generateHtmlBody() {
    this.emailData.cfilename = this.generateFileName();
    this.emailData.chtml = this.base64;
    this.emailData.cdados = `amount=${this.emailData.amount}&invoice=${this.emailData.cfilename}&amountreadonly=true&invoicereadonly=true`;
    this.emailData.cAssunto = this.emailData.cAssunto ?? '';

    const isInvoice = this.pedido.notaFiscal.length > 1 ? true : false;
    let name;
    this.sessionService.userInformation$.subscribe(
      (data: any) => (name = data.title)
    );
    const nameEmailUser = `${name} - ${this.emailData.cFrom}`;
    let literalInvoice = '';
    if (this.emailData.cAssunto.indexOf('Invoice') !== -1) {
      literalInvoice = `invoice ${this.pedido.notaFiscal}`;
    } else {
      literalInvoice = `sales order ${this.pedido.numeroPedido}`;
    }

    const literalEmail =
      this.hidePaymentLink === false
        ? `for ${this.utilService.formatCurrency(
            this.emailData.amount,
            'en-US',
            'USD'
          )}`
        : '';

    const links = {
      sandbox:
        'https://paylink.paytrace.com?m=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMTI4R0NNIn0.cLjhYvjUCzAdKAMlqylkhXzM15jiThrc.7LVxulIGHl4Z5_gq.cqa0iiA70CXU7PP0-i0IGoR9gRBYDhNj-vMpKigkJ5juRvepThD7RPzc0g8tAjzKVRI02m-JQrNnC9EM95Xm9ojXiVoW_7usoXCdpvXJ3uAVhGvvnCQHy8PAOTulYdeMHBFHVCba1XNWsu9DrfDumVlyrxUrBg4kg5wnelOIt-XW2hsdXOWRvzaRuFBAH75Wr8RHzpHf2CytzzF-8dcQ4ZymnvMLZdC0D3MRGl5X0A96bviplsPlg-2gh45Zu6q7SyasQXFPtIoBe-6ALj2oJ1SfP7rMTfUVNSCJvGE.Wt-gOqSP9_8979qsYlcu9g',
      producao:
        'https://paylink.paytrace.com?m=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMTI4R0NNIn0.mm94viS7O-gZPVcUcadOhVYzDH8VgEG6.D9_ozRu4fdHFun46.8Lhl3lNmhHqvwlfdTm6AHbfSZtVcqwxLrx4Y0wbUeMjAK6BDwAkKINQT9D34NjAbW73UlckSWUOnoxuKY0u5eCQJib75jr-kKXj3t6sjSQBrsvjBUA3HwOx3Ioru__XFx_D3v32_kAjPYil6LPuV60v8fU7mBrEPTtdIOx3pdSacUVEKJreaS8W9NeYNCb5wHusR8RBYI3_np9laTv-ynBvUCjmBk8MZPF11fZOZGqcD61XrlodiLjfrq5E_9sPakIoEU__Yl_Y070fcglVu5EGUJb8efNEVcuOjGbE.k4tVaerQbht6tDLgoMYTKw&amountReadOnly=true&invoiceReadOnly=true',
    };
    const today = new Date();
    const year = today.getFullYear();
    if (this.emailData.paytrace) {
      this.emailData.cOptionalMensagem = `<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.0 Transitional//EN">
  <html>
  <head>
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  </head>
  <body link="000000" vlink="000000" alink="000000">
      <center>
          <table width="760" cellpadding="0" cellspacing="0" bgcolor="white">
              <tr>
                  <td align="center" colspan="3" valign="top">
                      <table width="100%" cellpadding="0" cellspacing="0" bordercolor="EEEEEE" border="1" bgcolor="white">
                          <tr>
                              <td>
                                  <table width="100%" cellpadding="0" cellspacing="0" bgcolor="white">
                                      <tr>
                                          <td align="center">
                                              <font face="Verdana" size="3" color=""><b>RSC Sales Associates: RSCOLL - ROBERTA SCHILLING COLLECTION</b></font>
                                          </td>
                                  </table>
                                  </tr>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
          <table width="760" cellpadding="0" cellspacing="0">
              <tr>
                  <td height="5"></td>
              </tr>
          </table>

          <table width="760" cellpadding="0" cellspacing="0" bordercolor="EEEEEE" border="1" bgcolor="white">
          <tbody><tr>
              <td>
                  <table width="100%" cellpadding="10" cellspacing="10" bgcolor="white">
                      <tbody><tr>
                          <td align="rigth">
                          <font face="Verdana" size="2" color="000000"><br><br>Dear Customer.
                              <br><br>Your ${literalInvoice} ${literalEmail} is attached.
                              <br><br>Thank you for your business - we appreciate it very much.
                              <br><br> Sincerely,
                              <br><br>${nameEmailUser}
                      </td>
                      </tr>
                  </tbody></table>
              </td>
          </tr>
      </tbody></table>
          <table width="760" cellpadding="0" cellspacing="0" bordercolor="EEEEEE" border="1" bgcolor="white">
              <tr>
                  <td>
                      <table width="100%" cellpadding="10" cellspacing="10" bgcolor="white">
                          <tr>
                              <td align="center">
                                  <font face="Verdana" size="2" color="000000"><br><br>This message has been sent to you from RSC Sales Associates who would like you to click the link below and process a $ ${this.emailData.amount} Payment on their secure web site.
                                      <br><br>-------------------------------------------------------------<br><br><a href="${links.producao}&${this.emailData.cdados}"
                                      target="_blank">Click here to make your
                                  payment</a></font>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
          <table width="760" cellpadding="0" cellspacing="0">
              <tr>
                  <td height="5"></td>
              </tr>
          </table>
          <table width="760" cellpadding="0" cellspacing="0">
              <tr>
                  <td align="center" colspan="3" valign="top" bgcolor="">
                      <table width="100%" cellpadding="0" cellspacing="0" bgcolor="white" border="1" bordercolor="EEEEEE">
                          <tr>
                              <td width="100%" height="20" align="center" valign="middle">
                                  <font face="Verdana" size="1" color="000000">
                                      <a href="https://www.rscollection.com">
                                          <font face="Verdana" size="1" color="000000"><b>https://www.rscollection.com</b>
                                          </font>
                                      </a>&nbsp;|&nbsp;
                                      <font face="Verdana" size="1" color="000000">Copyright ${year} <b>RSC Sales Associates</b> All Rights Reserved.</font>
                                  </font>
                              </td>
                          </tr>
                      </table>
                  </td>
              </tr>
          </table>
          </table>
      </center>
  </body>
  </html>`;
    } else {
      this.emailData.cOptionalMensagem = `
      <html><head></head>
    <body link="000000" vlink="000000" alink="000000">
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
    <center>
        <table width="760" cellpadding="0" cellspacing="0" bgcolor="white">
            <tbody><tr>
                <td align="center" colspan="3" valign="top">
                    <table width="100%" cellpadding="0" cellspacing="0" bordercolor="EEEEEE" border="1" bgcolor="white">
                        <tbody><tr>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" bgcolor="white">
                                    <tbody><tr>
                                        <td align="center">
                                            <font face="Verdana" size="3" color=""><b>RSC Sales Associates:
                                            RSCOLL - ROBERTA SCHILLING COLLECTION</b></font>
                                        </td>
                                </tr></tbody></table>
                                </td></tr>
                    </tbody></table>
                </td>
            </tr>
        </tbody></table>
        <table width="760" cellpadding="0" cellspacing="0">
            <tbody><tr>
                <td height="5"></td>
            </tr>
        </tbody></table>
        <table width="760" cellpadding="0" cellspacing="0" bordercolor="EEEEEE" border="1" bgcolor="white">
            <tbody><tr>
                <td>
                    <table width="100%" cellpadding="10" cellspacing="10" bgcolor="white">
                        <tbody><tr>
                            <td align="rigth">
                            <font face="Verdana" size="2" color="000000"><br><br>Dear Customer.
                                <br><br>Your ${literalInvoice} ${literalEmail} is attached.
                                <br><br>Thank you for your business - we appreciate it very much.
                                <br><br> Sincerely,
                                <br><br>${nameEmailUser}</font>
                        </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        </tbody></table>
        <table width="760" cellpadding="0" cellspacing="0">
            <tbody><tr>
                <td height="5"></td>
            </tr>
        </tbody></table>
        <table width="760" cellpadding="0" cellspacing="0">
            <tbody><tr>
                <td align="center" colspan="3" valign="top" bgcolor="">
                    <table width="100%" cellpadding="0" cellspacing="0" bgcolor="white" border="1" bordercolor="EEEEEE">
                        <tbody><tr>
                            <td width="100%" height="20" align="center" valign="middle">
                                <font face="Verdana" size="1" color="000000">
                                <a href="https://www.rscollection.com">
                                  <font face="Verdana" size="1" color="000000"><b>https://www.rscollection.com</b>
                                        </font>
                                    </a>&nbsp;|&nbsp;
                                    <font face="Verdana" size="1" color="000000">Copyright ${year} <b>RSC Sales Associates</b> All Rights Reserved.</font>
                                </font>
                            </td>
                        </tr>
                    </tbody></table>
                </td>
            </tr>
        </tbody></table>
    </center>
</body>
</html>
      `;
    }
  }

  public clickButton() {
    const links = {
      sandbox:
        'https://paylink.paytrace.com?m=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMTI4R0NNIn0.cLjhYvjUCzAdKAMlqylkhXzM15jiThrc.7LVxulIGHl4Z5_gq.cqa0iiA70CXU7PP0-i0IGoR9gRBYDhNj-vMpKigkJ5juRvepThD7RPzc0g8tAjzKVRI02m-JQrNnC9EM95Xm9ojXiVoW_7usoXCdpvXJ3uAVhGvvnCQHy8PAOTulYdeMHBFHVCba1XNWsu9DrfDumVlyrxUrBg4kg5wnelOIt-XW2hsdXOWRvzaRuFBAH75Wr8RHzpHf2CytzzF-8dcQ4ZymnvMLZdC0D3MRGl5X0A96bviplsPlg-2gh45Zu6q7SyasQXFPtIoBe-6ALj2oJ1SfP7rMTfUVNSCJvGE.Wt-gOqSP9_8979qsYlcu9g',
      producao:
        'https://paylink.paytrace.com/?m=eyJhbGciOiJBMjU2S1ciLCJlbmMiOiJBMTI4R0NNIn0.mm94viS7O-gZPVcUcadOhVYzDH8VgEG6.D9_ozRu4fdHFun46.8Lhl3lNmhHqvwlfdTm6AHbfSZtVcqwxLrx4Y0wbUeMjAK6BDwAkKINQT9D34NjAbW73UlckSWUOnoxuKY0u5eCQJib75jr-kKXj3t6sjSQBrsvjBUA3HwOx3Ioru__XFx_D3v32_kAjPYil6LPuV60v8fU7mBrEPTtdIOx3pdSacUVEKJreaS8W9NeYNCb5wHusR8RBYI3_np9laTv-ynBvUCjmBk8MZPF11fZOZGqcD61XrlodiLjfrq5E_9sPakIoEU__Yl_Y070fcglVu5EGUJb8efNEVcuOjGbE.k4tVaerQbht6tDLgoMYTKw',
    };
    this.emailData.cfilename = this.generateFileName();
    const btn = document.createElement('button');
    btn.innerHTML = 'Click here to pay';
    btn.type = 'submit';
    btn.onclick = () =>
      window.open(
        `${links.producao}&amount=${this.emailData.amount}&invoice=${this.emailData.cfilename}`,
        '_blank'
      );
    btn.hidden = true;
    btn.id = 'submitPayment';
    document.body.appendChild(btn);
    document.getElementById('submitPayment')!.click();
  }

  public generateFileName(option?: any) {
    let tipoPagamento = '';
    if (option) {
      tipoPagamento = `PACKING LIST ${this.numeroPedido}`;
    } else {
      tipoPagamento =
        this.pedido.notaFiscal.length > 1
          ? `INV. ${this.pedido.notaFiscal}`
          : `S.O. ${this.pedido.numeroPedido}`;
    }
    return tipoPagamento;
  }

  private retornaElementoSelecionado() {
    this.loading = true;
    const item = this.table.items
      .filter((a: any) => a.$selected === true)
      .pop();
    return item;
  }

  private openModalInvoice() {
    this.pedido = this.retornaElementoSelecionado() as any;
    const envio = {
      procedure: true,
      data: {
        SALES_ORDER: this.pedido.numeroPedido,
      },
      metodo: '',
    } as EnvioPadraoBackend;
    this.listaInvoiceService.getListaInvoice(envio).subscribe({
      next: (element: any) => {
        if (element.status) {
          this.emailData.paytrace = false;
          this.emailData.amount = this.pedido.totalDue;
          this.selects.invoices = element.data.map((item: any) => {
            return {
              label: `${item.D2_DOC}-${
                item.D2_SERIE
              } ${this.utilService.formatDateAmerican(item.D2_EMISSAO)} U$ ${
                item.D2_TOTAL
              }`,
              value: `{"nota":"${item.D2_DOC}","serie":"${item.D2_SERIE}","valor":"${item.D2_TOTAL}"}`,
            } as PoSelectOption;
          });
          this.optionInvoice = this.selects.invoices[0].value;
          this.hidePaymentLink = this.emailData.amount === 0 ? true : false;
          this.modalInvoice.open();
          this.loading = false;
        } else {
          this.notificationService.information(element.mensagem);
          this.loading = false;
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.error(error.error);
        this.notificationService.error(error.error);
      },
    });
  }

  public clearFilters() {
    this.form.reset();
    this.controls.filtros.setValue('numeroPedido');
    this.controls.somenteAbertos.setValue(3);
    this.getPedidosAberto();
  }

  private toExcel() {
    if (this.table.items.length >= 1) {
      this.loading = true;
      const obj = this.retornaFiltros();
      obj.page = 1;
      obj.limit = this.pagination.totalItems;
      const envio = {
        procedure: true,
        data: obj,
      };
      this.relacaoPedidosV2.getPedidos(envio).subscribe((element: any) => {
        const arr = element.data.data.map((a: any) => {
          return {
            ['Sales Order']: a.numeroPedido,
            ['Oper Type']: a.tipoOperacao,
            ['Emission Date']: this.utilService.formatDateAmerican(
              a.dataEmissao
            ),
            ['Customer Code']: a.codigoCliente,
            ['Customer Name']: a.nomeCliente,
            ['State']: a.estado,
            ['Po Number']: a.ponumber,
            ['Sales Rep.']: a.nomeRepresentante,
            ['Invoice']: a.notaFiscal,
            ['Total Order']: a.totalPedido,
            ['Paid']: a.valorRecebido,
            ['Total Due']: a.totalPedido - a.valorRecebido,
          };
        });
        this.excelService.exportAsExcelFile(arr, 'Dispatch-Orders');
        this.loading = false;
      });
    } else {
      this.notificationService.warning('Search first to export the excel');
    }
  }

  public convertBoo(value: boolean): string {
    if (value) {
      return 'true';
    } else {
      return 'false';
    }
  }

  public getListaProdutos(filter: any) {
    this.loading = true;
    const envio = {
      procedure: true,
      data: {
        filter,
        page: this.tableProdutos.page,
        limit: this.tableProdutos.limit,
      },
    };
    this.produtosService.getProdutos(envio).subscribe({
      next: (element: any) => {
        if (element.status) {
          this.tableProdutos.items = element.data.data;
          this.utilService.emitTotalItems.emit(element.data.total);
        } else {
          this.tableProdutos.items = [];
          this.notificationService.information('Not found');
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = true;
        this.notificationService.error(error.error.mensagem);
      },
    });
  }

  public onPageChangeProdutos(event: any) {
    this.tableProdutos.page = event;
    this.getListaProdutos(this.tableProdutos.filtro);
  }

  // INVOICE MODAL
  private validateOpenInvoiceModal() {
    const selectedItem = this.filterSelectedItemFromTable();

    if (!selectedItem.length) {
      return this.notificationService.warning('Please select an item!');
    } else if (
      selectedItem[0]['legenda'] !== 'color-09' &&
      selectedItem[0]['legenda'] !== 'color-10' &&
      selectedItem[0]['legenda'] !== 'color-05'
    ) {
      return this.notificationService.warning(
        'Please select sales order released!'
      );
    }

    let user = JSON.parse((localStorage.getItem('user') as any) ?? '');

    // if (user?.username === "rokamoto" && user?.roleId === 1) {
    this.resetInvoiceFields();
    const recno = selectedItem[0]['RECNO'] || 0;
    this.openInvoiceModal(recno);
    // } else {
    //   return this.notificationService.warning("You are not an admin!")
    // }
  }

  public openInvoiceModal(recno: any) {
    this.assingInvoiceModalSelects(recno);
    this.assignInvoiceModalRoles();
    //this.getOrder()
    //this.assingInvoiceModalSelects();
    //this.assignInvoiceModalRoles();
    this.invoiceModal.open();
  }

  private resetInvoiceFields() {
    this.invoiceForm.reset(this.validateOpenInvoiceModal);
  }

  private assingInvoiceModalSelects(recno: any) {
    /*
    this.getInvoiceSeries();
    this.getCustomers();
    this.getOperationsList();
    this.getListaStatusCode();
    this.getRepresentantes();
    this.getListaCondicaoPagamento();
    this.getListaTabelaPreco();
    */

    this.loading = true;
    this.getInvoiceSeries(false,() => {
      this.getCustomers(() => {
        this.getOperationsList(() => {
          this.getListaStatusCode(false, () => {
            this.getRepresentantes(false, () => {
              this.getListaCondicaoPagamento(false, () => {
                this.getListaTabelaPreco(false, () => {
                  this.getOrder(recno, false,() => {
                    this.loading = false;
                  });
                });
              });
            });
          });
        });
      });
    });

  }

  private filterSelectedItemFromTable() {
    const selectedItem =
      this.table.items.filter((item: any) => item.$selected) ?? [];
    return selectedItem;
  }

  private assignInvoiceModalRoles() {
    this.saveInvoiceModal = {
      action: () => this.saveInvoice(),
      label: 'Save',
    };
    this.cancelInvoiceModal = {
      action: () => {
        this.invoiceModal.close();
      },
      label: 'Cancel',
    };
  }

  private getOperationsList(callback:any) {
    const arr: Array<any> =
      this.activadeRoute.snapshot.data['listaTipoOperacao'].data;
    this.tipoOperacaoList = arr.map((t) => {
      return {
        label: `${t.tipoOperacao} - ${t.descricao}`,
        value: t.tipoOperacao,
      } as PoSelectOption;
    });
    callback()
  }

  private getCustomers(callback:any) {
    const ArrayListaClientes =
      this.activadeRoute.snapshot.data['listaClientes'].data.data;
    this.selects.customer = ArrayListaClientes.map((item: any) => {
      return {
        label: `${item.codigo}-${item.nomeFantasia} `,
        value: `"codigo":"${item.codigo}","loja":"${item.loja}"`,
      } as PoSelectOption;
    });

    callback()
  }

  private getRepresentantes(exibeLoad:boolean, callback:any) {
    if (exibeLoad) {
      this.loading = true;
    }
    this.representantesService
      .getRepresentantes({ limit: 99999, page: 1, procedure: true })
      .subscribe({
        next: (element: any) => {
          this.selects.representantes = element.data.data.map(
            (item: { codigo: any; nomeFantasia: any }) => {
              return {
                label: `${item.codigo}-${item.nomeFantasia}`,
                value: item.codigo,
              } as PoSelectOption;
            }
          );
          if (exibeLoad) {
            this.loading = false;
          }
          callback()
        },
        error: (error: HttpErrorResponse) => {
          if (exibeLoad) {
            this.loading = false;
          }
          console.error(error.error);
          callback()
        },
      });
  }

  private getListaCondicaoPagamento(exibeLoad:boolean, callback:any) {
    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;

    if (exibeLoad) {
      this.loading = true;
    }

    this.condicaoPagamentoService
      .getListaCondicaoPagamento(envio)
      .subscribe((element: any) => {
        this.selects.condicaoPagamento = element.data.map((item: any) => {
          return {
            label: `${item.codigo}-${item.descricao}`,
            value: item.codigo,
          } as PoSelectOption;
        });
        if (exibeLoad) {
          this.loading = false;
        }
        callback()
      });
  }

  private getInvoiceSeries(exibeLoad:boolean,callback:any) {
    try {
      const envio = {
        procedure: true,
        homologacao: false,
        metodo: 'LISTA_INVOICE_SERIES',
        data: {},
      };
      if (exibeLoad){
        this.loading = true;
      }
      this.listasManterService.manter(envio).subscribe({
        next: (resposta: any) => {
          try {
            if (resposta.status) {
              this.selects.series = resposta.data.map((item: any) => {
                return {
                  label: `${item.DESCRICAO}`,
                  value: item.CODIGO,
                  PROX_F2_DOC: item.PROX_F2_DOC,
                } as any;
              });

              if (this.selects.series.length > 0) {
                this.invoiceForm.controls['F2_SERIE'].setValue(
                  this.selects.series[0].value
                );
                this.invoiceForm.controls['F2_DOC'].setValue(
                  this.selects.series[0].PROX_F2_DOC
                );
              }

              if (exibeLoad) {
                this.loading = false;
              }
            } else {
              this.notificationService.warning(resposta.mensagem);
              if (exibeLoad) {
                this.loading = false;
              }
            }

            callback()
          } catch (err: any) {
            this.notificationService.error(err);
            if (exibeLoad) {
              this.loading = false;
            }
            callback()
          }
        },
        error: (err: any) => {
          this.notificationService.error(err);
          if (exibeLoad) {
            this.loading = false;
          }
          callback()
        },
      });
    } catch (err: any) {
      this.notificationService.error(err);
      if (exibeLoad) {
        this.loading = false;
      }
      callback()
    }
  }

  private getListaTabelaPreco(exibeLoad: boolean ,callback:any) {
    if (exibeLoad) {
      this.loading = true;
    }

    const envio = {
      procedure: true,
      data: {},
      metodo: '',
    } as EnvioPadraoBackend;
    this.listaTabelaPrecoService.getListaTabelaPrecos(envio).subscribe({
      next: (element: any) => {
        if (element.status) {
          this.selects.tabelaPreco = element.data.map((item: any) => {
            return {
              label: `${
                item.codigoTabela
              } - ${item.descricaoTabela.toUpperCase()}`,
              value: item.codigoTabela,
            } as PoSelectOption;
          });
        } else {
          this.notificationService.warning(element.mensagem);
        }
        if (exibeLoad) {
          this.loading = false;
        }
        callback()
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        if (exibeLoad) {
          this.loading = false;
        }
        callback()
      },
    });
  }

  private getOrder(recno: number, exibeLoad:boolean, callback:any) {
    if (exibeLoad) {
      this.loading = true;
    }
    const obj = {
      recno,
    };
    this.pedidoItemService.getPedido(obj).subscribe({
      next: (a: any) => {
        const data = a.data.data.pop();
        this.invoiceForm.patchValue(data, { emitEvent: false, onlySelf: true });
        this.invoiceForm.controls['C5_CLIENTE'].setValue(
          `"codigo":"${data.C5_CLIENTE}","loja":"${data.C5_LOJACLI}"`,
          { emitEvent: false }
        );

        this.invoiceForm.controls['C5_TOTAL'].setValue(
          data.F2_VALIMP1 > 0 ? data.F2_VALIMP1 : data.C5_XPRVSTX
        );

        const dataEmissao =
          data.C5_EMISSAO.length > 0
            ? this.utilService.multiFormataData(data.C5_EMISSAO, 'mm/dd/yyyy')
            : data.C5_EMISSAO;

        this.invoiceForm.controls['C5_EMISSAO'].setValue(dataEmissao);

        const dataColeta =
          data.C5_XCOLEDA === '1900-01-01' ? '' : data.C5_XCOLEDA;

        this.invoiceForm.controls['C5_XCOLEDA'].setValue(dataColeta);

        this.invoiceModalTable.items = data.SC6.map((data: any) => {
          return { ...data, C6_VALOR: data.C6_QTDVEN * data.C6_VALOR, C6_QTDLIB:  0 };
        });

        this.invoiceModal.title = `Order No. : ${data.C5_NUM} - Emission : ${dataEmissao} `;

        const dataAtual = this.utilService.dataAtual();
        this.invoiceForm.controls['F2_EMISSAO'].setValue(dataAtual);

        if (this.selects.series.length > 0) {
          this.invoiceForm.controls['F2_SERIE'].setValue(
            this.selects.series[0].value
          );
          this.invoiceForm.controls['F2_DOC'].setValue(
            this.selects.series[0].PROX_F2_DOC
          );
        }

        this.calculateTotal();

        if (exibeLoad) {
          this.loading = false;
        }
        callback()
      },
      error: (error) => {
        console.error('error --->', error);
        this.notificationService.error('Error in loading data');
        if (exibeLoad) {
          this.loading = false;
        }
        callback()
      },
    });
  }

  public saveInvoice() {

    //valida se houve a digitacao de algum item
    const itens = this.invoiceModalTable.items.filter(
      (item: any) => item.C6_QTDLIB > 0
    );
    if (itens.length === 0) {
      this.notificationService.warning('No items have been released, please enter the quantity to generate the invocation');
      return
    }

    //total em estoque zerado
    const itensEstoqueZero = this.invoiceModalTable.items.filter((linha) => {
      const qtdeEstoque = (linha.QTDESTOQUE + linha.QTDRES)
      return qtdeEstoque <= 0
    });
    // if (itensEstoqueZero.length > 0 ){
    //   this.notificationService.warning('Some products are out of stock');
    //   return
    // }

    //total em estoque menor que a quantidade a ser liberada
    const itensEstoqueindisponiveis = this.invoiceModalTable.items.filter((linha) => {
      const qtdeEstoque = (linha.QTDESTOQUE + linha.QTDRES)
      const qtdeDisponivelParaLiberar = (linha.C6_QTDVEN - linha.C6_QTDENT)

      return qtdeEstoque < qtdeDisponivelParaLiberar
    });
    // if (itensEstoqueindisponiveis.length > 0) {
    //   this.notificationService.warning('some products have insufficient stock to meet the quantity released');
    //   return
    // }

    //qtde liberado nao pode ser maior que a qtde ven e a quantidade liberada anteriormente
    const itensQtdeLibMaiorQueQtdVenMaisQtdeLiberadoAnterior = this.invoiceModalTable.items.filter((linha) => {
      return ((linha.C6_QTDLIB + linha.C6_QTDENT) > linha.C6_QTDVEN)
    });
    if (itensQtdeLibMaiorQueQtdVenMaisQtdeLiberadoAnterior.length > 0){
      this.notificationService.warning('Some Released quantity cannot be greater than sale quantity + previously released quantity');
    }

    if (!this.validateDocumentNumber().valid) {
      return this.notificationService.warning(
        `The document number cannot be greater than ${
          this.validateDocumentNumber().nextDocumentNumber
        } : Check and try again.`
      );
    } else {
      try {

        const object = {
          metodo: 'INCLUIR',
          procedure: false,
          homologacao: false,
          data: {
            C5_NUM: this.invoiceForm.controls['C5_NUM'].value,
            F2_EMISSAO: this.invoiceForm.controls['F2_EMISSAO'].value,
            F2_SERIE: this.invoiceForm.controls['F2_SERIE'].value,
            F2_DOC: this.invoiceForm.controls['F2_DOC'].value,
            ITEM: itens//this.invoiceModalTable.items
          },
        };
        (JSON.stringify(object))
        //return
        this.loading = true;
        this.invoiceSalesOrderService.postInvoiceSalesOrder(object).subscribe({
          next: (response: {
            status: any;
            titulos: string | any[];
            mensagem: string;
            data: { titulos: any };
          }) => {
            try {
              if (response.status) {
                if (response.titulos?.length) {
                  this.setCompentationModal(
                    response.mensagem,
                    response.data.titulos
                  );
                } else {
                  this.notificationService.success(response.mensagem);
                  this.invoiceModal.close();
                }
                this.getPedidosAberto();
              } else {
                this.notificationService.warning(response.mensagem);
                this.loading = false;
              }
            } catch (err: any) {
              this.notificationService.error(err);
              this.loading = false;
            }
          },
          error: (err: string | PoNotification) => {
            this.notificationService.error(err);
            this.loading = false;
          },
        });
      } catch (err: any) {
        this.notificationService.error(err);
        this.loading = false;
      }
    }
  }

  public defineStatusClass(value: boolean): string {
    if (value === true) {
      return 'SETTLEMENT';
    } else {
      return 'NO SETTLEMENT';
    }
  }

  private validateDocumentNumber(): ReturnValidateDocumentNumber {
    const filteredItems = this.selects.series.filter(
      (item) => item.value === this.invoiceForm.controls['F2_SERIE'].value
    );
    const filteredItem = filteredItems[filteredItems.length - 1];
    if (filteredItem) {
      return {
        valid:
          parseInt(this.invoiceForm.controls['F2_DOC'].value) >
          parseInt(filteredItem.PROX_F2_DOC)
            ? false
            : true,
        nextDocumentNumber: filteredItem.PROX_F2_DOC,
      };
    } else {
      return {
        valid: false,
      };
    }
  }

  private setCompentationModal(title: string, tableData: Array<any>) {
    this.compensationModalTable.items = [];
    this.compensationModalTitle = title;
    this.compensationModalTable.items = tableData;
    this.loading = false;
    this.compensationModal.open();
  }

  public calculateTotal() {
    const somaTotalProdutos = this.invoiceModalTable.items.reduce(
      (total, element) => element.C6_VALOR + total,
      0
    ) as number;

    const salesTax = this.invoiceForm.controls['C5_TOTAL'].value as number;
    const freight =
      this.invoiceForm.controls['C5_FRETE'].value ?? (0 as number);
    const packingFee =
      this.invoiceForm.controls['C5_DESPESA'].value ?? (0 as number);
    const sum = freight + salesTax + (somaTotalProdutos + packingFee);
    this.invoiceForm.controls['TOTAL'].setValue(sum);
    this.invoiceForm.controls['totalDue'].setValue(
      sum - this.invoiceForm.controls['valorRecebido'].value
    );
  }
}
