<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb">
  <form [formGroup]="form">
    <po-tabs [p-small]="false">
      <po-tab p-label="Order" [p-active]="true">
        <div class="po-row po-mt-4">
          <po-input p-label="Order No." p-disabled="true" class="po-md-1" formControlName="C5_NUM"></po-input>
          <po-input p-label="ID GoSales" p-disabled="true" class="po-md-1" formControlName="C5_XPEDAPP"></po-input>
          <!-- <po-combo p-label="Customer" [p-filter-mode]="poFilterMode.contains" [p-disabled]="disableF" class="po-md-4" formControlName="C5_CLIENTE" [p-options]="select.customer">
                    </po-combo> -->
          <div class="positionRelative po-md-4">
            <po-input name="input" p-label="Customer (Code-Unit-Name)" p-placeholder="" p-readonly="true"
              [p-disabled]="returnBooleanForPoUi(disableF)" class="paddingInput" formControlName="customer"></po-input>
            <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" [p-disabled]="disableF"
              (p-click)="openModalClientes()">
            </po-button>
            <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger" [p-disabled]="disableF"
              (p-click)="clearCustomer()">
            </po-button>
          </div>
          <po-input p-label="Emission" p-disabled="true" class="po-md-2" formControlName="C5_EMISSAO" p-locale="en">
          </po-input>
          <po-select p-label="Freight Type" [p-disabled]="disableF" class="po-md-2" formControlName="C5_TPFRETE"
            [p-options]="select.freightType">
          </po-select>
          <po-select p-label="Invoice" [p-options]="select.invoice" class="po-md-2" formControlName="C5_NOTA">
          </po-select>
          <!-- <po-input
            *ngIf="this.quantidadeInvoice <= 1"
            p-label="Invoice"
            class="po-md-3"
            formControlName="C5_NOTA"
            p-readonly="true"
            p-placeholder="Invoice"
          ></po-input> -->
          <po-select p-label="Oper Type" [p-disabled]="disableF" class="po-md-3" formControlName="C5_OPER"
            [p-options]="tipoOperacaoList"></po-select>
          <po-input p-label="PO Number" [p-disabled]="returnBooleanForPoUi(disableF)" class="po-md-2"
            formControlName="C5_XPO" [p-maxlength]="20"></po-input>
          <po-select p-label="Price List" [p-disabled]="disableF" class="po-md-1" formControlName="C5_TABELA"
            [p-options]="select.tabelaPreco">
          </po-select>
          <po-select p-label="Sales Rep" [p-disabled]="disableF" class="po-md-2" formControlName="C5_VEND1"
            [p-options]="select.representante">
          </po-select>
          <po-select p-label="Sales Rep 2" [p-disabled]="disableF" class="po-md-2" formControlName="C5_VEND2"
            [p-options]="select.representante">
          </po-select>
          <po-input p-label="Invoice Date" p-disabled="true" class="po-md-2" formControlName="F2_EMISSAO">
          </po-input>
          <po-select p-label="Payment Term" [p-disabled]="disableF" class="po-md-2" formControlName="C5_CONDPAG"
            [p-options]="select.condicaoPagamento">
          </po-select>
          <po-select [p-disabled]="disableF" p-label="Status Code" class="po-md-2" formControlName="C5_XCODSTA"
            [p-options]="select.statusCode"></po-select>
          <po-decimal p-label="Sales Tax" class="po-md-1" formControlName="C5_TOTAL" p-locale="en" p-disabled="true">
          </po-decimal>
          <po-decimal p-label="Freight" class="po-md-1" formControlName="C5_FRETE" p-locale="en"
            [p-disabled]="returnBooleanForPoUi(disableF)" (p-change-model)="calculaTotal()"></po-decimal>
          <po-decimal p-label="Packing Fee" class="po-md-2" formControlName="C5_DESPESA" p-locale="en"
            [p-disabled]="returnBooleanForPoUi(disableF)" (p-change-model)="calculaTotal()" [p-min]="0">
          </po-decimal>
          <po-decimal p-label="Total" class="po-md-1" formControlName="TOTAL" p-locale="en" p-disabled="true">
          </po-decimal>
          <po-decimal p-label="Paid" class="po-md-1" formControlName="valorRecebido" p-locale="en" p-disabled="true">
          </po-decimal>
          <po-decimal p-label="Total Due" class="po-md-2" formControlName="totalDue" p-locale="en" p-disabled="true">
          </po-decimal>
        </div>
      </po-tab>
      <po-tab p-label="Notes">
        <div class="po-row po-mt-4">
          <po-textarea [p-disabled]="disabelEdit" p-label="Commercial Notes" class="po-md-4"
            formControlName="C5_XOBSCOM"></po-textarea>
          <po-textarea [p-disabled]="disabelEdit" p-label="Financial Notes" class="po-md-4" formControlName="C5_XFIN">
          </po-textarea>
          <po-textarea [p-disabled]="disabelEdit" p-label="Additional Notes" class="po-md-4"
            formControlName="C5_XOBSFEI"></po-textarea>
          <!-- <po-textarea [p-disabled]="disabelEdit" p-label="Internal Note 1" class="po-md-4"
                        formControlName="C5_XOBSGC1" [p-maxlength]="254"></po-textarea>
                    <po-textarea [p-disabled]="disabelEdit" p-label="Internal Note 2" class="po-md-4"
                        formControlName="C5_XOBSGC2" [p-maxlength]="254"></po-textarea> -->
        </div>
      </po-tab>
      <po-tab p-label="Bill to">
        <po-input p-label="Customer" p-disabled="true" class="po-md-4" formControlName="A1_NOME"></po-input>
        <po-input p-label="Address" p-disabled="true" class="po-md-4" formControlName="A1_END"></po-input>
        <po-input p-label="State" p-disabled="true" class="po-md-1" formControlName="A1_EST"></po-input>
        <po-input p-label="City" p-disabled="true" class="po-md-2" formControlName="A1_MUN"></po-input>
        <po-input p-label="Zip Code" p-disabled="true" class="po-md-1" formControlName="A1_CEP"></po-input>
        <po-input p-label="E-mail" p-disabled="true" class="po-md-4" formControlName="A1_EMAIL"></po-input>
        <po-input p-label="Fone" p-disabled="true" class="po-md-4" formControlName="A1_TEL"></po-input>
      </po-tab>
      <po-tab p-label="Ship To">
        <!-- <div class="po-md-6 po-mt-4"> -->
        <po-textarea class="po-md-12" [p-rows]="5" p-label="Ship to" [p-disabled]="disabelEdit"
          formControlName="C5_XLOG"></po-textarea>
        <!-- </div> -->
        <!-- <div class="po-md-6 po-mt-4">
                    <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" class="po-md-12" p-label="Ship to Name" [p-maxlength]="30" formControlName="C5_XSHIPTO"></po-input>
                    <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" class="po-md-8" p-label="Address" [p-maxlength]="60" formControlName="C5_XENDENT">
                    </po-input>
                    <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" class="po-md-4" p-label="City" [p-maxlength]="60" formControlName="C5_XENDEN2">
                    </po-input>
                    <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" class="po-md-6" p-label="Zip code" [p-maxlength]="6" formControlName="C5_XSHPZIP">
                    </po-input>
                    <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" class="po-md-6" p-label="State" [p-maxlength]="2" formControlName="C5_XSHPUF">
                    </po-input>
                    <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" class="po-md-12" p-label="E-mail" [p-maxlength]="70" formControlName="C5_XSHPEMA">
                    </po-input>
                </div> -->
      </po-tab>
      <po-tab p-label="Dispatch Control" [p-active]="false">
        <div class="po-md-9 po-mt-4">
          <po-select [p-disabled]="disabelEdit" p-label="Status" class="po-md-3" formControlName="C5_XDESPAC"
            [p-options]="select.status"></po-select>
          <po-select [p-disabled]="disabelEdit" p-label="Freight Forwarder" class="po-md-5"
            formControlName="FreighForwarder" [p-options]="select.transportadoras"></po-select>
          <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" p-label="Collector Name" class="po-md-4"
            formControlName="C5_XCOLENA"></po-input>
          <po-datepicker [p-disabled]="returnBooleanForPoUi(disabelEdit)" p-label="Collect Date" class="po-md-2"
            formControlName="C5_XCOLEDA" p-locale="en" p-format="mm/dd/yyyy">
          </po-datepicker>
          <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" p-label="Tracking Number" class="po-md-2"
            formControlName="C5_XTRACNU"></po-input>
          <po-decimal [p-disabled]="returnBooleanForPoUi(disabelEdit)" p-label="Volume Qty" class="po-md-2"
            formControlName="C5_VOLUME1"></po-decimal>
          <po-input [p-disabled]="returnBooleanForPoUi(disabelEdit)" p-label="Volume Name" class="po-md-2"
            formControlName="C5_ESPECI1"></po-input>
          <po-decimal [p-disabled]="returnBooleanForPoUi(disabelEdit)" p-label="Gross Weight" class="po-md-2"
            formControlName="C5_PBRUTO"></po-decimal>
          <po-decimal [p-disabled]="returnBooleanForPoUi(disabelEdit)" p-label="Net Weight" class="po-md-2"
            formControlName="C5_PESOL"></po-decimal>
        </div>
        <div class="po-md-3 po-mt-4">
          <po-textarea [p-disabled]="disabelEdit" p-label="Notes" class="po-md-12" [p-rows]="5"
            formControlName="C5_XNOTE"></po-textarea>
        </div>
      </po-tab>
    </po-tabs>
  </form>
  <po-divider class="po-md-10" p-label="Items"> </po-divider>
  <po-button class="po-md-2 po-mt-2" p-label="Add" p-icon="po-icon-plus" p-type="primary" (p-click)="openModal()"
    [p-disabled]="disableF">
  </po-button>
  <div class="po-row po-mt-4">
    <po-table class="po-md-12" [p-height]="table.height" [p-items]="table.items" [p-columns]="table.columns"
      [p-sort]="true" [p-actions]="table.actions" [p-hide-text-overflow]="true" [p-striped]="true">
      <ng-template p-table-cell-template let-column="column" let-row="row">
        <div *ngIf="row.AUTDELETA === 'S'">
          <span *ngIf="column.property === 'C6_ITEM'" [style.background]="'#b6bdbf'">{{ row.C6_ITEM }}</span>
          <span *ngIf="column.property === 'C6_PRODUTO'" [style.background]="'#b6bdbf'">{{ row.C6_PRODUTO }}</span>
          <span *ngIf="column.property === 'C6_DESCRI'" [style.background]="'#b6bdbf'">{{ row.C6_DESCRI }}</span>
          <span *ngIf="column.property === 'C6_QTDVEN'" [style.background]="'#b6bdbf'">{{ row.C6_QTDVEN }}</span>
          <span *ngIf="column.property === 'C6_PRCVEN'" [style.background]="'#b6bdbf'">{{ row.C6_PRCVEN | currency:
            "USD" }}</span>
          <span *ngIf="column.property === 'C6_VALOR'" [style.background]="'#b6bdbf'">{{ row.C6_VALOR | currency: "USD"
            }}</span>
          <span *ngIf="column.property === 'QTDESTOQUE'" [style.background]="'#b6bdbf'">{{ row.QTDESTOQUE }}</span>
          <span *ngIf="column.property === 'QTDRES'" [style.background]="'#b6bdbf'">{{ row.QTDRES }}</span>
          <span *ngIf="column.property === 'C6_QTDENT'" [style.background]="'#b6bdbf'">{{ row.C6_QTDENT }}</span>
          <span *ngIf="column.property === 'C6_LOCAL'" [style.background]="'#b6bdbf'">{{ row.C6_LOCAL }}</span>
          <span *ngIf="column.property === 'C6_XOBS'" [style.background]="'#b6bdbf'">{{ row.C6_XOBS }}</span>
          <span *ngIf="column.property === 'C6_TES'" [style.background]="'#b6bdbf'">{{ row.C6_TES }}</span>
        </div>

        <div *ngIf="row.AUTDELETA === 'N'">
          <span *ngIf="column.property === 'C6_ITEM'">{{ row.C6_ITEM }}</span>
          <span *ngIf="column.property === 'C6_PRODUTO'">{{
            row.C6_PRODUTO
            }}</span>
          <span *ngIf="column.property === 'C6_DESCRI'">{{
            row.C6_DESCRI
            }}</span>
          <span *ngIf="column.property === 'C6_QTDVEN'">{{
            row.C6_QTDVEN
            }}</span>
          <span *ngIf="column.property === 'C6_PRCVEN'">{{
            row.C6_PRCVEN | currency: "USD"
            }}</span>
          <span *ngIf="column.property === 'C6_VALOR'">{{
            row.C6_VALOR | currency: "USD"
            }}</span>
          <span *ngIf="column.property === 'QTDESTOQUE'">{{
            row.QTDESTOQUE
            }}</span>
          <span *ngIf="column.property === 'QTDRES'">{{ row.QTDRES }}</span>
          <span *ngIf="column.property === 'C6_QTDENT'">{{
            row.C6_QTDENT
            }}</span>
          <span *ngIf="column.property === 'C6_LOCAL'">{{ row.C6_LOCAL }}</span>
          <span *ngIf="column.property === 'C6_XOBS'">{{ row.C6_XOBS }}</span>
          <span *ngIf="column.property === 'C6_TES'">{{ row.C6_TES }}</span>
        </div>
      </ng-template>
    </po-table>
    <div class="po-md-4"></div>
    <po-info class="po-md-1 po-mt-2" p-label="Total Items" [p-value]="totalItems"
      [p-orientation]="poOrientation.horizontal">
    </po-info>
    <po-info class="po-md-2 po-mt-2" p-label="Grand Total" p-value="{{ grandTotal | currency: 'USD' }}"
      [p-orientation]="poOrientation.horizontal" p-locale="en"></po-info>
  </div>
</po-page-default>

<po-modal #modal p-title="Add" [p-primary-action]="primaryActionModal" [p-secondary-action]="secondaryActionModal"
  p-size="md">
  <div [formGroup]="modalForm">
    <div class="positionRelative po-md-6">
      <po-input name="input" p-label="Product" p-placeholder="" p-disabled="true" class="paddingInput"
        formControlName="C6_PRODUTO"></po-input>
      <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" [p-disabled]="disabledFieldsModalForm"
        (p-click)="openModalList('C6_PRODUTO')">
      </po-button>
      <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger"
        [p-disabled]="disabledFieldsModalForm" (p-click)="limparField(modalForm.controls)">
      </po-button>
    </div>
    <po-input class="po-md-6" formControlName="C6_DESCRI" p-required="true" p-disabled="true" p-label="Description"
      [p-maxlength]="255">
    </po-input>
    <po-decimal p-label="Qty" class="po-md-4" formControlName="C6_QTDVEN" p-locale="en"
      (p-change-model)="calcTotalItem()" [p-decimals-length]="2" [p-min]="modalForm.controls['C6_QTDENT'].value"
      p-disabled="false"></po-decimal>
    <po-decimal p-label="Qty. Invoiced" class="po-md-4" formControlName="C6_QTDENT" p-locale="en"
      [p-decimals-length]="2" p-disabled="true" [p-min]="0"></po-decimal>
    <po-decimal p-label="Stock" class="po-md-4" formControlName="QTDESTOQUE" p-locale="en" [p-decimals-length]="2"
      p-disabled="true"></po-decimal>
    <po-decimal p-label="Price" class="po-md-4" formControlName="C6_PRCVEN" p-locale="en"
      (p-change-model)="calcTotalItem()" [p-decimals-length]="2" p-disabled="false" [p-min]="1"></po-decimal>
    <po-decimal p-label="Total" class="po-md-4" formControlName="C6_VALOR" p-locale="en" [p-decimals-length]="2"
      p-disabled="true"></po-decimal>
    <po-select class="po-md-4" p-label="Warehouse" formControlName="C6_LOCAL" [p-options]="warehouseSelect"
      (p-change)="warehouseChange($event)">
    </po-select>
    <po-select class="po-md-12" p-label="Outflow Type - Description - Update Inventory - Gen. Accounts Receivable - % Sales Tax" formControlName="C6_TES" [p-options]="select.tes"></po-select>
    <po-textarea class="po-md-12" formControlName="C6_XOBS" p-label="Notes" [p-rows]="3">
    </po-textarea>
  </div>
  <div class="po-row">
    <po-table class="po-md-12" p-container="box" [p-columns]="tableF4EstoqueProduto.columns"
      [p-items]="tableF4EstoqueProduto.items" [p-height]="tableF4EstoqueProduto.height"
      [p-actions]="tableF4EstoqueProduto.actions" [p-hide-text-overflow]="true"></po-table>
  </div>
</po-modal>

<po-modal #modalSearch [p-title]="titlePopup" [p-primary-action]="confirmModalSearch"
  [p-secondary-action]="closeModalSearch">
  <div class="po-row" (keyup.enter)="searchListPromise()">
    <po-input class="po-md-6" [(ngModel)]="tableSearchPopup.fieldValue.filter" p-label="Product / Description"
      p-clean="true">
    </po-input>

    <po-input class="po-md-4" [(ngModel)]="tableSearchPopup.fieldValue.group" p-label="Collection" p-clean="true">
    </po-input>

    <po-button class="po-md-2 po-mt-4" (p-click)="searchListPromise()" p-icon="po-icon-search" p-label="Search"
      p-type="primary"></po-button>
  </div>

  <div class="po-row">
    <po-table [p-hide-columns-manager]="true" [p-columns]="tableSearchPopup.columns" [p-items]="tableSearchPopup.items"
      [p-height]="tableSearchPopup.height" [p-hide-text-overflow]="true" [p-sort]="false" [p-striped]="true"
      [p-loading]="tableSearchPopup.loading" [p-literals]="tableSearchPopup.customLiterals" [p-selectable]="true"
      [p-single-select]="true" (p-selected)="selectProd($event)" (p-unselected)="unselectedProd()">
    </po-table>
  </div>
</po-modal>

<po-modal #modalActionsF4Estoque p-title="" [p-hide-close]="true" [p-click-out]="false">
  <div class="po-row">
    <po-table class="po-md-12" [p-literals]="talbeActionF4Estoque.literals" [p-columns]="talbeActionF4Estoque.columns"
      [p-items]="talbeActionF4Estoque.items" [p-height]="talbeActionF4Estoque.height"></po-table>
  </div>
</po-modal>

<po-modal #modalClientes p-title="Customers" [p-hide-close]="true" [p-click-out]="false">
  <po-input p-clean="true" class="po-md-10" p-label="Customer Code" p-clean="true"
    [ngModel]="modalClientesProperties.codigoCliente" (p-change-model)="modalClientesProperties.codigoCliente = $event">
  </po-input>
  <po-button class="po-md-2 po-mt-4" p-label="Search" p-icon="po-icon-search" p-type="primary"
    (p-click)="getRelacaoClientes()"></po-button>
  <div class="po-row">
    <po-table [p-columns]="modalClientesProperties.table.columns" [p-items]="modalClientesProperties.table.items"
      [p-actions]="modalClientesProperties.table.actions" [p-height]="modalClientesProperties.table.height"
      class="po-md-12"></po-table>
    <app-pagination class="po-md-12" [totalItems]="modalClientesProperties.pagination.total"
      [itemsPerPage]="modalClientesProperties.pagination.limit" [currentPage]="modalClientesProperties.pagination.page"
      [disabled]="this.loading" (pageChanged)="pageChangeModalClientes($event)"></app-pagination>
  </div>
</po-modal>

<po-loading-overlay [p-screen-lock]="true" p-text="Loading, please wait ..." *ngIf="loading">
</po-loading-overlay>