import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface RetornoTransfer {
  data: Array<any>,
  mensagem: string,
  status: boolean
}

@Injectable({
  providedIn: 'root'
})

export class TransferEstoqueService {


  constructor(
    private httpClient: HttpClient,
  ) { }

  private relativeLink = 'transfer_estoque'
  private linkRestDireto = 'http://38.108.45.131:11724/REST/RSC/transfer_estoque'

  getTransferEstoque(param: any): Observable<RetornoTransfer> {
    // const params = this.utilService.retornaFiltros(param);
    // const params: HttpParams = new HttpParams({fromObject: param});
    param.homologacao = !environment.production;
    return this.httpClient.post(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`, param) as Observable<RetornoTransfer>;
  }

}
