import { environment } from './../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ResaleCertificateRecordEnvio {
  data: {
    ZA2_URL: string,
    ZA2_DATA: string,
    ZA2_HORA: string,
    ZA2_NOTA: string,
    ZA2_CODCLI: string,
    ZA2_LOJACL: string,
    ZA2_EXPDT: string,
    ZA2_NRCERT: string,
    RECNO: number,
    DELET: number
  },
  procedure: boolean,
  homologacao: boolean,
  tipoFile: number,
}

export interface ResaleCertificateGet {
  RECNO?: number,
  ZA2_CODCLI?: string,
  ZA2_LOJACL?: string,
}

interface RetornoResaleCertificateGet {
  response: [
    {
      ZA2_CODCLI: string,
      ZA2_LOJACL: string,
      ZA2_DATA: string,
      ZA2_HORA: string,
      ZA2_NOTA: string,
      ZA2_EXPDT: string,
      ZA2_URL: string,
      ZA2_NRCERT: string,
    }
  ]
}

interface RetornoRecordResaleCertificate {
  chave: number,
  gravado: number,
  mensagem: string
}

@Injectable({
  providedIn: 'root'
})
export class ResaleCertificateService {

  constructor(
    private http: HttpClient
  ) { }

  private apiRootRecord = `${environment.URL.APIPRIVATE}/v1/upload-resale-certificate`;
  private apiRootGet = `${environment.URL.APIPRIVATE}/downloadfile`;

  public addNewResaleCertificate(file: File, jsonParam: ResaleCertificateRecordEnvio): Observable<RetornoRecordResaleCertificate> {
    jsonParam.homologacao = !environment.production
    const formData = new FormData();
    formData.append('file', file);
    formData.append('dados', JSON.stringify(jsonParam));
    return this.http.post(this.apiRootRecord, formData) as Observable<RetornoRecordResaleCertificate>;
  }

  public getResaleCertificate(key: any) {
    return this.http.get(`${this.apiRootGet}/${key}`, { responseType: 'blob' })
  }
}
