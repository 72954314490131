import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseJavaApi } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class ListasService {
  constructor(private http: HttpClient) {}

  private endpoint = 'listas';

  returnListas(param: any): Observable<ResponseJavaApi<any>> {
    param.homologacao = !environment.production;
    return this.http.post(
      `${environment.URL.APIPRIVATE}/v1/${this.endpoint}`,
      param
    ) as Observable<ResponseJavaApi<any>>;
  }

  returnListasProtheus(param: any) {
    param.homologacao = !environment.production;
    return this.http.post(`http://38.108.45.131:11724/rest/rsc/listas`, param);
  }
}
