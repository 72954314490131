<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb" [p-literals]="page.literals">
    <po-tabs>
        <po-tab p-label="Wire Transfer" [p-active]="true">

            <po-dynamic-form [p-fields]="fieldsContainer" [p-value]="containerForm">
            </po-dynamic-form>

            <div class="po-row">
                <po-divider [ngClass]="{'po-md-12': this.isGenerate, 'po-md-10': !this.isGenerate}" p-label="Items">
                </po-divider>
                <po-button *ngIf="!this.isGenerate" class="po-md-2 po-mt-2" p-type="primary" p-icon="po-icon-plus" p-label="Add Item" (p-click)="openModalList('PO_WIRE','PO_WIRE')" [p-disabled]="tipoTela === 'view' || tipoTela === 'delete' ? true : false"></po-button>
                <po-table id="scrollSome" style="width: 100%;" #poTableContainer [p-sort]="true" [p-hide-text-overflow]="true" [p-columns]="table.columns" [p-striped]="true" [p-items]="table.items" [p-height]="table.height"></po-table>
            </div>



        </po-tab>
        <po-tab p-label="Upfront" (p-click)="validarUpFront(false)">

            <div *ngIf="isGenerate && tipoTela !== 'view'">
                <po-table #poTableUpfront [p-hide-text-overflow]="true" [p-selectable]="true" [p-single-select]="false" (p-selected)="selectedGeneratedItem($event)" (p-unselected)="unSelectedGeneratedItem($event)" (p-all-selected)="allGeneratedSelected($event)" (p-all-unselected)="unAllGeneratedSelected()"
                    [p-sort]="true" [p-columns]="tableUpfront.columns" [p-striped]="true" [p-items]="tableUpfront.items" [p-height]="tableUpfront.height">

                    <ng-template p-table-cell-template let-column="column" let-row="row">
                        <span *ngIf="column.property === 'ZR7_DESCON'"> $ {{ row?.ZR7_DESCON | number : '1.2-2'}}
            </span>
                    </ng-template>
                </po-table>
            </div>

            <!-- TABLE WIRE REQUEST INIT -->
            <div *ngIf="!isGenerate && tipoTela !== 'view'">
                <po-table #poTableUpfront [p-sort]="true" [p-hide-text-overflow]="true" [p-columns]="tableUpfront.columns" [p-striped]="true" [p-items]="tableUpfront.items" [p-height]="tableUpfront.height">
                    <ng-template p-table-cell-template let-column="column" let-row="row">
                        <po-decimal *ngIf="column.property === 'ZR7_DESCON' && row.alterar" [(ngModel)]="row.ZR7_DESCON" (p-change-model)="calcBalanceAndTotalizador(row)" p-type="currency" p-format="USD" [p-min]="0">
                        </po-decimal>
                        <span *ngIf="column.property === 'ZR7_DESCON' && !row.alterar"> $ {{ row?.ZR7_DESCON | number : '1.2-2'}}
            </span>
                    </ng-template>
                </po-table>
            </div>
            <!-- TABLE WIRE REQUEST END -->

            <!-- TABLE WIRE REQUEST AND PAYMENT INIT -->
            <div *ngIf="tipoTela === 'view'">
                <po-table #poTableUpfront [p-sort]="true" [p-hide-text-overflow]="true" [p-columns]="tableUpfront.columns" [p-striped]="true" [p-items]="tableUpfront.items" [p-height]="tableUpfront.height" [p-actions]="tableUpfront.actions">
                    <ng-template p-table-cell-template let-column="column" let-row="row">
                        <span *ngIf="column.property === 'ZR7_DESCON'"> $ {{ row?.ZR7_DESCON | number : '1.2-2'}}
            </span>
                    </ng-template>
                </po-table>
            </div>
            <!-- TABLE WIRE REQUEST AND PAYMENT END -->

            <div class="po-row po-md-3 po-mt-5">
                <div class="valoresTotal">Total Amount : $ {{containerForm.ZR5_AMOUNT | number : '1.2-2'}} </div>
            </div>

            <div *ngIf="isGenerate && tipoTela !== 'view'" class="po-row po-md-3 po-mt-5">
                <div class="valoresTotal">Total Selected: $ {{totalizadores.selectedQuantity | number : '1.2-2'}} </div>
            </div>

        </po-tab>
    </po-tabs>
</po-page-default>

<po-loading-overlay *ngIf="loading" p-text="Loading, please wait ...">
</po-loading-overlay>

<po-modal #modalSearch p-title="Search {{titlePopup}}" [p-primary-action]="closeModalSearch" class="limitModal">

    <div>
        <div [formGroup]="modalSearchForm" (keyup.enter)="dynamicForm()">
            <po-input class="po-md-2" p-label="P.O. No." p-clean="true" formControlName="C7_NUM"></po-input>
            <po-datepicker class="po-md-2" p-label="Issue Dt." p-clean="true" formControlName="C7_EMISSAO">
            </po-datepicker>
            <po-input class="po-md-2" p-label="Supplier" p-clean formControlName="C7_FORNECE"></po-input>
            <po-input class="po-md-2" p-label="Store" p-clean formControlName="C7_LOJA"></po-input>
            <po-button class="po-md-2 po-mt-4" p-label="Search" p-icon="po-icon-search" p-type="primary" (p-click)="dynamicForm()"></po-button>
            <po-button class="po-md-2 po-mt-4" p-label="Clear Filters" p-icon="po-icon-close" p-type="danger" (p-click)="clearFilter()"></po-button>
        </div>
        <po-divider class="po-md-12"></po-divider>
    </div>

    <div class="po-row">
        <po-table [p-selectable]="true" [p-single-select]="true" (p-selected)="selectPopUp($event)" [p-loading]="tableSearchPopup.loading" (p-unselected)="unselectPopUp($event)" [p-hide-columns-manager]="true" [p-columns]="tableSearchPopup.columns" [p-items]="tableSearchPopup.items"
            [p-hide-text-overflow]="true" [p-sort]="true" [p-striped]="true" [p-literals]="tableSearchPopup.customLiterals" [p-height]="tableSearchPopup.height">
        </po-table>
    </div>

</po-modal>

<po-modal #modalItensPo p-title="" [p-primary-action]="confirmModalItensPo" [p-secondary-action]="closeModalItensPo" [p-click-out]="false" [p-hide-close]="true" class="limitModal">
    <table class="table text-center po-table-main-container po-table">
        <thead>
            <tr>
                <th class="po-table-header">
                    <po-checkbox [(ngModel)]="tablePo.selectedAll" (p-change)="onChangeCheckBox($event, true)">
                    </po-checkbox>
                </th>
                <th class="po-table-header">Item</th>
                <th class="po-table-header">Product</th>
                <th class="po-table-header">Description</th>
                <th class="po-table-header">Balance</th>
                <th class="po-table-header">Quantity</th>
                <th class="po-table-header">Unit Price</th>
                <th class="po-table-header">Total Amount</th>
                <th class="po-table-header">Container</th>
                <th class="po-table-header">SO Origin</th>
                <th class="po-table-header">Nota Fiscal</th>
                <th class="po-table-header">Notes</th>
            </tr>
        </thead>

        <tbody *ngIf="tablePo.items.length > 0">
            <tr *ngFor="let item of tablePo.items" class="altura-linha">

                <td class="po-table-column po-table-striped">
                    <po-checkbox (p-change)="onChangeCheckBox($event,false)" [(ngModel)]="item.$selected">
                    </po-checkbox>
                </td>
                <td class="po-table-column po-table-striped">{{item?.C7_ITEM}}</td>
                <td class="po-table-column po-table-striped text-ellipsis">{{item?.C7_PRODUTO}}</td>
                <td class="po-table-column po-table-striped text-ellipsis">{{item?.C7_DESCRI}}</td>
                <td class="po-table-column po-table-striped">{{item?.SALDO}}</td>
                <td class="po-table-column po-table-striped">
                    <po-decimal [(ngModel)]="item.C7_QUANT" (p-change)="validateQuantity(item,true)" p-locale="en" [p-min]=0 [p-max]="item.SALDO"></po-decimal>
                </td>
                <td class="po-table-column po-table-striped">
                  <!-- {{item?.C7_PRECO | currency: 'USD'}} -->
                  <po-decimal [(ngModel)]="item.C7_PRECO" p-locale="en" [p-min]=0 p-disabled="true"></po-decimal>
                </td>
                <td class="po-table-column po-table-striped">{{item?.C7_TOTAL | currency: 'USD'}}</td>
                <td class="po-table-column po-table-striped">{{item?.C7_XCONTR}}</td>
                <td class="po-table-column po-table-striped">{{item?.C7_SO}}</td>
                <td class="po-table-column po-table-striped">
                    <po-input [(ngModel)]="item.ZR6_DOC"></po-input>
                </td>
                <td class="po-table-column po-table-striped">
                    <po-input [(ngModel)]="item.ZR6_OBS"></po-input>
                </td>
            </tr>
        </tbody>
    </table>
    <po-loading-overlay *ngIf="loading" p-text="Loading, please wait ...">
    </po-loading-overlay>
</po-modal>

<po-modal #modalUpfront p-click-out="false" [p-hide-close]="true" class="limitModal">

    <div *ngIf="!hideDetails">
        <po-divider p-label="Selected Items"> </po-divider>
        <po-table [p-sort]="true" class="po-md-12" [p-columns]="tableResumoUpfront.columns" [p-striped]="true" [p-items]="tableResumoUpfront.items" [p-height]="200"></po-table>
    </div>

    <div [formGroup]="modalUpfrontForm">
        <po-combo class="po-md-6" p-label="Bank" formControlName="BANK_ORIGINAL" [p-options]="selects.sa6" (p-change)="bankSelec(controlsUpfrontForm.BANK_ORIGINAL.value)" [p-required]="true" [p-filter-mode]="filterModeCombo"></po-combo>
        <po-datepicker class="po-md-6" formControlName="ZR7_EMISSA" p-clean="true" p-disabled="false" p-error-pattern="" p-label="Issue Dt" p-clean="true" p-format="mm/dd/yyyy" p-readonly="false" p-required="true">
        </po-datepicker>
        <po-combo class="po-md-6" p-label="Class" formControlName="ZR7_NATURE_ORIGINAL" [p-options]="selects.sed" (p-change)="classSelec(controlsUpfrontForm.ZR7_NATURE_ORIGINAL.value)" [p-required]="true" [p-filter-mode]="filterModeCombo"></po-combo>
        <po-combo class="po-md-6" p-label="C. Center" formControlName="ZR7_CCUSTO_ORIGINAL" [p-options]="selects.ctt" (p-change)="ccenterSelec(controlsUpfrontForm.ZR7_CCUSTO_ORIGINAL.value)" [p-filter-mode]="filterModeCombo">
        </po-combo>
        <po-textarea class="po-md-6" formControlName="ZR7_HISTOR" p-focus="false" [p-disabled]="false" p-label="History" p-max-length="40" p-min-length="1" p-placeholder="" [p-rows]="5">
        </po-textarea>
        <po-textarea class="po-md-6" formControlName="ZR7_HIST2" p-focus="false" [p-disabled]="false" p-label="History 2" p-min-length="1" p-placeholder="" [p-rows]="5">
        </po-textarea>
    </div>

    <po-modal-footer>
        <po-button [p-label]="hideDetails ? 'Show Summary' : 'Hide Summary'" (p-click)="showItems()" [p-icon]="hideDetails ? 'po-icon po-icon-eye' : 'po-icon po-icon-eye-off'"> </po-button>
        <po-button p-label="Close" (p-click)="closeModalUpFront()" p-type="danger"> </po-button>
        <po-button p-label="Confirm" p-type="primary" (p-click)="confirmGenerate()"> </po-button>
    </po-modal-footer>
</po-modal>


<po-modal #modalEditItemContainer p-click-out="false" [p-hide-close]="true" p-title="Edit Item" class="limitModal" [p-primary-action]="confirmEditItem" [p-secondary-action]="closeEditItem">
    <div [formGroup]="containerAddForm">
        <po-input class="po-md-4" p-label="Nota Fiscal" [p-disabled]="disabledModalFields" formControlName="ZR6_DOC">
        </po-input>
        <po-input class="po-md-4" p-label="RSC" p-disabled="true" formControlName="C7_FORNECE"></po-input>
        <po-input class="po-md-4" p-label="Vendor" p-disabled="true" formControlName="A2_NOME"></po-input>
        <po-input class="po-md-4" p-label="Purchase Order" p-disabled="true" formControlName="C7_NUM"></po-input>
        <po-input class="po-md-4" p-label="Sales Order" p-disabled="true" formControlName="C6_NUM"></po-input>
        <po-textarea class="po-md-12" formControlName="ZR6_OBS" p-focus="false" [p-disabled]="disabledModalFields" p-label="Notes" p-min-length="1" p-placeholder="" [p-rows]="5"></po-textarea>
        <po-datepicker class="po-md-4" formControlName="C7_EMISSAO" p-clean="true" p-format="mm/dd/yyyy" p-disabled="true" p-error-pattern="" p-label="Date Order PO"></po-datepicker>
        <po-input class="po-md-4" p-label="Vendor Product Code" p-disabled="true" formControlName="B1_XCODFOR">
        </po-input>
        <po-input class="po-md-4" p-label="Product" p-disabled="true" formControlName="C7_PRODUTO"></po-input>
        <po-input class="po-md-4" p-label="Description" p-disabled="true" formControlName="B1_DESC"></po-input>
        <po-decimal class="po-md-4" p-label="Quantity" formControlName="ZR6_QUANT" [p-disabled]="disabledModalFields" p-locale="en" [p-min]="0" (p-change-model)="validateQuantity(containerAddForm.controls,false)" p-disabled="disabledModalFields">
        </po-decimal>
        <po-decimal class="po-md-4" p-label="Unit Price" formControlName="C7_PRECO" p-locale="en" p-disabled="true">
        </po-decimal>
        <po-decimal class="po-md-4" p-label="Total" formControlName="C7_TOTAL" p-locale="en" p-disabled="true">
        </po-decimal>
    </div>
</po-modal>

<po-modal #modalDetailUpfront p-title="Detail" [p-primary-action]="closeModalDetailUpfront" class="limitModal">


    <div *ngIf="tipoModalDetalhe === 1" class="po-row">

        <div class="po-md-10">
        </div>

        <div class="po-md-2">
            <po-button class="po-md-12" p-icon="po-icon-download" p-label="Excel" [p-small]="true" p-type="primary" (p-click)="toExcel(this.tableDetailUpfront.columns,this.tableDetailUpfront.items, this.tableDetailUpfront)">
            </po-button>
        </div>

        <po-table class="po-mt-2" [p-selectable]="true" [p-single-select]="true" (p-selected)="selectDetailItem($event)" [p-loading]="tableDetailUpfront.loading" (p-unselected)="unselectPopUp($event)" [p-hide-columns-manager]="true" [p-columns]="tableDetailUpfront.columns"
            [p-items]="tableDetailUpfront.items" [p-hide-text-overflow]="true" [p-sort]="true" [p-striped]="true" [p-literals]="tableDetailUpfront.customLiterals" [p-height]="tableDetailUpfront.height">
        </po-table>
        <div class="po-row po-md-4 po-mt-5">
            <div class="valoresTotal">Total Wire Amount : $ {{totalizadoresModaisDetalhes.wireAmount | number : '1.2-2'}}
            </div>
        </div>

        <div class="po-row po-md-4 po-mt-5">
            <div class="valoresTotal">Total Wire Bill: $ {{totalizadoresModaisDetalhes.wireBill | number : '1.2-2'}}
            </div>
        </div>

        <div class="po-row po-md-4 po-mt-5">
            <div class="valoresTotal">Total Bill Amount: $ {{totalizadoresModaisDetalhes.wireBillAmount | number : '1.2-2'}}
            </div>
        </div>
    </div>

    <div *ngIf="tipoModalDetalhe === 2" class="po-row po-mt-4">

        <div class="po-md-10">
        </div>

        <div class="po-md-2">
            <po-button class="po-md-12" p-icon="po-icon-download" p-label="Excel" [p-small]="true" p-type="primary" (p-click)="toExcel(this.tableDetailItensUpfront.columns,this.tableDetailItensUpfront.items, this.tableDetailItensUpfront)">
            </po-button>
        </div>

        <po-table class="po-mt-2" [p-loading]="tableDetailItensUpfront.loading" [p-hide-columns-manager]="true" [p-columns]="tableDetailItensUpfront.columns" [p-items]="tableDetailItensUpfront.items" [p-hide-text-overflow]="true" [p-sort]="true" [p-striped]="true"
            [p-literals]="tableDetailItensUpfront.customLiterals" [p-height]="tableDetailItensUpfront.height">
        </po-table>
        <div class="po-row po-md-3 po-mt-1">
            <div class="valoresTotal">Total Quantity : {{totalizadoresModaisDetalhes.detailItemQuantity}} </div>
        </div>

        <div *ngIf="isGenerate" class="po-row po-md-3 po-mt-1">
            <div class="valoresTotal">Total : $ {{totalizadoresModaisDetalhes.detailItemTotal | number : '1.2-2'}}
            </div>
        </div>
    </div>


</po-modal>
