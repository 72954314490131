import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  PoBreadcrumb,
  PoBreadcrumbItem,
  PoComboFilterMode,
  PoDynamicFormField,
  PoModalAction,
  PoModalComponent,
  PoNotificationService,
  PoPageAction,
  PoPageDefaultLiterals,
  PoSelectOption,
  PoTableAction,
  PoTableColumn,
  PoTableComponent,
  PoTableLiterals,
} from '@po-ui/ng-components';
import { isNull, isString, isUndefined } from 'lodash';
import { ProductlabelModal } from 'src/app/labels/product-labels/product-labels.component';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { F3Service } from 'src/app/service/f3/f3.service';
import { ListasService } from 'src/app/service/listas/listas.service';
import { UtilService } from 'src/app/service/util/util.service';
import {
  WireTransferDetailEnvio,
  WireTransferDetailItemEnvio,
  WireTransferService,
  WireTransferUpFront,
} from 'src/app/service/wire-transfer/wire-transfer.service';
import { Operations } from 'src/app/stock/container/container-edit/container-edit.component';

@Component({
  selector: 'app-wire-transfer-edit',
  templateUrl: './wire-transfer-edit.component.html',
  styleUrls: ['./wire-transfer-edit.component.css'],
})
export class WireTransferEditComponent implements OnInit {
  actions: Array<PoTableAction> = [
    { action: this.details.bind(this), icon: 'po-icon-info', label: 'Details' },
  ];

  constructor(
    private location: Location,
    private wireTransferService: WireTransferService,
    private utilService: UtilService,
    private f3Service: F3Service,
    private notificationService: PoNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private listasService: ListasService,
    private cdref: ChangeDetectorRef,
    private excelService: ExcelService
  ) {}

  page = {
    title: 'Add Wire Transfer',
    breadcrumb: {
      items: [{ label: 'Purchase' }] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [] as Array<PoPageAction>,
    literals: {
      otherActions: 'Other Actions',
    } as PoPageDefaultLiterals,
  };

  public loading = false as boolean;
  public containerForm: any = {};
  public fieldsContainer: Array<PoDynamicFormField> = [];

  public UpfrontForm: any = {};
  public fieldsUpfront: Array<PoDynamicFormField> = [];

  public modalUpfrontForm = this.fb.group({
    actions: ['', []],
    alterar: ['', []],
    BANK_ORIGINAL: ['', [Validators.required]],
    ZR7_BANCO: ['', []],
    ZR7_AGENCI: ['', []],
    ZR7_CONTA: ['', []],
    ZR7_PREFIX: ['', []],
    ZR7_BILL: ['', []],
    ZR7_PARCEL: ['', []],
    ZR7_VALOR: ['', []],
    ZR7_EMISSA: ['', [Validators.required]],
    ZR7_NATURE: ['', []],
    ZR7_NATURE_ORIGINAL: ['', [Validators.required]],
    ZR7_DESCRI: ['', []],
    ZR7_FORNEC_ORIGINAL: ['', []],
    ZR7_FORNEC: ['', []],
    ZR7_LOJA: ['', []],
    ZR7_NOME: ['', []],
    ZR7_HISTOR: ['', []],
    ZR7_HIST2: ['', []],
    recno: ['', []],
    ZR7_CCUSTO: ['', []],
    ZR7_CCUSTO_ORIGINAL: ['', []],
  });

  public modalSearchForm = this.fb.group({
    C7_NUM: ['', []],
    C7_EMISSAO: ['', []],
    C7_FORNECE: ['', []],
    C7_LOJA: ['', []],
  });

  public disabledModalFields: any = false;
  public containerAddForm = this.fb.group({
    recno: ['', []],
    sequencia: ['', []],
    A2_NOME: ['', []],
    B1_DESC: ['', []],
    B1_XCODFOR: ['', []],
    C6_NUM: ['', []],
    C7_EMISSAO: ['', []],
    C7_FORNECE: ['', []],
    C7_ITEM: ['', []],
    C7_NUM: ['', []],
    C7_PRECO: ['', []],
    C7_PRODUTO: ['', []],
    C7_TOTAL: ['', []],
    C7_QUANT: ['', []],
    C7_QUJE: ['', []],
    SALDO: ['', []],
    ZR6_DOC: ['', []],
    ZR6_FORNEC: ['', []],
    ZR6_ITEMPC: ['', []],
    ZR6_LOJA: ['', []],
    ZR6_NUMPC: ['', []],
    ZR6_OBS: ['', []],
    ZR6_QUANT: ['', []],
    actions: ['', []],
    alterar: ['', []],
    mensagem: ['', []],
    recno_sc7: ['', []],
    C7_SO: ['', []],
    C7_XCONTR: ['', []],
    itemOriginal: [false, []],
  });
  public containerAddFormOriginal: any = {};
  public fieldsAddContainer: Array<PoDynamicFormField> = [];

  public isModalUpfront = false;

  table = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.2),
  };

  tableUpfront = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
    actions: [] as Array<PoTableAction>,
  };

  public tipoTela = 'add';
  public operation = Operations.Incluir;

  totalizadores = {
    totalValue: '',
    selectedQuantity: 0,
  };

  metodos = {
    WIRE_BILL_DETAIL: 'WIRE_BILL_DETAIL',
    WIRE_BILL_DETAIL_ITEM: 'WIRE_BILL_DETAIL_ITEM',
    SALVAR_ITEM_ZR6: 'SALVAR_ITEM_ZR6',
  };
  // ********************************************************************************************************************
  //                                                 SEARCH MODAL
  // ********************************************************************************************************************
  public titlePopup = '';
  public searchTerm = '';
  public formValue = {};
  public formFields = [] as Array<PoDynamicFormField>;
  public typeListModal: ProductlabelModal = 1;

  @ViewChild('modalSearch', { static: true }) poModalSearch!: PoModalComponent;

  closeModalSearch: PoModalAction = {
    action: () => {
      this.poModalSearch.close();
    },
    label: 'Close',
    danger: true,
  };

  public tableSearchPopup = {
    columns: [] as PoTableColumn[],
    items: [],
    height: 250,
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals,
  };

  public tablePo = {
    columns: [] as PoTableColumn[],
    items: [] as any,
    height: 400,
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals,
    selectedAll: false,
  };

  public arrSelectedtems: any[] = [];

  // ********************************************************************************************************************
  //                                                 UPFRONT MODAL
  // ********************************************************************************************************************

  @ViewChild('modalUpfront', { static: true }) modalUpfront!: PoModalComponent;

  public arrGenerateSelectedItems: any[] = [];

  confirmGenerate() {
    const invalidForm = this.utilService.findInvalidControls(
      this.modalUpfrontForm.controls
    );
    if (invalidForm.length > 0) {
      invalidForm.map((data: any) => {
        if (data === 'BANK_ORIGINAL') {
          data = 'Bank';
        } else if (data === 'ZR7_EMISSA') {
          data = 'Issue Dt';
        } else if (data === 'ZR7_NATURE_ORIGINAL') {
          data = 'Class';
        }
        return this.notificationService.warning(
          `Please fill in a valid value for the field: ${data} `
        );
      });
    } else {
      this.arrGenerateSelectedItems = this.arrGenerateSelectedItems.map(
        (el: any) => {
          return {
            ZR7_DESCON: el.ZR7_DESCON,
            SALDO: el.SALDO,
            alterar: el.alterar,
            ZR7_FORNEC: el.ZR7_FORNEC,
            ZR7_LOJA: el.ZR7_LOJA,
            ZR7_VALOR: el.ZR7_VALOR,
            mensagem: el.mensagem,
            $selected: el.$selected,
            ZR7_BANCO: this.modalUpfrontForm.controls.ZR7_BANCO.value,
            ZR7_AGENCI: this.modalUpfrontForm.controls.ZR7_AGENCI.value,
            ZR7_CONTA: this.modalUpfrontForm.controls.ZR7_CONTA.value,
            ZR7_PREFIX: el.ZR7_PREFIX ?? '',
            ZR7_BILL: el.ZR7_BILL ?? '',
            ZR7_PARCEL: el.ZR7_PARCEL ?? '',
            ZR7_EMISSA: this.modalUpfrontForm.controls.ZR7_EMISSA.value,
            ZR7_NATURE: this.modalUpfrontForm.controls.ZR7_NATURE.value,
            ZR7_DESCRI: el.ZR7_DESCRI ?? '',
            ZR7_NOME: el.ZR7_NOME ?? '',
            ZR7_HISTOR: this.modalUpfrontForm.controls.ZR7_HISTOR.value,
            ZR7_HIST2: this.modalUpfrontForm.controls.ZR7_HIST2.value,
            recno: el.recno,
            ZR7_CCUSTO: this.modalUpfrontForm.controls.ZR7_CCUSTO.value,
            estornar: el.estornar === 1 ? false : true,
          };
        }
      );
      this.returnSave(
        this.operation,
        'GERAR_UPFRONT',
        'generate',
        this.arrGenerateSelectedItems
      );
      this.modalUpfront.close();
      this.utilService.resetForm(this.modalUpfrontForm);
    }
  }

  closeModalUpFront() {
    this.modalUpfront.close();
    this.utilService.resetForm(this.modalUpfrontForm);
  }

  showItems() {
    this.hideDetails = !this.hideDetails;
  }

  @ViewChild('poTableUpfront', { static: true })
  poTableUpfront!: PoTableComponent;

  @ViewChild('poTableContainer', { static: true })
  poTableContainer!: PoTableComponent;

  // ********************************************************************************************************************
  //                                                 MODAL ITENS PO MODAL
  // ********************************************************************************************************************

  @ViewChild('modalItensPo', { static: true })
  poModalItensPo!: PoModalComponent;

  confirmModalItensPo: PoModalAction = {
    action: () => {
      let envio = this.tablePo.items.filter(
        (item: any) => item.$selected === true
      );
      envio = envio.filter((item: any) => item.C7_QUANT > 0);
      if (envio.length > 0) {
        this.sendValidar(envio);
      } else {
        this.notificationService.warning(
          'The quantity entered must be greater than 0.'
        );
      }
    },
    label: 'Confirm',
  };

  closeModalItensPo: PoModalAction = {
    action: () => {
      this.tablePo.items = [];
      this.poModalItensPo.close();
      this.openModalList('PO_WIRE', 'PO_WIRE');
    },
    label: 'Close',
    danger: true,
  };

  @ViewChild('modalEditItemContainer', { static: true })
  modalEditItemContainer!: PoModalComponent;

  confirmEditItem: PoModalAction = {
    action: () => {
      this.loading = true;
      const filtrado = this.table.items.filter(
        (item: any) =>
          `${item.C7_ITEM}${item.C7_NUM}` ===
          `${this.itemSelecionado.C7_ITEM}${this.itemSelecionado.C7_NUM}`
      );
      filtrado.map((ret) => {
        Object.entries(ret).forEach(([chave, valor]) => {
          ret[chave] = this.containerAddForm.controls[chave].value;
        });
      });
      if (filtrado[0].ZR6_QUANT > 0) {
        this.gravarItem(filtrado, false, false);
        this.containerForm.ZR5_AMOUNT = this.reduceArrayByProperty(
          this.table.items,
          'C7_TOTAL'
        );
      } else {
        this.notificationService.warning(
          'The quantity entered must be greater than 0.'
        );
      }
    },
    label: 'Confirm',
  };

  closeEditItem: PoModalAction = {
    action: () => {
      this.modalEditItemContainer.close();
      this.validarUpFront(false);
    },
    label: 'Close',
    danger: true,
  };

  private statusWire: any;
  private recno: any;
  selects = {
    ctt: <Array<PoSelectOption>>[],
    sed: <Array<PoSelectOption>>[],
    sa6: <Array<any>>[],
  };

  public isGenerate: boolean = false;
  public itemSelecionado: any = new Object();
  public filterModeCombo = PoComboFilterMode.contains;
  public upfrontSelecionado: any = new Object();
  public hideDetails = true;

  public tableResumoUpfront = {
    columns: [
      { property: 'ZR7_FORNEC', label: 'Supplier', width: '15%' },
      { property: 'ZR7_LOJA', label: 'Store', width: '10%' },
      { property: 'ZR7_NOME', label: 'Suppl.Name', width: '30%' },
      {
        property: 'ZR7_VALOR',
        label: 'Bill Value',
        type: 'currency',
        format: 'USD',
        width: '15%',
      },
      {
        property: 'ZR7_DESCON',
        label: 'Discount',
        type: 'currency',
        format: 'USD',
        width: '15%',
      },
      {
        property: 'SALDO',
        label: 'Balance',
        type: 'currency',
        format: 'USD',
        width: '15%',
      },
    ] as PoTableColumn[],
    items: [] as any,
    height: 400,
    loading: false,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals,
  };

  // ********************************************************************************************************************
  //                                                 DETAIl MODAL
  // ********************************************************************************************************************

  @ViewChild('modalDetailUpfront', { static: true })
  modalDetailUpfront!: PoModalComponent;

  closeModalDetailUpfront: PoModalAction = {
    action: () => {
      if (this.tipoModalDetalhe === 2) {
        this.tableDetailItensUpfront.loading = true;
        setTimeout(() => {
          this.tableDetailItensUpfront.loading = false;
          this.closeModalDetailUpfront.label = 'Close';
          this.tipoModalDetalhe = 1;
          this.tableDetailItensUpfront.items = [];
        }, 500);
      } else {
        this.modalDetailUpfront.close();
      }
    },
    label: 'Close',
    danger: true,
  };

  public tableDetailUpfront = {
    columns: [
      { property: 'E2_FILIAL', label: 'Branch', width: '10%' },
      { property: 'E2_TIPO', label: 'Type', width: '10%' },
      { property: 'prefixo', label: 'Prefix', width: '10%' },
      { property: 'bill', label: 'Bill', width: '15%' },
      { property: 'emission', label: 'Emission', width: '10%' },
      {
        property: 'wire_amount',
        label: 'Wire Amount',
        width: '15%',
        type: 'currency',
        format: 'USD',
      },
      {
        property: 'wire_bill',
        label: 'Wire Bill',
        width: '15%',
        type: 'currency',
        format: 'USD',
      },
      {
        property: 'bill_amount',
        label: 'Bill Amount',
        width: '15%',
        type: 'currency',
        format: 'USD',
      },
    ] as PoTableColumn[],
    items: [],
    height: this.utilService.calcularHeight(innerHeight, 0.3),
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals,
  };

  public tableDetailItensUpfront = {
    columns: [
      { property: 'D1_ITEM', label: 'Item', width: '5%' },
      { property: 'D1_COD', label: 'Code', width: '16%' },
      { property: 'D1_DESCRI', label: 'Description', width: '16%' },
      { property: 'D1_TES', label: 'Inf Type', width: '7%' },
      { property: 'F4_TEXTO', label: 'Inf Type Description', width: '18%' },
      { property: 'D1_QUANT', label: 'Quantity', width: '7%' },
      {
        property: 'D1_VUNIT',
        label: 'Unit Price',
        width: '7%',
        type: 'currency',
        format: 'USD',
      },
      {
        property: 'D1_TOTAL',
        label: 'Total',
        width: '10%',
        type: 'currency',
        format: 'USD',
      },
      { property: 'gen_bill', label: 'Gen Bill', width: '7%' },
      { property: 'inventory', label: 'Inventory', width: '7%' },
    ] as PoTableColumn[],
    items: [] as any,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals,
    selectedAll: false,
  };

  public itemDetailSelecionado: any = new Object();
  public tipoModalDetalhe = 1 as number;

  totalizadoresModaisDetalhes = {
    wireAmount: 0,
    wireBill: 0,
    wireBillAmount: 0,
    detailItemTotal: 0,
    detailItemQuantity: 0,
  };

  ngOnInit(): void {
    let typePage = '';
    if (this.router.url.indexOf('wire-request') !== -1) {
      this.isGenerate = false;
      this.page.actions = [
        {
          label: 'Save',
          action: () =>
            this.validaCabec(this.operation, 'SALVAR_WIRE', 'request', []),
          icon: 'po-icon-ok',
        },
        {
          label: 'Back',
          action: () => this.location.back(),
          icon: 'po-icon-edit',
        },
      ];
      typePage = 'Wire Request';
    } else {
      this.isGenerate = true;
      this.page.actions = [
        { label: 'Generate', action: () => this.addUpfrontItem() },
        {
          label: 'Back',
          action: () => this.location.back(),
          icon: 'po-icon-edit',
        },
        {
          label: 'Reverse',
          action: () => this.reverseItem(),
          icon: 'po-icon-change',
        },
      ];
      typePage = 'Wire Payment';
    }

    let obj: any = { recno: null };
    if (this.router.url.indexOf('add') !== -1) {
      this.getById();
      this.tipoTela = 'add';
      this.page.title = `Add ${typePage} `;
      this.page.breadcrumb.items.push(
        { label: `${typePage}` },
        { label: `Add ${typePage}` }
      );
      this.containerForm.ZR5_EMISSA = this.utilService.dataAtual();
      this.containerForm.ZR5_DISCOU = 0;
      this.operation = Operations.Incluir;
      this.containerForm.recno = 0;
    } else {
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        obj.recno = paramMap.get('RECNO');
        obj.recno = parseInt(obj.recno, 10);
        this.statusWire = paramMap.get('STATUS');
        this.recno = obj.recno;
        this.getById(obj);
      });
      if (this.router.url.indexOf('edit') !== -1) {
        this.tipoTela = 'edit';
        this.page.title = `Edit ${typePage} `;
        this.page.breadcrumb.items.push(
          { label: `${typePage}` },
          { label: `Edit ${typePage}` }
        );
        this.operation = Operations.Alterar;
      } else if (this.router.url.indexOf('view') !== -1) {
        this.tipoTela = 'view';
        this.page.title = `View ${typePage} `;
        this.page.breadcrumb.items.push(
          { label: `${typePage}` },
          { label: `View ${typePage}` }
        );
        this.page.actions[0].disabled = true;
        if (this.isGenerate) {
          this.page.actions[2].disabled = true;
        }
        this.operation = Operations.Visualizar;
      } else {
        this.tipoTela = 'delete';
        this.page.title = `Delete ${typePage} `;
        this.page.breadcrumb.items.push(
          { label: `${typePage}` },
          { label: `Delete ${typePage}` }
        );
        this.page.actions[0].disabled = false;
        this.page.actions[0].label = 'Ok, Delete';
        this.operation = Operations.Excluir;
      }
    }
    this.selects.ctt = this.returnListas('CTT');
    this.selects.sa6 = this.returnListas('SA6');
    this.selects.sed = this.returnListas('SED');
    this.table.height = this.utilService.calcularHeight(innerHeight, 0.26);
    this.tableUpfront.height = this.utilService.calcularHeight(
      innerHeight,
      0.45
    );

    if (this.tipoTela !== 'add' && this.tipoTela !== 'delete') {
      const action = {
        label: 'Excel',
        action: () => this.exportExcel(),
        icon: 'po-icon-export',
      };
      this.page.actions = this.utilService.spreadArrayToProperty(
        this.page.actions,
        action
      );
    }
  }

  get controlsUpfrontForm() {
    return this.modalUpfrontForm.controls;
  }

  returnListas(table: any): any {
    const params = {
      procedure: false,
      data: { table },
    };
    this.listasService.returnListas(params).subscribe({
      next: (data: { status: any; data: { items: any }[] }) => {
        if (data !== null) {
          if (data.status) {
            const retorno = data.data[0].items;
            if (table === 'SA6') {
              this.selects.sa6 = retorno.map((item: any) => {
                return {
                  label: item.concatenado,
                  value: item.valor,
                  fields: item.titulo,
                } as any;
              });
            } else if (table === 'SED') {
              this.selects.sed = retorno.map((item: any) => {
                return {
                  label: item.concatenado,
                  value: item.valor[0],
                } as PoSelectOption;
              });
            } else if (table === 'CTT') {
              this.selects.ctt = retorno.map((item: any) => {
                return {
                  label: item.concatenado,
                  value: item.valor[0],
                } as PoSelectOption;
              });
            }
          }
        }
      },
      error: (err: any) => {
        console.error(err, 'Err -->');
      },
    });
  }

  getById(param?: object) {
    this.loading = true;
    const obj = {
      procedure: false,
      metodo: 'LISTAR_WIRE',
      data: param ?? {},
    };
    this.wireTransferService.returnWire(obj).subscribe({
      next: (element: { data: any[] } | null) => {
        if (element !== null) {
          element.data.map((val: any) => {
            if (val.ZR5) {
              val.ZR5.forEach((tab: any) => {
                if (tab.fields) {
                  this.fieldsContainer = tab.fields;
                  // this.fieldsContainer.push({
                  //   disabled: true,
                  //   format: "USD",
                  //   gridColumns: 2,
                  //   label: "Discount",
                  //   maxLength: 12,
                  //   minLength: 1,
                  //   order: 3,
                  //   placeholder: "",
                  //   property: "ZR5_DISCOU",
                  //   required: true,
                  //   type: "currency"
                  // })

                  let conditions: any;
                  if (this.tipoTela === 'add') {
                    conditions = ['ZR5_EMISSA', 'ZR5_AMOUNT', 'ZR5_DISCOU'];
                  } else if (
                    this.tipoTela === 'edit' &&
                    !this.isGenerate &&
                    this.statusWire == '1'
                  ) {
                    conditions = [
                      'ZR5_EMISSA',
                      'ZR5_AMOUNT',
                      'ZR5_DISCOU',
                      'ZR5_NUM',
                    ];
                  } else {
                    conditions = [
                      'ZR5_EMISSA',
                      'ZR5_AMOUNT',
                      'ZR5_DISCOU',
                      'ZR5_NUM',
                      'ZR5_OBS',
                    ];
                  }
                  this.fieldsContainer.forEach((item: any) => {
                    if (conditions.includes(item.property)) {
                      item.disabled = true;
                    } else {
                      item.disabled = false;
                    }
                    item.gridColumns = 2;

                    if (item.property === 'ZR5_OBS') {
                      item.gridColumns = 4;
                    }

                    if (item.property === 'ZR5_DISCOU') {
                      item.type = 'string ';
                    }
                    if (this.containerForm.ZR5_DISCOU === 'NAN') {
                      this.containerForm.ZR5_DISCOU = 0;
                    }
                  });
                } else if (tab.items) {
                  if (isNull(tab.items[0].ZR5_DISCOU)) {
                    this.containerForm.ZR5_DISCOU = 0;
                  }
                  if (this.tipoTela !== 'add') {
                    this.containerForm = tab.items[0];
                    if (this.containerForm.ZR5_EMISSA.indexOf('/') !== -1) {
                      this.containerForm.ZR5_EMISSA =
                        this.containerForm.ZR5_EMISSA.split('/').join('-');
                    }
                  }
                }
              });
            } else if (val.ZR6) {
              val.ZR6.forEach((ret: any) => {
                if (ret.columns) {
                  const columns = ret.columns;
                  const conditionsSm = [
                    'ZR6_FORNEC',
                    'ZR6_LOJA',
                    'ZR6_QUANT',
                    'C7_TOTAL',
                  ];
                  const conditionsMd = [
                    'C7_NUM',
                    'C7_EMISSAO',
                    'C7_PRECO',
                    'B1_XCODFOR',
                    'C6_NUM',
                    'ZR6_DOC',
                  ];
                  const conditionsXl = [
                    'A2_NOME',
                    'C7_PRODUTO',
                    'B1_DESC',
                    'ZR6_OBS',
                  ];
                  columns.forEach((item: any) => {
                    if (conditionsXl.includes(item.property)) {
                      item.width = '200px';
                    } else if (conditionsMd.includes(item.property)) {
                      item.width = '115px';
                    } else if (conditionsSm.includes(item.property)) {
                      item.width = '85px';
                    } else {
                      item.width = '100px';
                    }
                  });
                  this.table.columns = columns;
                  if (
                    this.tipoTela !== 'view' &&
                    this.tipoTela !== 'delete' &&
                    !this.isGenerate
                  ) {
                    this.table.columns.unshift({
                      label: 'Actions',
                      property: 'actions',
                      width: '125px',
                      type: 'icon',
                      icons: [
                        {
                          action: this.details.bind(this),
                          tooltip: 'Edit',
                          icon: 'po-icon-edit',
                          value: 'edit',
                        },
                        {
                          action: this.removeTableContainer.bind(this),
                          tooltip: 'Remove',
                          icon: 'po-icon-delete',
                          value: 'delete',
                        },
                      ],
                    });
                  }
                } else if (ret.items) {
                  if (this.operation === '3') {
                    this.table.items = [];
                  } else {
                    this.table.items = ret.items;
                    this.table.items.map((item) => {
                      (item.actions = ['edit', 'delete']),
                        (item.sequencia = item.recno + 1);
                    });
                    this.containerForm.ZR5_AMOUNT = this.reduceArrayByProperty(
                      this.table.items,
                      'C7_TOTAL'
                    );
                  }
                }
              });
            } else if (val.ZR7) {
              val.ZR7.forEach((tab: any) => {
                if (tab.columns) {
                  const columns = tab.columns;
                  const conditions = [
                    'ZR7_NOME',
                    'ZR7_DESCRI',
                    'ZR7_HISTOR',
                    'ZR7_HIST2',
                  ];
                  columns.forEach((item: any) => {
                    if (conditions.includes(item.property)) {
                      item.width = '250px';
                    } else {
                      item.width = '100px';
                    }
                  });
                  this.tableUpfront.columns = columns;
                  if (this.isGenerate) {
                    this.tableUpfront.columns.unshift({
                      property: 'status',
                      type: 'subtitle',
                      width: '80px',
                      label: '-',
                      subtitles: [
                        {
                          value: 'pending',
                          color: 'color-08',
                          label: 'Pending',
                          content: 'P',
                        },
                        {
                          value: 'generated',
                          color: 'color-11',
                          label: 'Generated',
                          content: 'G',
                        },
                      ],
                    });
                    this.tableUpfront.columns.unshift({
                      property: 'action',
                      type: 'icon',
                      width: '80px',
                      label: 'Actions',
                      icons: [
                        {
                          value: '0',
                          icon: 'po-icon-eye',
                          action: this.viewDetail.bind(this),
                          tooltip: 'View Details',
                        },
                      ],
                    });
                  }
                } else if (tab.items) {
                  if (this.operation === '3') {
                    this.tableUpfront.items = [];
                  } else {
                    this.tableUpfront.items = tab.items;
                    if (this.isGenerate) {
                      this.tableUpfront.items.map((item) => {
                        if (
                          this.utilService.verifyUnfinedNull(item.ZR7_BILL) ===
                            '' &&
                          this.utilService.verifyUnfinedNull(
                            item.ZR7_PARCEL
                          ) === '' &&
                          this.utilService.verifyUnfinedNull(
                            item.ZR7_PREFIX
                          ) === ''
                        ) {
                          item.status = 'pending';
                          item.action = '1';
                        } else {
                          item.status = 'generated';
                          item.action = '0';
                        }
                      });
                    } else {
                      this.validarUpFront(false);
                    }
                    this.containerForm.Upfront = this.reduceArrayByProperty(
                      this.tableUpfront.items,
                      'ZR7_VALOR'
                    );
                  }
                }
              });
            } else if (val.ZR7F) {
              val.ZR7F.forEach((tab: any) => {
                if (tab.fields) {
                  this.fieldsUpfront = tab.fields;
                } else if (tab.items) {
                  this.UpfrontForm = tab.items[0];
                }
              });
            }
          });
          this.totalizadores.totalValue = this.reduceArrayByProperty(
            this.tableUpfront.items,
            'SALDO'
          );
          if (this.totalizadores.totalValue === undefined) {
            this.totalizadores.totalValue = '0';
          }
          const desconto =
            this.containerForm.ZR5_AMOUNT -
            parseFloat(this.totalizadores.totalValue);
          this.containerForm.ZR5_DISCOU = desconto.toFixed(2);
          this.loading = false;
        } else {
          this.notificationService.error('Error please try again later');
          this.location.back();
        }

        this.cdref.detectChanges();
      },
      error: (err: any) => {
        console.error(err, 'ERROR --->');
        this.loading = false;
      },
    });
  }

  reduceArrayByProperty(arr: Array<any>, property: string) {
    return arr.reduce(
      (total, valor) => parseFloat(total) + parseFloat(valor[property]),
      0
    );
  }

  // ********************************************************************************************************************
  //                                                METODOS MODAL  LISTA
  // ********************************************************************************************************************

  public searchList() {
    let termo;
    this.searchListPromise(
      termo,
      true,
      (status: any, error: any, mensagem: any) => {
        if (error === true) {
          this.notificationService.error(mensagem);
        } else if (status === false) {
          this.notificationService.warning(mensagem);
        }
      }
    );
  }

  public searchListPromise(table: any, firstCall: boolean, callback?: any) {
    table = table.toUpperCase();
    this.tableSearchPopup.loading = true;
    const params = {
      metodo: 'listar',
      procedure: false,
      data: {
        table,
        filters: JSON.stringify(this.modalSearchForm.value),
        returnItems: firstCall,
        ZR5_NUM: this.containerForm.ZR5_NUM,
      },
    };
    switch (table) {
      case 'PO':
        this.titlePopup = 'Purchase Order';
        break;
      case 'PRODUCT':
        this.titlePopup = 'Product';
        break;
      case 'CONTAINER':
        this.titlePopup = 'Container';
        break;
    }
    this.f3Service.getf3data(params).subscribe({
      next: (data: any) => {
        if (data !== null) {
          data.data.map((element: any) => {
            if (element.columns) {
              this.tableSearchPopup.columns = element.columns;
            }
            if (element.items) {
              this.tableSearchPopup.items = element.items;
            }
            if (element.filters) {
              this.formFields = element.filters;
              this.formFields.forEach((item) => {
                (item.gridSmColumns = 3),
                  (item.gridMdColumns = 3),
                  (item.gridLgColumns = 3);
              });
            }
          });
        }
        this.tableSearchPopup.loading = false;
        if (typeof callback === 'function') {
          callback(true, false, 'succefuly');
        }
      },
      error: (error: HttpErrorResponse) => {
        this.tableSearchPopup.loading = false;
        if (typeof callback === 'function') {
          callback(false, true, error);
        }
      },
    });
  }

  public openModalList(list: any, field: any) {
    if (this.utilService.verifyUnfinedNull(this.containerForm.ZR5_NUM) === '') {
      return this.notificationService.warning(
        'Please insert a Wire Transfer number'
      );
    } else {
      this.loading = true;
      this.formValue = {};
      this.tableSearchPopup.field = field;
      this.tableSearchPopup.items = [];
      this.modalSearchForm.reset();
      this.typeListModal = list;
      this.searchListPromise(
        list,
        false,
        (status: any, error: any, mensagem: any) => {
          if (status) {
            this.loading = false;
            this.poModalSearch.open();
          } else {
            if (error === true) {
              this.notificationService.error(mensagem);
            } else if (status === false) {
              this.notificationService.warning(mensagem);
            }
          }
        }
      );
    }
  }

  public selectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
    this.setListItem();
    this.poModalSearch.close();
    this.tablePo.selectedAll = false;
    this.poModalItensPo.open();
  }

  public unselectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
  }

  public setListItem() {
    this.tablePo.columns = [];
    this.tablePo.items = [];
    if (this.tableSearchPopup.fieldValue.$selected) {
      this.loading = true;
      const obj = {
        procedure: false,
        metodo: 'BUSCAR_WIRE',
        data: {
          C7_NUM: this.tableSearchPopup.fieldValue.C7_NUM,
          ZR5_NUM: this.containerForm.ZR5_NUM,
        },
      };
      this.wireTransferService.returnWire(obj).subscribe({
        next: (ret: any) => {
          if (ret !== null) {
            ret.data.map((element: any) => {
              if (element.columns) {
                this.tablePo.columns = element.columns;
              }
              if (element.items) {
                const retorno = element.items;
                retorno.forEach((el: any) => {
                  this.table.items.map((item) => {
                    if (el.recno === item.recno_sc7) {
                      el.C7_QUANT = item.ZR6_QUANT;
                      el.ZR6_OBS = item.ZR6_OBS;
                      el.ZR6_DOC = item.ZR6_DOC;
                      el.$selected = true;
                    } else {
                      return item;
                    }
                  });
                });
                this.tablePo.items = retorno;
              }
            });
          }
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;
        },
      });
    } else {
      this.notificationService.warning('Please select a option');
    }
  }

  selectedItem(event: any) {
    return (this.arrSelectedtems = this.utilService.spreadArrayToProperty(
      this.arrSelectedtems,
      event
    ));
  }

  unSelectedItem(event: any) {
    return (this.arrSelectedtems = this.arrSelectedtems.filter(
      (item) => item.$selected === true
    ));
  }

  allSelected(event: any) {
    this.arrSelectedtems = [];
    return (this.arrSelectedtems = event);
  }

  unAllSelected() {
    this.arrSelectedtems = [];
  }

  public limparField(controls: any, field: any) {
    controls[field].setValue('');
  }

  dynamicForm() {
    this.searchListPromise(this.typeListModal, true);
  }

  validateQuantity(e: any, isNgModel: boolean) {
    if (isNgModel) {
      if (e.C7_QUANT > e.SALDO) {
        e.C7_QUANT =
          this.itemSelecionado.ZR6_QUANT === undefined
            ? 1
            : this.itemSelecionado.ZR6_QUANT;
        return this.notificationService.warning(
          `The maximum quantity allowed is: : ${e.SALDO} `
        );
      } else {
        e.C7_TOTAL = e.C7_QUANT * e.C7_PRECO;
      }
    } else {
      if (e.ZR6_QUANT.value > e.SALDO.value) {
        e.ZR6_QUANT.setValue(this.itemSelecionado.ZR6_QUANT);
        return this.notificationService.warning(
          `The maximum quantity allowed is: : ${e.SALDO.value} `
        );
      } else {
        const total = e.ZR6_QUANT.value * e.C7_PRECO.value;
        e.C7_TOTAL.setValue(total);
      }
    }
  }

  sendValidar(array: Array<any>) {
    if (array.length > 0) {
      this.loading = true;
      const obj = {
        procedure: false,
        metodo: 'VALIDAR_WIRE',
        data: array,
      };
      this.wireTransferService.returnWire(obj).subscribe({
        next: (ret: any) => {
          if (ret !== null) {
            const returnItems = ret.data[0].items;
            if (this.table.items.length <= 0) {
              this.notificationService.success(
                `Wire request ${this.containerForm.ZR5_NUM} created successfully`
              );
            }
            this.gravarItem(returnItems, false, false);
          } else {
            this.notificationService.warning('Error please try again later');
          }
        },
        error: (err: any) => {
          this.loading = false;
        },
      });
    } else {
      return this.notificationService.warning(
        'Please select an item from the list'
      );
    }
  }

  gravarItem(array: Array<any>, deleta: boolean, back: boolean) {
    this.loading = true;
    const arrEnvio = array.map((item) => {
      return {
        ZR6_NUM: this.containerForm.ZR5_NUM,
        ZR6_NUMPC: item.ZR6_NUMPC,
        ZR6_ITEMPC: item.ZR6_ITEMPC,
        ZR6_FORNEC: item.ZR6_FORNEC,
        ZR6_LOJA: item.ZR6_LOJA,
        ZR6_QUANT: item.ZR6_QUANT,
        ZR6_OBS: item.ZR6_OBS,
        ZR6_DOC: item.ZR6_DOC,
        recno: item.recno ?? 0,
        deletado: item.deletado === true ? 1 : 0,
      };
    });
    if (isNaN(this.containerForm.ZR5_DISCOU)) {
      this.containerForm.ZR5_DISCOU = 0;
    } else if (isString(this.containerForm.ZR5_DISCOU)) {
      this.containerForm.ZR5_DISCOU = parseFloat(this.containerForm.ZR5_DISCOU);
    }

    const obj = {
      procedure: false,
      homologacao: false,
      metodo: this.metodos.SALVAR_ITEM_ZR6,
      data: [{ ZR5: this.containerForm }, { ZR6: arrEnvio }],
    };

    this.wireTransferService.returnWire(obj).subscribe({
      next: (
        element: { data: any[]; status: boolean; mensagem: string } | null
      ) => {
        if (element !== null) {
          if (element.status) {
            element.data.map((val: any) => {
              if (val.ZR5) {
                val.ZR5.forEach((tab: any) => {
                  if (tab.fields) {
                    this.fieldsContainer = tab.fields;
                    // let conditions: any = ['ZR5_EMISSA', 'ZR5_AMOUNT', 'ZR5_NUM'];;
                    let conditions: any;
                    if (this.tipoTela === 'add') {
                      conditions = ['ZR5_EMISSA', 'ZR5_AMOUNT', 'ZR5_DISCOU'];
                    } else if (
                      this.tipoTela === 'edit' &&
                      !this.isGenerate &&
                      this.statusWire == '1'
                    ) {
                      conditions = [
                        'ZR5_EMISSA',
                        'ZR5_AMOUNT',
                        'ZR5_DISCOU',
                        'ZR5_NUM',
                      ];
                    } else {
                      conditions = [
                        'ZR5_EMISSA',
                        'ZR5_AMOUNT',
                        'ZR5_DISCOU',
                        'ZR5_NUM',
                        'ZR5_OBS',
                      ];
                    }
                    this.fieldsContainer.forEach((item: any) => {
                      if (conditions.includes(item.property)) {
                        item.disabled = true;
                      } else {
                        item.disabled = false;
                      }
                      item.gridColumns = 2;

                      if (item.property === 'ZR5_OBS') {
                        item.gridColumns = 4;
                      }
                    });
                  } else if (tab.items) {
                    this.containerForm = tab.items[0];

                    if (this.containerForm.ZR5_EMISSA.indexOf('/') !== -1) {
                      this.containerForm.ZR5_EMISSA =
                        this.containerForm.ZR5_EMISSA.split('/').join('-');
                    }
                  }
                });
              } else if (val.ZR6) {
                val.ZR6.forEach((ret: any) => {
                  if (ret.items) {
                    this.table.items = [];
                    const returnItems = ret.items;
                    returnItems.forEach((element: any) => {
                      element.actions = ['edit', 'delete'];
                      element.itemOriginal = false;
                      this.table.items = this.utilService.spreadArrayToProperty(
                        this.table.items,
                        element
                      );
                    });

                    const filtrado = this.removeDuplicates(this.table.items);
                    this.table.items = filtrado;

                    this.validarUpFront(back);
                    this.poModalSearch.close();
                    this.poModalItensPo.close();
                    this.modalEditItemContainer.close();
                  }
                });
              }
            });
          } else {
            this.notificationService.warning(element.mensagem);
            this.loading = false;
          }
        }
      },
      error: (err: any) => {
        this.notificationService.error(err.mensagem);
        this.loading = false;
      },
    });
  }

  validarUpFront(back: boolean, deletedItem?: any) {
    if (this.tipoTela === 'add' || this.tipoTela === 'edit') {
      if (this.table.items.length > 0 && !this.isGenerate) {
        const envio = this.table.items.map((item) => {
          return {
            ZR6_FORNEC: item.ZR6_FORNEC,
            ZR6_LOJA: item.ZR6_LOJA,
            C7_TOTAL: item.C7_TOTAL,
          };
        });
        this.loading = true;
        const data = { recno: this.containerForm.recno };

        const obj = {
          procedure: false,
          metodo: 'VALIDAR_UPFRONT',
          data: data,
        };
        this.wireTransferService.returnWire(obj).subscribe({
          next: (ret: any) => {
            if (ret !== null) {
              const returnItems = ret.data[0].items;
              returnItems.forEach((element: any) => {
                if (!element.ZR7_DESCON && this.tipoTela === 'add') {
                  element.ZR7_DESCON = 0;
                }
                const chave = `${element.ZR7_FORNEC}${element.ZR7_LOJA}`;
                if (this.tableUpfront.items.length > 0) {
                  this.tableUpfront.items.forEach((valor) => {
                    valor.ZR7_NOME = valor.ZR7_NOME ?? '';
                    const chaveUpfront = `${valor.ZR7_FORNEC}${valor.ZR7_LOJA}`;
                    if (chave !== chaveUpfront) {
                      this.tableUpfront.items =
                        this.utilService.spreadArrayToProperty(
                          this.tableUpfront.items,
                          element
                        );
                    } else {
                      if (valor.ZR7_VALOR !== element.ZR7_VALOR) {
                        valor.ZR7_VALOR = element.ZR7_VALOR;
                        valor.ZR7_NOME =
                          valor.ZR7_NOME.length > 0
                            ? valor.ZR7_NOME
                            : element.ZR7_NOME;
                      }
                      valor.recno = element.recno;
                    }
                  });
                } else {
                  this.tableUpfront.items =
                    this.utilService.spreadArrayToProperty(
                      this.tableUpfront.items,
                      element
                    );
                }
              });

              let filtrado = this.tableUpfront.items.filter(
                (thing: any, index: any, self: any) =>
                  index ===
                  self.findIndex(
                    (t: any) =>
                      `${t.ZR7_FORNEC}${t.ZR7_LOJA}` ===
                      `${thing.ZR7_FORNEC}${thing.ZR7_LOJA}`
                  )
              );

              filtrado = this.utilService.sortArrayByPropertyConcat(
                filtrado,
                'ZR7_FORNEC',
                'ZR7_LOJA'
              );
              if (deletedItem) {
                filtrado = filtrado.filter(
                  (item) =>
                    `${item.ZR7_FORNEC}${item.ZR7_LOJA}` !==
                    `${deletedItem.ZR6_FORNEC}${deletedItem.ZR6_LOJA}`
                );
              }

              if (this.tipoTela === 'add') {
                filtrado.forEach((valor) => {
                  this.calcBalanceAndTotalizador(valor);
                });
              }
              this.tableUpfront.items = filtrado;
              this.containerForm.ZR5_AMOUNT = this.reduceArrayByProperty(
                this.table.items,
                'C7_TOTAL'
              );
              this.totalizadores.totalValue = this.reduceArrayByProperty(
                this.tableUpfront.items,
                'SALDO'
              );
              const desconto = this.reduceArrayByProperty(
                this.tableUpfront.items,
                'ZR7_DESCON'
              );
              this.containerForm.ZR5_DISCOU = desconto.toFixed(2);
              if (back) {
                this.confirmSaveUpFront(
                  'SALVAR_ITEM_ZR7',
                  back,
                  this.tableUpfront.items
                );
              } else {
                this.loading = false;
              }
            }
          },
          error: (err: any) => {
            this.loading = false;
          },
        });
      }
    }
  }

  detailsUpfront(item: any) {
    if (item.alterar) {
      this.modalUpfrontForm.patchValue(item);
      this.modalUpfront.open();
    } else {
      return this.notificationService.warning(item.mensagem);
    }
  }

  details(item: any) {
    if (item.alterar) {
      this.disabledModalFields = false;
    } else {
      this.disabledModalFields = true;
      return this.notificationService.information(item.mensagem);
    }
    this.isModalUpfront = false;
    this.modalEditItemContainer.title = 'Edit';
    this.itemSelecionado = item;

    if (item.C7_EMISSAO.indexOf('/') !== -1) {
      item.C7_EMISSAO = item.C7_EMISSAO.split('/').join('-');
    }

    this.containerAddForm.patchValue(item);
    this.gerarSequencia(this.table.items, this.containerAddForm.controls);
    this.modalEditItemContainer.open();
  }

  removeTableUpfront(item: { [key: string]: any }) {
    if (item.alterar) {
      this.poTableUpfront.removeItem(item);
      this.tableUpfront.items = this.poTableUpfront.items;
      this.containerForm.Upfront = this.reduceArrayByProperty(
        this.tableUpfront.items,
        'ZR7_VALOR'
      );
    } else {
      return this.notificationService.warning(item.mensagem);
    }
  }

  removeTableContainer(item: { [key: string]: any }) {
    if (item.alterar) {
      if (this.table.items.length > 1) {
        item.deletado = true;
        this.gravarItem(this.table.items, true, false);
      } else {
        return this.notificationService.warning(`
        Sorry, you cannot delete this item as the minimum quantity is 1 item. If necessary, you can delete the wire to complete this task.`);
      }
    } else {
      return this.notificationService.warning(item.mensagem);
    }
  }

  addUpfrontItem() {
    this.arrGenerateSelectedItems.forEach((item) => {
      if (item.status === 'generated') {
        item.$selected = false;
        this.notificationService.warning(
          `Item ${item.ZR7_FORNEC}${item.ZR7_LOJA} - ${item.ZR7_NOME} has already been generated, please select only items with status pending `
        );
      }
    });
    const itensPeding: Array<any> = this.arrGenerateSelectedItems.filter(
      (item) => item.status === 'pending'
    );
    if (itensPeding.length <= 0) {
      return this.notificationService.warning(
        'Please select at least one item'
      );
    } else if (this.arrGenerateSelectedItems.length > 0) {
      this.UpfrontForm = {};
      this.tableResumoUpfront.items = itensPeding;
      this.arrGenerateSelectedItems = itensPeding;
      this.modalUpfront.title = `Generate - ${this.reduceArrayByProperty(
        this.tableResumoUpfront.items,
        'SALDO'
      ).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`;
      this.modalUpfront.open();
    }
  }

  montarEnvio(operation: Operations, metodo: string, arrayItems?: any) {
    if (operation !== '3') {
      this.containerForm['recno'] = this.recno;
    } else {
      this.containerForm['recno'] = 0;
    }
    let items: any;
    metodo === 'GERAR_UPFRONT' ? arrayItems : this.tableUpfront.items;
    if (metodo === 'GERAR_UPFRONT') {
      items = arrayItems;
    } else {
      items = this.tableUpfront.items;
    }

    if (items.length > 0) {
      items.forEach((item: any) => {
        if (item.estornar === 0) {
          item.estornar = true;
        } else {
          item.estornar = false;
        }
      });
    }

    if (isNaN(this.containerForm.ZR5_DISCOU)) {
      this.containerForm.ZR5_DISCOU = 0;
    } else {
      this.containerForm.ZR5_DISCOU = parseFloat(this.containerForm.ZR5_DISCOU);
    }
    const envio = {
      ZR5: [{ items: this.containerForm }],
      ZR6: [{ items: this.table.items }],
      ZR7: [{ items: items }],
      operation,
    };
    return envio;
  }

  validaCabec(
    operation: Operations,
    metodo: string,
    acao: string,
    arrayItems: any
  ) {
    if (this.utilService.verifyUnfinedNull(this.containerForm.ZR5_NUM) === '') {
      return this.notificationService.warning(
        'Please insert a Wire Transfer number'
      );
    } else if (
      this.utilService.verifyUnfinedNull(this.containerForm.ZR5_EMISSA) === ''
    ) {
      return this.notificationService.warning('Please insert a Request Date');
    } else if (this.table.items.length <= 0 && this.tipoTela !== 'delete') {
      return this.notificationService.warning(
        'Please select at least one po item'
      );
    } else {
      this.returnSave(operation, metodo, acao, arrayItems);
    }
  }

  returnSave(
    operation: Operations,
    metodo: string,
    acao: string,
    arrayItems: any
  ) {
    this.loading = true;
    if (
      !this.isGenerate &&
      (this.tipoTela === 'add' || this.tipoTela === 'edit')
    ) {
      this.validarUpFrontPromise(
        operation,
        metodo,
        acao,
        arrayItems,
        (status: any, error: any, mensagem: any) => {
          if (status) {
            this.gravarItem(this.table.items, false, true);
          } else {
            this.notificationService.warning(mensagem);
          }
          if (error === true) {
            this.notificationService.error(mensagem);
          }
        }
      );
    } else {
      this.confirmSave(operation, metodo, acao, arrayItems);
    }
  }

  confirmSave(
    operation: Operations,
    metodo: string,
    acao: string,
    arrayItems?: Array<any>
  ) {
    const obj = {
      procedure: false,
      metodo,
      data: this.montarEnvio(operation, metodo, arrayItems),
    };
    this.wireTransferService.returnWire(obj).subscribe({
      next: (ret: any) => {
        if (ret !== null) {
          if (ret.status === true) {
            this.notificationService.success(ret.mensagem);
            this.loading = false;
            if (acao !== 'reverse') {
              this.location.back();
            } else {
              this.getById({ recno: this.recno });
            }
          } else {
            this.notificationService.warning(ret.mensagem);
            this.loading = false;
          }
        } else {
          this.notificationService.error('Error, please try again!');
          this.loading = false;
        }
      },
      error: (err: { mensagem: any }) => {
        console.error(err, '--> error');
        this.notificationService.error(err.mensagem);
        this.loading = false;
      },
    });
  }

  public bankSelec(item: any) {
    if (item) {
      this.controlsUpfrontForm.ZR7_BANCO.setValue(item[0]);
      this.controlsUpfrontForm.ZR7_CONTA.setValue(item[2]);
      this.controlsUpfrontForm.ZR7_AGENCI.setValue(item[1]);
    }
  }

  public ccenterSelec(item: any) {
    if (item) {
      this.controlsUpfrontForm.ZR7_CCUSTO.setValue(item);
    }
  }

  public classSelec(item: any) {
    if (item) {
      this.controlsUpfrontForm.ZR7_NATURE.setValue(item);
    }
  }

  selectedGeneratedItem(event: any) {
    this.upfrontSelecionado = event;
    this.arrGenerateSelectedItems = this.utilService.spreadArrayToProperty(
      this.arrGenerateSelectedItems,
      event
    );
    this.totalizadores.selectedQuantity = this.reduceArrayByProperty(
      this.arrGenerateSelectedItems,
      'ZR7_VALOR'
    );
    return;
  }

  unSelectedGeneratedItem(event: any) {
    this.upfrontSelecionado = {};
    this.arrGenerateSelectedItems = this.arrGenerateSelectedItems.filter(
      (item) => item.$selected === true
    );
    this.totalizadores.selectedQuantity = this.reduceArrayByProperty(
      this.arrGenerateSelectedItems,
      'ZR7_VALOR'
    );
    return;
  }

  allGeneratedSelected(event: any) {
    this.arrGenerateSelectedItems = [];
    this.arrGenerateSelectedItems = event;
    this.totalizadores.selectedQuantity = this.reduceArrayByProperty(
      this.arrGenerateSelectedItems,
      'ZR7_VALOR'
    );
    return;
  }

  unAllGeneratedSelected() {
    this.arrGenerateSelectedItems = [];
    this.totalizadores.selectedQuantity = 0;
    return;
  }

  gerarSequencia(array: Array<any>, form: any) {
    if (array.length <= 0) {
      const sequencia = 1;
      form.sequencia.setValue(sequencia);
    } else {
      const table = array;
      table.sort((a, b) => {
        return a.sequencia - b.sequencia;
      });
      const ultimoItem = table[table.length - 1].sequencia;
      const sequencia = parseInt(ultimoItem, 10) + 1;
      form.sequencia.setValue(sequencia);
    }
  }

  reverseItem() {
    this.arrGenerateSelectedItems.forEach((item) => {
      if (item.status === 'pending') {
        item.$selected = false;
        this.notificationService.warning(
          `Item ${item.ZR7_FORNEC}${item.ZR7_LOJA} - ${item.ZR7_NOME} is pending, select only items with generated status`
        );
      }
    });
    const itensGenerated: Array<any> = this.arrGenerateSelectedItems.filter(
      (item) => item.status === 'generated'
    );
    if (itensGenerated.length > 0) {
      this.arrGenerateSelectedItems = itensGenerated;
      this.arrGenerateSelectedItems = this.arrGenerateSelectedItems.map(
        (item: any) => {
          if (item.status === 'generated') {
            if (item.estornar === 1) {
              item.estornar = 0;
            } else if (item.estornar === 0) {
              item.estornar = 1;
            } else {
              item.estornar = 0;
            }
            this.validaCabec(
              this.operation,
              'GERAR_UPFRONT',
              'reverse',
              this.arrGenerateSelectedItems
            );
          } else {
            return this.notificationService.warning(
              'Please select only items with status generated'
            );
          }
        }
      );
    } else if (this.arrGenerateSelectedItems.length <= 0) {
      return this.notificationService.warning(
        'Please select at least one item'
      );
    }
  }

  validateUnitPrice(e: any) {
    if (e.ZR6_VLRBRL < 0) {
      e.ZR6_VLRBRL = 0;
      return this.notificationService.warning(
        `The minimum quantity allowed is : 0 `
      );
    }
  }

  onChangeCheckBox(event: any, all: boolean) {
    if (!event && !all) {
      this.tablePo.selectedAll = event;
    }
    if (all) {
      this.tablePo.items.forEach((el: any) => (el.$selected = event));
    }
  }

  removeDuplicates(original: Array<any>) {
    let reduced: Array<any> = [];
    original.forEach((item) => {
      const duplicated =
        reduced.findIndex((redItem) => {
          return item.recno_sc7 == redItem.recno_sc7;
        }) > -1;

      if (!duplicated) {
        reduced = this.utilService.spreadArrayToProperty(reduced, item);
      } else {
        reduced.forEach((ret) => {
          if (ret.recno_sc7 === item.recno_sc7) {
            ret.A2_NOME = item.A2_NOME;
            ret.B1_DESC = item.B1_DESC;
            ret.B1_XCODFOR = item.B1_XCODFOR;
            ret.C6_NUM = item.C6_NUM;
            ret.C7_EMISSAO = item.C7_EMISSAO;
            ret.C7_FORNECE = item.C7_FORNECE;
            ret.C7_ITEM = item.C7_ITEM;
            ret.C7_NUM = item.C7_NUM;
            ret.C7_PRECO = item.C7_PRECO;
            ret.C7_PRODUTO = item.C7_PRODUTO;
            ret.C7_QUANT = item.C7_QUANT;
            ret.C7_QUJE = item.C7_QUJE;
            ret.C7_TOTAL = item.C7_TOTAL;
            ret.SALDO = item.SALDO;
            ret.ZR6_DOC = item.ZR6_DOC;
            ret.ZR6_ITEMPC = item.ZR6_ITEMPC;
            ret.ZR6_NUMPC = item.ZR6_NUMPC;
            ret.ZR6_OBS = item.ZR6_OBS;
            ret.ZR6_QUANT = item.ZR6_QUANT;
            ret.ZR6_VOL = item.ZR6_VOL;
            ret.actions = item.actions;
            ret.alterar = item.alterar;
            ret.itemOriginal = item.itemOriginal;
            ret.mensagem = item.mensagem;
            ret.recno = item.recno;
            ret.recno_sc7 = item.recno_sc7;
          }
        });
      }
    });
    return reduced;
  }

  private exportExcel() {
    this.loading = true;
    this.validarUpFrontPromise(
      this.operation,
      'VALIDAR_UPFRONT',
      'excel',
      [],
      (status: any, error: any, mensagem: any) => {
        if (error === true) {
          this.notificationService.error(mensagem);
        } else if (status === false) {
          this.notificationService.warning(mensagem);
        }

        const cabec = [this.containerForm];
        const result = this.table.items.map((item) => {
          cabec.forEach((el: any) => {
            Object.entries(el).forEach(([chave, valor]) => {
              item[chave] = valor;
            });
          });

          this.tableUpfront.items.forEach((a: any) => {
            Object.entries(a).forEach(([chave, valor]) => {
              item[chave] = valor;
            });
          });
          return item;
        });

        result.forEach((data: any) => {
          cabec.map((ret: any) => {
            Object.entries(ret).forEach(([chave, valor]) => {
              if (ret.property === chave) {
                chave = ret.label;
              }
            });
          });
        });

        const arr = result.map((element: any) => {
          return {
            'Wire Transf.': element.ZR5_NUM,
            'Request Date': this.utilService.multiFormataData(
              element.ZR5_EMISSA,
              'mm/dd/yyyy'
            ),
            Amount: element.ZR5_AMOUNT,
            Notes: element.ZR5_OBS,
            'Nota Fiscal': element.ZR6_DOC,
            Vendor: element.ZR6_FORNEC,
            Store: element.ZR6_LOJA,
            Name: element.A2_NOME,
            'Purchase Order': element.C7_NUM,
            'Sales Order': element.C6_NUM,
            PO_ITEM_NOTES: element.ZR6_OBS,
            'Date Order PO': this.utilService.multiFormataData(
              element.C7_EMISSAO,
              'mm/dd/yyyy'
            ),
            'Vendor Product Code': element.B1_XCODFOR,
            Product: element.C7_PRODUTO,
            Description: element.B1_DESC,
            Quantity: element.ZR6_QUANT,
            'Unit. Price': element.C7_PRECO,
            Total: element.C7_TOTAL,
            Supplier: element.ZR7_FORNEC,
            Upfront_Store: element.ZR7_LOJA,
            'Suppl.Name': element.ZR7_NOME,
            'Bill Value': element.ZR7_VALOR,
            Prefix: element.ZR7_PREFIX,
            'Bill Number': element.ZR7_BILL,
            Installment: element.ZR7_PARCEL,
            'Issue Dt': this.utilService.multiFormataData(
              element.ZR7_EMISSA,
              'mm/dd/yyyy'
            ),
            Class: element.ZR7_NATURE,
            Upfront_Description: element.ZR7_DESCRI,
            History: element.ZR7_HISTOR,
            'History 2': element.ZR7_HIST2,
          };
        });

        this.excelService.exportAsExcelFile(arr, this.page.title);
        this.loading = false;
      }
    );
  }

  public validarUpFrontPromise(
    operation: Operations,
    metodo: string,
    acao: string,
    arrayItems?: any,
    callback?: any
  ) {
    const envio = this.table.items.map((item) => {
      return {
        ZR6_FORNEC: item.ZR6_FORNEC,
        ZR6_LOJA: item.ZR6_LOJA,
        C7_TOTAL: item.C7_TOTAL,
      };
    });
    const data = { recno: this.containerForm.recno };

    const obj = {
      procedure: false,
      metodo: 'VALIDAR_UPFRONT',
      data: data,
    };
    this.wireTransferService.returnWire(obj).subscribe({
      next: (data: any) => {
        if (data !== null) {
          const returnItems = data.data[0].items;
          returnItems.forEach((element: any) => {
            const chave = `${element.ZR7_FORNEC}${element.ZR7_LOJA}`;
            if (this.tableUpfront.items.length > 0) {
              this.tableUpfront.items.forEach((valor) => {
                valor.ZR7_NOME = valor.ZR7_NOME ?? '';
                const chaveUpfront = `${valor.ZR7_FORNEC}${valor.ZR7_LOJA}`;
                if (chave !== chaveUpfront) {
                  this.tableUpfront.items =
                    this.utilService.spreadArrayToProperty(
                      this.tableUpfront.items,
                      element
                    );
                } else {
                  if (valor.ZR7_VALOR !== element.ZR7_VALOR) {
                    valor.ZR7_VALOR = element.ZR7_VALOR;
                    valor.ZR7_NOME =
                      valor.ZR7_NOME.length > 0
                        ? valor.ZR7_NOME
                        : element.ZR7_NOME;
                  }
                }
              });
            } else {
              this.tableUpfront.items = this.utilService.spreadArrayToProperty(
                this.tableUpfront.items,
                element
              );
            }
          });

          let filtrado = this.tableUpfront.items.filter(
            (thing: any, index: any, self: any) =>
              index ===
              self.findIndex(
                (t: any) =>
                  `${t.ZR7_FORNEC}${t.ZR7_LOJA}` ===
                  `${thing.ZR7_FORNEC}${thing.ZR7_LOJA}`
              )
          );
          filtrado = this.utilService.sortArrayByPropertyConcat(
            filtrado,
            'ZR7_FORNEC',
            'ZR7_LOJA'
          );
          this.tableUpfront.items = filtrado;
          this.containerForm.Upfront = this.reduceArrayByProperty(
            this.tableUpfront.items,
            'ZR7_VALOR'
          );
        }
        if (typeof callback === 'function') {
          callback(true, false, 'succefuly');
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        if (typeof callback === 'function') {
          callback(false, true, error);
        }
      },
    });
  }

  calcBalanceAndTotalizador(row: any) {
    if (
      row.ZR7_DESCON < 0 ||
      isNull(row.ZR7_DESCON) ||
      isUndefined(row.ZR7_DESCON)
    ) {
      row.ZR7_DESCON = 0;
      this.notificationService.warning(`The minimum discount allowed is : 0`);
    } else if (row.ZR7_DESCON > row.ZR7_VALOR) {
      row.ZR7_DESCON = row.ZR7_VALOR;
      this.notificationService.warning(
        `The maximum discount allowed is : ${row.ZR7_VALOR}`
      );
    }

    row.SALDO = row.ZR7_VALOR - row.ZR7_DESCON;

    this.containerForm.ZR5_AMOUNT = this.reduceArrayByProperty(
      this.table.items,
      'C7_TOTAL'
    );
    this.totalizadores.totalValue = this.reduceArrayByProperty(
      this.tableUpfront.items,
      'SALDO'
    );
    const desconto = this.reduceArrayByProperty(
      this.tableUpfront.items,
      'ZR7_DESCON'
    );

    this.containerForm.ZR5_DISCOU = desconto.toFixed(2);
  }

  clearFilter() {
    this.modalSearchForm.reset();
    this.dynamicForm();
  }

  viewDetail(item: WireTransferUpFront) {
    this.itemDetailSelecionado = item;
    this.loading = true;
    const envio: WireTransferDetailEnvio = {
      ZR7_BILL: item.ZR7_BILL,
      ZR7_FORNEC: item.ZR7_FORNEC,
      ZR7_LOJA: item.ZR7_LOJA,
      ZR7_PARCEL: item.ZR7_PARCEL,
      ZR7_PREFIX: item.ZR7_PREFIX,
      ZR7_TIPO: item.ZR7_TIPO,
    };
    const obj = {
      procedure: true,
      metodo: this.metodos.WIRE_BILL_DETAIL,
      data: envio,
    };
    this.wireTransferService.returnWire(obj).subscribe({
      next: (data: { status: any; data: never[]; mensagem: any }) => {
        if (data.status) {
          this.tipoModalDetalhe = 1;
          this.tableDetailUpfront.items = data.data;
          this.totalizadoresModaisDetalhes.wireAmount =
            this.reduceArrayByProperty(
              this.tableDetailUpfront.items,
              'wire_amount'
            );
          this.totalizadoresModaisDetalhes.wireBill =
            this.reduceArrayByProperty(
              this.tableDetailUpfront.items,
              'wire_bill'
            );
          this.totalizadoresModaisDetalhes.wireBillAmount =
            this.reduceArrayByProperty(
              this.tableDetailUpfront.items,
              'bill_amount'
            );
          this.modalDetailUpfront.open();
        } else {
          this.tableDetailUpfront.items = [];
          this.notificationService.warning(data.mensagem);
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
        this.notificationService.error(error.error.mensagem);
      },
    });
  }

  public selectDetailItem(event: any) {
    if (event.bill === '') {
      return this.notificationService.warning('No data available ');
    } else {
      this.tableDetailUpfront.loading = true;
      const envio: WireTransferDetailItemEnvio = {
        ZR7_FORNEC: this.itemDetailSelecionado.ZR7_FORNEC,
        ZR7_LOJA: this.itemDetailSelecionado.ZR7_LOJA,
        bill: event.bill,
        prefixo: event.prefixo,
      };
      const obj = {
        procedure: true,
        metodo: this.metodos.WIRE_BILL_DETAIL_ITEM,
        data: envio,
      };
      this.wireTransferService.returnWire(obj).subscribe({
        next: (data: { status: any; data: any; mensagem: any }) => {
          if (data.status) {
            this.tipoModalDetalhe = 2;
            this.tableDetailItensUpfront.items = data.data;
            this.totalizadoresModaisDetalhes.detailItemQuantity =
              this.reduceArrayByProperty(
                this.tableDetailItensUpfront.items,
                'D1_QUANT'
              );
            this.totalizadoresModaisDetalhes.detailItemTotal =
              this.reduceArrayByProperty(
                this.tableDetailItensUpfront.items,
                'D1_TOTAL'
              );
            this.closeModalDetailUpfront.label = 'Back';
            this.modalDetailUpfront.open();
          } else {
            this.tableDetailItensUpfront.items = [];
            this.notificationService.warning(data.mensagem);
          }
          this.tableDetailUpfront.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error.error);
          this.tableDetailUpfront.loading = false;
          this.notificationService.error(error.error.mensagem);
        },
      });
    }
  }

  toExcel(columns: any, items: any, table: any) {
    table.loading = true;
    setTimeout(() => {
      this.excelService.organizaArrParaExcel(columns, items, this.page.title);
      table.loading = false;
    }, 500);
  }

  confirmSaveUpFront(metodo: string, back: boolean, array: Array<any>) {
    const arrEnvio = array.map((item) => {
      return {
        ZR7_DESCON: item.ZR7_DESCON,
        ZR7_FORNEC: item.ZR7_FORNEC,
        ZR7_LOJA: item.ZR7_LOJA,
        ZR7_NOME: item.ZR7_NOME,
        ZR7_VALOR: item.ZR7_VALOR,
        alterar: item.alterar,
        mensagem: item.mensagem,
        recno: item.recno,
      };
    });

    const obj = {
      procedure: false,
      metodo,
      data: [{ items: arrEnvio }],
    };

    this.wireTransferService.returnWire(obj).subscribe({
      next: (ret: { status: any; data: any; mensagem: any }) => {
        if (ret !== null) {
          if (ret.status === true) {
            this.notificationService.success(ret.mensagem);
            this.loading = false;
            if (back) {
              this.location.back();
            }
          } else {
            this.notificationService.warning(ret.mensagem);
            this.loading = false;
          }
        } else {
          this.notificationService.error('Error, please try again!');
          this.loading = false;
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.tableDetailUpfront.loading = false;
        this.notificationService.error(error.error.mensagem);
      },
    });
  }
}
