import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PurchaseOrderEditComponent } from './purchase-order/purchase-order-edit/purchase-order-edit.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { WireTransferEditComponent } from './wire-transfer/wire-transfer-edit/wire-transfer-edit.component';
import { WireTransferComponent } from './wire-transfer/wire-transfer.component';

const routes: Routes = [
  {
    path: 'purchase-order', children: [
      { path: '', component: PurchaseOrderComponent },
      { path: 'view/:RECNO', component: PurchaseOrderEditComponent },
      { path: 'edit/:RECNO', component: PurchaseOrderEditComponent }
    ]
  },
  {
    path: 'wire-request', children: [
      { path: '', component: WireTransferComponent },
      { path: 'add', component: WireTransferEditComponent },
      { path: 'edit/:RECNO/:STATUS', component: WireTransferEditComponent },
      { path: 'view/:RECNO', component: WireTransferEditComponent },
      { path: 'delete/:RECNO', component: WireTransferEditComponent }
    ]
  },
  {
    path: 'wire-payment', children: [
      { path: '', component: WireTransferComponent },
      { path: 'add', component: WireTransferEditComponent },
      { path: 'edit/:RECNO/:STATUS', component: WireTransferEditComponent },
      { path: 'view/:RECNO', component: WireTransferEditComponent },
      { path: 'delete/:RECNO', component: WireTransferEditComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PurchaseRoutingModule { }
