import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvioPadraoBackend } from '../envio-padrao-backend';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'print';

  public getPrint(parameters: EnvioPadraoBackend): Observable<Blob> {
    parameters.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, parameters, {responseType: 'blob'}) as Observable<Blob>;
  }
}
