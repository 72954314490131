import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface RetornoListaPaises {
  response: Array<{
    YA_PAIS_I: string,
    YA_CODGI: string
  }>
}

@Injectable({
  providedIn: 'root'
})

export class CountryServices {

  constructor(
    private http: HttpClient
  ) { }

  private readonly apiRoot = `${environment.URL.APIPRIVATE}/v1/countries-list`;

  public postCountries(body: any) {
    body.homologacao = !environment.production
    return this.http.post(`${this.apiRoot}`, body) as Observable<RetornoListaPaises>
  }

}
