import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PoBreadcrumb,
  PoComboFilterMode,
  PoNotificationService,
  PoPageAction,
  PoSelectOption,
  PoTableColumn,
} from '@po-ui/ng-components';
import { CountryServices } from 'src/app/service/country/country.service';
import { CustomersListService } from 'src/app/service/customers-list.service';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { LoadedPageService } from 'src/app/service/loadedPage/loaded-page.service';
import { RepresentantesService } from 'src/app/service/representantes/representantes.service';
import { UtilService } from 'src/app/service/util/util.service';

interface itemTable {
  A1_CGC: string;
  A1_COD: string;
  A1_DDD: string;
  A1_EMAIL: string;
  A1_EST: string;
  A1_LOJA: string;
  A1_MUN: string;
  A1_NOME: string;
  A1_NREDUZ: string;
  A1_PAIS: string;
  A1_TEL: string;
  A1_END: string;
  A1_CEP: string;
  A1_VEND: string;
  A3_NREDUZ: string;
  A1_ULTCOM: string;
  $selected: boolean;
}

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.css'],
})
export class CustomersListComponent implements OnInit {
  constructor(
    private customersListService: CustomersListService,
    private representanteService: RepresentantesService,
    private utilService: UtilService,
    private fb: UntypedFormBuilder,
    private notification: PoNotificationService,
    private countryServices: CountryServices,
    private router: Router,
    private loadedPageService: LoadedPageService,
    private activatedRoute: ActivatedRoute,
    private excelService: ExcelService
  ) {}

  public loading = false;
  public listaCountries = [] as Array<PoSelectOption>;
  public filterMode = PoComboFilterMode.contains;

  public page = {
    title: 'Customers',
    actions: [
      {
        label: 'Create',
        action: () => {
          this.changeRoute('create');
        },
      },
      {
        label: 'Edit',
        action: () => {
          this.mudarRota('edit');
        },
      },
      {
        label: 'Delete',
        action: () => {
          this.mudarRota('delete');
        },
        type: 'danger',
        icon: 'po-icon-delete',
      },
      {
        label: 'Excel',
        action: () => {
          this.exportExcel();
        },
        icon: 'po-icon-export',
      },
    ] as Array<PoPageAction>,
    breadcrumb: {
      items: [
        { label: 'Home' },
        { label: 'Commercial' },
        { label: 'Customers' },
      ],
    } as PoBreadcrumb,
  };

  public form = this.fb.group({
    nomeFantasia: [''],
    razaoSocial: [''],
    codigoCliente: [''],
    pais: [''],
    uf: [''],
    bloqueado: ['3'],
    codRepresentante: [''],
  });

  public table = {
    columns: [
      { label: 'Code', property: 'A1_COD', width: '100px' },
      { label: 'Unit', property: 'A1_LOJA', width: '100px' },
      { label: 'Name', property: 'A1_NOME', width: '150px' },
      { label: 'Short Nam.', property: 'A1_NREDUZ', width: '150px' },
      { label: 'Tax ID', property: 'A1_CGC', width: '100px' }, //**CAMPO IMPORTANTE, VERIFICAR FUNCIONALIDADE */
      { label: 'Address', property: 'A1_END', width: '150px' },
      { label: 'City', property: 'A1_MUN', width: '100px' },
      { label: 'State', property: 'A1_EST', width: '100px' },
      { label: 'ZIP Code', property: 'A1_CEP', width: '100px' },
      { label: 'Sales Rep Code', property: 'A1_VEND', width: '90px' },
      { label: 'Sales Rep', property: 'A3_NREDUZ', width: '130px' },
      { label: 'Last Purchase', property: 'A1_ULTCOM', width: '150px' },
      { label: 'Email', property: 'A1_EMAIL', width: '150px' },
      { label: 'DDD', property: 'A1_DDD', width: '100px' },
      { label: 'Phone', property: 'A1_TEL', width: '100px' },
      {
        label: 'Resale Certificate Number',
        property: 'A1_INSCR',
        width: '130px',
      },
      { label: 'Expiration Date', property: 'A1_XRNDTEX', width: '130px' },
      { label: 'Country', property: 'A1_PAIS', width: '130px' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.35),
  };

  public pagination = {
    page: 1,
    limit: 50,
    total: 0,
  };

  public select = {
    bloqueado: [
      { label: 'ACTIVE', value: '2' },
      { label: 'BLOCKED', value: '1' },
      { label: 'ALL', value: '3' },
    ] as Array<PoSelectOption>,
    representante: [] as Array<PoSelectOption>,
  };

  ngOnInit(): void {
    this.loadParameters();
    const country = this.activatedRoute.snapshot.data['listCountries'].data;
    this.listaCountries = this.utilService.montarListaComResolver(
      country,
      'YA_PAIS_I',
      'YA_CODGI'
    );
    const representante =
      this.activatedRoute.snapshot.data['listRepresentante'].data.data;
    this.select.representante = this.utilService.montarListaComResolver(
      representante,
      'nomeFantasia',
      'codigo'
    );
  }

  public getCustomers(form: any) {
    this.loading = true;
    const obj = {
      procedure: true,
      metodo: '',
      // homologacao: false,
      data: {
        ...form,
        page: this.pagination.page,
        limit: this.pagination.limit,
      },
    };
    Object.assign(this.form.value, this.pagination);
    this.customersListService.getCustomersList(obj).subscribe({
      next: (element: any) => {
        this.loading = false;
        this.table.items = element.data.data;
        this.pagination.total = element.data.total;
        this.utilService.formatDataFromArray(this.table.items, 'A1_XRNDTEX');
        this.utilService.formatDataFromArray(this.table.items, 'A1_ULTCOM');
        this.utilService.emitTotalItems.emit(element.data.total);
        if (element.data.data.length <= 0) {
          this.notification.information('Not found data');
        }
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
      },
    });
  }

  public pageChange(page: number) {
    this.pagination.page = page;
    this.getCustomers(this.form.value);
  }

  public clearSearch() {
    this.form.controls['nomeFantasia'].setValue('');
    this.form.controls['razaoSocial'].setValue('');
    this.form.controls['codigoCliente'].setValue('');
    this.form.controls['pais'].setValue('');
    this.form.controls['uf'].setValue('');
    this.form.controls['codRepresentante'].setValue('');
    this.form.controls['bloqueado'].setValue('3');
    this.getCustomers(this.form.value);
  }

  private devolveItemSelecionado(): itemTable | null {
    const item = this.table.items.filter((a: any) => a.$selected).pop();
    if (item) {
      return item;
    } else {
      this.notification.warning('Sorry, not found a selected item');
      return null;
    }
  }

  private changeRoute(route: string) {
    try {
      if (route === 'create') {
        this.router.navigate([route], { relativeTo: this.activatedRoute });
      } else {
        const item = this.devolveItemSelecionado();
        this.router.navigate([route, item?.A1_COD, item?.A1_LOJA], {
          relativeTo: this.activatedRoute,
        });
      }
    } catch {
      return;
    }
  }

  private exportExcel() {
    const obj = {
      procedure: true,
      metodo: '',
      // homologacao: false,
      data: {
        ...this.form.value,
        limit: this.pagination.total,
      },
    };
    this.loading = true;
    this.customersListService.getCustomersList(obj).subscribe({
      next: (element: any) => {
        if (element.data.data.length <= 0) {
          this.notification.information('Not found data');
        } else {
          this.utilService.formatDataFromArray(element.data.data, 'A1_XRNDTEX');
          this.utilService.formatDataFromArray(element.data.data, 'A1_ULTCOM');
          this.excelService.organizaArrParaExcel(
            this.table.columns,
            element.data.data,
            this.page.title
          );
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
      },
    });
  }

  public getRepresentanteInterno(brand: any) {
    this.loading = true;
    const obj = {
      brand,
      inactive: true,
    };
    this.representanteService.getRepresentantes(obj).subscribe({
      next: (dados: any) => {
        const retorno = dados.response.data.map((ret: any) => {
          return {
            label: `${ret.codigo} - ${ret.nomeFantasia}`,
            value: ret.codigo,
          } as PoSelectOption;
        });
        this.select.representante = retorno;
        this.loadParameters();
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
      },
    });
  }

  private loadParameters() {
    const value = this.loadedPageService.verifyRoute(this.router.url);
    if (value !== false) {
      this.utilService.currentPage.emit(1);
      this.form.patchValue(value.params);
      this.getCustomers(this.form.value);
    } else {
      this.getCustomers(this.form.value);
      return;
    }
  }

  private mudarRota(rota: string) {
    const params = {
      params: {
        ...this.form.value,
        page: this.pagination,
        limit: this.pagination.limit,
      },
      page: this.pagination.page,
      route: this.router.url,
    };
    this.loadedPageService.changeRoute(params);
    const item = this.table.items.filter((a) => a.$selected === true).pop();
    if (item) {
      this.router.navigate([`${rota}`, item?.A1_COD, item?.A1_LOJA], {
        relativeTo: this.activatedRoute,
      });
    } else {
      return this.notification.warning('Please select a customers');
    }
  }
}
