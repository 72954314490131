import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvioPadraoBackend } from '../envio-padrao-backend';

@Injectable({
  providedIn: 'root'
})
export class ListaFeirasService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'lista-feiras';

  public getListaFeiras() {
    const params = {data: {}, metodo: '', procedure: true, homologacao: !environment.production} as EnvioPadraoBackend
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, params);
  }
}
