import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { PoNotificationService, PoBreadcrumbItem, PoBreadcrumb, PoPageAction, PoTableColumn, PoPageDefaultLiterals } from '@po-ui/ng-components';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { SessionService } from 'src/app/service/session/session.service';
import { UtilService } from 'src/app/service/util/util.service';
import { WireTransferService } from 'src/app/service/wire-transfer/wire-transfer.service';

export enum Operations {
  Pesquisar = "1",
  Visualizar = "2",
  Incluir = "3",
  Alterar = "4",
  Excluir = "5"
}

@Component({
  selector: 'app-wire-transfer',
  templateUrl: './wire-transfer.component.html',
  styleUrls: ['./wire-transfer.component.css']
})
export class WireTransferComponent implements OnInit {

  constructor(
    private wireTransferService: WireTransferService,
    private router: Router,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private notificationService: PoNotificationService,
    private excelService: ExcelService,
    private sessionService: SessionService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef
  ) { }

  public loading = false as boolean;
  public item = {
    $selected: false
  } as any

  page = {
    title: '',
    breadcrumb: {
      items: [
        { label: 'Purchase' },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    action: [
    ] as Array<PoPageAction>,
    literals:  {
      otherActions: 'Other Actions'
    } as PoPageDefaultLiterals
  };

  table = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.32)
  }

  pagination = {
    totalItems: 0,
    itemsPerPage: 50,
    currentPage: 1,
  };

  public isGenerate: boolean = false;

  filtersForm = this.fb.group({
    ZR5_NUM: ['', []],
    ZR5_EMISSA: ['', []],
    ZR5_AMOUNT: [0, []],
    ZR5_OBS: ['', []],
    EMISSAO_ORIGNAL: ['', []],
    REQUEST_DATE_FROM: ['', []],
    REQUEST_DATE_TO: ['', []],
    AMOUNT_FROM: ['', []],
    AMOUNT_TO: ['', []],
    ZR6_NUMPC: ['', []]
  })


  ngOnInit(): void {
    this.getListaBrowser();

    if (this.router.url.indexOf('wire-request') !== -1) {
      this.isGenerate = false;
      this.page.title = 'Wire Request';
      this.page.breadcrumb.items.push({ label: 'Wire Request' });
      this.page.action = [
        {
          label: 'Add',
          action: () => this.addWire(),
          icon: 'po-icon-plus'
        },
        {
          label: 'Edit',
          action: () => this.editWire(),
          icon: 'po-icon-edit'
        },
        {
          label: 'View',
          action: () => this.viewWire(),
          icon: 'po-icon-zoom-in'
        },
        {
          label: 'Delete',
          action: () => this.deleteWire(),
          icon: 'po-icon-delete'
        },
        {
          label: 'Excel',
          action: () => this.exportExcel(),
          icon: 'po-icon-export'
        }
      ]
    } else {
      this.isGenerate = true;
      this.page.title = 'Wire Payment';
      this.page.breadcrumb.items.push({ label: 'Wire Payment' });
      this.page.action = [
        {
          label: 'Edit',
          action: () => this.editWire(),
          icon: 'po-icon po-icon-edit'
        },
        {
          label: 'View',
          action: () => this.viewWire(),
          icon: 'po-icon-zoom-in'
        },
        {
          label: 'Excel',
          action: () => this.exportExcel(),
          icon: 'po-icon-export'
        }
      ]
    }

    // this.controls.EMISSAO_ORIGNAL.valueChanges
    //   .subscribe((data) => {
    //     if (data !== null) {
    //       this.controls.ZR5_EMISSA.setValue([data.start, data.end]);
    //     }
    //   })
  }

  get controls() {
    return this.filtersForm.controls
  }

  ngAfterViewInit(): void {
    this.table.height = 0;
    this.table.height = this.utilService.calcularHeight(innerHeight, 0.32);
    this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.table.height = 0;
    this.table.height = this.utilService.calcularHeight(innerHeight, 0.32);
    this.cdr.detectChanges();
  }

  public getListaBrowser() {
    this.loading = true;
    delete this.filtersForm.value.ZR6_NUMPC
    const obj = {
      procedure: false,
      metodo: 'LISTAR_BROWSE',
      data: {
        limit: this.pagination.itemsPerPage,
        page: this.pagination.currentPage,
        filter: { ...this.filtersForm.value, ZR5_AMOUNT: [this.controls.AMOUNT_FROM.value, this.controls.AMOUNT_TO.value], ZR5_EMISSA: [this.controls.REQUEST_DATE_FROM.value, this.controls.REQUEST_DATE_TO.value] },
        subquery: { ZR6_NUMPC: this.controls.ZR6_NUMPC.value }
      },
    }
    this.wireTransferService.returnWire(obj)
      .subscribe({
        next: (element: any) => {
          if (element !== null) {
            element.data.map((data: any) => {
              if (data.columns) {
                this.table.columns = this.excelService.adjustColumnsAndStatus(data.columns);
              }
              if (data.items) {
                this.table.items = data.items;
              }
              if (data['total']) {
                this.pagination.totalItems = data['total'];
                this.utilService.emitTotalItems.emit(this.pagination.totalItems);
              }
            })
          }
        this.loading = false;
        }, error: (error: any) => {
          this.loading = false
          console.error(error);
          this.notificationService.error('Error while loading data')
        }
      }
    );
  }

  public selectedItem(event: any) {
    this.item = event
  }
  public unselectedItem() {
    this.item = {}

  }

  private addWire() {
    this.router.navigate(['add'], { relativeTo: this.route })
  }

  private editWire() {
    if (this.isGenerate) {
      if (this.item['$selected']) {
        return this.router.navigate(['edit', this.item.RECNO, this.item.ZR5_STATUS], { relativeTo: this.route })
      } else {
        return this.notificationService.warning('Please select an wire transfer!');
      }
    } else {
      if (this.item['$selected'] && this.verifyStatus(this.item)) {
        this.router.navigate(['edit', this.item.RECNO, this.item.ZR5_STATUS], { relativeTo: this.route })
      } else if (this.item['$selected'] && !this.verifyStatus(this.item)) {
        this.notificationService.warning('Wire Closed');
      } else {
        this.notificationService.warning('Please select an wire transfer!');
      }
    }
  }

  private viewWire() {
    if (this.item['$selected']) {
      this.router.navigate(['view', this.item.RECNO], { relativeTo: this.route })
    } else {
      this.notificationService.warning('Please select an wire transfer!');
    }
  }

  private deleteWire() {
    if (this.item['$selected'] && this.verifyStatus(this.item)) {
      this.router.navigate(['delete', this.item.RECNO], { relativeTo: this.route })
    } else if (this.item['$selected'] && !this.verifyStatus(this.item)) {
      this.notificationService.warning('Wire Closed');
    } else {
      this.notificationService.warning('Please select an wire transfer!');
    }
  }

  private verifyStatus(item: any): boolean {
    if (item.ZR5_STATUS === '2') {
      return false
    } else {
      return true
    }
  }

  public onPageChange(event: any) {
    this.pagination.currentPage = event;
    this.getListaBrowser();
  }

  private exportExcel() {
    this.loading = true
    const arr = this.table.items
      .map((element: any) => {
        return {
          "WIRE TRANSF.": element.ZR5_NUM,
          "REQUEST DATE": element.ZR5_EMISSA,
          AMOUNT: element.ZR5_AMOUNT,
          NOTES: element.ZR5_OBS
        }
      })
    this.excelService.exportAsExcelFile(arr, this.page.title);
    setTimeout((a: any) => {
      this.loading = false
    }, 1000)
  }

  limparFiltros() {
    this.filtersForm.reset();
    this.getListaBrowser();
  }
}
