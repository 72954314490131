import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PoNotificationService } from '@po-ui/ng-components';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from 'src/app/service/session/session.service';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private router: Router, private notificationService: PoNotificationService, private sessionService: SessionService) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const checkSessionRegex = /session\/self/gi;
    const re = /session/gi;

    if ((request.url.search(re) === -1 || request.url.search(checkSessionRegex) >= 0) && request.headers.get('Authorization') == null) {

      const jwt = localStorage.getItem('token');

      request = request.clone({
        headers: request.headers.set('Authorization', `${jwt}`)
      });

    }
    // return next.handle(request);
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 500) {
          if (error.error.mensagem) {
            this.sessionService.logout();
            // this.notificationService.error(`${error.error['message']}`);
          }
        } else if (error.status === 401) {
          this.sessionService.logout();
          // this.notificationService.warning(`${error.error['message']}`);
          this.router.navigate(['login']);
        }
        return throwError(error);

      }));
  }
}
