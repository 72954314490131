import { GenericCustomerComponent } from './customers-list/generic-customer/generic-customer.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SalesByProductComponent } from './sales-by-product/sales-by-product.component';
import { SalesOrderEditComponent } from './sales-order/sales-order-edit/sales-order-edit.component';
import { SalesOrderComponent } from './sales-order/sales-order.component';
import { TopSalesComponent } from './top-sales/top-sales.component';
import { TopSoEntrancesComponent } from './top-so-entrances/top-so-entrances.component';
import { CustomersListComponent } from './customers-list/customers-list.component';
import { RepresentantesListResolver } from './customers-list/representantes-list.resolver';
import { CountriesListResolver } from './customers-list/generic-customer/countries-list.resolver';
import { ListaClientesResolver } from './sales-order/lista-clientes.resolver';
import { ListaFeirasResolver } from './sales-order/lista-feiras.resolver';
import { ListaTipoOperacaoResolve } from './sales-order/lista-tipo-operacao.resolver';

const routes: Routes = [
  {
    path: 'sales-order', children: [
      {
        path: '', component: SalesOrderComponent,
        resolve: {
          listaTipoOperacao: ListaTipoOperacaoResolve,
          listaClientes: ListaClientesResolver,
          listaFeiras: ListaFeirasResolver,
        },        
      },
      {
        path: 'edit-sales-order/:RECNO', component: SalesOrderEditComponent,
        resolve: {
          listaTipoOperacao: ListaTipoOperacaoResolve,
          listaClientes: ListaClientesResolver,
          listaFeiras: ListaFeirasResolver,
        },
      }
    ]
  },
  {
    path: 'top-sales', component: TopSalesComponent
  },
  {
    path: 'top-so-entrances', component: TopSoEntrancesComponent
  },
  {
    path: 'sales-by-product', component: SalesByProductComponent
  },
  {
    path: 'customers', children: [
      {
        path: '', component: CustomersListComponent, resolve: {listRepresentante: RepresentantesListResolver, listCountries: CountriesListResolver}
      },
      {
        path: 'create', component: GenericCustomerComponent, resolve: {listCountries: CountriesListResolver}
      },
      //
      {
        path: 'edit/:codigo/:loja', component: GenericCustomerComponent, resolve: {listRepresentante: RepresentantesListResolver, listCountries: CountriesListResolver}
      },
      {
        path: 'delete/:codigo/:loja', component: GenericCustomerComponent, resolve: {listRepresentante: RepresentantesListResolver, listCountries: CountriesListResolver }
      }

    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommercialRoutingModule { }
