
import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { CountryServices, RetornoListaPaises } from 'src/app/service/country/country.service';

@Injectable({
  providedIn: 'root'
})
export class CountriesListResolver implements Resolve<Observable<RetornoListaPaises>>{
  constructor(private countryServices:CountryServices){

  };
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RetornoListaPaises>{
    const obj = {procedure: true, data: {}, metodo: "" }
    return this.countryServices.postCountries(obj);
  }
}

