import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { PoBreadcrumb, PoBreadcrumbItem, PoComboFilterMode, PoDynamicFormField, PoDynamicFormValidation, PoModalAction, PoModalComponent, PoNotificationService, PoPageAction, PoPageDefaultLiterals, PoSelectOption, PoTableAction, PoTableColumn, PoTableComponent, PoTableLiterals } from '@po-ui/ng-components';
import { ContainerService } from 'src/app/service/container/container.service';
import { F3Service } from 'src/app/service/f3/f3.service';
import { ListasService } from 'src/app/service/listas/listas.service';
import { UtilService } from 'src/app/service/util/util.service';

export enum ProductlabelModal {
  Po = 1
}

export enum Operations {
  Pesquisar = "1",
  Visualizar = "2",
  Incluir = "3",
  Alterar = "4",
  Excluir = "5"
}

@Component({
  selector: 'app-container-edit',
  templateUrl: './container-edit.component.html',
  styleUrls: ['./container-edit.component.css']
})
export class ContainerEditComponent implements OnInit {


  constructor(
    private location: Location,
    private containerService: ContainerService,
    private utilService: UtilService,
    private f3Service: F3Service,
    private notificationService: PoNotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private listasService: ListasService,
    private cdr: ChangeDetectorRef
  ) { }

  page = {
    title: 'Add Container',
    breadcrumb: {
      items: [
        { label: 'Stock' },
        { label: 'Container', link: '/stock/container' },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [
      {
        label: 'Save',
        action: () => this.returnSave(this.operation),
        icon: 'po-icon-ok'
      },
      {
        label: 'Back',
        action: () => this.location.back(),
        icon: 'po-icon-edit'
      }
    ] as Array<PoPageAction>,
    literals: {
      otherActions: 'Other Actions'
    } as PoPageDefaultLiterals
  };

  public filterModeCombo = PoComboFilterMode.contains
  public loading = false as boolean;
  public containerForm: any = {};
  public fieldsContainer: Array<PoDynamicFormField> = [];
  public fieldsContainerForm = this.fb.group({
    ZR2_NUM: ['', [Validators.required]],
    ZR2_BOL: ['', []],
    ZR2_ETD: ['', []],
    ZR2_ETA: ['', []],
    ZR2_DWAREH: ['', []],
    ZR2_DSYSTE: ['', []],
    ZR2_AMOUNT: ['', []],
    EXPENSES: ['', []],
  });

  // public expensesForm: any = {};
  public fieldsExpenses: Array<PoDynamicFormField> = [];

  public modalExpensesForm = this.fb.group({
    ZR4_BILL: ['', [Validators.required]],
    ZR4_PARCEL: ['', []],
    ZR4_VALOR: [0, [Validators.required]],
    ZR4_EMISSA: ['', [Validators.required]],
    ZR4_VENCTO: ['', [Validators.required]],
    ZR4_FORNEC_ORIGINAL: ['', []],
    ZR4_FORNEC: ['', [Validators.required]],
    ZR4_LOJA: ['', []],
    ZR4_NATURE: ['', [Validators.required]],
    ZR4_NATURE_ORIGINAL: ['', []],
    ZR4_CCUSTO: ['', []],
    ZR4_CCUSTO_ORIGINAL: ['', []],
    ZR4_HISTOR: ['', []],
    ZR4_HIST2: ['', []],
    actions: ['', []],
    isExpense: ['', []],
    alterar: ['', []],
    sequencia: ['', []],
    recno: ['', []],
    ZR4_PREFIX: ['', []],
    mensagem: [''],
    ZR4_DESCRI: [''],
    ZR4_NOME: [''],
    ZR4_TIPO: [''],
    itemOriginal: ['', []]
  });

  public disabledModalFields: any = false;
  public disabledModalFieldsOptional: any = false;
  public containerAddFormOriginal: any = {};
  public containerAddForm = this.fb.group({
    A2_NOME: ['', []],
    B1_DESC: ['', []],
    B1_XCODFOR: ['', []],
    C6_NUM: ['', []],
    C7_EMISSAO: ['', []],
    C7_FORNECE: ['', []],
    C7_ITEM: ['', []],
    C7_NUM: ['', []],
    C7_PRECO: ['', []],
    C7_PRODUTO: ['', []],
    C7_QUANT: ['', []],
    C7_QUJE: ['', []],
    C7_TOTAL: ['', []],
    ZR3_DOC: ['', []],
    ZR3_OBS: ['', []],
    ZR3_NUMPC: ['', []],
    ZR3_QUANT: ['', []],
    ZR3_ITEMPC: ['', []],
    ZR3_VOL: ['', []],
    actions: ['', []],
    alterar: ['', []],
    isExpense: ['', []],
    itemOriginal: ['', []],
    mensagem: ['', []],
    recno: ['', []],
    sequencia: ['', []],
    SALDO: [, []],
    ZR3_VLRBRL: ['', []],
    recno_sc7: ['', []]
  });
  public fieldsAddContainer: Array<PoDynamicFormField> = [];

  public isModalExpenses = false;

  table = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: 0
  }

  tableExpenses = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: 0
  }

  public tipoTela = 'add';
  public operation = Operations.Incluir;
  // ********************************************************************************************************************
  //                                                 SEARCH MODAL
  // ********************************************************************************************************************
  public titlePopup = '';
  public searchTerm = '';
  public formValue = {};
  public formFields = [] as Array<PoDynamicFormField>;
  public typeListModal: ProductlabelModal = 1;
  public totalAmount = 0;


  @ViewChild('modalSearch', { static: true }) poModalSearch!: PoModalComponent;

  closeModalSearch: PoModalAction = {
    action: () => {
      this.poModalSearch.close();
    },
    label: 'Close',
    danger: true,
  };

  public tableSearchPopup = {
    columns: [] as PoTableColumn[],
    items: [],
    height: 250,
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals
  };

  public tablePo = {
    columns: [] as PoTableColumn[],
    items: [] as any,
    height: 400,
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals,
    selectedAll: false
  };

  public arrSelectedtems: any[] = [];

  @ViewChild('modalExpenses', { static: true }) modalExpenses!: PoModalComponent;

  confirmPoModal: PoModalAction = {
    action: () => {
      this.preparaSalvar(this.modalExpensesForm.value)
    },
    label: 'Confirm'
  };

  closePoModal: PoModalAction = {
    action: () => {
      this.utilService.resetForm(this.modalExpensesForm);
      this.modalExpenses.close();
    },
    label: 'Close',
    danger: true,
  };

  @ViewChild('poTableExpenses', { static: true }) poTableExpenses!: PoTableComponent;


  @ViewChild('poTableContainer', { static: true }) poTableContainer!: PoTableComponent;

  @ViewChild('modalItensPo', { static: true }) poModalItensPo!: PoModalComponent;

  confirmModalItensPo: PoModalAction = {
    action: () => {
      const envio = this.tablePo.items.filter((item: any) => item.$selected === true);
      this.sendValidar(envio);
    },
    label: 'Confirm'
  };

  closeModalItensPo: PoModalAction = {
    action: () => {
      this.tablePo.items = [];
      this.poModalItensPo.close();
      this.openModalList('PO_CONTAINER', 'PO_CONTAINER');
    },
    label: 'Close',
    danger: true,
  };


  @ViewChild('modalEditItemContainer', { static: true }) modalEditItemContainer!: PoModalComponent;

  confirmEditItem: PoModalAction = {
    action: () => {
      const filtrado = this.table.items.filter((item: any) => `${item.C7_ITEM}${item.C7_NUM}` === `${this.itemSelecionado.C7_ITEM}${this.itemSelecionado.C7_NUM}`);
      filtrado.map((ret) => {
        Object.entries(ret)
          .forEach(([chave, valor]) => {
            ret[chave] = this.containerAddForm.controls[chave].value
          })
      });
      this.containerForm.ZR5_AMOUNT = this.reduceArrayByProperty(this.table.items, 'C7_TOTAL');
      this.modalEditItemContainer.close();
    },
    label: 'Confirm'
  };

  closeEditItem: PoModalAction = {
    action: () => {
      this.modalEditItemContainer.close();
    },
    label: 'Close',
    danger: true,
  };

  recno: any;
  selects = {
    ctt: <Array<PoSelectOption>>[],
    sed: <Array<PoSelectOption>>[],
    sa2: <Array<any>>[],
  }

  tipoModal = 1;
  itemSelecionado: any = new Object;
  disabledZR2FieldsOthers = "false";
  disabledZR2FieldsEdit = "false";

  reduced: any

  ngOnInit(): void {
    let obj: any = { recno: null };
    if (this.router.url.indexOf('add') !== -1) {
      this.getById();
      this.tipoTela = 'add';
      this.page.title = 'Add Container ';
      this.page.breadcrumb.items.push({ label: 'Add Container' });
      this.operation = Operations.Incluir;
    } else {
      this.route.paramMap
        .subscribe((paramMap: ParamMap) => {
          obj.recno = paramMap.get('RECNO');
          obj.recno = parseInt(obj.recno, 10);
          this.recno = obj.recno
          this.getById(obj);
        });
      if (this.router.url.indexOf('edit') !== -1) {
        this.tipoTela = 'edit';
        this.page.title = 'Edit Container ';
        this.page.breadcrumb.items.push({ label: 'Edit Container' });
        this.operation = Operations.Alterar;
        this.disabledZR2FieldsOthers = "false";
      } else if (this.router.url.indexOf('view') !== -1) {
        this.tipoTela = 'view';
        this.page.title = 'View Container ';
        this.page.breadcrumb.items.push({ label: 'View Container' });
        this.page.actions[0].disabled = true;
        this.operation = Operations.Visualizar;
        this.disabledZR2FieldsOthers = "true";
      } else {
        this.tipoTela = 'delete';
        this.page.title = 'Delete Container ';
        this.page.breadcrumb.items.push({ label: 'Delete Container' });
        this.page.actions[0].label = 'Ok, Delete';
        this.page.actions[0].disabled = false;
        this.operation = Operations.Excluir;
        this.disabledZR2FieldsOthers = "true";
      }
      this.disabledZR2FieldsEdit = "true";
    }

    this.selects.ctt = this.returnListas('CTT');
    this.selects.sa2 = this.returnListas('SA2');
    this.selects.sed = this.returnListas('SED');
    this.table.height = this.utilService.calcularHeight(innerHeight, 0.30);
    this.tableExpenses.height = this.utilService.calcularHeight(innerHeight, 0.30);
  }

  ngAfterViewInit(): void {
    // this.table.height = 0;
    // this.table.height = this.utilService.calcularHeight(innerHeight, 0.30);
    // this.tableExpenses.height = 0;
    // this.tableExpenses.height = this.utilService.calcularHeight(innerHeight, 0.30);
    // this.cdr.detectChanges();
  }

  ngAfterViewChecked(): void {
    // this.table.height = 0;
    // this.table.height = this.utilService.calcularHeight(innerHeight, 0.30);
    // this.tableExpenses.height = 0;
    // this.tableExpenses.height = this.utilService.calcularHeight(innerHeight, 0.30);
    // this.cdr.detectChanges();
  }

  get controlsExpensesForm() {
    return this.modalExpensesForm.controls;
  }

  get controls() {
    return this.fieldsContainerForm.controls;
  }

  returnListas(table: any, callback?: any): any {
    const params =
    {
      procedure: false,
      data: { table }
    };
    this.listasService
      .returnListas(params)
      .subscribe({
        next: (data) => {
        if (data.status) {
          const retorno = data.data[0].items;
          if (table === 'SA2') {
            this.selects.sa2 = retorno.map((item: any) => {
              return {
                label: item.concatenado,
                value: item.valor
              } as any
            })
          } else if (table === 'SED') {
            this.selects.sed = retorno.map((item: any) => {
              return { label: item.concatenado, value: item.valor[0] } as PoSelectOption
            })
          } else if (table === 'CTT') {
            this.selects.ctt = retorno.map((item: any) => {
              return { label: item.concatenado, value: item.valor[0] } as PoSelectOption
            })
          }
        }

        if (typeof callback === 'function') {
          callback(true, false, 'succefuly');
        }
      }, error: (err) => {
        console.error(err, 'Err -->');
      }})
  }

  getById(param?: object) {
    this.loading = true;
    const obj = {
      procedure: false,
      metodo: 'LISTAR_CONTAINER',
      data: param ?? {},
    }
    this.containerService.returnContainer(obj)
      .subscribe({
        next: (element) => {
        if (element !== null) {
          element.data
            .map((val) => {
              if (val.ZR2) {
                val.ZR2
                  .forEach((tab: any) => {
                    if (tab.items) {
                      this.containerForm = tab.items[0];
                      if (this.tipoTela !== 'add') {
                        this.containerForm.ZR2_ETD = this.containerForm.ZR2_ETD !== null ? this.containerForm.ZR2_ETD.split('/').join('-') : null
                        this.containerForm.ZR2_ETA = this.containerForm.ZR2_ETA !== null ? this.containerForm.ZR2_ETA.split('/').join('-') : null
                        this.containerForm.ZR2_DWAREH = this.containerForm.ZR2_DWAREH !== null ? this.containerForm.ZR2_DWAREH.split('/').join('-') : null
                        this.containerForm.ZR2_DSYSTE = this.containerForm.ZR2_DSYSTE !== null ? this.containerForm.ZR2_DSYSTE.split('/').join('-') : null
                      }
                      this.fieldsContainerForm.patchValue(this.containerForm);
                    }
                  });
              } else if (val.ZR3) {
                val.ZR3
                  .forEach((ret: any) => {
                    if (ret.columns) {
                      this.table.columns = ret.columns;
                      this.table.columns
                        .map((item: any) => {
                          Object.keys(item)
                            .map((data: any) => {
                              if (item.property === 'ZR3_QUANT' || item.property === 'C7_PRECO' || item.property === 'C7_TOTAL' || item.property === 'ZR3_VOL' || item.property === 'ZR3_DOC' || item.property === 'C6_NUM') {
                                item.width = '100px'
                              } else {
                                item.width = "200px"
                              }
                            });
                          return item;
                        });
                      if (this.tipoTela !== 'view' && this.tipoTela !== 'delete') {
                        this.table.columns.unshift({
                          label: 'Actions', property: 'actions', width: '130px', type: 'icon', icons: [
                            { action: this.details.bind(this), tooltip: 'Edit', icon: 'po-icon-edit', value: 'edit' },
                            { action: this.removeTableContainer.bind(this), tooltip: 'Remove', icon: 'po-icon-delete', value: 'delete' },
                          ]
                        });
                      }
                    } else if (ret.items) {
                      if (this.operation === '3') {
                        this.table.items = [];
                      } else {
                        this.table.items = ret.items;
                        this.table.items.map((item) => { item.actions = ['edit', 'delete'], item.isExpense = false, item.itemOriginal = true, item.sequencia = item.recno + 1 });
                        this.table.items.forEach((val) => {
                          if (val.C7_EMISSAO) {
                            val.C7_EMISSAO = val.C7_EMISSAO.split('/').join('-');
                          }
                        })
                        this.controls.ZR2_AMOUNT.setValue(this.reduceArrayByProperty(this.table.items, 'C7_TOTAL'));


                      }
                    }
                  });
              }
              else if (val.ZR4) {
                val.ZR4
                  .forEach((tab: any) => {
                    if (tab.columns) {
                      this.tableExpenses.columns = tab.columns;
                      this.tableExpenses.columns.forEach((item) => item.property === "ZR4_EMISSA" || item.property === 'ZR4_VENCTO' ? item.type = "date" : item.type = '');
                      this.tableExpenses.columns.forEach((item) => item.width = "200px");
                      if (this.tipoTela !== 'view' && this.tipoTela !== 'delete') {
                        this.tableExpenses.columns.unshift({
                          label: 'Actions', property: 'actions', width: '130px', type: 'icon', icons: [
                            { action: this.viewExpenses.bind(this), tooltip: 'View', icon: 'po-icon po-icon-zoom-in', value: 'view' },
                            { action: this.detailsExpenses.bind(this), tooltip: 'Edit', icon: 'po-icon-edit', value: 'edit' },
                            { action: this.removeTableExpenses.bind(this), tooltip: 'Remove', icon: 'po-icon-delete', value: 'delete' },
                          ]
                        });
                      }
                    } else if (tab.items) {
                      if (this.operation === '3') {
                        this.tableExpenses.items = [];
                      } else {
                        this.tableExpenses.items = tab.items;
                        this.tableExpenses.items.map((item) => { item.actions = ['edit', 'delete'], item.itemOriginal = true, item.isExpense = true, item.sequencia = item.recno + 1 });

                        this.tableExpenses.items
                          .map((item) => {
                            Object.keys(item).map((data) => {
                              if ((data === 'ZR4_EMISSA' || data === 'ZR4_VENCTO') && item.itemOriginal) {
                                item[data] = item[data].split('/').join('-');
                              } else if (item.valorpago > 0) {
                                item.actions = ['edit', 'delete', 'view']
                              }
                            });
                            return item;
                          });
                        this.controls.EXPENSES.setValue(this.reduceArrayByProperty(this.tableExpenses.items, 'ZR4_VALOR'))
                      }
                    }
                  });
              }
            })
          this.loading = false;
        } else {
          this.notificationService.error('Error please try again later');
          this.location.back();
        }
      }, error: (err) => {
        console.error(err, 'ERROR --->');
        this.loading = false;
      }})
  }

  reduceArrayByProperty(arr: Array<any>, property: string) {
    return arr.reduce((total, valor) => parseFloat(total) + (parseFloat(valor[property])), 0);
  }

  // ********************************************************************************************************************
  //                                                METODOS MODAL  LISTA
  // ********************************************************************************************************************

  public searchList() {
    let termo;
    this.searchListPromise(
      termo,
      (status: any, error: any, mensagem: any) => {
        if (error === true) {
          this.notificationService.error(mensagem);
        } else if (status === false) {
          this.notificationService.warning(mensagem);
        }
      }
    );
  }

  public searchListPromise(table: any, callback?: any) {
    table = table.toUpperCase();
    this.tableSearchPopup.loading = true;
    const params = {
      metodo: 'listar',
      procedure: false,
      data: {
        ZR2_NUM: this.containerForm.ZR2_NUM ?? '',
        table,
        filters: JSON.stringify(this.formValue),
      },
    };
    switch (table) {
      case 'PO':
        this.titlePopup = 'Purchase Order';
        break;
      case 'PRODUCT':
        this.titlePopup = 'Product';
        break;
      case 'CONTAINER':
        this.titlePopup = 'Container';
        break;
    }
    this.f3Service.getf3data(params).subscribe({
      next: (data: any) => {
        if (data !== null) {

          data.data
            .map((element: any) => {
              if (element.columns) {
                this.tableSearchPopup.columns = element.columns;
              }
              if (element.items) {
                this.tableSearchPopup.items = element.items;
              }
              if (element.filters) {
                this.formFields = element.filters;
                this.formFields.forEach((item) => { item.gridSmColumns = 3, item.gridMdColumns = 3, item.gridLgColumns = 3 });
              }
            });
        }
        this.tableSearchPopup.loading = false;
        if (typeof callback === 'function') {
          callback(true, false, 'succefuly');
          // callback(false, true, error);
        }
      },
      error: (error: HttpErrorResponse) => {
        this.tableSearchPopup.loading = false;
        if (typeof callback === 'function') {
          callback(false, true, error);
        }
      }
    });
  }

  public openModalList(list: any, field: any) {
    this.loading = true;
    this.formValue = {};
    this.tableSearchPopup.field = field;
    this.tableSearchPopup.items = [];
    this.searchTerm = '';
    this.typeListModal = list;
    this.searchListPromise(list, (status: any, error: any, mensagem: any) => {
      if (status) {
        this.loading = false;
        this.poModalSearch.open();
      } else {
        if (error === true) {
          this.notificationService.error(mensagem);
        } else if (status === false) {
          this.notificationService.warning(mensagem);
        }
      }
    });
  }

  public selectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
    this.setListItem();
    this.poModalSearch.close();
    this.tablePo.selectedAll = false;
    this.poModalItensPo.open();
  }

  public unselectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
  }

  public setListItem() {
    this.tablePo.columns = [];
    this.tablePo.items = [];
    if (this.tableSearchPopup.fieldValue.$selected) {
      this.loading = true;
      const obj = {
        procedure: false,
        metodo: 'BUSCAR_PO',
        data: {
          C7_NUM: this.tableSearchPopup.fieldValue.C7_NUM,
          ZR2_NUM: this.containerForm.ZR2_NUM ?? ''
        },
      }
      this.containerService.returnContainer(obj)
        .subscribe({
          next: (ret: any) => {
          if (ret !== null) {

            ret.data
              .map((element: any) => {
                if (element.columns) {
                  this.tablePo.columns = element.columns;


                }
                if (element.items) {
                  const retorno = element.items;
                  retorno.forEach((el: any) => {
                    this.table.items
                      .map((item) => {
                        if (el.recno === item.recno_sc7) {
                          el.C7_QUANT = item.ZR3_QUANT;
                          el.ZR6_OBS = item.ZR3_OBS;
                          el.ZR3_DOC = item.ZR3_DOC;
                          el.ZR3_VOL = item.ZR3_VOL;
                          el.ZR3_OBS = item.ZR3_OBS
                          el.$selected = true;
                        } else {
                          return item
                        }
                      })

                  });
                  this.tablePo.items = retorno;
                }
              });
          }
          this.loading = false;
        }, error: (err: any) => {
          this.loading = false;
        }})
    } else {
      this.notificationService.warning('Please select a option');
    }
  }

  public limparField(controls: any, field: any) {
    controls[field].setValue('');
  }

  dynamicForm() {
    this.searchListPromise(this.typeListModal)
  }

  validateQuantity(e: any, isNgModel: boolean) {
    if (isNgModel) {
      if (e.C7_QUANT > e.SALDO) {
        e.C7_QUANT = this.itemSelecionado.ZR3_QUANT === undefined ? e.SALDO : this.itemSelecionado.ZR3_QUANT;
        return this.notificationService.warning(`The maximum quantity allowed is : ${e.SALDO} `);
      } else if (e.C7_QUANT <= 0) {
        e.C7_QUANT = e.SALDO > 0 ? 1 : 0;
        return this.notificationService.warning(`The minimum quantity allowed is : 1 `)
      } else {
        e.C7_TOTAL = e.C7_QUANT * e.C7_PRECO;
      }
    } else {
      if (e.ZR3_QUANT.value >= e.SALDO.value) {
        e.ZR3_QUANT.setValue(this.itemSelecionado.ZR3_QUANT);
        return this.notificationService.warning(`The maximum quantity allowed is: : ${e.SALDO.value} `);
      } else if (e.ZR3_QUANT.value <= 0) {
        const total = e.SALDO.value > 0 ? 1 : e.SALDO.value;
        e.C7_TOTAL.setValue(total);
        return this.notificationService.warning(`The minimum quantity allowed is : 1 `)
      } else {
        const total = e.ZR3_QUANT.value * e.C7_PRECO.value;
        e.C7_TOTAL.setValue(total);
      }
    }
    this.calculaTotalAmount();
  }

  validateUnitPrice(e: any) {
    if (e.ZR3_VLRBRL < 0) {
      e.ZR3_VLRBRL = 0;
      return this.notificationService.warning(`The minimum quantity allowed is : 0 `)
    }
  }

  sendValidar(array: Array<any>) {
    if (array.length > 0) {
      const obj = {
        procedure: false,
        metodo: 'VALIDAR_PO',
        data: array,
      }
      this.containerService.returnContainer(obj)
        .subscribe({
          next: (ret: any) => {
          if (ret !== null) {
            this.notificationService.success(ret.mensagem);
            const returnItems = ret.data[0].items;
            returnItems
              .forEach((element: any) => {
                if (element.C7_EMISSAO) {
                  element.C7_EMISSAO = element.C7_EMISSAO.split('/').join('-');
                  element.itemOriginal = false;
                }
                element.actions = ['edit', 'delete'];
                this.table.items = this.utilService.spreadArrayToProperty(this.table.items,element);
              });
            this.controls.ZR2_AMOUNT.setValue(this.reduceArrayByProperty(this.table.items, 'C7_TOTAL'));
            const filtrado = this.removeDuplicates(this.table.items);
            this.table.items = filtrado;
            this.poModalSearch.close();
            this.poModalItensPo.close();
          }
          this.loading = false;
        },
        error: (err: any) => {
          this.loading = false;
        }
      })
    } else {
      return this.notificationService.warning('Please select an item from the list');
    }
  }

  removeDuplicates(original: Array<any>) {
    let reduced: Array<any> = [];
    original.forEach((item) => {
      const duplicated = reduced
        .findIndex(redItem => {
          return item.recno_sc7 == redItem.recno_sc7;
        }) > -1;

      if (!duplicated) {
        reduced = this.utilService.spreadArrayToProperty(reduced,item);
      } else {
        reduced.forEach((ret) => {
          if (ret.recno_sc7 === item.recno_sc7) {
            ret.A2_NOME = item.A2_NOME;
            ret.B1_DESC = item.B1_DESC;
            ret.B1_XCODFOR = item.B1_XCODFOR;
            ret.C6_NUM = item.C6_NUM;
            ret.C7_EMISSAO = item.C7_EMISSAO;
            ret.C7_FORNECE = item.C7_FORNECE;
            ret.C7_ITEM = item.C7_ITEM;
            ret.C7_NUM = item.C7_NUM;
            ret.C7_PRECO = item.C7_PRECO;
            ret.C7_PRODUTO = item.C7_PRODUTO;
            ret.C7_QUANT = item.C7_QUANT;
            ret.C7_QUJE = item.C7_QUJE;
            ret.C7_TOTAL = item.C7_TOTAL;
            ret.SALDO = item.SALDO;
            ret.ZR3_DOC = item.ZR3_DOC;
            ret.ZR3_ITEMPC = item.ZR3_ITEMPC;
            ret.ZR3_NUMPC = item.ZR3_NUMPC;
            ret.ZR3_OBS = item.ZR3_OBS;
            ret.ZR3_QUANT = item.ZR3_QUANT;
            ret.ZR3_VOL = item.ZR3_VOL;
            ret.actions = item.actions;
            ret.alterar = item.alterar;
            ret.itemOriginal = item.itemOriginal;
            ret.mensagem = item.mensagem;
            ret.recno = item.recno;
            ret.recno_sc7 = item.recno_sc7;
          }
        })
      }
    });
    return reduced
  }

  viewExpenses(item: any) {
    this.utilService.resetForm(this.modalExpensesForm);
    if (item.itemOriginal) {
      item.ZR4_FORNEC_ORIGINAL = [item.ZR4_FORNEC, item.ZR4_LOJA];
      item.ZR4_CCUSTO_ORIGINAL = [item.ZR4_CCUSTO];
      item.ZR4_NATURE_ORIGINAL = [item.ZR4_NATURE];
      this.disabledModalFields = true;
      this.disabledModalFieldsOptional = true;
    }
    this.itemSelecionado = item;
    this.modalExpensesForm.patchValue(item);
    this.tipoModal = 2;
    this.gerarSequencia(this.tableExpenses.items, this.controlsExpensesForm);
    this.modalExpenses.title = 'View Item';
    this.confirmPoModal.disabled = true;
    this.modalExpenses.open();
  }


  detailsExpenses(item: any) {
    if (item.valorpago > 0) {
      return this.notificationService.warning(item.mensagem);
    } else {
      this.utilService.resetForm(this.modalExpensesForm);
      this.disabledModalFieldsOptional = false;
      if (item.itemOriginal) {
        item.ZR4_FORNEC_ORIGINAL = [item.ZR4_FORNEC, item.ZR4_LOJA];
        item.ZR4_CCUSTO_ORIGINAL = [item.ZR4_CCUSTO];
        item.ZR4_NATURE_ORIGINAL = [item.ZR4_NATURE];
        this.disabledModalFields = true;
      } else {
        this.disabledModalFields = false;
      }
      this.itemSelecionado = item;
      this.modalExpensesForm.patchValue(item);
      this.tipoModal = 2;
      this.gerarSequencia(this.tableExpenses.items, this.controlsExpensesForm);
      this.modalExpenses.title = 'Edit Item';
      this.modalExpenses.open();
    }
  }


  details(item: any) {
    if (item.alterar) {
      this.disabledModalFields = false;
    } else {
      this.disabledModalFields = true;
    }

    this.isModalExpenses = false;
    this.modalEditItemContainer.title = 'Edit container';
    this.itemSelecionado = item;
    this.containerAddForm.patchValue(item);
    this.gerarSequencia(this.table.items, this.containerAddForm.controls);
    this.modalEditItemContainer.open();
  }

  removeTableExpenses(item: { [key: string]: any }) {
    if (item.valorpago > 0) {
      return this.notificationService.warning(item.mensagem);
    } else {
      this.poTableExpenses.removeItem(item);
      this.tableExpenses.items = this.poTableExpenses.items;
      this.controls.EXPENSES.setValue(this.reduceArrayByProperty(this.tableExpenses.items, 'ZR4_VALOR'))
    }
  }

  removeTableContainer(item: { [key: string]: any }) {
    if (item.alterar) {
      this.poTableContainer.removeItem(item);
      this.table.items = this.poTableContainer.items;
      this.controls.ZR2_AMOUNT.setValue(this.reduceArrayByProperty(this.table.items, 'C7_TOTAL'))
    } else {
      return this.notificationService.warning(item.mensagem);
    }
  }

  addExpensesItem() {
    this.modalExpenses.title = 'Add Item';
    this.tipoModal = 1;
    this.gerarSequencia(this.tableExpenses.items, this.controlsExpensesForm);
    this.controlsExpensesForm.ZR4_VALOR.setValue(0);
    this.disabledModalFields = false;
    this.disabledModalFieldsOptional = false;
    this.modalExpenses.open();
  }

  public supllierSelec(item: any) {
    this.controlsExpensesForm.ZR4_FORNEC.setValue(item[0])
    this.controlsExpensesForm.ZR4_LOJA.setValue(item[1])
  }

  public ccenterSelec(item: any) {
    this.controlsExpensesForm.ZR4_CCUSTO.setValue(item)
  }

  public classSelec(item: any) {
    this.controlsExpensesForm.ZR4_NATURE.setValue(item)
  }


  gerarSequencia(array: Array<any>, form: any) {
    if (array.length <= 0) {
      const sequencia = 1;
      form.sequencia.setValue(sequencia);
    } else {
      const table = array;
      table.sort((a, b) => { return a.sequencia - b.sequencia });
      const ultimoItem = table[table.length - 1].sequencia;
      const sequencia = parseInt(ultimoItem, 10) + 1;
      form.sequencia.setValue(sequencia);
    }
  }


  confirmAddItemExpense() {
    if (this.tipoModal === 1) {
      this.controlsExpensesForm['alterar'].setValue(true);
      this.controlsExpensesForm.actions.setValue(['edit', 'delete']);
      this.controlsExpensesForm.itemOriginal.setValue(false);
      this.utilService.multiFormataData(this.modalExpensesForm.controls['ZR4_EMISSA'].value, 'yyyy-mm-dd');
      this.utilService.multiFormataData(this.modalExpensesForm.controls['ZR4_VENCTO'].value, 'yyyy-mm-dd');
      this.tableExpenses.items = this.utilService.spreadArrayToProperty(this.tableExpenses.items,this.modalExpensesForm.value);
      this.controls.EXPENSES.setValue(this.reduceArrayByProperty(this.tableExpenses.items, 'ZR4_VALOR'))
      this.utilService.resetForm(this.modalExpensesForm)
      this.modalExpenses.close();
    } else {
      this.controlsExpensesForm.itemOriginal.setValue(false);
      const expenseSelecionado = this.tableExpenses.items.filter((item: any) => item.sequencia === this.itemSelecionado['sequencia']);
      expenseSelecionado.map((ret) => {
        Object.entries(ret)
          .forEach(([chave, valor]) => {
            if (chave === 'ZR4_CCUSTO' || chave === 'ZR4_NATURE') {
              ret[chave] = this.modalExpensesForm.controls[chave].value[0]
            } else {
              ret[chave] = this.modalExpensesForm.controls[chave].value
            }
          })
      })
      this.controls.EXPENSES.setValue(this.reduceArrayByProperty(this.tableExpenses.items, 'ZR4_VALOR'))
      this.modalExpenses.close();
    }
  }

  private preparaSalvar(param: any) {
    const invalidForm = this.utilService.findInvalidControls(this.modalExpensesForm.controls);
    if (invalidForm.length > 0) {
      invalidForm.map((data: any) => {
        this.tableExpenses.columns
          .map((valor: any) => {
            if (valor.property === data) {
              data = valor.label;
            }
          })
        return this.notificationService.warning(`Please fill in a valid value for the field: ${data} `);
      })
    } else {
      this.confirmAddItemExpense();
    }
  }

  returnSave(operation: Operations) {
    if (this.fieldsContainerForm.invalid) {
      return this.notificationService.warning('Please insert a container number');
    }
    if (this.tipoTela !== 'delete' && this.table.items.length <= 0) {
      return this.notificationService.warning('Please select at least one po item');
    } else {
      this.loading = true;
      let ZR2: any;
      if (operation !== '3') {
        ZR2 = { ...this.fieldsContainerForm.value, recno: this.recno };
      } else {
        ZR2 = { ...this.fieldsContainerForm.value, recno: 0 };
      }
      const envio = {
        "ZR2": [{ "items": ZR2 }],
        "ZR3": [{ "items": this.table.items }],
        "ZR4": [{ "items": this.tableExpenses.items }],
        operation
      }

      const obj = {
        procedure: false,
        metodo: 'SALVAR_CONTAINER',
        data: envio
      }


      this.containerService.returnContainer(obj)
        .subscribe({
          next: (ret: any) => {

          if (ret !== null) {
            if (ret.status === true) {
              this.notificationService.success(ret.mensagem);
              this.location.back();
              this.loading = false;
            } else {
              this.notificationService.warning(ret.mensagem);
              this.loading = false;
            }
          } else {
            this.notificationService.error('Error, please try again!')
            this.loading = false;
          }
        }, error: (err) => {
          console.error(err, '--> error');
          this.notificationService.error(err.mensagem);
          this.loading = false;
        }})
    }
  }

  onChangeCheckBox(event: any, all: boolean) {
    if (!event && !all) {
      this.tablePo.selectedAll = event;
    }
    if (all) {
      this.tablePo.items.forEach((el: any) => el.$selected = event);
    }
    this.calculaTotalAmount();
  }

  public calculaTotalAmount() {
    const itensSelecionados = this.tablePo.items.filter((item: any) => item.$selected);
    this.totalAmount = itensSelecionados.reduce((total: number, item: any) => total + item.C7_TOTAL, 0)
  }

}
