<po-page-default
  p-title="Purchase Order"
  [p-breadcrumb]="page.breadcrum"
  [p-actions]="page.action"
>
  <form
    class="po-row"
    [formGroup]="filtersForm"
    (keyup.enter)="getListaBrowser()"
  >
    <po-input
      class="po-md-2"
      p-label="P.O. No."
      p-clean="true"
      formControlName="C7_NUM"
    ></po-input>
    <po-input
      class="po-md-2"
      p-label="Product Description"
      p-clean="true"
      formControlName="C7_DESCRI"
    ></po-input>
    <po-combo
      class="po-md-2"
      p-label="Supplier"
      formControlName="FORNEC_ORIGINAL"
      [p-filter-mode]="filterModeCombo"
      [p-options]="selectsSA2"
      (p-change)="supllierSelec(controls.FORNEC_ORIGINAL.value)"
    ></po-combo>
    <po-datepicker-range
      class="po-md-3"
      name="datepickerRange"
      formControlName="EMISSAO_ORIGINAL"
      p-label="Issue Date"
    ></po-datepicker-range>
    <po-button
      class="po-md-1 po-mt-4"
      p-icon="po-icon-close"
      p-label=""
      [p-small]="false"
      p-type="danger"
      (p-click)="limparFiltros()"
    ></po-button>
    <po-button
      class="po-md-2 po-mt-4"
      p-label="Search"
      p-icon="po-icon-search"
      p-type="primary"
      (click)="getListaBrowser()"
    ></po-button>
  </form>
  <po-table
    [p-sort]="true"
    class="po-md-12"
    [p-selectable]="true"
    [p-single-select]="true"
    [p-columns]="table.columns"
    [p-striped]="true"
    [p-items]="table.items"
    [p-height]="table.height"
    (p-selected)="selectedItem($event)"
    (p-unselected)="unselectedItem()"
  ></po-table>

  <app-pagination
    class="po-md-12"
    (totalItems)="(pagination.totalItems)"
    [itemsPerPage]="pagination.itemsPerPage"
    (currentPage)="(pagination.currentPage)"
    (pageChanged)="onPageChange($event)"
  ></app-pagination>
</po-page-default>

<po-loading-overlay
  *ngIf="loading"
  [p-screen-lock]="true"
  p-text="Loading, please wait..."
>
</po-loading-overlay>
