import { environment } from './../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface retornoCustomersListV2 {
  response: {
    data: [
      {
        A1_CGC: string;
        A1_COD: string;
        A1_DDD: string;
        A1_EMAIL: string;
        A1_EST: string;
        A1_LOJA: string;
        A1_MUN: string;
        A1_NOME: string;
        A1_NREDUZ: string;
        A1_PAIS: string;
        A1_TEL: string;
        A1_END: string;
        A1_CEP: string;
        A1_VEND: string;
        A3_NREDUZ: string;
        A1_ULTCOM: string;
      }
    ];
    total: number;
    limit: number;
    offset: number;
  };
}

@Injectable({
  providedIn: 'root',
})


export class CustomersListService {
  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'clientes-list';

  public getCustomersList(body: any) {
    body.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.ip}`,  body ) as Observable<retornoCustomersListV2>;
  }
}
