import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PoService {
  constructor(private httpClient: HttpClient) {}

  relativeLink = 'po';

  getPo(param: any): Observable<any> {
    param.homologacao = !environment.production;
    return this.httpClient.post(
      `${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`,
      param
    ) as Observable<any>;
  }
}
