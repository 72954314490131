import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PoBreadcrumbItem, PoBreadcrumb, PoPageAction, PoPageDefaultLiterals, PoNotificationService, PoDialogService } from '@po-ui/ng-components';

@Component({
  selector: 'app-create-route',
  templateUrl: './create-route.component.html',
  styleUrls: ['./create-route.component.css']
})
export class CreateRouteComponent implements OnInit {

  // ********************************************************************************************************************
  //                                                  CONSTRUTOR AND FORM
  // ********************************************************************************************************************
  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: PoNotificationService,
    private dialog: PoDialogService
  ) { }

  mainForm: UntypedFormGroup = this.fb.group({
    // id: [0, []],
    // titulo: ['', [Validators.required]],
    // resumo: ['', []],
    // resumoHtml: ['', [Validators.required]],
    // url: ['', []],
    // urlDirecionamento: ['', []],
    // ativo: [true, []],
    // ordem: [0, [Validators.required]],
    // hasFile: [false, []],
    // urlImagem: ['', []],
    // posicao:  ['R', [Validators.required]]
  });

   // ********************************************************************************************************************
  //                                                  ATRIBUTOS DA CLASSE
  // ********************************************************************************************************************



  public page = {
    title: 'Create Route',
    breadcrumb: {
      items: [
        { label: 'Settings' },
        { label: 'Create Route' },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [
      {
        label: 'Save',
        icon: 'po-icon-ok',
        action: () => ('')
      },
      {
        label: 'Clear',
        icon: 'po-icon-close',
        action: () => ('')
      }
    ] as Array<PoPageAction>,
    literals: {
      otherActions: 'Other Actions'
    } as PoPageDefaultLiterals
  };

  ngOnInit(): void {
  }

}
