import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseJavaApi, SendJavaApi } from '../util/util.service';

export enum Methods {
  LISTAR_CONTAINER = 'LISTAR_CONTAINER',
  LISTAR_BROWSE = 'LISTAR_BROWSE'
}


@Injectable({
  providedIn: 'root'
})
export class ContainerService {

  constructor(
    private http: HttpClient,
  ) { }

  private endpoint = 'container';
  container: any;

  returnContainer(param: SendJavaApi): Observable<ResponseJavaApi<any>> {
    param.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.endpoint}`, param) as Observable<ResponseJavaApi<any>>;
  }

  setAddItemContainer(container: any) {
    this.container = container;
  }

  getAddItemContainer() {
    return this.container;
  }
}
