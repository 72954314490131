<po-page-default
  [p-title]="page.title"
  [p-breadcrumb]="page.breadcrumb"
  [p-actions]="page.actions"
>
  <po-table
    [p-hide-columns-manager]="true"
    [p-sort]="true"
    [p-striped]="true"
    [p-columns]="table.columns"
    [p-items]="table.items"
    [p-height]="table.height"
    [p-actions]="table.actions"
  >
  </po-table>
</po-page-default>

<po-loading-overlay
  p-text="Loading"
  [p-screen-lock]="true"
  *ngIf="isLoading"
></po-loading-overlay>
