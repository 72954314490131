import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginationComponent } from './pagination/pagination.component';
import {
  PoButtonGroupModule,
  PoButtonModule,
  PoDialogModule,
  PoDynamicModule,
  PoFieldModule,
  PoGridModule,
  PoMenuModule,
  PoModalModule,
  PoTableModule,
  PoToolbarModule,
} from '@po-ui/ng-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [PaginationComponent],
  imports: [
    CommonModule,
    PoToolbarModule,
    PoDialogModule,
    PoModalModule,
    FormsModule,
    ReactiveFormsModule,
    PoFieldModule,
    PoMenuModule,
    PoButtonModule,
    PoButtonGroupModule,
    ReactiveFormsModule,
    PoDynamicModule,
    PoTableModule,
    PoGridModule,
  ],
  exports: [PaginationComponent],
})
export class GlobalComponentsModule {}
