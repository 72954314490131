import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Top50ClientesService {
  constructor(private http: HttpClient) {}

  private readonly ip = 'top-50-clientes';

  public getTop(parameters: any) {
    parameters.homologacao = !environment.production;
    return this.http.post(
      `${environment.URL.APIPRIVATE}/v1/${this.ip}`,
      parameters
    );
  }
}
