import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, empty, interval } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private relativeLink = 'session';
  private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private userInfo$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private userName$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  private languagePortal$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // variavel utilizada para separar atualizações e documentações do portal (utilizar para chumbado)
  private groupPortal$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public emitEventLogin: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  get isLogged$() {
    const token = localStorage.getItem('token');
    if (!token) {
      this.isLoggedIn$.next(false);
    } else {
      this.isLoggedIn$.next(true);
    }
    return this.isLoggedIn$.asObservable();
  }

  get userInformation$() {
    const information = localStorage.getItem('user') as string
    const user = JSON.parse(information);
    this.userInfo$.next(user);
    return this.userInfo$.asObservable();
  }

  get username$() {
    const information = localStorage.getItem('username') as string
    const username = JSON.parse(information);
    this.userName$.next(username);
    return this.userName$.asObservable();
  }

  get language$() {
    const language = localStorage.getItem('language');
    this.languagePortal$.next(language as any);
    return this.languagePortal$.asObservable();
  }

  get group$() {
    const group = localStorage.getItem('group');
    this.groupPortal$.next(group as any);
    return this.groupPortal$.asObservable();
  }

  get is_representante$() {
    return localStorage.getItem('is_representante');
  }

  get id_marca$() {
    return localStorage.getItem('id_marca');
  }

  get email$() {
    return localStorage.getItem('email');
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.emitEventLogin.emit(false);
    this.isLoggedIn$.next(false);
    this.router.navigate(['login']);
  }

  login(form: any) {
    this.languagePortal$.next(form['language']);
    this.groupPortal$.next(form['group']);
    delete form['language'];
    return this.http.post(`${environment.URL.APIPUBL}/v1/${this.relativeLink}`, form)
      .pipe(
        map((response: any) => {

          if (response.data.token) {

            localStorage.setItem('language', this.languagePortal$.value == undefined ? 'pt-BR' : this.languagePortal$.value);
            const jwtToken = response.data.token;
            localStorage.setItem('token', jwtToken);

            const userInformation = response.data ;
            localStorage.setItem('user', JSON.stringify(userInformation));

            this.userInfo$.next(userInformation);

            const username = { username: response.data.username };
            localStorage.setItem('username', JSON.stringify(username));

            this.userName$.next(username);

            this.isLoggedIn$.next(true);

            localStorage.setItem('email', response.data.email);

            return response.data;
          }
          return response;
        })
      );
  }

  isActive() {
    return interval(10000)
      .pipe(
        switchMap((_) => this.http.head(`${environment.URL.APIPUBL}/v1/${this.relativeLink}/self`)
          .pipe(
            catchError(() => {
              this.logout();
              this.router.navigate(['/']);
              return empty();
            })
          ))
      );
  }
}
