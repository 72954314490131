<po-page-default
  [p-title]="page.title"
  [p-breadcrumb]="page.breadcrumb"
  [p-actions]="page.actions"
>
  <form [formGroup]="form">
    <div class="po-row">
      <po-input
        p-label="Quotation ID"
        class="po-md-3"
        formControlName="idPedido"
        p-disabled="true"
      ></po-input>
      <po-input
        p-label="Customer Code"
        class="po-md-3"
        formControlName="codCliente"
        p-disabled="true"
      ></po-input>
      <po-input
        p-label="Company Name"
        class="po-md-3"
        formControlName="razaoSocial"
        p-disabled="true"
      ></po-input>
      <po-input
        p-label="License ID"
        class="po-md-3"
        formControlName="idLicenca"
        p-disabled="true"
      ></po-input>
      <po-input
        p-label="Seller Code"
        class="po-md-3"
        formControlName="codRepresentante"
        p-disabled="true"
      ></po-input>
      <po-input
        p-label="Seller Name"
        class="po-md-3"
        formControlName="apelido"
        p-disabled="true"
      ></po-input>
      <po-input
        p-label="Emission Date"
        class="po-md-3"
        formControlName="dataEmissao"
        p-disabled="true"
      ></po-input>
      <po-input
        p-label="Amount"
        class="po-md-3"
        formControlName="total"
        p-disabled="true"
      ></po-input>
    </div>
  </form>

  <po-table
    [p-hide-columns-manager]="true"
    [p-columns]="table.columns"
    [p-items]="table.items"
    [p-height]="table.height"
  >
  </po-table>
</po-page-default>

<po-loading-overlay *ngIf="isLoading" p-text="Loading"></po-loading-overlay>
