<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb">
    <po-divider p-label="User Data"></po-divider>

    <form [formGroup]="addUserForm">
        <div class="po-row">
            <po-input formControlName="id_protheus" p-label="Id Protheus" class="po-md-3"></po-input>
            <po-input formControlName="name" p-required="true" p-label="Name" class="po-md-3" p-error-pattern="Apenas letras e sem espaços em branco!"></po-input>
            <po-input formControlName="username" p-required="true" p-label="Username" class="po-md-3"></po-input>
            <po-password formControlName="password" p-required="true" p-label="Password" class="po-md-3" p-error-pattern="Proibido espaços em branco e sequências numéricas de 6 dígitos"></po-password>
        </div>

        <div class="po-row">
            <po-combo *ngIf="roleOptions" formControlName="role_id" [p-required]="true" p-label="Role" [p-options]="roleOptions" class="po-md-3">
            </po-combo>
            <!-- <po-select class="po-md-3" *ngIf="controls.is_representante" formControlName="id_marca" p-label="Marca" [p-options]="selects.marcas">
            </po-select> -->
            <po-switch class="po-md-2" p-label="Active" formControlName="active" p-label-on="Yes" p-label-off="No">
            </po-switch>
            <!-- <po-switch class="po-md-2" p-label="Representante" formControlName="is_representante" p-label-on="Sim" p-label-off="Não"></po-switch> -->
        </div>

    </form>

</po-page-default>

<po-modal #confirmUserModal p-title="Confirm user" [p-primary-action]="confirmPrimaryAction" [p-secondary-action]="confirmSecondaryAction" [p-hide-close]="true">
    <div>
        <po-info p-label="Name" [p-orientation]="orientation" [p-value]="controls.name.value"></po-info>
        <po-info p-label="Username" [p-orientation]="orientation" [p-value]="controls.username.value"></po-info>
        <po-info p-label="Password" [p-orientation]="orientation" [p-value]="controls.password.value"></po-info>
        <!-- <po-info p-label="Representante" [p-orientation]="orientation" [p-value]="controls.is_representante.value"></po-info> -->
        <po-info p-label="Role" [p-orientation]="orientation" [p-value]="roles"></po-info>
        <!-- <po-info p-label="Marca" [p-orientation]="orientation" [p-value]="controls.id_marca.value"></po-info> -->
        <po-info p-label="Active" [p-orientation]="orientation" [p-value]="controls.active.value"></po-info>
    </div>
</po-modal>

<po-loading-overlay [p-screen-lock]="true" p-text="Loading..." *ngIf="loading">
</po-loading-overlay>