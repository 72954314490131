<po-page-default [p-title]="page.title" [p-breadcrumb]="pageBreadcrumb" [p-actions]="pageActions">
    <po-table [p-columns]="table.columns" [p-items]="table.items" [p-height]="table.height" [p-striped]="true" [p-sort]="true" [p-selectable]="true" [p-single-select]="true" (p-selected)="selectItem($event)" (p-unselected)="unselectItem($event)">
    </po-table>
    <app-pagination (totalItems)="pagination.totalItems" [itemsPerPage]="pagination.itemsPerPage" (currentPage)="pagination.currentPage" (pageChanged)="onPageChange($event)"></app-pagination>
</po-page-default>
<po-modal #modalArmazem [p-primary-action]="primaryAction" [p-secondary-action]="secondaryAction">
    <form [formGroup]="form">
        <po-select p-label="From:" class="po-md-6" formControlName="origem" [p-options]="select.armazemOrigem" [p-required]="true"></po-select>
        <po-select p-label="To:" class="po-md-6" [p-options]="select.armazemDestino" formControlName="destino" [p-required]="true"></po-select>
    </form>
</po-modal>
<po-loading-overlay *ngIf="loading" [p-screen-lock]="true" p-text="Loading, please wait..."></po-loading-overlay>