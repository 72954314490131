import { Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  PoMenuItem,
  PoModalAction,
  PoModalComponent,
  PoNotificationService,
  PoPopupAction,
  PoToolbarAction,
} from '@po-ui/ng-components';
import { take } from 'rxjs/operators';
import { MenuService } from './service/menu/menu.service';
import { SessionService } from './service/session/session.service';
import { UserService } from './service/user/user.service';

const { version: appVersion } = require('package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild('modalUser', { static: true }) modalUser?: PoModalComponent;

  modalPrimaryAction = {
    label: 'Save',
    action: () => {
      this.changePassword(this.formPassword);
    },
  } as PoModalAction;

  modalSecondaryAction = {
    label: 'Cancel',
    action: () => {
      this.modalUser?.close();
    },
  } as PoModalAction;

  public profileActions: Array<PoToolbarAction> = [];
  public isLoggedIn$ = this.sessionService.isLogged$;
  public profile = {
    roleId: 2,
    is_representante: false,
    subtitle: 'developer',
    id: 1,
    avatar: 'https://api.adorable.io/avatars/64/admin',
    title: 'developer',
    id_marca: 0,
    username: 'developer',
  };
  public regexPasswordInfo = `One uppercase letter, one number and six digits`;
  public popUpActions: Array<PoPopupAction> = [];
  public loading = false;

  public formPassword: UntypedFormGroup = this.fb.group({
    old: ['', [Validators.required]],
    new: [
      '',
      [
        Validators.required,
        Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/),
      ],
    ],
    confirm: ['', [Validators.required]],
    userId: [0],
    adm: false,
  });

  public menus: Array<PoMenuItem> = [];
  public version = appVersion;
  public collapsed = false;

  constructor(
    private router: Router,
    private sessionService: SessionService,
    private renderer: Renderer2,
    private menuService: MenuService,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private notification: PoNotificationService
  ) {}

  ngOnInit() {
    this.sessionService.emitEventLogin.subscribe({
      next: (data: any) => {
        if (data) {
          this.getMenus();
        }
      },
    });

    this.getMenus();

    this.profileActions = [
      {
        label: 'Profile',
        icon: 'po-icon-edit',
        separator: true,
        action: () => {
          this.modalUser?.open();
        },
        type: 'primary',
      },
      {
        label: 'Log Out',
        icon: 'po-icon-exit',
        type: 'danger',
        separator: true,
        action: () => this.sessionService.logout(),
      },
    ];

    this.sessionService.userInformation$
      .pipe(take(1))
      .subscribe((element: any) => {
        if (element) {
          element.title = element.title.toUpperCase();
          this.formPassword.controls.userId.setValue(element.id);
          this.profile = element;
        }
      });
  }

  private getMenus() {
    this.isLoggedIn$.pipe(take(1)).subscribe({
      next: (element) => {
        if (element) {
          this.menuService.getMenu().subscribe((element: any) => {
            this.menus = this.sortMenu(element.data.response);
          });
          this.addJsToElement();
        } else {
          this.router.navigate(['/login']);
          this.sessionService.logout();
        }
      },
      error: () => {
        this.router.navigate(['/login']);
        this.sessionService.logout();
      },
    });
  }

  private sortMenu(menu: Array<any>) {
    menu.sort((a, b) => a.label.localeCompare(b.label));
    menu.forEach((element) => {
      element.subItems.sort((a: any, b: any) => a.label.localeCompare(b.label));
    });
    return this.settingsLastElement(menu);
  }

  private settingsLastElement(menu: Array<any>) {
    const idMenuSettings = 9;

    const indexSettings = menu.findIndex((a) => a.id === idMenuSettings);

    const tamanhoMenu = menu.length;
    menu.splice(tamanhoMenu, 0, menu.splice(indexSettings, 1)[0]);
    return menu;
  }

  private addJsToElement(): void {
    const script = document.createElement('link');
    script.type = 'image/x-icon';
    script.rel = 'icon';
    script.id = 'favIcon';
    script.title = `PORTAL RSC ${this.version}`;
    script.href = '../assets/images/short-logo-rsc.png';
    this.renderer.appendChild(document.head, script);
  }

  private changePassword(form: UntypedFormGroup): void {
    if (form.invalid) {
      this.notification.warning('Please, fill the form correctly');
    } else if (form.valid) {
      this.loading = true;
      this.userService.changePassword(form.value).subscribe({
        next: (element: any) => {
          this.modalUser?.close();
          this.notification.success(element.meta.message);
          this.loading = false;
          this.sessionService.logout();
        },
        error: (error: any) => {
          console.error(error);
          this.notification.error(error.error.meta.message);
          this.loading = false;
        }
      }
      );
    }
  }
}
