<po-page-default p-title="Container" [p-breadcrumb]="page.breadcrumb" [p-actions]="page.action" [p-literals]="page.literals">
    <po-accordion>
        <po-accordion-item p-label="Filters">
            <div [formGroup]="filtersForm" (keyup.enter)="getListaBrowser()">
                <po-input class="po-md-2" p-label='Container' p-clean="true" formControlName="ZR2_NUM"></po-input>
                <po-datepicker class="po-md-2" p-locale="en" p-format="mm/dd/yyyy" formControlName="ETD_FROM" p-label="ETD from"></po-datepicker>
                <po-datepicker class="po-md-2" p-locale="en" p-format="mm/dd/yyyy" formControlName="ETD_TO" p-label="ETD To"></po-datepicker>
                <po-datepicker class="po-md-2" p-locale="en" p-format="mm/dd/yyyy" formControlName="ETA_FROM" p-label="ETA from"></po-datepicker>
                <po-datepicker class="po-md-2" p-locale="en" p-format="mm/dd/yyyy" formControlName="ETA_TO" p-label="ETA To"></po-datepicker>
                <po-input class="po-md-2" p-label="Bill of Lading" p-clean="true" formControlName="ZR2_BOL"></po-input>
                <po-input class="po-md-2" p-label='Po Number' p-clean="true" formControlName="ZR3_NUMPC"></po-input>
                <po-button class="po-md-2 po-mt-4" p-icon="po-icon-close" p-label="" [p-small]="false" p-type="danger" (p-click)="limparFiltros()"></po-button>
                <po-button class="po-md-2 po-mt-4" p-label="Search" p-icon="po-icon-search" p-type="primary" (click)="getListaBrowser()"></po-button>
            </div>
        </po-accordion-item>
    </po-accordion>
    <div class="po-row">
        <po-table [p-sort]="true" class="po-md-12" [p-selectable]="true" [p-single-select]="true" [p-columns]="table.columns" [p-striped]="true" [p-items]="table.items" [p-height]="table.height" (p-selected)="selectedItem($event)" (p-unselected)="unselectedItem($event)"></po-table>
    </div>

    <app-pagination (totalItems)="pagination.totalItems" [itemsPerPage]="pagination.itemsPerPage" (currentPage)="pagination.currentPage" (pageChanged)="onPageChange($event)"></app-pagination>
</po-page-default>
<po-loading-overlay *ngIf="loading" [p-screen-lock]="true" p-text="Loading, please wait...">
</po-loading-overlay>