import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';


export class SendEmail {
  cFrom: any = '';
  cEmailTo: string = '';
  cEmailCc: string = '';
  cEmailBCC: string = '';
  cAssunto: string = '';
  cMensagem: string = '';
  paytrace: boolean = false;
  amount: number = 0;
  cfilename: string = '';
  cdados: string = '';
  chtml: string = '';
  cOptionalMensagem: string = '';
  funcao: string = '';
}

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor(
    private http: HttpClient
  ) { }

  sendEmail(body: any) {
    body.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/sendemail`, body)
  }
}
