import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ResponsePagination, UtilService } from '../util/util.service';


export interface Representante {
  ativo: boolean,
  bairro: string,
  bloqueado: boolean,
  celular: string,
  cep: string,
  codigo: string,
  estado: string,
  logradouro: string,
  municipio: string,
  nomeFantasia: string,
  razaoSocial: string,
  telefone: string,
  telefoneDois: string,
  segmento: string,
  codigoCarteira: string,
  comissao: string;
}

@Injectable({
  providedIn: 'root'
})
export class RepresentantesService {

  private relativeLink = 'representantes';

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  public getRepresentantes(parameters?: any, limit?: number): Observable<ResponsePagination<Array<Representante>>> {
    parameters.homologacao = !environment.production
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`, parameters) as Observable<ResponsePagination<Array<Representante>>>;
  }

  public getAllRepresentantes(parameters?: any) {
    return this.getRepresentantes(parameters, 1)
      .pipe(
        map((item: any) => item.data.total),
        switchMap((limit) => this.getRepresentantes(parameters, limit))
      );
  }
}
