import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BoxLabelsComponent } from './box-labels/box-labels.component';
import { RouterModule } from '@angular/router';
import { LabelsRoutingModule } from './labels-routing.module';
import { PoButtonModule, PoDividerModule, PoDynamicModule, PoFieldModule,  PoLoadingModule,  PoModalModule, PoPageModule, PoTableModule } from '@po-ui/ng-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalComponentsModule } from '../global-components/global-components.module';
import { ProductLabelsComponent } from './product-labels/product-labels.component';



@NgModule({
  declarations: [
    BoxLabelsComponent,
    ProductLabelsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    LabelsRoutingModule,
    PoFieldModule,
    PoTableModule,
    PoModalModule,
    FormsModule,
    ReactiveFormsModule,
    PoButtonModule,
    PoPageModule,
    PoLoadingModule,
    PoDividerModule,
    PoDynamicModule,
    GlobalComponentsModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LabelsModule { }
