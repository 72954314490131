import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { RepresentantesService } from 'src/app/service/representantes/representantes.service';
import { ResponsePagination } from 'src/app/service/util/util.service';
// import { Representative } from 'src/app/interfaces/representative';
// import { RepresentantesService } from 'src/app/services/representantes/representantes.service';
// import { ResponsePagination } from 'src/app/utils/util-service/util.service';

export interface Representative {
  ativo: boolean;
  bairro: string;
  bloqueado: boolean;
  celular: string;
  cep: string;
  codigo: string;
  estado: string;
  logradouro: string;
  municipio: string;
  nomeFantasia: string;
  razaoSocial: string;
  telefone: string;
  telefoneDois: string;
  segmento: string;
  codigoCarteira: string;
  comissao: string;
}

@Injectable({
  providedIn: 'root'
})
export class RepresentantesListResolver implements Resolve<Observable<ResponsePagination<Array<Representative>>>> {
  constructor(private representantesService:RepresentantesService){

  };
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResponsePagination<Array<Representative>>> {
    const obj = {procedure: true, data: {}, metodo: "" }
    return this.representantesService.getAllRepresentantes(obj);
  }
}
