<po-page-default [p-title]="title" [p-actions]="actions" [p-breadcrumb]="page.breadcrumb">

    <div class="po-row">
        <po-container [p-height]="160" [p-no-border]="false" [p-no-padding]="false" [p-no-shadow]="false" class="po-md-12">
            <div class="po-row">
                <po-info p-label="Code" class="po-md-4" [p-label-size]="7" [p-value]="item.ZR0_NUM"></po-info>
                <po-info p-label="Date & Hour" [p-label-size]="7" class="po-md-4" [p-value]="item.dataHora"></po-info>
                <!-- <po-info p-label="Hora" [p-label-size]="7" class="po-md-4"  [p-value]="item.ZR0_HORA"></po-info> -->
                <po-info p-label="User Name" class="po-md-4" [p-label-size]="7" [p-value]="item.ZR0_USERNA"></po-info>
                <po-info p-label="From:" class="po-md-4" [p-label-size]="7" [p-value]="item.ARMAZEM_ORIGEM"></po-info>
                <po-info p-label="To:" class="po-md-4" [p-label-size]="7" [p-value]="item.ARMAZEM_DESTINO"></po-info>
            </div>
        </po-container>
    </div>
    <div class="po-md-12 po-mt-4">
        <po-table [p-striped]="true" [p-sort]="true" [p-columns]="table.columns" [p-items]="table.items" [p-height]="260"></po-table>
    </div>
</po-page-default>
<po-loading-overlay [p-screen-lock]="true" p-text="Loading, please wait..." *ngIf="loading">
</po-loading-overlay>