import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


export interface EnvioRecordOcurrencesSalesOrder {
  data: {
    ZT_CLIENTE: string
    ZT_LOJA: string
    ZT_PEDIDO: string
    ZT_DATA: string
    ZT_HORA: string
    ZT_CODUSR: string
    ZT_TIPO: string
    DESCRICAO: string
  }
}

interface RetornoRecordOcurrencesSalesOrder {
    mensagem: string,
    chave: number // RECNO DA GRAVACAO
    gravado: number // 1 = Gravado 0 = Erro na garavacao
}

@Injectable({
  providedIn: 'root'
})
export class SalesOcurrenceService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly apiRoot = `${environment.URL.APIPRIVATE}/v1/sales-order-ocurrence-record`;

  public recordOcurrenceSales(parameters: any): Observable<RetornoRecordOcurrencesSalesOrder> {
    parameters.homologacao = !environment.production;
    return this.http.post(this.apiRoot, parameters) as Observable<RetornoRecordOcurrencesSalesOrder>
  }
}
