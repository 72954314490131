import { EventEmitter, Injectable, Output } from '@angular/core';
import { PoSelectOption } from '@po-ui/ng-components';

export interface ResponseJavaApi<T> {
  data: Array<T>;
  mensagem: string;
  status: boolean;
  token?: string;
}

export interface ResponseProtheusApi<T> {
  mensagem: string;
  gravado: number;
  chave?: string;
  erro?: number;
}

export interface SendJavaApi {
  metodo: string;
  procedure?: boolean;
  data: object | undefined;
  homologacao?: boolean
}

export interface ResponseMeta {
  code: number;
  message: string;
}

export interface ResponseApi<T> {
  meta: ResponseMeta;
  data: ResponseData<T>;
}

export interface ResponseData<T> {
  total: number;
  response: T;
}

export interface Response<T> {
  response: T;
}

export interface RestProtheus {
  gravacao: boolean;
  mensagem?: string;
  chave?: string;
}

export interface ResponsePagination<T> {
  response: {
    total: number,
    offset: number,
    limit: number,
    data: T
  };
  href: string;
  params: object;
}


export interface ResponsePaginationJava<T> {
  total: number;
  offset: number;
  data: T;
  mensagem: string;
  limit: number;
  status: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() { }

  @Output() emitTotalItems = new EventEmitter<number>();
  @Output() currentPage = new EventEmitter<number>();

  /**
   *
   * @param json recebe um JSON com chave e valor
   * EX: {"nome": "Daniel", filter: "pesquisa"}
   * @returns retornas uma string separando chave e valor com & '"E" comercial'
   *  nome="Daniel"&filter=pesquisa
   */
  getParameters(json: any) {
    return Object.keys(json).map((key) => {
      if (json[key] === undefined || json[key] === null) {
        json[key] = '';
      }
      return `${key}=${json[key]}`;
    }).join('&');
  }

  //#region adicionado por Mateus, copiado de outro utilService

  montarListaComResolver(dados: any, propertyLabel: any, propertyValue: any) {
    return dados.map((item: any) => {
      return { label: `${item[propertyValue]} - ${item[propertyLabel]}`, value: item[propertyValue] } as PoSelectOption;
    });
  }

  public formatDataFromArray(arr: Array<any>, propertyData: string) {
    this.restiraDatasInvalidas(arr, propertyData);
    arr.map((element) => {
      element[propertyData] = this.formatDateAmerican(element[propertyData])
    })
  }

  public restiraDatasInvalidas(arr: Array<any>, property: string) {
    arr.map((element) => {
      if (element[property] === '1900-01-01') {
        element[property] = ''
      }
    })
  }

  //#endregion

  /**
   * terceira versão
   * @param date date
   * @returns yyyy-MM-dd
   */
  formatDate(date: string) {
    return date.split('T')[0];
  }

  /**
   *
   * @param windowHeight innerHeight
   * @param percent valor em percentual para calcular o tamanho da tela
   */
  calcularHeight(windowHeight: number, percent: number) {
    windowHeight = innerHeight;
    windowHeight = windowHeight * percent;
    return windowHeight;
  }

  /**
   * Função retorna o dia atual formatado
   * @returns hoje = 'yyyy-mm-dd' => 2019-07-31
   */
  dataAtual() {
    const today = new Date();
    let dd: any = today.getDate();
    let mm: any = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    const hoje = yyyy + '-' + mm + '-' + dd;
    return hoje;
  }

  /**
   * Função retorna a hora atual formatada
   * @returns hh:mm => 14:39
   */
  horaAtual() {
    const hoje = new Date();
    const hh = hoje.getHours();
    let hora;
    const mm = hoje.getMinutes();
    let minuto;
    if (hh < 10) {
      hora = `0${hh}`;
    } else {
      hora = hh;
    }
    if (mm < 10) {
      minuto = `0${mm}`;
    } else {
      minuto = mm;
    }
    const horaAtual = `${hora}:${minuto}`;
    return horaAtual;
  }

  /**
   * Função utilizada para manipular a data atual e inserir data Futura
   * @returns hoje = 'yyyy-mm-dd' => 2019-07-31
   */
  futureDate(days: any) {
    const today = new Date();
    let dd: any = today.getDate() + days;
    let mm: any = today.getMonth() + 1; // January is 0!
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    const dataFutura = yyyy + '-' + mm + '-' + dd;
    return dataFutura;
  }

  /**
   *
   * @param campoTexto Recebe uma string com o CPF ou CNPJ
   * ela verifica se é CNPJ ou CPF, e chama a respectiva função que formata
   * @returns uma string formatada
   */
  formatarCnpjCpf(campoTexto: string) {
    if (campoTexto.length <= 11) {
      campoTexto = this.mascaraCpf(campoTexto);
    } else {
      campoTexto = this.mascaraCnpj(campoTexto);
    }
    return campoTexto;
  }

  /**
   *
   * @param campoTexto Recebe uma string com o CPF ou CNPJ
   * retirar os pontos e traços da string
   * @returns uma string de numeros
   */
  retirarFormatacaoCnpjCpf(campoTexto: string) {
    campoTexto = campoTexto.replace(/(\.|\/|\-)/g, '');
  }

  /**
   *
   * @param valor recebe CPF, somente números
   * @returns uma string formatada em CPF
   */
  private mascaraCpf(valor: string) {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
  }

  /**
   *
   * @param valor recebe CPF, somente números
   * @returns uma string formatada em CNPJ
   */
  private mascaraCnpj(valor: string) {
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
  }

  /**
   *
   * @param telefone recebe uma string com número de telefone ou celular
   * @returns uma string formatada utilizando como paramêtro o length da string
   */
  mascaraDeTelefone(telefone: any) {
    const textoAtual = telefone;
    const isCelular = textoAtual.length === 9;
    let teleCel;
    if (isCelular) {
      teleCel = textoAtual.replace(/(\d{5})(\d{4})/,
        (regex: any, arg1: any, arg2: any) => {
          return arg1 + '-' + arg2;
        });
    } else {
      const parte1 = textoAtual.slice(0, 4);
      const parte2 = textoAtual.slice(4, 8);
      teleCel = `${parte1}-${parte2}`;
    }
    return teleCel;
  }

  /**
   *
   * @param telefone recebe uma string com pontos e traços
   * @returns string somente números
   */
  removeMascaraDeTelefone(telefone: any) {
    telefone = telefone.replace(/[^0-9]+/g, '');
    return telefone;
  }

  /**
   *
   * @param val dados a ser convertido, recebe qualquer tipo de dados
   * @returns se string convert em uppercase, senão devolve o valor
   *
   */
  convertUpperCase(val: any) {
    let filter = val;
    filter = filter.toUpperCase();
    return filter;
  }

  /**
   *
   * @param arr Array com os valores
   * @param value valor que deseja retirar
   * @returns retorna um Array sem o value informado
   */
  arrayRemove(arr: Array<any>, value: any) {

    return arr.filter((ele) => {
      return ele !== value;
    });
  }

  /**
   *
   * @param int qualquer tipo de variavel, porém deve ser um número
   * @returns um número (String) formatado em real
   */
  formatReal(int: any) {
    // let tmp = int + '';
    // tmp = tmp.replace(/([0-9]{2})$/g, "$1");
    // if (tmp.length > 6)
    //   tmp = tmp.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    // tmp = tmp.replace('.', ',');
    // return tmp;
    int = int.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    // int = int.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    return int;
  }

  /**
   *
   * @param arr Array e posição do que deseja somar. EX: 'data.response' || 'data'
   * @param keyObj chave do objeto. EX: '{valor: 1500}, chave = 'valor'
   * @returns valor total dos campos somados
   */
  somaArray(arr: Array<any>, keyObj: string) {
    if (keyObj === '') {
      let soma = 0;
      arr.map((item) => {
        soma += item;
      });
      return soma;
    } else {
      let soma = 0;
      arr.map((item) => {
        soma += item[keyObj];
      });
      return soma;
    }
  }

  /**
   *
   * @param data recebe uma data yyyy/mm/dd
   * @returns string dd/mm/yyyy
   */
  formataData(data: string) {
    const fullDate = data;
    const yyyy = fullDate.substr(0, 4);
    const mm = fullDate.substr(5, 2);
    const dd = fullDate.substr(8, 2);
    return `${dd}/${mm}/${yyyy}`;
  }

  multiFormataData(data: string, formatoVolta: string) {
    const fullDate = data;

    if (formatoVolta === 'yyyy-mm-dd') {
      const yyyy = fullDate.substr(6, 4);
      const mm = fullDate.substr(3, 2);
      const dd = fullDate.substr(0, 2);
      return `${yyyy}-${dd}-${mm}`;
    } else if (formatoVolta === 'dd/mm/yyyy') {
      const yyyy = fullDate.substr(0, 4);
      const mm = fullDate.substr(5, 2);
      const dd = fullDate.substr(8, 2);
      return `${dd}/${mm}/${yyyy}`;
    } else if (formatoVolta === 'mm/dd/yyyy') {
      const yyyy = fullDate.substr(0, 4);
      const mm = fullDate.substr(5, 2);
      const dd = fullDate.substr(8, 2);
      return `${mm}/${dd}/${yyyy}`;
    } else {
      return;
    }
  }

  formataCep(cep: string) {
    return `${cep.substr(0, 5)}-${cep.substr(5, 3)}`;
  }

  /**
   * @param json json com os filtros
   * @returns json sem os campos em branco e null
   */
  public retornaFiltros(json: any) {
    json === undefined || json === null ? json = {} : json = json;
    const form = {
      key: ''
    };
    Object.keys(json).map((key) => {
      if (json[key] !== null && json[key] !== undefined && json[key] !== '') {
        form.key = json[key];
      }
    });
    return form;
  }

  public findInvalidControls(form: any) {
    let invalid: Array<any> = [];
    const controls = form;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid = this.spreadArrayToProperty(invalid,name);
      }
    }
    return invalid;
  }

  public resetForm(form: any) {
    Object.keys(form.value).map((element: any) => {
      form.controls[element].setValue('');
    })
  }

  public changeBarByTraco(item: any) {
    let str = item;
    const find = '/';
    const re = new RegExp(find, 'g');
    return str = str.replace(re, '-');
  }

  public verifyUnfinedNull(text: string) {
    return text ?? ''
  }

  sortArrayByPropertyConcat(arr: Array<any>, property: string, property2: string) {
    arr.sort((a, b) => {
      if (`${a[property]}${a[property2]}` > `${b[property]}${b[property2]}`) {
        return 1;
      }
      if (`${a[property]}${a[property2]}` < `${b[property]}${b[property2]}`) {
        return -1;
      }
      return 0;
    });
    return arr
  }

  reduceArrayByProperty(arr: Array<any>, property: string) {
    return arr.reduce((total, valor) => parseFloat(total) + (parseFloat(valor[property])), 0);
  }

  public formatCurrency(
    valor: any,
    idioma: string = "pt-BR",
    currency: string = "BRL"
  ) {
    // Create our number formatter.
    const formatter = new Intl.NumberFormat(idioma, {
      style: "currency",
      currency,

      // Ex:
      // These options are needed to round to whole numbers if that's what you want.
      // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(valor); /* $2,500.00 */
  }

  public formatDateAmerican(date: string): string {
    const value = date ?? ''
    if (value) {
      const yyyy = value.substring(0, 4);
      const mm = value.substring(5, 7);
      const dd = value.substring(8, 10);
      return `${mm}/${dd}/${yyyy}`;
    } else {
      return ''
    }
  }

  sortArrayByProperty(arr: Array<any>, property: string, ascending: boolean) {
    if (ascending) {
      arr.sort((a, b) => {
        if (a[property] > b[property]) {
          return -1;
        }
        if (a[property] < b[property]) {
          return 1;
        }
        return 0;
      });
    } else {
      arr.sort((a, b) => {
        if (a[property] > b[property]) {
          return 1;
        }
        if (a[property] < b[property]) {
          return -1;
        }
        return 0;
      });
    }
    return arr;
  }

  public reduceArrayToProperty(array: Array<any>, property: string) {
    const initialValue = 0;
    return array.reduce(
      (acc, valor) => parseFloat(acc) + parseFloat(valor[property]),
      initialValue
    );
  }

  public spreadArrayToProperty(array: Array<any>, item: any): Array<any> {
    const newArray = [...array, item];
    return  newArray;
  }
}
