import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ResponseApi, UtilService } from '../util/util.service';

export interface Role {
  id: number;
  name: string;
  created: number;
  modified: number;
  active: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {

  private relativeLink = 'roles';
  private url = `${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`;

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  getRoles(parameters?: any): Observable<ResponseApi<Role[]>> {
    parameters = this.utilService.retornaFiltros(parameters);
    const params: HttpParams = new HttpParams({ fromObject: parameters });
    return this.http.get(`${this.url}?${parameters}`) as Observable<ResponseApi<Role[]>>;
  }

  getRole(id: number): Observable<ResponseApi<Role[]>> {
    return this.http.get(`${this.url}/${id}`) as Observable<ResponseApi<Role[]>>;
  }
  /**
   *
   * @param obj formulário com os dados para gravar uma nova role
   *
   * adiciona uma role, passar o formulário com
   * nome: String e admin: boolean
   */
  // addRoles(obj: any) {
  //   return this.http.post(`${this.url}`, obj);
  // }

  // updateRole(id: number, obj: any) {
  //   return this.http.put(`${this.url}/${id}`, obj);
  // }

  // saveRole(group: any) {
  //   return this.http.post(`${this.url}`, group);

  // }

  // compareName(name: string) {
  //   return this.http.head(`${this.url}/${name}`);
  // }


  getAllRoles() {
    return this.http.get(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`)
  }

  getRoleEspecifica(id: any) {
    return this.http.get(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}/${id}`)
  }

  /**
   * 
   * @param parameters o id da role não pode ser editavel
   * @returns 
   */
  updateRole(parameters: any) {
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`, parameters)
  }

  createRole(parameters: any) {
    parameters.id = 0
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`, parameters)
  }

  deleteRole(id: number) {
    return this.http.delete(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}/${id}`)
  }

}
