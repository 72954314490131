import { Component, OnInit } from '@angular/core';
import { PoPageAction } from '@po-ui/ng-components';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PoBreadcrumb } from '@po-ui/ng-components';
import { PoNotificationService } from '@po-ui/ng-components';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { RoleService } from 'src/app/service/role/role.service';

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.css']
})
export class RoleAddComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private roleService: RoleService,
    private notification: PoNotificationService,
    private router: Router
  ) { }

  activateOptions: any[] = [
    { label: 'True', value: true },
    { label: 'False', value: false }
  ];

  readonly breadcrumb: PoBreadcrumb = {
    items: [
      { label: 'Home' },
      { label: 'Settings' },
      { label: 'Roles' },
      { label: 'Add Role' }
    ]
  };

  /* Realizar Links */
  private relativeLink = 'settings/roles';

  /**
   * Page Actions
   */

  saveAction: PoPageAction = { label: 'Save', action: () => { this.addRole(); } };
  cancelAction: PoPageAction = { label: 'Cancel', action: () => { this.router.navigate([this.relativeLink]); } };
  readonly actions: PoPageAction[] = [this.saveAction, this.cancelAction];

  /* Form Add Role */
  addRoleForm: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required]],
    active: [true, []]
  });

  ngOnInit() {
    this.saveAction.disabled = true;

    this.addRoleForm.valueChanges
      .subscribe((data) => {
        this.saveAction.disabled = this.addRoleForm.invalid;
      });
  }

  private addRole() {
    this.roleService.createRole(this.addRoleForm.value)
      .subscribe({
        next: (data) => {
        this.notification.success('Success Saved');
        this.router.navigate([this.relativeLink]);
      },
        error: (error: HttpErrorResponse) => {
          this.notification.error(error.error.meta.message);
        }
      });
  }


}
