<po-page-list [p-title]="title" [p-actions]="actions" [p-breadcrumb]=breadcrumb>
    <!-- [p-filter]="filterSettings" -->

    <po-table [p-columns]="table.columns" [p-items]="table.items" [p-sort]="true" [p-striped]="true" [p-actions]="table.actions" [p-literals]="table.literals"></po-table>

</po-page-list>

<po-modal #advancedFilterActionModal p-title="Advanced Filter" [p-primary-action]="advancedFilterPrimaryAction" [p-hide-close]="false" [p-secondary-action]="clearFilterSecundaryAction">
    <form [formGroup]="filtersForm">
        <div class="po-row">
            <po-input p-required="true" class="po-md-3" p-label="Name" formControlName="name" p-error-pattern="Only letters"></po-input>
            <po-select [p-required]="true" class="po-md-3" p-label="Active" [p-options]="selectOptions" formControlName="active"></po-select>
        </div>
    </form>
</po-modal>

<po-loading-overlay *ngIf="loading" [p-screen-lock]="true" p-text="Loading..">
</po-loading-overlay>