import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponsePagination, UtilService } from '../util/util.service';

export class ListaPedidos {
  aprovado: string = '';
  canalOrigem: string = '';
  cnpj: string = '';
  codigoCliente: string = '';
  codigoFeira: string = '';
  codigoRepresentante: string = '';
  codigoStatus: string = '';
  dataEmissao: string = '';
  dataEntradaMeta: string = '';
  descricaoStatus: string = '';
  estado: string = '';
  fomraPagamento: string = '';
  legenda: string = '';
  lojaCliente: string = '';
  nomeCliente: string = '';
  nomeFantasia: string = '';
  nomeRepresentante: string = '';
  notaFiscal: string = '';
  numeroOrcamento: string = '';
  numeroPedido: string = '';
  A1_EMAIL: string = '';
  pedidoAPP: string = '';
  ponumber: string = '';
  saldoPedido: string = '';
  serieNotaFiscal: string = '';
  tipoOperacao: string = '';
  tipoPedido: string = '';
  totalPedido: number = 0;
  empresa: string = '';
  filial: string = '';
  descricaoMarca: string = '';
  numeroInterno: string = '';
  recno: number = 0;
  statusdespacho: string = '';
  C5_XLOG: string = '';
  C5_XSHIPTO: string = '';
  C5_XENDENT: string = '';
  C5_XSHPZIP: string = '';
  C5_XENDEN2: string = '';
  C5_XSHPUF: string = '';
  C5_XSHPEMA: string = '';
  C5_FRETE: number = 0;
  valorRecebido: number = 0;
  C5_XPRVSTX: number = 0;
  C5_XDESPAC: string = '';
  C5_XCODSTA: string = '';
  funcao: string = '';
  opcao: string = '';
  totalDue : number = 0;
}

export interface ParametrosEnviar {
  numeroPedido?: string,
  marca: string,
  somenteAbertos: string,
  page: string,
  limit: string,
  numeroNotaFiscal?: string,
  codigoCliente?: string,
  nomeFantasia?: string,
  razaoSocial?: string,
  start?: string,
  numeroOrcamento?: string,
  cnpj?: string,
}

@Injectable({
  providedIn: 'root'
})

export class RelacaoPedidosV2Service {

  private relativeLink = 'relacao-pedidos-V2'
  private linkMinori = 'relacao-pedidos'
  // public pedido;

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  public getPedidos(paramters: any): Observable<ResponsePagination<Array<ListaPedidos>>> {
    // paramters = this.utilService.retornaFiltros(paramters);
    // const params: HttpParams = new HttpParams({ fromObject: paramters });
    paramters.homologacao = !environment.production
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`, paramters) as
      Observable<ResponsePagination<Array<ListaPedidos>>>
  }

  public getSales(paramters: any): Observable<ResponsePagination<Array<ListaPedidos>>> {
    paramters = this.utilService.retornaFiltros(paramters);
    const params: HttpParams = new HttpParams({ fromObject: paramters });
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.linkMinori}`, { params }) as
      Observable<ResponsePagination<Array<ListaPedidos>>>
  }

  public getPedidosItem(parameters: any): Observable<ResponsePagination<Array<any>>> {
    parameters.homologacao = !environment.production
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/pedido-item`, parameters) as Observable<ResponsePagination<Array<any>>>
  }

}
