import { Injectable } from '@angular/core';
import { PoTableColumn } from '@po-ui/ng-components';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { UtilService } from '../util/util.service';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  private EXCEL_TYPE =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private EXCEL_EXTENSION = 'xlsx';

  constructor(private utilService: UtilService) {}

  /**
   * Documentação
   * @param json Recebe um array plano de objetos
   * @param excelFileName nome do arquivo a ser exportado
   */
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    // const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: this.EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      `${fileName}-${this.utilService.dataAtual()}-${new Date().getTime()}.${
        this.EXCEL_EXTENSION
      }`
    );
  }

  public organizaArrParaExcel(
    tableColumns: Array<PoTableColumn>,
    tableItems: Array<any>,
    name: string
  ) {
    let arr = [] as Array<any>;
    let arrRet = [] as Array<any>;
    tableColumns.map((col) => {
      arr = this.utilService.spreadArrayToProperty(arr, {
        [col.property as string]: col.label,
      });
    });
    tableItems.map((item: any) => {
      let obj = {};
      arr.map((objKeys) => {
        Object.entries(objKeys).map((keys: Array<any>) => {
          const propertie = keys[1] as string;
          Object.assign(obj, { [propertie]: item[keys[0]] });
        });
      });
      arrRet = this.utilService.spreadArrayToProperty(arrRet, obj);
    });
    this.exportAsExcelFile(arrRet, name);
  }

  public organizaArrParaExcelPelaProperty(
    tableColumns: Array<PoTableColumn>,
    tableItems: Array<any>,
    name: string
  ) {
    let arr = [] as Array<any>;
    let arrRet = [] as Array<any>;
    tableColumns.map((col) => {
      arr = this.utilService.spreadArrayToProperty(arr, {
        [col.property as string]: col.label,
      });
    });
    tableItems.map((item: any) => {
      let obj = {};
      arr.map((objKeys) => {
        Object.entries(objKeys).map((keys: Array<any>) => {
          const propertie = keys[1] as string;
          Object.assign(obj, { [propertie]: item[keys[0]] });
        });
      });
      arrRet = this.utilService.spreadArrayToProperty(arrRet, obj);
    });
    this.exportAsExcelFile(arrRet, name);
  }

  adjustColumnsAndStatus(json: Array<any>) {
    // Encontre a coluna com a maior largura
    let largestColumn = json.reduce(
      (largest, column) => {
        if (
          column.width &&
          parseFloat(column.width) > parseFloat(largest.width)
        ) {
          return column;
        }
        return largest;
      },
      { width: '0%' }
    );

    // Calcular a soma total das larguras das colunas
    const totalWidth = json.reduce((acc, column) => {
      if (column.width) {
        return acc + parseFloat(column.width);
      }
      return acc;
    }, 0);

    // Verificar se a soma total é superior a 100%
    if (totalWidth > 100) {
      // Redistribuir a largura da maior coluna
      const difference = totalWidth - 100;
      largestColumn.width =
        Math.floor(parseFloat(largestColumn.width) - difference) + '%';
    }

    // Pesquisar a literal "STATUS" e atribuir 10% à coluna correspondente
    for (let i = 0; i < json.length; i++) {
      const column = json[i];
      // Verifique se a propriedade 'label' ou 'property' contém a literal "STATUS"
      if (
        (column.label && column.label.toLowerCase().includes('status')) ||
        (column.property && column.property.toLowerCase().includes('status'))
      ) {
        column.width = '10%';
      }
    }

    return json;
  }
}
