<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb">
    <div *ngIf="formFields.length > 0" (keyup.enter)="dynamicForm()">
        <po-dynamic-form #dynamicFormPopUp [p-fields]="formFields" [p-value]="formValue"></po-dynamic-form>
        <po-divider class="po-md-10"></po-divider>
        <po-button class="po-md-2" p-icon="po-icon-search" (p-click)="dynamicForm()"></po-button>
    </div>

    <div class="po-row">
        <po-table [p-selectable]="true" [p-single-select]="true" (p-selected)="selectPopUp($event)" (p-unselected)="unselectPopUp($event)" [p-hide-columns-manager]="true" [p-columns]="tableSearchPopup.columns" [p-items]="tableSearchPopup.items" [p-hide-text-overflow]="true"
            [p-sort]="true" [p-striped]="true" [p-literals]="tableSearchPopup.customLiterals" [p-height]="250">
        </po-table>
    </div>

    <po-divider class="po-md-12 po-mt-2" p-label="PO ITEMS"></po-divider>

    <div class="po-row">
        <po-table [p-selectable]="true" [p-single-select]="false" [p-hide-columns-manager]="true" [p-columns]="tablePo.columns" [p-items]="tablePo.items" [p-hide-text-overflow]="true" [p-sort]="false" [p-striped]="true" [p-loading]="tablePo.loading" [p-literals]="tablePo.customLiterals"
            [p-height]="tablePo.height" (p-selected)="selectedItem($event)" (p-unselected)="unSelectedItem($event)" (p-all-selected)="allSelected($event)" (p-all-unselected)="unAllSelected()">
            <ng-template p-table-cell-template let-column="column" let-row="row">
                <po-decimal *ngIf="column.property === 'C7_QUANT'" [(ngModel)]="row.C7_QUANT" (p-change)="validateQuantity(row)" [p-max]="row.SALDO"></po-decimal>
                <po-input *ngIf="column.property === 'ZR3_VOL'" [(ngModel)]="row.ZR3_VOL"></po-input>
                <po-input *ngIf="column.property === 'ZR3_DOC'" [(ngModel)]="row.ZR3_DOC"></po-input>
            </ng-template>
        </po-table>
    </div>
</po-page-default>

<po-loading-overlay *ngIf="loading" p-text="Loading, please wait ...">
</po-loading-overlay>