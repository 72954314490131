import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SettingsRoutingModule } from './settings-routing.module';
import { PoPageModule, PoInfoModule, PoContainerModule } from '@po-ui/ng-components';
import { PoBreadcrumbModule } from '@po-ui/ng-components';
import { PoTableModule } from '@po-ui/ng-components';
import { PoModalModule } from '@po-ui/ng-components';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PoNotificationModule } from '@po-ui/ng-components';
import { PoButtonModule } from '@po-ui/ng-components';
import { PoDividerModule } from '@po-ui/ng-components';
import { PoLoadingModule } from '@po-ui/ng-components';
import { RolesComponent } from './roles/roles.component';
import { UsersComponent } from './users/users.component';
import { PoFieldModule } from '@po-ui/ng-components';
import { PoAvatarModule } from '@po-ui/ng-components';
import { RoleAddComponent } from './roles/role-add/role-add.component';
import { RoleEditComponent } from './roles/role-edit/role-edit.component';
import { RoleListComponent } from './roles/role-list/role-list.component';
import { UserAddComponent } from './users/user-add/user-add.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { CreateRouteComponent } from './create-route/create-route/create-route.component';

@NgModule({
  declarations: [
    UserListComponent,
    RoleListComponent,
    UserEditComponent,
    UserAddComponent,
    RoleEditComponent,
    RoleAddComponent,
    RolesComponent,
    UsersComponent,
    CreateRouteComponent,
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    PoPageModule,
    PoBreadcrumbModule,
    PoTableModule,
    PoModalModule,
    PoFieldModule,
    FormsModule,
    ReactiveFormsModule,
    PoNotificationModule,
    PoButtonModule,
    PoDividerModule,
    PoLoadingModule,
    PoAvatarModule,
    PoInfoModule,
    PoLoadingModule,
    PoContainerModule,
  ]
})
export class SettingsModule { }
