import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleListComponent } from './roles/role-list/role-list.component';
import { RoleAddComponent } from './roles/role-add/role-add.component';
import { RoleEditComponent } from './roles/role-edit/role-edit.component';
import { RolesComponent } from './roles/roles.component';
import { UsersComponent } from './users/users.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { UserAddComponent } from './users/user-add/user-add.component';
import { UserEditComponent } from './users/user-edit/user-edit.component';
import { CreateRouteComponent } from './create-route/create-route/create-route.component';

const routes: Routes = [
  {
    path: 'users', component: UsersComponent, children: [
      { path: '', component: UserListComponent },
      { path: 'add', component: UserAddComponent },
      { path: ':action/:user_id', component: UserEditComponent },
    ]
  },
  {
    path: 'roles', component: RolesComponent, children: [
      { path: '', component: RoleListComponent },
      { path: 'add', component: RoleAddComponent },
      { path: ':action/:id', component: RoleEditComponent }
    ]
  },
  {
    path: 'create-route', component: CreateRouteComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
