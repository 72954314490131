import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StockRoutingModule } from './stock-routing.module';
import { AddTransferComponent } from './stock-transfer/add-transfer/add-transfer.component';
import { PoAccordionModule, PoButtonModule, PoContainerModule, PoDialogModule, PoDividerModule, PoDynamicModule, PoFieldModule, PoInfoModule, PoLoadingModule, PoModalModule, PoPageModule, PoTableModule, PoTabsModule } from '@po-ui/ng-components';
import { ViewCancelTransferComponent } from './stock-transfer/view-cancel-transfer/view-cancel-transfer.component';
import { StockTransferComponent } from './stock-transfer/stock-transfer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { GlobalComponentsModule } from '../global-components/global-components.module';
import { ContainerComponent } from './container/container.component';
import { ContainerEditComponent } from './container/container-edit/container-edit.component';
import { AddItemContainerComponent } from './container/add-item-container/add-item-container/add-item-container.component';



@NgModule({
  declarations: [
    AddTransferComponent,
    ViewCancelTransferComponent,
    StockTransferComponent,
    ContainerComponent,
    ContainerEditComponent,
    AddItemContainerComponent,
  ],
  imports: [
    CommonModule,
    StockRoutingModule,
    PoPageModule,
    PoInfoModule,
    PoButtonModule,
    PoTableModule,
    PoLoadingModule,
    PoModalModule,
    PoContainerModule,
    ReactiveFormsModule,
    PoFieldModule,
    RouterModule,
    FormsModule,
    PoDividerModule,
    PoDialogModule,
    GlobalComponentsModule,
    PoDynamicModule,
    PoTabsModule,
    PoAccordionModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class StockModule { }
