<po-page-default p-title="Product Labels" [p-breadcrumb]="page.breadcrumb">
    <div [formGroup]="productForm" class="po-row" (keyup.enter)="searchByTerm()">
        <div class="po-row">
            <div class="positionRelative po-md-3">
                <po-select p-label="Price List *" [p-options]="priceListOptions" [p-required]="true" formControlName="priceList">
                </po-select>
            </div>
            <div class="positionRelative po-md-3">
                <po-select p-label="Select" [p-options]="selectOptions" [p-required]="true" formControlName="generateBy" (p-change)="clearOnSelectChange()">
                </po-select>
            </div>

            <div *ngIf="controls.generateBy.value === 'po'" class="positionRelative po-md-3">
                <po-input name="input" p-label="Purchase Order" p-placeholder="" class="paddingInput" formControlName="po"></po-input>
                <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" (p-click)="openModalList(controls.generateBy.value, 'po')">
                </po-button>
                <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger" (p-click)="limparField(controls,'po')"></po-button>
            </div>

            <div *ngIf="controls.generateBy.value === 'container'" class="positionRelative po-md-3">
                <po-input name="input" p-label="Container" p-placeholder="" class="paddingInput" formControlName="container"></po-input>
                <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" (p-click)="openModalList(controls.generateBy.value, 'container')">
                </po-button>
                <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger" (p-click)="limparField(controls,'container')"></po-button>
            </div>
            <!--
            <div class="positionRelative po-md-3">
                <po-input name="input" p-label="Price List" p-placeholder="" class="paddingInput" formControlName="priceList"></po-input>
                <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" (p-click)="openModalList('price_list', 'priceList')">
                </po-button>
                <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger" (p-click)="limparField(controls,'priceList')"></po-button>
            </div> -->

            <div *ngIf="controls.generateBy.value === 'warehouse'" class="positionRelative po-md-3">
                <po-input name="input" p-label="Warehouse" p-placeholder="" class="paddingInput" formControlName="warehouse"></po-input>
                <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" (p-click)="openModalList(controls.generateBy.value, 'warehouse')">
                </po-button>
                <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger" (p-click)="limparField(controls,'warehouse')"></po-button>
            </div>
            <div class="positionRelative po-md-3">
                <po-input name="input" p-label="Product From" p-placeholder="" class="paddingInput" formControlName="productFrom"></po-input>
                <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" (p-click)="openModalList('PRODUCT', 'productFrom')">
                </po-button>
                <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger" (p-click)="limparField(controls,'productFrom')"></po-button>
            </div>

            <div class="positionRelative po-md-3">
                <po-input name="input" p-label="Product To" p-placeholder="" class="paddingInput" formControlName="productTo"></po-input>
                <po-button class="buttonInput" p-label="" p-icon="po-icon po-icon-search" (p-click)="openModalList('PRODUCT', 'productTo')">
                </po-button>
                <po-button class="buttonClose" p-label="" p-icon="po-icon-close" p-type="danger" (p-click)="limparField(controls,'productTo')"></po-button>
            </div>

            <po-button class="po-md-3 po-mt-4" p-label="Search" p-type="primary" p-icon="po-icon po-icon-search" (p-click)="searchByTerm()"></po-button>
        </div>
    </div>

    <div *ngIf="table.items.length > 0 else empty">
        <div class="po-row">
            <form [formGroup]="formTableActions" class="po-md-12">
              <po-divider class="po-md-7 po-mt-2" [p-label]="labelDivider"></po-divider>
              <po-switch class="po-md-1" p-label="Show Price ?" formControlName="mostrarPreco" p-label-on="Yes"
                p-label-off="No"></po-switch>
              <po-button class="po-md-2 po-mt-4" p-label="Generate Labels" p-type="primary"
                (p-click)="generateProductLabels()"></po-button>
              <po-button class="po-md-2 po-mt-4" p-label="Excel" p-type="primary" p-icon="po-icon-export"
                [p-disabled]="table.items.length <= 0" (p-click)="exportExcel()"></po-button>
            </form>
            <po-table class="po-md-12" [p-hide-text-overflow]="true" [p-columns]="table.columns" [p-items]="table.items" [p-selectable]="true" [p-single-select]="false" [p-sort]="true" [p-striped]="true" [p-height]="table.height" (p-selected)="selectedItem($event)"
                (p-unselected)="unSelectedItem($event)" (p-all-selected)="allSelected($event)" (p-all-unselected)="unAllSelected()">
                <ng-template p-table-cell-template let-column="column" let-row="row">
                    <po-decimal *ngIf="column.property === 'C7_QUANT'" [p-decimals-length]="0" [ngModel]="row.C7_QUANT" (p-change-model)="row.C7_QUANT = $event" [p-min]="1"></po-decimal>
                </ng-template>
            </po-table>
        </div>
        <app-pagination *ngIf="pagination.disable" (totalItems)="pagination.totalItems" [itemsPerPage]="pagination.limit" (currentPage)="pagination.page" (pageChanged)="pageEvent($event)"></app-pagination>
    </div>
    <ng-template #empty>
        <div [hidden]="isFirstRequest" class=container>
            <p> No data found, please try again </p>
        </div>
    </ng-template>
</po-page-default>

<po-loading-overlay *ngIf="loading" p-text="Loading, please wait ...">
</po-loading-overlay>

<po-modal #modalSearch p-title="Search {{titlePopup}}" [p-primary-action]="closeModalSearch">

    <po-dynamic-form #dynamicFormPopUp [p-fields]="fields" [p-value]="formValue">
    </po-dynamic-form>
    <po-divider class="po-md-8">
    </po-divider>
    <po-button class="po-md-2" p-icon="po-icon-search" (p-click)="dynamicForm(dynamicFormPopUp.form.value)"></po-button>
    <po-button class="po-md-2" p-type="primary" p-label="Set Value" (p-click)="setListItem()"></po-button>

    <div class="po-row">
        <po-table [p-selectable]="true" [p-single-select]="true" (p-selected)="selectPopUp($event)" (p-unselected)="unselectPopUp($event)" [p-hide-columns-manager]="true" [p-columns]="tableSearchPopup.columns" [p-items]="tableSearchPopup.items" [p-height]="tableSearchPopup.height"
            [p-hide-text-overflow]="true" [p-sort]="false" [p-striped]="true" [p-loading]="tableSearchPopup.loading" [p-literals]="tableSearchPopup.customLiterals">
        </po-table>
    </div>
</po-modal>
