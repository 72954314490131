import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { PoPageLoginModule, PoTemplatesModule } from '@po-ui/ng-templates';
import {
  PoDynamicModule,
  PoModule,
  PoPageModule,
  PoPopoverModule,
} from '@po-ui/ng-components';
import { RouterModule } from '@angular/router';
import { StockModule } from './stock/stock.module';
import { AppRoutingModule } from './app-routing.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PurchaseModule } from './purchase/purchase.module';
import { PurchaseRoutingModule } from './purchase/purchase-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './authentication/interceptor/interceptor.service';
import { GlobalComponentsModule } from './global-components/global-components.module';
import { SettingsModule } from './settings/settings.module';
import { SettingsRoutingModule } from './settings/settings-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LabelsModule } from './labels/labels.module';
import { LabelsRoutingModule } from './labels/labels-routing.module';
import { CommercialModule } from './commercial/commercial.module';
import { CommercialRoutingModule } from './commercial/commercial-routing.module';
import { GoSalesRoutingModule } from './goSales/go-sales-routing.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    PoTemplatesModule,
    PoModule,
    RouterModule.forRoot([]),
    PoPageModule,
    StockModule,
    RouterModule,
    AppRoutingModule,
    PoPageLoginModule,
    DashboardModule,
    ReactiveFormsModule,
    FormsModule,
    PurchaseModule,
    PurchaseRoutingModule,
    GlobalComponentsModule,
    SettingsModule,
    SettingsRoutingModule,
    PoPopoverModule,
    LabelsModule,
    LabelsRoutingModule,
    CommercialModule,
    CommercialRoutingModule,
    GoSalesRoutingModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
