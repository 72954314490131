import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  PoBreadcrumb,
  PoNotificationService,
  PoPageAction,
  PoSelectOption,
  PoTableColumn,
} from '@po-ui/ng-components';
import { CategoriasService } from 'src/app/service/categorias/categorias.service';
import { ColecoesService } from 'src/app/service/colecoes/colecoes.service';
import { EnvioPadraoBackend } from 'src/app/service/envio-padrao-backend';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { LocalidadesService } from 'src/app/service/localidades/localidades.service';
import { RepresentantesService } from 'src/app/service/representantes/representantes.service';
import { Top50ClientesService } from 'src/app/service/top50Clientes/top50-clientes.service';
import { UtilService } from 'src/app/service/util/util.service';

@Component({
  selector: 'app-top-sales',
  templateUrl: './top-sales.component.html',
  styleUrls: ['./top-sales.component.css'],
})
export class TopSalesComponent implements OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private notifcation: PoNotificationService,
    private utilService: UtilService,
    private excelService: ExcelService,
    private top50ClientesService: Top50ClientesService,
    private colecoesService: ColecoesService,
    private representantesService: RepresentantesService,
    private localidadesService: LocalidadesService,
    private categoriasService: CategoriasService
  ) {}

  public page = {
    title: 'Top Sales',
    breadcrumb: {
      items: [
        { label: 'Home' },
        { label: 'Commercial' },
        { label: 'Top Sales' },
      ],
    } as PoBreadcrumb,
    actions: [
      {
        label: 'Excel',
        icon: 'po-icon-download',
        action: () => {
          this.excelService.organizaArrParaExcel(
            this.table.columns,
            this.table.items,
            this.page.title
          );
        },
      },
    ] as Array<PoPageAction>,
  };

  public table = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.5),
  };

  public form = this.fb.group({
    categoria: [''],
    uf: [''],
    codigoMunicipio: [''],
    start: ['', Validators.required],
    end: ['', Validators.required],
    quantidadeItens: ['50'],
    codigoRepresentante: [''],
    colecao: [''],
  });

  public select = {
    colecoes: [] as Array<PoSelectOption>,
    estados: [] as Array<PoSelectOption>,
    cidades: [] as Array<PoSelectOption>,
    representantes: [] as Array<PoSelectOption>,
    categorias: [] as Array<PoSelectOption>,
    top: [
      { label: '25', value: '25' },
      { label: '50', value: '50' },
      { label: '75', value: '75' },
      { label: '100', value: '100' },
      { label: 'ALL', value: 999999999 },
    ] as Array<PoSelectOption>,
  };

  public loading = false as boolean;
  public totalPedidos = 0 as number;

  ngOnInit(): void {
    this.getRepresentantes();
    this.getLocalidades();
    this.getCategorias();
  }

  public get controls() {
    return this.form.controls;
  }

  public getTopData() {
    if (this.form.valid) {
      this.loading = true;
      const params = {
        procedure: true,
        data: this.form.value,
      } as EnvioPadraoBackend;
      this.top50ClientesService.getTop(params).subscribe({
        next: (element: any) => {
          if (element.status) {
            this.table.items = element.data.map((item: any) => {
              item.valoresMensais.map((mensal: any) => {
                item[mensal.anoMes] = mensal.valor;
              });
              return item;
            });
            this.totalPedidos = element.data.reduce(
              (total: any, b: any) => total + b.valorTotal,
              0
            );
            this.table.items.sort((a: any, b: any) => a.ranking - b.ranking);
          } else {
            this.notifcation.information(element.mensagem);
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          this.loading = false;
          console.error(error.error);
          this.notifcation.error(error.error);
        },
      });
    } else {
      this.notifcation.warning('Please fill the form correctly');
    }
  }

  public montaColuna() {
    var startControl = this.controls['start'].value;
    var endControl = this.controls['end'].value;
    if (startControl > endControl) {
      this.notifcation.information('Start date bigger than end date');
      return;
    }
    this.table.columns = [];
    var anoStart = startControl.substr(0, 4);
    var mesStart = startControl.substr(5, 2);
    var anoEnd = endControl.substr(0, 4);
    var mesEnd = endControl.substr(5, 2);
    let table: Array<PoTableColumn> = [];

    let countOrdem = 1;

    let start = parseInt(anoStart + mesStart);
    let end = parseInt(anoEnd + mesEnd);

    while (start !== end + 1) {
      let startString = start.toString() as string;
      if (startString.substr(4) === '13') {
        start = start + 88;
        startString = start.toString();
      }

      let ano = startString.substr(0, 4);
      let mes = startString.substr(4);

      const objTable = {
        label: `${mes}/${ano}`,
        property: `${ano}${mes}`,
        width: '120px',
        ordem: countOrdem,
        type: 'currency',
        format: ' ',
      };
      table = this.utilService.spreadArrayToProperty(table, objTable);
      countOrdem++;
      start++;
    }
    table.sort((a: any, b: any) => b['ordem'] - a['ordem']);

    table.unshift({
      label: 'Sales Rep',
      property: 'nomeVendedor',
      width: '180px',
      type: '',
      format: ' ',
    });
    table.unshift({
      label: 'City',
      property: 'cidade',
      width: '120px',
      type: '',
      format: ' ',
    });
    table.unshift({
      label: 'State',
      property: 'estado',
      width: '100px',
      type: '',
      format: ' ',
    });
    table.unshift({
      label: 'Total Amount',
      property: 'valorTotal',
      width: '120px',
      type: 'currency',
      format: ' ',
    });
    table.unshift({
      label: 'Customer',
      property: 'nomeGrupo',
      width: '180px',
      type: '',
      format: '',
    });
    table.unshift({
      label: 'Top',
      property: 'ranking',
      width: '75px',
      type: '',
      format: '',
    });

    this.table.columns = table;
    this.getTopData();
  }

  private getRepresentantes() {
    this.loading = true;
    const params = {
      procedure: true,
      data: {},
    };
    this.representantesService.getRepresentantes(params).subscribe({
      next: (element: any) => {
        this.select.representantes = element.data.data.map((item: any) => {
          return {
            label: item.nomeFantasia,
            value: item.codigo,
          } as PoSelectOption;
        });
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.error(error.error);
      },
    });
  }

  private getLocalidades() {
    this.loading = true;
    const params = {
      data: {},
      metodo: '',
      procedure: true,
    } as EnvioPadraoBackend;
    this.localidadesService.getLocalidaes(params).subscribe({
      next: (element: any) => {
        this.select.estados = element.data.map((item: any) => {
          return {
            label: `${item.sigla} - ${item.nome}`,
            value: item.sigla,
          } as PoSelectOption;
        });
      },
    });
  }

  private getCategorias() {
    this.loading = true;
    const params = {
      data: {},
      metodo: '',
      procedure: true,
    } as EnvioPadraoBackend;
    this.categoriasService.getCategorias(params).subscribe({
      next: (element: any) => {
        this.select.categorias = element.data.data.map((item: any) => {
          return {
            label: item.descricao,
            value: item.codigo,
          } as PoSelectOption;
        });
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.error(error.error);
        this.notifcation.error(error.error.mensagem);
      },
    });
  }
}
