import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PoInfoOrientation, PoNotification, PoSelectOption, PoTableColumn } from '@po-ui/ng-components';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { PoPageAction } from '@po-ui/ng-components';
import { PoNotificationService } from '@po-ui/ng-components';
import { HttpErrorResponse } from '@angular/common/http';
import { RoleService } from 'src/app/service/role/role.service';
import { UtilService } from 'src/app/service/util/util.service';
import { RolesPagesService } from 'src/app/service/roles-pages/roles-pages.service';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.css'],
})
export class RoleEditComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fb: UntypedFormBuilder,
    private roleService: RoleService,
    private notification: PoNotificationService,
    private utilService: UtilService,
    private rolesPagesService: RolesPagesService
  ) { }

  title: string = '';
  isDisabled: boolean = false;
  roleId: number = 0;
  actions: PoPageAction[] = [];
  isLoaded = false;
  private relativeLink = 'settings/roles';
  // compare: string;
  tableActions: any;
  private selectedParent = {} as any;
  private jsonEx = [];
  public loading = false as boolean;
  orientation: PoInfoOrientation = PoInfoOrientation.Vertical;

  roleForm: UntypedFormGroup = this.fb.group({
    modified: ['', [Validators.required]],
    roleId: ['', [Validators.required]],
    name: ['', [Validators.required]],
    active: [true, [Validators.required]],
    created: ['', [Validators.required]],
  });

  tableParents = {
    columns: [
      {
        label: '.', property: 'subtitle', width: '10%', type: 'subtitle', subtitles: [
          { value: 'vinculado', color: 'color-10', label: 'Linked' },
          { value: 'naoVinculado', color: 'color-07', label: 'Unlinked' },
        ]
      },
      { label: 'Name', property: 'parentName', width: '90%' },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
    literals: {
      noData: 'No Data Found'
    }
  };

  tableChilds = {
    columns: [
      { label: 'Name', property: 'name' },
      { label: 'Route', property: 'route' },
    ] as Array<PoTableColumn>,
    items: [],
    height: this.utilService.calcularHeight(innerHeight, 0.3),
    literals: {
      noData: 'No Data Found'
    }
  };

  selectOptions: PoSelectOption[] = [
    { label: 'Yes', value: 'true' },
    { label: 'No', value: 'false' },
  ];

  ngOnInit() {
    this.route.paramMap
      .subscribe((params: ParamMap) => {
        const action = params.get('action');
        this.roleId = params.get('id') as any;
        this.getRolesPages(this.roleId);
        this.title = `${action} Role`;
        this.isDisabled = action === 'view';

        this.actions = [
          {
            label: 'Save',
            disabled: this.isDisabled,
            icon: 'po-icon po-icon-ok',
            action: () => {
              this.updateRole();
            },
          },
          {
            label: 'Cancel',
            action: () => {
              this.router.navigate([this.relativeLink]);
            },
          },
        ];

        this.isLoaded = false;

        this.roleService.getRoleEspecifica(this.roleId)
          .subscribe(
            (data: any) => {
              const value = data.data;
              this.roleForm.patchValue(value);
              this.roleForm.controls.roleId.setValue(value.id);
              this.isLoaded = true;
            },
            (error: any) => {
              this.notification.error('An error has occured');
              this.router.navigate([this.relativeLink]);
            }
          );
      });
  }

  get controls() {
    return this.roleForm.controls;
  }

  updateRole() {
    const values = this.roleForm.value;
    this.postRolesPages();
    this.roleService
      .updateRole(values)
      .pipe()
      .subscribe({
        next: (data: any) => {
          this.notification.success('Roles sucessfully updated');
          this.router.navigate(['/settings/roles']);
        },
        error: (error: HttpErrorResponse) => {
          this.notification.error(error.error.meta.description);
        }
      });
  }

  public selectRoute(routes: any) {
    this.selectedParent = routes;
    this.tableChilds.items = this.jsonEx
      .filter((ret: any) => routes.parentId === ret.parentId);
  }


  public monta(array: any) {
    let items: any[] = [];
    array.forEach((element: any) => {
      let item = element;
      let pos = items.findIndex((obj) => obj.parentId === item.parentId);
      if (pos === -1) {
        const ret = { parentId: item.parentId, parentName: item.parentName };
        items = this.utilService.spreadArrayToProperty(items,ret);
      }
    });
    return this.criaLegendas(items, array);
  }

  private criaLegendas(parents: Array<any>, childs: Array<any>) {
    parents.forEach((element: any) => {
      const arr = childs.filter((ret) => element.parentId === ret.parentId && ret.$selected === true)
      if (arr.length > 0) {
        element.subtitle = 'vinculado'
      } else {
        element.subtitle = 'naoVinculado'
      }
    })
    return parents;
  }

  public getRolesPages(roleId: string | number) {
    const role = roleId ?? '';
    this.loading = true;
    this.jsonEx = [];
    this.tableChilds.items = [];
    this.selectedParent.$selected = false;
    this.rolesPagesService.getRolesPages(role)
      .subscribe({
        next: (element: any) => {
        this.jsonEx = element.data;
        this.tableParents.items = this.monta(element.data);
        this.loading = false;
      }, error: (err) => {
        console.error(err, 'Error');
        this.notification.error(err);
        this.router.navigate([this.relativeLink]);
      }
      });
  }

  public postRolesPages() {
    this.loading = true;
    const arr = this.jsonEx
      .map((element: any) => {
        return {
          parentName: element.parentName,
          route: element.route,
          roleId: element.roleId,
          name: element.name,
          idPage: element.idPage,
          parentId: element.parentId,
          selected: element.$selected,
        };
      });
    this.rolesPagesService.postRolesPages({ dados: arr })
      .subscribe({
        next: (ret: any) => {
          this.notification.success("OK");
          this.loading = false;
          this.getRolesPages(this.roleId);
        },
        error: (error: string | PoNotification): void => {
          this.loading = false;
          this.notification.error(error);
          console.error(error);
        }
      });
  }
}
