<po-page-default [p-title]="page.title" [p-breadcrumb]="page.breadcrumb" [p-actions]="page.actions">
    <div class="po-row">
        <po-container [p-height]="105" class="po-md-12">
            <po-info class="po-md-2" p-label="Supplier" [p-label-size]="7" p-url="" [p-value]="header.C7_FORNECE">
            </po-info>
            <po-info class="po-md-2" p-label="PO number" [p-label-size]="7" p-url="" [p-value]="header.C7_NUM">
            </po-info>
            <po-info class="po-md-2" p-label="Emission" [p-label-size]="7" p-url="" [p-value]="header.C7_EMISSAO">
            </po-info>
            <po-info class="po-md-1" p-label="Shop" [p-label-size]="7" p-url="" [p-value]="header.C7_LOJA">
            </po-info>
            <po-info class="po-md-3" p-label="Name" [p-label-size]="7" p-url="" [p-value]="header.A2_NOME">
            </po-info>
            <po-info class="po-md-2" p-label="Total Amount" [p-label-size]="7" p-url="" [p-value]="header.TOTAL_AMOUNT">
            </po-info>
        </po-container>
    </div>
    <div class="po-md-12 po-mt-4">
        <po-table [p-hide-text-overflow]="true" [p-sort]="true" [p-striped]="true" [p-columns]="table.columns" [p-items]="table.items" [p-height]="table.height">
            <ng-template p-table-cell-template let-column="column" let-row="row">
                <po-decimal *ngIf="column.property === 'C7_PRECO'" [(ngModel)]="row.C7_PRECO" [p-disabled]="row.disabled" (p-change-model)="calculaValorTotal(row)" p-type="currency" p-format="USD" [p-min]="0"></po-decimal>
                <po-number *ngIf="column.property === 'C7_LOCAL'" [(ngModel)]="row.C7_LOCAL" [p-disabled]="row.disableLocal" (p-change-model)="calculaValorTotal(row)"></po-number>
                <po-input *ngIf="column.property === 'C7_SO'" [(ngModel)]="row.C7_SO"></po-input>
                <po-input *ngIf="column.property === 'C7_OBS'" [(ngModel)]="row.C7_OBS"></po-input>
            </ng-template>
        </po-table>
    </div>
</po-page-default>
<po-loading-overlay *ngIf="loading" [p-screen-lock]="false" p-text="Loading, please wait...">
</po-loading-overlay>
