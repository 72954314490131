import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ListaStatusCodeService {

  constructor(
    private http: HttpClient
  ) { }

  public getLista(parameters: any) {
    parameters.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/lista-status-code`, parameters)
  }
}
