import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PoBreadcrumb, PoBreadcrumbItem, PoDynamicFormField, PoNotificationService, PoPageAction, PoTableColumn, PoTableLiterals } from '@po-ui/ng-components';
import { ContainerService } from 'src/app/service/container/container.service';
import { F3Service } from 'src/app/service/f3/f3.service';
import { UtilService } from 'src/app/service/util/util.service';

@Component({
  selector: 'app-add-item-container',
  templateUrl: './add-item-container.component.html',
  styleUrls: ['./add-item-container.component.css']
})
export class AddItemContainerComponent implements OnInit {

  page = {
    title: 'Add Item Container',
    breadcrumb: {
      items: [
        { label: 'Stock' },
        { label: 'Container' },
        { label: 'Add Container' },
        { label: 'Add Item Container' },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [
      {
        label: 'Save',
        action: () => this.sendValidar(),
        icon: 'po-icon-ok'
      },
      {
        label: 'Back',
        icon: 'po-icon-edit',
        action: () => this.location.back()
      }
    ] as Array<PoPageAction>,
  };

  public formValue = {};
  public formFields = [] as Array<PoDynamicFormField>;

  public tableSearchPopup = {
    columns: [] as PoTableColumn[],
    items: [] as Array<any>,
    height: 250,
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals
  };
  typeListModal = 'PO_CONTAINER';

  public tablePo = {
    columns: [] as PoTableColumn[],
    items: [],
    height: 300,
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals
  };

  public loading = false as boolean;
  public arrSelectedtems: any[] = [];
  constructor(
    private f3Service: F3Service,
    private containerService: ContainerService,
    private notificationService: PoNotificationService,
    private location: Location,
    private utilService: UtilService
  ) { }

  ngOnInit(): void {
    this.createPage();
  }

  dynamicForm() {
    this.searchListPromise(this.typeListModal)
  }

  public searchListPromise(table: any, callback?: any) {
    table = table.toUpperCase();
    this.loading = true;
    const params = {
      metodo: 'listar',
      procedure: false,
      data: {
        table,
        filters: JSON.stringify(this.formValue),
      },
    };
    this.f3Service.getf3data(params).subscribe({
      next: (data: any) => {
        if (data !== null) {
          data.data
            .map((element: any) => {
              if (element.columns) {
                this.tableSearchPopup.columns = element.columns;
              }
              if (element.items) {
                let items = element.items;
                if (items === 1) {
                  items = items.forEach((item: any) => item.$selected = true);
                  this.setListItem()
                }
                this.tableSearchPopup.items = items;
              }
              if (element.filters) {
                this.formFields = element.filters;
              }
            });
        }
        this.loading = false;
        if (typeof callback === 'function') {
          callback(true, false, 'succefuly');
        }
      },
      error: (error: HttpErrorResponse) => {
        this.loading = false;
        if (typeof callback === 'function') {
          callback(false, true, error);
        }
      }
    });
  }

  public selectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
    this.setListItem()
  }

  public unselectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
  }

  validateQuantity(e: any) {

    if (e.C7_QUANT > e.SALDO) {
      e.C7_QUANT = e.SALDO;
      return this.notificationService.warning(`The maximum balance allowed is: : ${e.SALDO} `);
    }
  }

  public setListItem() {
    if (this.tableSearchPopup.fieldValue.$selected) {
      this.tablePo.loading = true;
      const obj = {
        procedure: false,
        metodo: 'BUSCAR_PO',
        data: {
          C7_NUM: this.tableSearchPopup.fieldValue.C7_NUM
        },
      }
      this.containerService.returnContainer(obj)
        .subscribe({
          next: (ret: any) => {
          if (ret !== null) {
            ret.data
              .map((element: any) => {
                if (element.columns) {
                  this.tablePo.columns = element.columns;
                }
                if (element.items) {
                  this.tablePo.items = element.items;
                }
              });
          }
          this.tablePo.loading = false;
        }, error: (err: any) => {
          this.tablePo.loading = false;
        }
      })
    } else {
      this.notificationService.warning('Please select a option');
    }
  }

  selectedItem(event: any) {
    this.arrSelectedtems = this.utilService.spreadArrayToProperty(this.arrSelectedtems,event);
    return this.arrSelectedtems;
  }

  unSelectedItem(event: any) {
    return (this.arrSelectedtems = this.arrSelectedtems.filter(
      (item) => item.$selected === true
    ));
  }

  allSelected(event: any) {
    this.arrSelectedtems = [];
    return (this.arrSelectedtems = event);
  }

  unAllSelected() {
    this.arrSelectedtems = [];
  }

  createPage() {
    this.loading = true;
    this.formValue = {};
    // this.tableSearchPopup.field = field;
    this.tableSearchPopup.items = [];
    // this.searchTerm = '';
    // this.typeListModal = list;
    // this.titlePopup = 'CONTAINER';
    this.searchListPromise('PO_CONTAINER', (status: any, error: any, mensagem: any) => {
      if (status) {
        this.loading = false;
        // this.poModalSearch.open();
      } else {
        if (error === true) {
          this.notificationService.error(mensagem);
        } else if (status === false) {
          this.notificationService.warning(mensagem);
        }
      }
    });
  }

  sendValidar() {
    if (this.arrSelectedtems.length > 0) {
      this.loading = true;
      const obj = {
        procedure: false,
        metodo: 'VALIDAR_PO',
        data: this.arrSelectedtems,
      }
      this.containerService.returnContainer(obj)
        .subscribe({
          next: (ret: any) => {

          if (ret !== null) {
            this.notificationService.success(ret.mensagem);
            this.containerService.setAddItemContainer(ret.data[0].items);
            this.location.back();
          }
          this.loading = false;
        }, error: (err: any) => {
          this.loading = false;
        }})
    } else {
      return this.notificationService.warning('Please select an item from the list');
    }
  }

}
