import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PoBreadcrumb, PoModalComponent, PoNotificationService, PoPageAction, PoSelectOption, PoTableAction, PoTableColumn } from '@po-ui/ng-components';
import { ColecoesService } from 'src/app/service/colecoes/colecoes.service';
import { EnvioPadraoBackend } from 'src/app/service/envio-padrao-backend';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { LocalidadesService } from 'src/app/service/localidades/localidades.service';
import { ProdutosService } from 'src/app/service/produtos/produtos.service';
import { RelacaoClientesService } from 'src/app/service/relacao-clientes/relacao-clientes.service';
import { UtilService } from 'src/app/service/util/util.service';
import { VendasPorProdutoService } from 'src/app/service/vendas-por-produto/vendas-por-produto.service';

@Component({
  selector: 'app-sales-by-product',
  templateUrl: './sales-by-product.component.html',
  styleUrls: ['./sales-by-product.component.css']
})
export class SalesByProductComponent implements OnInit {

  constructor(
    private fb: UntypedFormBuilder,
    private VendasPorProdutoService: VendasPorProdutoService,
    private utilService: UtilService,
    private produtosService: ProdutosService,
    private localidadesService: LocalidadesService,
    private excelService: ExcelService,
    private notificationService: PoNotificationService,
    // private listaSegmentoClienteService: ListaSegmentoClienteService,
    private relacaoClientesService: RelacaoClientesService,
    private colecoesService: ColecoesService
  ) {}


  @ViewChild('modalProdutos', { static: true }) modalProdutos!: PoModalComponent;
  @ViewChild('modalClientes', { static: true }) modalClientes!: PoModalComponent;
  @ViewChild('modalParametros', { static: true }) modalParametros!: PoModalComponent;


  public page = {
    title: 'Sales by Product' as string,
    breadcrumb: {
      items: [
        { label: 'Home' },
        { label: 'Comemrcial' },
        { label: 'Sales by Product' },
      ],
    } as PoBreadcrumb,
    actions: [
      {label: 'Filters', icon: 'po-icon-filter', action: () =>{this.modalParametros.open()}},
      { label: 'Excel', action: () => {this.toExcel()}, icon: 'po-icon-download' },
    ] as Array<PoPageAction>,
  };

  public form = this.fb.group({
    filter: ['', []],
    codigoCliente: ['', []],
    start: ['', [Validators.required]],
    end: ['', [Validators.required]],
    uf: ['', []],
    codigoSegmento: ['', []],
    codigoGrupoEconomico: ['', []],
    lojaCliente: ['', []],
    viewProduto: [''],
    viewCliente: [''],
    codigoColecao: ['']
  });

  public selects = {
    segmento: [] as Array<PoSelectOption>,
    uf: [] as Array<PoSelectOption>,
    grupoEconomico: [] as Array<PoSelectOption>,
    marcas: [] as Array<PoSelectOption>,
    colecoes: [] as Array<PoSelectOption>
  };

  public table = {
    columns: [
      {label: 'Customer', property: 'nomeCliente', width: '20%'},
      {label: 'State', property: 'estado', width: '10%'},
      {label: 'Order', property: 'codigoPedido', width: '10%'},
      {label: 'Product Code', property: 'codigoProduto', width: '10%'},
      {label: 'Product', property: 'descricaoProduto', width: '30%'},
      {label: 'Qty.', property: 'quantidadeProduto', width: '10%', type: 'number'},
      {label: 'Total', property: 'total', width: '10%', type: 'currency', format: ' '},
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.6)
  };

  public pagination = {
    limit: 100,
    total: 0,
    page: 1,
  };


  public modalCliente = {
    filter: '',
    table: {
      columns: [
        {label: 'Trade Name', property: 'nomeFantasia', width: '45%'},
        {label: 'Company Name', property: 'razaoSocial', width: '45%'},
        {label: 'Code', property: 'codigo', width: '10%'},
      ] as Array<PoTableColumn>,
      items: [] as Array<any>,
      height: this.utilService.calcularHeight(innerHeight, 0.3),
      actions: [
        {label: 'Select', icon: 'po-icon-ok', action: (item: any) => {
          this.controls['viewCliente'].setValue(item.razaoSocial);
          this.controls['codigoCliente'].setValue(item.codigo);
          this.controls['lojaCliente'].setValue(item.loja);
          this.modalClientes.close();
        }}
      ] as Array<PoTableAction>,
    },
    pagination: {
      total: 0,
      limit: 30,
      page: 1,
    },
  };

  public modalProduto = {
    filter: '',
    table: {
      columns: [
        {label: 'Code', property: 'codigo', width: '20%'},
        {label: 'Description', property: 'descricao', width: '80%'},
      ] as Array<PoTableColumn>,
      items: [] as Array<any>,
      height: this.utilService.calcularHeight(innerHeight, 0.3),
      actions: [
        {label: 'Select', icon: 'po-icon-ok', action: (item: any) => {
          this.controls['viewProduto'].setValue(item.descricao);
          this.controls['filter'].setValue(item.codigo);
          this.modalProdutos.close();
        }}
      ] as Array<PoTableAction>,
    },
    pagination: {
      total: 0,
      limit: 30,
      page: 1,
    },
  };

  public loading = false as boolean;
  public valorTotal = 0 as number

  ngOnInit() {
    this.getUF();
    this.getColecoes();
    // this.getListaSegmentos();
  }

  get controls() {
    return this.form.controls;
  }

  public getVendasPorProduto() {
    if (this.form.valid) {
      this.modalParametros.close()
      this.loading = true;
      const objEnvio = {
        data: {
          page: this.pagination.page,
          limit: this.pagination.limit,
        },
        metodo: '',
        procedure: true
      } as EnvioPadraoBackend;
      Object.assign(objEnvio.data, this.form.value)
      this.VendasPorProdutoService.getVendasPorProduto(objEnvio).subscribe({
        next: (element: any) => {
          if(element.status) {
            this.table.items = element.data.data;
            this.pagination.total = element.data.total;
            this.utilService.emitTotalItems.emit(element.data.total)
            this.valorTotal = element.data.totalPedidos;
          } else {
            this.notificationService.warning(element.mensagem)
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error.error);
          this.loading = false;
        },
      });
    } else {
      this.notificationService.warning(
        'Please fill the form correctly'
      );
    }
  }

  public changePageData(page: number) {
    this.pagination.page = page;
    this.getVendasPorProduto();
  }


  public getClientes() {
      this.loading = true;
      const objEnvio = {
        data: {
          page: this.modalCliente.pagination.page,
          limit: this.modalCliente.pagination.limit,
          codigoCliente: this.modalCliente.filter.toUpperCase() //tem esse nome porem filtra por tudo
        },
        metodo: '',
        procedure: true
      } as EnvioPadraoBackend;
      this.relacaoClientesService.getRelacaoClientes(objEnvio).subscribe({
        next: (element: any) => {
          if (element.status) {
            this.modalCliente.table.items = element.data.data;
            this.modalCliente.pagination.total = element.data.total;
            this.utilService.emitTotalItems.emit(element.data.total)
          } else {
            this.notificationService.information('Not found data');
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error.error);
          this.notificationService.error(error.error);
          this.loading = false;
        },
      });
  }

  public pageChangeCliente(page: number) {
    this.modalCliente.pagination.page = page;
    this.getClientes();
  }

  public getProdutos() {
      this.loading = true;
      const objEnvio = {
        procedure: true,
        metodo: '',
        data: {
          filter: this.modalProduto.filter,
        }
      } as EnvioPadraoBackend;
      this.produtosService.getProdutos(objEnvio).subscribe({
        next: (element: any) => {
          if (element.status) {
            this.modalProduto.table.items = element.data.data;
            this.modalProduto.pagination.total = element.data.total;
            this.utilService.emitTotalItems.emit(element.data.total)
          }
          if (element.data.data.length === 0){
            this.notificationService.information('Not found data');
          }
          this.loading = false;
        },
        error: (error: HttpErrorResponse) => {
          console.error(error.error);
          this.notificationService.error(error.error);
          this.loading = false;
        },
      });
  }

  private getColecoes() {
    this.loading = true;
    const obj = {
      data: {},
      metodo: '',
      procedure: true
    } as EnvioPadraoBackend
    this.colecoesService.getColecoes(obj)
    .subscribe({
      next: (element: any) => {  
        this.selects.colecoes = element.data.map((item: any) => {
          return {label: item.descricao, value: item.codigoGrupo} as PoSelectOption
        })
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
        this.notificationService.error('O servidor parou de funcionar')
      }
    })
  }

  public pageChangeProduto(page: number) {
    this.modalProduto.pagination.page = page;
    this.getProdutos();
  }

  private getListaSegmentos() {
  //   this.loading = true;
  //   this.listaSegmentoClienteService.getListaSegmentos().subscribe({
  //     next: (data: any) => {
  //       if (!Array.isArray(data)) {
  //         this.selects.segmento = data.response.map((item: any) => {
  //           return {
  //             label: item.descricaoSegmento,
  //             value: item.codigoSegmento,
  //           } as PoSelectOption;
  //         });
  //       } else {
  //         this.notificationService.information(
  //           'Nenhum dado encontrado em Segmentos'
  //         );
  //       }
  //       this.loading = false;
  //     },
  //     error: (error: HttpErrorResponse) => {
  //       this.loading = false;
  //       console.error(error.error);
  //       this.notificationService.error(error.error);
  //     },
  //   });
  }

  private getUF() {
    this.loading = true;
    const objEnvio = {
      data: {},
      metodo: '',
      procedure: true
    } as EnvioPadraoBackend
    this.localidadesService.getLocalidaes(objEnvio)
    .subscribe({
      next: (element: any) => {
        if(element.status) {
          this.selects.uf = element.data.map((item: any) => {
            return {label: `${item.sigla} - ${item.nome}`, value: item.sigla} as PoSelectOption
          })
        } else { 
          this.notificationService.warning('Not found data in states')
        }
        this.loading = false;
      }, error: (error: HttpErrorResponse) => {
        this.loading = false;
        console.error(error.error);
        this.notificationService.error('Error while loading states')
      }
    })
  }

  public cleanProdutos() {
    this.controls['viewProduto'].setValue('');
    this.controls['filter'].setValue('');
  }

  public cleanClientes() {
    this.controls['viewCliente'].setValue('');
    this.controls['codigoCliente'].setValue('');
    this.controls['lojaCliente'].setValue('');
  }

  private toExcel() {
    this.loading = true;
    const objEnvio = {
      page: this.pagination.page,
      limit: 999999,
    };
    Object.assign(objEnvio, this.form.value)
    const obj = {
      data: objEnvio,
      metodo: '',
      procedure: true
    } as EnvioPadraoBackend
    this.VendasPorProdutoService.getVendasPorProduto(obj).subscribe({
      next: (element: any) => {
        this.excelService.organizaArrParaExcel(this.table.columns, element.data.data, this.page.title);
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
      },
    });
  }
}

