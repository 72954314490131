import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Route, RouterModule } from '@angular/router';
import { BoxLabelsComponent } from './box-labels/box-labels.component';
import { ProductLabelsComponent } from './product-labels/product-labels.component';

const routes = [
  {
    path: 'product-label', component: ProductLabelsComponent
  },
  {
    path: 'box-label', component: BoxLabelsComponent,
  }
] as Array<Route>

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class LabelsRoutingModule { }
