import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseJavaApi, SendJavaApi } from '../util/util.service';

@Injectable({
  providedIn: 'root'
})
export class ProductLabelsService {

  constructor(
    private http: HttpClient,
  ) { }

  private endpoint = 'etiquetas'

  returnEtiquetas(param: any): Observable<ResponseJavaApi<any>> {
    param.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.endpoint}`, param) as Observable<ResponseJavaApi<any>>;
  }
}
