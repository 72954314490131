import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, NgForm, Validators } from '@angular/forms';
import {
  PoBreadcrumb,
  PoBreadcrumbItem,
  PoDynamicFormField,
  PoModalAction,
  PoModalComponent,
  PoNotificationService,
  PoPageDefault,
  PoSelectOption,
  PoTableColumn,
  PoTableLiterals,
} from '@po-ui/ng-components';
import { Etiquetas3ColunasService } from 'src/app/service/etiquetas3Colunas/etiquetas3-colunas.service';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { F3Service } from 'src/app/service/f3/f3.service';
import { UtilService } from 'src/app/service/util/util.service';

export enum ProductlabelModal {
  Po = 1,
  Container = 2,
}

@Component({
  selector: 'app-product-labels',
  templateUrl: './product-labels.component.html',
  styleUrls: ['./product-labels.component.css'],
})
export class ProductLabelsComponent implements OnInit {
  constructor(
    private utilService: UtilService,
    private fb: UntypedFormBuilder,
    private productLabelsService: Etiquetas3ColunasService,
    private notificationService: PoNotificationService,
    private f3Service: F3Service,
    private excelService: ExcelService
  ) {}

  public page: PoPageDefault = {
    title: 'Product Label',
    breadcrumb: {
      items: [
        { label: 'Labels' },
        { label: 'Product Label' },
      ] as PoBreadcrumbItem[],
    } as PoBreadcrumb,
  };

  public loading = false;
  public labelDivider = 'Labels PO';
  public arrSelectedtems: any[] = [];
  public table = {
    columns: [
        {
          width: "07%",
          property: "sequencia",
          label: "Line",
          type: "",
        },
        {
          width: "08%",
          property: "po",
          label: "P.O.",
          type: "",
        },
        {
          width: "20%",
          property: "codigo",
          label: "Product",
          type: "",
        },
        {
          width: "20%",
          property: "descricao",
          label: "Description",
          type: "",
        },
        {
          visible: false,
          width: "5%",
          property: "descricaoTamanho",
          label: "Type",
          type: "",
        },
        {
          width: "15%",
          property: "cliente",
          label: "Name",
          type: "",
        },
        {
          width: "10%",
          property: "SO Origin",
          type: "",
        },
        {
          width: "10%",
          property: "numeroContainer",
          label: "Container",
          type: "",
        },
        {
          width: "10%",
          property: "pagina",
          label: "Volume",
          type: "",
        },
        {
          visible: false,
          width: "20%",
          property: "descricaoDetalhada",
          label: "Finishings",
          type: "",
        },
        {
          visible: false,
          width: "10%",
          property: "C7_ITEM",
          label: "Item",
          type: "",
        },
        {
          width: "10%",
          property: "C7_QUANT",
          label: "Quantity",
          type: "cellTemplate",
        },
        {
          visible: false,
          width: "10%",
          property: "B2_LOCAL",
          label: "Warehouse Co",
          type: "",
        },
        {
          visible: false,
          width: "01%",
          property: "quantidadeEtiqueta",
          label: "Qty",
          type: "",
        },
        {
          visible: true,
          width: "10%",
          property: "preco",
          label: "Sales Price",
          type: "currency",
          format:'USD'
        },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.3),
  };
  public pagination = {
    totalItems: 0,
    limit: 100,
    page: 1,
    disable: true as boolean,
  };
  public paginationPopUp = {
    total: 0,
    limit: 50,
    page: 10,
  };

  public fields = [] as Array<PoDynamicFormField>;

  public selectOptions: Array<PoSelectOption> = [
    { label: 'Purchase Order', value: 'po' },
    { label: 'Container', value: 'container' },
    { label: 'Warehouse', value: 'warehouse' },
  ];

  public priceListOptions = [] as Array<PoSelectOption>;

  public productForm = this.fb.group({
    generateBy: ['po', []],
    po: ['', []],
    container: ['', []],
    warehouse: ['', []],
    productFrom: ['', []],
    productTo: ['', []],
    priceList: ['RT', [Validators.required]],
  });

  public formTableActions = this.fb.group({
    mostrarPreco: [false],
  });

  // ********************************************************************************************************************
  //                                                 SEARCH MODAL
  // ********************************************************************************************************************
  @ViewChild('modalSearch', { static: true })
  poModalSearch!: PoModalComponent;

  closeModalSearch: PoModalAction = {
    action: () => {
      this.poModalSearch.close();
    },
    label: 'Close',
    danger: true,
  };

  public tableSearchPopup = {
    columns: [] as PoTableColumn[],
    items: [],
    height: 250,
    loading: false,
    field: '',
    fieldValue: {} as any,
    form: {} as NgForm,
    customLiterals: {
      loadingData: 'Loading, please wait ...',
      noColumns: 'No Columns',
      noData: 'No data found',
    } as PoTableLiterals,
  };

  public typeListModal: ProductlabelModal = 1;
  public titlePopup = '';
  public formValue = {};
  public isFirstRequest = true;

  // ********************************************************************************************************************
  //                                                  METHODS
  // ********************************************************************************************************************

  ngOnInit(): void {
    this.getPriceList();
    this.productForm.valueChanges.subscribe((data) => {
      this.labelDivider = `Labels ${data.generateBy.toUpperCase()}`;
    });
  }

  get controls() {
    return this.productForm.controls;
  }

  private returnProductLabels() {
    if (this.productForm.valid) {
      this.arrSelectedtems = [];
      this.isFirstRequest = false;
      this.loading = true;
      const obj = {
        metodo: 'listar',
        procedure: false,
        data: {
          limit: this.pagination.limit,
          page: this.pagination.page,
          produto: [
            this.controls.productFrom.value ?? '',
            this.controls.productTo.value ?? '',
          ],
          container: this.controls.container.value ?? '',
          po: this.controls.po.value ?? '',
          warehouse: this.controls.warehouse.value ?? '',
          pricelist: this.controls.priceList.value ?? '',
        },
      };
      this.productLabelsService.returnEtiquetas(obj).subscribe({
        next: (data) => {
          const returnLabels = data.data;
          returnLabels.map((element: any) => {
            if (element.items) {
              this.table.items = []
              this.table.items = element.items
              .map((item: any) => {
                if (!this.productForm.controls.po.value) {
                  item.C7_QUANT = 1
                }
                return item
              })
              if (this.table.items.length <= 0) {
                this.notificationService.warning(
                  'No data found, please try again'
                );
              }
            }
            if (element.total) {
              this.pagination.totalItems = element.total;
              this.utilService.emitTotalItems.emit(this.pagination.totalItems);
            }
          });
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
        }
      });
    } else {
      this.notificationService.information('Please fill the form correctly!');
    }
  }

  selectedItem(event: any) {
    this.arrSelectedtems = this.utilService.spreadArrayToProperty(this.arrSelectedtems,event);
  }


  unSelectedItem(event: any) {
    return (this.arrSelectedtems = this.arrSelectedtems.filter(
      (item) => item.$selected === true
    ));
  }

  allSelected(event: any) {
    this.arrSelectedtems = [];
    return (this.arrSelectedtems = event);
  }

  unAllSelected() {
    this.arrSelectedtems = [];
  }

  searchByTerm() {
    this.returnProductLabels();
  }

  generateProductLabels() {
    if (this.arrSelectedtems.length > 0) {
      this.loading = true;
      const obj = {
        procedure: false,
        metodo: 'GERAR',
        data: this.duplicateItems(this.arrSelectedtems.map((item:any) =>{
          return {...item, mostrarPreco: this.formTableActions.get('mostrarPreco')!.value}
        })),
      };
      this.productLabelsService.returnEtiquetas(obj).subscribe({
       next: (element: any) => {
          setTimeout(() => {
            window.open(element.data.url, '_blank');
            this.loading = false;
          }, 800);
        },
       error: (error) => {
          console.error(error);
          this.notificationService.error('Error while generating labels');
        }
    });
    } else {
      return this.notificationService.warning(
        'Please select an item from the list'
      );
    }
  }


  // ********************************************************************************************************************
  //                                                METODOS MODAL  LISTA
  // ********************************************************************************************************************

  public searchList() {
    let termo;
    this.searchListPromise(termo, (status: any, error: any, mensagem: any) => {
      if (error === true) {
        this.notificationService.error(mensagem);
      } else if (status === false) {
        this.notificationService.warning(mensagem);
      }
    });
  }

  public searchListPromise(table: any, callback?: any) {
    this.loading = true;
    table = table.toUpperCase();
    this.tableSearchPopup.loading = true;
    const params = {
      metodo: 'listar',
      procedure: false,
      data: {
        table,
        filters: JSON.stringify(this.formValue),
      },
    };
    switch (table) {
      case 'PO':
        this.titlePopup = 'Purchase Order';
        break;
      case 'PRODUCT':
        this.titlePopup = 'Product';
        break;
      case 'CONTAINER':
        this.titlePopup = 'Container';
        break;
      case 'WAREHOUSE':
        this.titlePopup = 'Warehouse';
        break;
    }
    this.f3Service.getf3data(params).subscribe({
      next: (data: any) => {
        if (data !== null) {
          data.data.map((element: any) => {
            if (element.columns) {
              this.tableSearchPopup.columns = element.columns;
            }
            if (element.items) {
              this.tableSearchPopup.items = element.items;
            }
            if (element.filters) {
              this.fields = element.filters;
            }
          });
        }
        this.tableSearchPopup.loading = false;
        if (typeof callback === 'function') {
          callback(true, false, 'succefuly');
          // callback(false, true, error);
        }
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        this.tableSearchPopup.loading = false;
        if (typeof callback === 'function') {
          callback(false, true, error);
        }
        this.loading = false;
      }
    });
  }

  public openModalList(list: any, field: any) {
    this.formValue = {};
    this.tableSearchPopup.field = field;
    this.tableSearchPopup.items = [];
    this.typeListModal = list;
    this.searchListPromise(list, (status: any, error: any, mensagem: any) => {
      if (status) {
        this.poModalSearch.open();
      } else {
        if (error === true) {
          this.notificationService.error(mensagem);
        } else if (status === false) {
          this.notificationService.warning(mensagem);
        }
      }
    });
  }

  public selectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
  }

  public unselectPopUp(event: any) {
    this.tableSearchPopup.fieldValue = event;
  }

  public setListItem() {
    if (this.tableSearchPopup.fieldValue.$selected) {
      const field = this.tableSearchPopup.field;
      this.controls[field].setValue(this.tableSearchPopup.fieldValue.codigo);
      this.poModalSearch.close();
    } else {
      this.notificationService.warning('Please select a option');
    }
  }

  public limparField(controls: any, field: any) {
    controls[field].setValue('');
  }

  public pageEvent(page: number) {
    this.pagination.page = page;
    this.returnProductLabels();
  }

  dynamicForm(form: NgForm) {
    this.searchListPromise(this.typeListModal);
  }

  clearOnSelectChange() {
    this.controls.po.reset();
    this.controls.container.reset();
    this.controls.warehouse.reset();
  }

  duplicateItems(array: any): any {
    let arr: Array<any> = [];
    array.forEach((ret: any) => {
      const element = [ret];
      let i = 0;
      element.map((item: any) => {
        item.quantidadeEtiqueta = item.C7_QUANT
        while (i < item.quantidadeEtiqueta) {
          const obj = {
            ...item,
            C7_QUANT: 1,
          };
          arr = this.utilService.spreadArrayToProperty(arr,obj);
          i++;
        }
      });
    });
    return arr;
  }

  public exportExcel() {
    this.loading = true;

    const arr = this.table.items.map((element: any) => {
      return {
        VOLUME: element.pagina,
        'PRODUCT CODE': element.codigo,
        DESCRIPTION: element.descricao,
        BALANCE: element.C7_QUANT,
        WAREHOUSE: element.B2_LOCAL,
        CONTAINER: element.numeroContainer,
        PO: element.po,
      };
    });
    this.excelService.exportAsExcelFile(arr, 'product_labels');
    setTimeout((a: any) => {
      this.loading = false;
    }, 1000);
  }

  private getPriceList() {
    this.loading = true
    const obj = {
      metodo: 'listar',
      procedure: false,
      data: {
        table: 'PRICE_LIST',
        filters: '{}',
      },
      homologacao: false,
    };
    this.f3Service.getf3data(obj).subscribe((element) => {
      element.data.map((data: any) => {
        if (data.items) {
          this.priceListOptions = data.items.map((item: any) => {
            return {label: `${item.DA0_CODTAB} - ${item.DA0_DESCRI}`, value: item.codigo} as PoSelectOption
          });
        }
      });
      this.loading = false;
    });
  }
}
