import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { ResponseApi, UtilService } from '../util/util.service';

export interface User {
  username: string;
  created: number;
  name: string;
  modified: number;
  id: number;
  role_id: number;
  active: boolean;
  avatar: string;
  is_representante: boolean;
  id_marca: string;
  password: string;
  id_protheus: string;
}

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private relativeLink = 'users';
  private url = `${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`;

  constructor(
    private http: HttpClient,
    private utilService: UtilService
  ) { }

  /**
   *
   * @param parameters id, name, active
   * @returns users
   */
  getUsers(parameters: any): Observable<ResponseApi<User[]>> {
    // parameters = this.utilService.retornaFiltros(parameters);
    const params: HttpParams = new HttpParams({ fromObject: parameters });
    return this.http.get(`${this.url}`, { params }) as Observable<ResponseApi<User[]>>;
  }

  /**
   *
   * @param userId id do usuário
   * @returns um user
   */
  getUser(userId: number): Observable<ResponseApi<User>> {
    return this.http.get(`${this.url}/${userId}`) as Observable<ResponseApi<User>>;
  }

  /**
   *
   * @param obj recebe um json com os dos do usuário
   * @returns mensagem de error
   */
  addUser(obj: any): Observable<ResponseApi<User[]>> {
    return this.http.post(`${this.url}`, obj) as Observable<ResponseApi<User[]>>;
  }

  changePassword(obj: any) {
    return this.http.put(`${environment.URL.APIPRIVATE}/v1/password`, obj);
  }

  /**
   *
   * @param id id usuário
   * @param obj formulário com os dados do usuário
   */
  updateUser(id: any, obj: any) {
    return this.http.put(`${this.url}/${id}`, obj);
  }

  /**
   *
   * @param userName nome do usuário
   * @returns HTTP 200 OU 404, se 200 o usernome existir, se 404 usernome não existe
   *
   */
  compareUserName(userName: string) {
    return this.http.head(`${this.url}/${userName}`);
  }

}
