import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InvoiceSalesOrderService {
  constructor(private http: HttpClient) {}

  private readonly endpoint = 'invoice-sales-order';

  public postInvoiceSalesOrder(data: any): Observable<any> {
    return this.http.post(
      `${environment.URL.APIPRIVATE}/v1/${this.endpoint}`,
      data
    ) as Observable<any>;
  }
}
