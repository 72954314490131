import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class F3Service {
  constructor(private http: HttpClient) {}

  private relativeLink = 'f3';

  public getf3data(params: any): Observable<any> {
    params.homologacao = !environment.production;
    return this.http.post(
      `${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`,
      params
    ) as Observable<any>;
  }
}
