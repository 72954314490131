import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'login',
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  {
    path: 'stock',
    loadChildren: () =>
      import('./stock/stock.module').then((m) => m.StockModule),
  },
  {
    path: 'purchase',
    loadChildren: () =>
      import('./purchase/purchase.module').then((m) => m.PurchaseModule),
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'cms',
    loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule),
  },
  {
    path: 'labels',
    loadChildren: () =>
      import('./labels/labels.module').then((m) => m.LabelsModule),
  },
  {
    path: 'commercial',
    loadChildren: () =>
      import('./commercial/commercial.module').then((m) => m.CommercialModule),
  },
  {
    path: 'market-reports',
    loadChildren: () =>
      import('./market-reports/market-reports.module').then(
        (m) => m.MarketReportsModule
      ),
  },
  {
    path: 'financial',
    loadChildren: () =>
      import('./financial/financial.module').then((m) => m.FinancialModule),
  },
  {
    path: 'accounting',
    loadChildren: () =>
      import('./accounting/accounting.module').then((m) => m.AccountingModule),
  },
  {
    path: 'go-sales',
    loadChildren: () =>
      import('./goSales/go-sales.module').then((m) => m.GoSalesModule),
  },
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      relativeLinkResolution: 'legacy',
    }),
    CommonModule,
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
