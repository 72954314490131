import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PedidoItemService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'pedido-item';

  public getPedido(recno: any) {
    const params = new HttpParams({fromObject: recno})
    return this.http.get(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, {params} )
  }

  public postPedido(obj: any) {
    obj.homologacao = false;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.ip}`,obj )
  }
}
