import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GravaClienteService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly apiRoot = `${environment.URL.APIPRIVATE}/v1/grava-cliente`;

  public postGravaCliente(parameters: any) {
    return this.http.post(this.apiRoot, parameters);
  }

}
