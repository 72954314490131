import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EnvioPadraoBackend } from '../envio-padrao-backend';
import { RetornoPadraoBackend } from '../retorno-padrao-backend';

@Injectable({
  providedIn: 'root'
})
export class ListaInvoiceService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'lista-invoice';

  public getListaInvoice(parameters: EnvioPadraoBackend) {
    parameters.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, parameters) as Observable<RetornoPadraoBackend>
  }
}
