import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PoBreadcrumb,
  PoBreadcrumbItem,
  PoNotificationService,
  PoTableAction,
  PoTableColumn,
} from '@po-ui/ng-components';
import { ExcelService } from 'src/app/service/excel/excel.service';
import {
  GoSalesService,
  dataQuotesInterface,
  quotesInterface,
} from 'src/app/service/goSales/go-sales.service';
import { LoadedPageService } from 'src/app/service/loadedPage/loaded-page.service';
import { UtilService } from 'src/app/service/util/util.service';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.css'],
})
export class QuotesComponent implements OnInit {
  constructor(
    private utilService: UtilService,
    private goSalesService: GoSalesService,
    private notificationService: PoNotificationService,
    private excelService: ExcelService,
    private loadedPageService: LoadedPageService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  public isLoading: boolean = false;

  public page = {
    title: 'Quotes',
    breadcrumb: {
      items: [
        {
          label: 'Home',
        },
        {
          label: 'goSales',
        },
        {
          label: 'Quotes',
        },
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [
      {
        label: 'Excel',
        icon: 'po-icon po-icon-download',
        action: () => this.toExcel(),
      },
    ],
  };

  public table = {
    columns: [
      { label: 'Seller Code', property: 'codRepresentante', width: '120px' },
      { label: 'Seller Name', property: 'apelido', width: '160px' },
      { label: 'Customer Code', property: 'codCliente', width: '100px' },
      { label: 'Company Name', property: 'razaoSocial', width: '120px' },
      {
        label: 'Emission Date',
        property: 'dataEmissao',
        width: '100px',
        type: 'date',
        format: 'MM/dd/yyyy',
      },
      { label: 'License ID', property: 'idLicenca', width: '100px' },
      { label: 'Quotation ID', property: 'idPedido', width: '100px' },
      {
        label: 'Amount',
        property: 'totalPedido',
        width: '100px',
        type: 'number',
        format: '1.2-2',
      },
    ] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.6),
    actions: [
      {
        label: 'Details',
        icon: 'po-icon-eye',
        action: (row: dataQuotesInterface) =>
          this.mudarRota('details', row.idPedido),
      },
    ] as Array<PoTableAction>,
  };

  async ngOnInit() {
    this.getQuotes();
  }

  /**
   * @description Método responsável por buscar as cotações.
   */
  public getQuotes() {
    try {
      this.isLoading = true;

      this.goSalesService.post().subscribe({
        next: (data: quotesInterface) => {
          try {
            if (data.status) {
              this.table.items = data.response;
            } else {
              this.notificationService.warning(data.mensagem);
            }
            this.isLoading = false;
          } catch (error: any) {
            this.notificationService.error(error);
            this.isLoading = false;
          }
        },
        error: (error: HttpErrorResponse) => {
          this.notificationService.error(error.error);
          this.isLoading = false;
        },
      });
    } catch (error: any) {
      this.notificationService.error(error);
    }
  }

  /**
   * @description Método responsável por exportar os dados para o excel.
   */
  private toExcel(): void {
    try {
      this.utilService.formatDataFromArray(this.table.items, 'entryDate');
      this.excelService.organizaArrParaExcel(
        this.table.columns,
        this.table.items,
        'Quotes'
      );
    } catch (error: any) {
      this.notificationService.error(error);
    }
  }

  /**
   * @param rota rota desejada
   * @partam id id do pedido
   * @description Método responsável por mudar a rota para a página de detalhes.
   */
  private mudarRota(rota: string, id: number) {
    this.router.navigate([`${rota}/`, id], {
      relativeTo: this.activatedRoute,
    });
  }
}
