import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactCustomerService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly apiRoot = `${environment.URL.APIPRIVATE}/v1/contact-customer`;

  public post(params: any) {
    params.homologacao = !environment.production;
    return this.http.post(this.apiRoot, params);
  }
}
