<po-page-default [p-title]="page.title" [p-actions]="page.actions" [p-breadcrumb]="page.breadcrumb" [p-literals]="page.literals">
    <po-tabs>
        <po-tab p-label="Container" [p-active]="true">
            <div [formGroup]="fieldsContainerForm">
                <po-input class="po-md-3" formControlName="ZR2_NUM" p-label="Container" [p-disabled]="disabledZR2FieldsEdit" [p-maxlength]=6 p-required [p-minlength]=1 p-clean></po-input>
                <po-input class="po-md-3" formControlName="ZR2_BOL" p-label="Bill of Lading" [p-disabled]="disabledZR2FieldsOthers" [p-maxlength]=20 [p-minlength]=1></po-input>
                <po-datepicker class="po-md-3" formControlName="ZR2_ETD" p-clean="true" p-format="mm/dd/yyyy" [p-disabled]="disabledZR2FieldsOthers" p-label="ETD"></po-datepicker>
                <po-datepicker class="po-md-3" formControlName="ZR2_ETA" p-clean="true" p-format="mm/dd/yyyy" [p-disabled]="disabledZR2FieldsOthers" [p-min-date]="controls.ZR2_ETD.value" p-label="ETA">
                </po-datepicker>
                <po-datepicker class="po-md-3" formControlName="ZR2_DWAREH" p-clean="true" p-format="mm/dd/yyyy" [p-disabled]="disabledZR2FieldsOthers" [p-min-date]="controls.ZR2_ETD.value" p-label="Received Warehouse">
                </po-datepicker>
                <po-datepicker class="po-md-3" formControlName="ZR2_DSYSTE" p-clean="false" p-format="mm/dd/yyyy" p-disabled=true p-label="Received System"></po-datepicker>
                <po-decimal class="po-md-3" p-label="Amount" [p-maxlength]=16 [p-decimals-length]=2 p-locale="en" p-disabled="true" formControlName="ZR2_AMOUNT"></po-decimal>
                <po-decimal class="po-md-3" p-label="Expenses" [p-maxlength]=16 [p-decimals-length]=2 p-locale="en" p-disabled="true" formControlName="EXPENSES"></po-decimal>
            </div>

            <div class="po-row">
                <po-divider class="po-md-10" p-label="Items"></po-divider>
                <po-button class="po-md-2 po-mt-2" p-type="primary" p-icon="po-icon-plus" p-label="Add Item" (p-click)="openModalList('PO_CONTAINER','PO_CONTAINER')" [p-disabled]="tipoTela === 'view' || tipoTela === 'delete' ? true : false"></po-button>
            </div>
            <po-table #poTableContainer [p-sort]="true" class="po-md-12" [p-columns]="table.columns" [p-striped]="true" [p-items]="table.items" [p-height]="table.height" [p-hide-text-overflow]="true"></po-table>

        </po-tab>
        <po-tab p-label="Expenses">

            <div class="po-row">
                <po-divider class="po-md-10" p-label="Items"></po-divider>
                <po-button class="po-md-2 po-mt-2" p-type="primary" p-icon="po-icon-plus" p-label="Add Item" (p-click)="addExpensesItem()" [p-disabled]="tipoTela === 'view' || tipoTela === 'delete' ? true : false">
                </po-button>
            </div>
            <po-table #poTableExpenses [p-sort]="true" class="po-   md-12" [p-columns]="tableExpenses.columns" [p-striped]="true" [p-items]="tableExpenses.items" [p-height]="tableExpenses.height"></po-table>
        </po-tab>
    </po-tabs>
</po-page-default>

<po-loading-overlay *ngIf="loading" p-text="Loading, please wait ...">
</po-loading-overlay>

<po-modal #modalSearch p-title="Search {{titlePopup}}" [p-primary-action]="closeModalSearch" class="limitModal">


    <div *ngIf="formFields.length > 0">
        <po-dynamic-form #dynamicFormPopUp [p-fields]="formFields" [p-value]="formValue"></po-dynamic-form>
        <po-divider class="po-md-10"></po-divider>
        <po-button class="po-md-2" p-icon="po-icon-search" (p-click)="dynamicForm()"></po-button>
    </div>

    <div class="po-row">
        <po-table [p-selectable]="true" [p-single-select]="true" (p-selected)="selectPopUp($event)" (p-unselected)="unselectPopUp($event)" [p-hide-columns-manager]="true" [p-columns]="tableSearchPopup.columns" [p-items]="tableSearchPopup.items" [p-hide-text-overflow]="true"
            [p-sort]="true" [p-striped]="true" [p-literals]="tableSearchPopup.customLiterals" [p-height]="tableSearchPopup.height" [p-loading]="tableSearchPopup.loading">
        </po-table>
    </div>

</po-modal>

<po-modal #modalItensPo p-title="" [p-primary-action]="confirmModalItensPo" [p-secondary-action]="closeModalItensPo" [p-click-out]="false" [p-hide-close]="true" class="limitModal">
    <table class="table text-center po-table-main-container po-table">
        <thead>
            <tr>
                <th class="po-table-header">
                    <po-checkbox [(ngModel)]="tablePo.selectedAll" (p-change)="onChangeCheckBox($event, true)"> </po-checkbox>
                </th>
                <th class="po-table-header">Item</th>
                <th class="po-table-header">Product</th>
                <th class="po-table-header">Description</th>
                <th class="po-table-header">Balance</th>
                <th class="po-table-header">Quantity</th>
                <th class="po-table-header">Price R$</th>
                <th class="po-table-header">Unit Price</th>
                <th class="po-table-header">Total Amount</th>
                <th class="po-table-header">Container</th>
                <th class="po-table-header">SO Origin</th>
                <th class="po-table-header">Nota Fiscal</th>
                <th class="po-table-header">Volumes</th>
                <th class="po-table-header">Notes</th>
            </tr>
        </thead>

        <tbody *ngIf="tablePo.items.length > 0">
            <tr *ngFor="let item of tablePo.items" class="altura-linha">

                <td class="po-table-column po-table-striped">
                    <po-checkbox (p-change)="onChangeCheckBox($event,false)" [(ngModel)]="item.$selected"> </po-checkbox>
                </td>
                <td class="po-table-column po-table-striped">{{item?.C7_ITEM}}</td>
                <td class="po-table-column po-table-striped text-ellipsis">{{item?.C7_PRODUTO}}</td>
                <td class="po-table-column po-table-striped text-ellipsis">{{item?.C7_DESCRI}}</td>
                <td class="po-table-column po-table-striped">{{item?.SALDO}}</td>
                <td class="po-table-column po-table-striped">
                    <po-decimal [(ngModel)]="item.C7_QUANT" (p-change)="validateQuantity(item,true)" p-locale="en" [p-min]=1 [p-max]="item.SALDO"></po-decimal>
                </td>
                <td class="po-table-column po-table-striped">
                    <po-decimal [(ngModel)]="item.ZR3_VLRBRL" (p-change)="validateUnitPrice(item)" p-locale="pt" [p-min]=0>
                    </po-decimal>
                </td>
                <td class="po-table-column po-table-striped">{{item?.C7_PRECO | currency: 'USD'}}</td>
                <td class="po-table-column po-table-striped">{{item?.C7_TOTAL | currency: 'USD'}}</td>
                <td class="po-table-column po-table-striped">{{item?.C7_XCONTR}}</td>
                <td class="po-table-column po-table-striped">{{item?.C7_SO}}</td>
                <td class="po-table-column po-table-striped">
                    <po-input [(ngModel)]="item.ZR3_DOC"></po-input>
                </td>
                <td class="po-table-column po-table-striped">
                    <po-number [(ngModel)]="item.ZR3_VOL"></po-number>
                </td>
                <td class="po-table-column po-table-striped">
                    <po-input [(ngModel)]="item.ZR3_OBS"></po-input>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="po-row">
        <div class="po-md-7"></div>
        <po-info p-label="Total Selected" class="po-md-2" p-value="{{this.totalAmount | currency: 'USD'}}"></po-info>
    </div>
    <po-loading-overlay *ngIf="loading" p-text="Loading, please wait ...">
    </po-loading-overlay>
</po-modal>

<po-modal #modalExpenses p-click-out="false" [p-hide-close]="true" p-title="Add Item" class="limitModal" [p-primary-action]="confirmPoModal" [p-secondary-action]="closePoModal">
    <div [formGroup]="modalExpensesForm">
        <po-input class="po-md-4" p-label="Bill Number" [p-disabled]="disabledModalFields" [p-maxlength]=13 p-required="true" [p-minlength]=1 formControlName="ZR4_BILL"></po-input>
        <po-input class="po-md-4" p-label="Installment" [p-disabled]="disabledModalFields" [p-maxlength]=2 [p-minlength]=1 formControlName="ZR4_PARCEL"></po-input>
        <po-decimal class="po-md-4" p-label="Bill Value" [p-disabled]="disabledModalFieldsOptional" [p-maxlength]=16 [p-decimals-length]=2 p-locale="en" p-required="true" [p-minlength]=1 formControlName="ZR4_VALOR">
        </po-decimal>
        <po-datepicker class="po-md-4" formControlName="ZR4_EMISSA" [p-disabled]="disabledModalFields" p-clean="true" p-format="mm/dd/yyyy" p-label="Issue Dt" p-readonly="false" p-required="false"></po-datepicker>
        <po-datepicker class="po-md-4" formControlName="ZR4_VENCTO" [p-disabled]="disabledModalFieldsOptional" p-clean="true" p-format="mm/dd/yyyy" [p-min-date]="controlsExpensesForm.ZR4_EMISSA.value" p-label="Due Date" p-readonly="false" p-required="false"></po-datepicker>
        <po-combo class="po-md-4" p-label="Supplier" [p-disabled]="disabledModalFields" formControlName="ZR4_FORNEC_ORIGINAL" [p-filter-mode]="filterModeCombo" [p-options]="selects.sa2" (p-change)="supllierSelec(controlsExpensesForm.ZR4_FORNEC_ORIGINAL.value)"></po-combo>
        <po-combo class="po-md-6" p-label="Class" formControlName="ZR4_NATURE_ORIGINAL" [p-disabled]="disabledModalFieldsOptional" [p-filter-mode]="filterModeCombo" [p-options]="selects.sed" (p-change)="classSelec(controlsExpensesForm.ZR4_NATURE_ORIGINAL.value)"></po-combo>
        <po-combo class="po-md-6" p-label="C. Center" formControlName="ZR4_CCUSTO_ORIGINAL" [p-disabled]="disabledModalFieldsOptional" [p-filter-mode]="filterModeCombo" [p-options]="selects.ctt" (p-change)="ccenterSelec(controlsExpensesForm.ZR4_CCUSTO_ORIGINAL.value)"></po-combo>
        <po-textarea class="po-md-6" formControlName="ZR4_HISTOR" p-focus="false" [p-disabled]="disabledModalFieldsOptional" p-label="History" p-max-length="40" p-min-length="1" p-placeholder="" [p-rows]="5">
        </po-textarea>
        <po-textarea class="po-md-6" formControlName="ZR4_HIST2" p-focus="false" [p-disabled]="disabledModalFieldsOptional" p-label="History 2" p-min-length="1" p-placeholder="" [p-rows]="5">
        </po-textarea>
    </div>
</po-modal>


<po-modal #modalEditItemContainer p-click-out="false" [p-hide-close]="true" p-title="Edit Item" class="limitModal" [p-primary-action]="confirmEditItem" [p-secondary-action]="closeEditItem">
    <div [formGroup]="containerAddForm">
        <po-input class="po-md-3" p-label="Nota Fiscal" formControlName="ZR3_DOC"></po-input>
        <po-input class="po-md-3" p-label="RSC" p-disabled="true" formControlName="C7_FORNECE"></po-input>
        <po-input class="po-md-3" p-label="Vendor" p-disabled="true" formControlName="A2_NOME"></po-input>
        <po-input class="po-md-3" p-label="Purchase Order" p-disabled="true" formControlName="C7_NUM"></po-input>
        <po-input class="po-md-3" p-label="Sales Order" p-disabled="true" formControlName="C6_NUM"></po-input>
        <po-datepicker class="po-md-3" formControlName="C7_EMISSAO" p-clean="true" p-format="mm/dd/yyyy" p-disabled="true" p-error-pattern="" p-label="Date Order PO"></po-datepicker>
        <po-input class="po-md-3" p-label="Vendor Product Code" p-disabled="true" formControlName="B1_XCODFOR">
        </po-input>
        <po-input class="po-md-3" p-label="Product" p-disabled="true" formControlName="C7_PRODUTO"></po-input>
        <po-input class="po-md-3" p-label="Description" p-disabled="true" formControlName="B1_DESC"></po-input>
        <po-decimal class="po-md-3" p-label="Quantity" formControlName="ZR3_QUANT" p-locale="en" (p-change-model)="validateQuantity(containerAddForm.controls,false)" p-disabled="disabledModalFields">
        </po-decimal>
        <po-decimal class="po-md-3" p-label="Unit Price" formControlName="C7_PRECO" p-locale="en" p-disabled="true">
        </po-decimal>
        <po-decimal class="po-md-3" p-label="Total" formControlName="C7_TOTAL" p-locale="en" p-disabled="true">
        </po-decimal>
        <po-number class="po-md-3" p-label="Volume" formControlName="ZR3_VOL" p-disabled="disabledModalFields">
        </po-number>
        <po-number class="po-md-3" p-label="Price R$" formControlName="ZR3_VLRBRL" p-locale="en" p-disabled="false">
        </po-number>
        <po-textarea class="po-md-12" formControlName="ZR3_OBS" p-focus="false" [p-disabled]="disabledModalFields" p-label="Notes" p-min-length="1" p-placeholder="" [p-rows]="5"></po-textarea>
    </div>
</po-modal>