import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  PoBreadcrumb,
  PoBreadcrumbItem,
  PoDialogService,
  PoModalAction,
  PoModalComponent,
  PoNotificationService,
  PoPageAction,
  PoSelectOption,
  PoTableColumn,
} from '@po-ui/ng-components';
import { TransferEstoqueService } from 'src/app/service/transfer-estoque/transfer-estoque.service';
import { UtilService } from 'src/app/service/util/util.service';

@Component({
  selector: 'app-stock-transfer',
  templateUrl: './stock-transfer.component.html',
  styleUrls: ['./stock-transfer.component.css'],
})
export class StockTransferComponent implements OnInit {
  constructor(
    private notificationService: PoNotificationService,
    private utilService: UtilService,
    private transferService: TransferEstoqueService,
    private router: Router,
    private route: ActivatedRoute,
    private alert: PoDialogService,
    private fb: UntypedFormBuilder,
  ) {}

  private item = {
    ZR0_USERNA: '',
    ZR0_STATUS: '',
    ZR0_LOCALD: '',
    ZR0_NUMORI: '',
    ZR0_NUM: '',
    ARMAZEM_ORIGEM: '',
    ZR0_LOCALO: '',
    RECNO: 1,
    ZR0_DATA: '',
    ZR0_HORA: '',
    ZR0_OPERAC: '',
    ARMAZEM_DESTINO: '',
    $selected: false,
  };

  form = this.fb.group({
    origem: [''],
    destino: ['']
  })

  @ViewChild('modalArmazem', { static: true }) modalArmazem!: PoModalComponent;
  primaryAction = {
    label: '',
    action: () => {
      this.incluirTransferencia();
    },
  } as PoModalAction;
  secondaryAction = {
    label: '',
    action: () => this.modalArmazem.close(),
    danger: true,
  } as PoModalAction;


  pageTitle = 'Stock Transfer' as string
  pageBreadcrumb: PoBreadcrumb = {
    items: [
      {label: 'Stock'},
      {label: 'Stock Transfer'},
    ] as Array<PoBreadcrumbItem>
  };
  pageActions: Array<PoPageAction> = [
    {label: 'Add', action: () => {this.openModalArmazem()}, icon:'po-icon-plus'},
    {label: 'View', action: () => this.visualizaTransferencia(this.item)},
    {label: 'Reverse', action: () => {this.estornarTransferencia(this.item)}, icon:'po-icon-refresh'},
  ]

  page = {
    title: 'Stock Transfer' as string
  }

  armazemDestino = '' as string
  armazemOrigem = '' as string

  table = {
    columns: [] as Array<PoTableColumn>,
    items: [],
    height: 200,
  };

  select = {
    armazemOrigem: [] as Array<PoSelectOption>,
    armazemDestino: [] as Array<PoSelectOption>,
  };

  metodo = {
    listar_browser: 'listar_browse',
    listar_armazem: 'listar_armazem',
  };


  pagination = {
    totalItems: 0,
    itemsPerPage: 50,
    currentPage: 1,
  };

  public loading = false as boolean;

  ngOnInit(): void {
    this.table.height = this.utilService.calcularHeight(innerHeight, 0.45);
    this.getListaBrowser();
    this.primaryAction.label = 'Confirm';
    this.secondaryAction.label = 'Cancel';
  }

  // private createPage() {
  //   this.page.title = this.literals.page.title
  //   this.page.breadcrumb.items = [
  //     {label: this.literals.page.breadcrumb.estoque},
  //     {label: this.literals.page.breadcrumb.transferenciaEstoque}
  //   ];
  //   this.page.actions = [
  //     { label: this.literals.page.actions.incluir, action: () => { this.openModalArmazem() } },
  //     { label: this.literals.page.actions.visualizar, action: () => { this.visualizaTransferencia(this.item) } },
  //     { label: 'Estornar Transferência', action: () => { this.estornarTransferencia(this.item)}}
  //   ]
  //   this.primaryAction.label = this.literals.page.modal.incluir
  //   this.secondaryAction.label = this.literals.page.modal.cancelar
  // }

  private getListaBrowser() {
    this.loading = true;
    const obj = {
      procedure: false,
      metodo: this.metodo.listar_browser,
      data: {
        limit: this.pagination.itemsPerPage,
        page: this.pagination.currentPage,
      },
    };

    this.transferService.getTransferEstoque(obj).subscribe({
      next: (arg) => {
        arg.data.map((element) => {
          if (element['columns']) {
            this.table.columns = element['columns'].sort(
              (a: any, b: any) => a.order - b.order
            );
          } else if (element['items']) {
            this.table.items = element['items'];
          } else if (element['total']) {
            this.pagination.totalItems = element['total'];
            this.utilService.emitTotalItems.emit(this.pagination.totalItems);
          }
        });
        this.loading = false;
      },
      error: (error) => {
        console.error('meotodo getListaBrowser', error);
        this.notificationService.error('Erro ao carregar dados');
        this.loading = false;
      }
    });
  }

  private openModalArmazem() {
    this.modalArmazem.open();
    this.getListaArmazem();
  }

  private getListaArmazem() {
    this.loading = true;
    const obj = {
      procedure: false,
      metodo: this.metodo.listar_armazem,
      data: {},
    };
    this.transferService.getTransferEstoque(obj).subscribe({
      next: (arg: any) => {
        if (arg.status) {
          this.select.armazemDestino = arg.data['destino'].map((arDes: any) => {
            return {
              label: `${arDes.NNR_CODIGO} - ${arDes.NNR_DESCRI}`,
              value: arDes.NNR_CODIGO,
            } as PoSelectOption;
          });
          this.select.armazemOrigem = arg.data['origem'].map((arDes: any) => {
            return {
              label: `${arDes.NNR_CODIGO} - ${arDes.NNR_DESCRI}`,
              value: arDes.NNR_CODIGO,
            } as PoSelectOption;
          });
        } else {
          this.notificationService.warning(arg.mensagem);
        }
        this.loading = false;
      },
      error: (error) => {
        console.error('meotodo getListaArmazem', error);
        this.notificationService.error('Erro ao carregar dados');
        this.loading = false;
      }
    });
  }

  private incluirTransferencia() {
    this.loading = true;
    if(this.form.controls.origem.value !== this.form.controls.destino.value &&
      this.form.controls.origem.value !== "" && this.form.controls.destino.value !== "") {
      this.router.navigate(
        [
          'add-transfer',
          this.form.controls.origem.value,
          this.form.controls.destino.value,
        ],
        { relativeTo: this.route }
      );
      this.loading = false;
    } else {
      this.notificationService.warning('Invalid Locals');
      this.loading = false;
    }
  }

  public selectItem(item: any) {
    this.item = item;
  }

  public unselectItem(item: any) {
    // this.item.RECNO = 0;
  }

  public visualizaTransferencia(item: any) {
    this.loading = true;
    if (this.item.$selected === true) {
      this.router.navigate(['view', item.RECNO], {
        relativeTo: this.route,
      });
      this.loading = false;
    } else {
      this.notificationService.warning(
        'Selecione alguma transferência para visualizar'
      );
      this.loading = false;
    }
  }

  public estornarTransferencia(item: any) {
    this.loading = true;
    if (this.item.$selected === true && this.item.ZR0_STATUS === '2') {
      this.router.navigate(['reverse', item.RECNO], {
        relativeTo: this.route,
      });
      this.loading = false;
    } else {
      this.notificationService.warning(
        'Not possible to reverse'
      );
      this.loading = false;
    }
  }

  public onPageChange(event: any) {
    this.pagination.currentPage = event;
    this.getListaBrowser();
  }
}
