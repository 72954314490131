import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class F3ClientesService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'f3-clientes'

  public getClientes(parameters: any) {
    const params = new HttpParams({fromObject: parameters})
    return this.http.get(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, {params})
  }
}
