import { GenericCustomerComponent } from './customers-list/generic-customer/generic-customer.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommercialRoutingModule } from './commercial-routing.module';
import { SafeHtmlPipe } from './sales-order/sales-order-edit/safe-html.pipe';
import { SalesOrderComponent } from './sales-order/sales-order.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PoPageModule, PoFieldModule, PoButtonModule, PoTableModule, PoModalModule, PoDividerModule, PoLoadingModule, PoContainerModule, PoInfoModule, PoTabsModule, PoWidgetModule } from '@po-ui/ng-components';
import { GlobalComponentsModule } from '../global-components/global-components.module';
import { PoPageBlockedUserModule } from '@po-ui/ng-templates';
import { SalesOrderEditComponent } from './sales-order/sales-order-edit/sales-order-edit.component';
import { TopSalesComponent } from './top-sales/top-sales.component';
import { TopSoEntrancesComponent } from './top-so-entrances/top-so-entrances.component';
import { SalesByProductComponent } from './sales-by-product/sales-by-product.component';
import { PerformanceReportComponent } from './performance-report/performance-report.component';
import { CustomersListComponent } from './customers-list/customers-list.component';


@NgModule({
  declarations: [
    SalesOrderComponent,
    SalesOrderEditComponent,
    SafeHtmlPipe,
    TopSalesComponent,
    TopSoEntrancesComponent,
    SalesByProductComponent,
    PerformanceReportComponent,
    CustomersListComponent,
    GenericCustomerComponent,

  ],
  imports: [
    CommonModule,
    CommercialRoutingModule,
    PoPageModule,
    ReactiveFormsModule,
    PoFieldModule,
    PoButtonModule,
    PoTableModule,
    FormsModule,
    PoModalModule,
    PoDividerModule,
    PoLoadingModule,
    PoModalModule,
    PoContainerModule,
    PoInfoModule,
    PoTabsModule,
    PoWidgetModule,
    GlobalComponentsModule,
    PoPageBlockedUserModule,
  ],
  exports:[
    SalesOrderComponent,
    SalesOrderEditComponent
  ]
})
export class CommercialModule { }
