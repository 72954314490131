import { Component, OnInit, ViewChild } from '@angular/core';
import { PoPageDefault, PoSelectOption, PoInfoOrientation, PoBreadcrumbItem, PoBreadcrumb, PoPageAction } from '@po-ui/ng-components';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
// import { UserService } from '../../../services/user/user.service';
// import { RoleService } from '../../../services/role/role.service';
import { PoNotificationService } from '@po-ui/ng-components';
import { HttpErrorResponse } from '@angular/common/http';
import { PoModalComponent, PoModalAction } from '@po-ui/ng-components';
// import { MarcasService } from 'src/app/services/marcas/marcas.service';
import { Location } from '@angular/common';
import { UserService } from 'src/app/service/user/user.service';
import { RoleService } from 'src/app/service/role/role.service';

@Component({
  selector: 'app-user-add',
  templateUrl: './user-add.component.html',
  styleUrls: ['./user-add.component.css']
})
export class UserAddComponent implements OnInit {


  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private roleService: RoleService,
    private notification: PoNotificationService,
    // private marcasService: MarcasService,
    private location: Location
  ) { }

  get controls() {
    return this.addUserForm.controls;
  }

  page = {
    title: 'Add User',
    breadcrumb: {
      items: [
        { label: 'Home' },
        { label: 'Settings' },
        { label: 'Users' },
        { label: 'Add User' }
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [
      {
        label: 'Save', action: () => {
          this.confirmUserModal.open();
          const [role] = this.roleOptions.filter((item: any) => item.value === this.controls.role_id.value);
          this.roles = role.label;
        }, disabled: false
      },
      { label: 'Cancel', action: () => { this.location.back() } }

    ] as Array<PoPageAction>,
  }

  roleOptions: any;
  roles: any = [];
  orientation: PoInfoOrientation = PoInfoOrientation.Horizontal;
  loading = true as boolean;

  // activeOptions: any[] = [
  //   { label: 'Sim', value: true },
  //   { label: 'Não', value: false }
  // ];

  selects = {
    marcas: [] as PoSelectOption[]
  }

  // Validators.pattern('^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$')
  /** Form Add User */
  addUserForm: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.required, Validators.minLength(3)]],
    username: ['', [Validators.required]],
    password: ['', [Validators.pattern(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/), Validators.required]],
    active: [false, [Validators.required]],
    role_id: ['', [Validators.required]],
    id_marca: ['', []],
    is_representante: [false, []],
    id_protheus: ['', [Validators.required]]
  });

  /** Modal de Confirmação */
  /** Modal de Confirmação */

  @ViewChild('confirmUserModal', { static: true })
  confirmUserModal!: PoModalComponent;

  public readonly confirmPrimaryAction: PoModalAction = {
    label: 'Confirm', action: () => {
      this.addUser();
      this.confirmUserModal.close();
    }
  };

  public readonly confirmSecondaryAction: PoModalAction = {
    label: 'Edit', action: () => { this.confirmUserModal.close(); }
  };

  ngOnInit() {
    this.page.actions[0].disabled = true;

    this.roleService.getAllRoles().subscribe({
      next: (data: any) => {
      this.roleOptions = data.data.map((item: { name: string; id: any; }) => {
        return { label: item.name.toLocaleUpperCase(), value: item.id };
      });
      this.loading = false;
    }, error: (error: HttpErrorResponse) => {
      this.loading = false;
      this.notification.warning('Erro while loading roles')
      console.error(error.error.meta.message);
    }
    });

    this.addUserForm.valueChanges
      .subscribe((data) => {
        this.page.actions[0].disabled = this.addUserForm.invalid;
      });
  }

  /**
   * Add new user
   */
  private addUser() {
    this.userService.addUser(this.addUserForm.value)
      .subscribe({
        next: (data) => {
        this.notification.success('Successfully saved');
        this.location.back();
      },
       error: (error: HttpErrorResponse) => {
          console.error(error, 'ERROR');
          this.notification.error(error.message);
        }
      });
  }

  compareUserName(name: string) {
    this.userService.compareUserName(name)
      .subscribe({
        next: () => {
          this.notification.error('Username is already in use');
        },
        error: () => {
          this.notification.success('Username available');
        }
      });
  }

}
