import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private httpClient: HttpClient) {}

  relativeLink = 'pages';

  getMenu(param?: any): Observable<any> {
    // return this.httpClient.get(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`)
    return of({
      data: {
        response: [
          {
            parent: 0,
            route: '/financial',
            created: '2022-01-21T12:34:13.070',
            subItems: [
              {
                parent: 10033,
                route: '/sales-tax-reports',
                created: '2022-01-21T12:34:13.070',
                subItems: [],
                icon: 'po-icon po-icon-menu',
                link: '/financial/sales-tax-reports',
                modified: '2022-01-21T12:34:13.070',
                shortLabel: 'false',
                id: 10034,
                label: 'Sales Tax Report',
              },
              {
                parent: 10033,
                route: '/accounts-payable',
                created: '2023-09-26T08:40:44.617',
                subItems: [],
                icon: 'po-icon po-icon-menu',
                link: '/financial/accounts-payable',
                modified: '2023-09-26T08:40:44.617',
                shortLabel: 'false',
                id: 10043,
                label: 'Accounts Payable',
              },
              {
                parent: 10033,
                route: '/accounts-receivable',
                created: '2023-09-27T05:36:45.767',
                subItems: [],
                icon: 'po-icon po-icon-menu',
                link: '/financial/accounts-receivable',
                modified: '2023-09-27T05:36:45.767',
                shortLabel: 'false',
                id: 10044,
                label: 'Accounts Receivable',
              },
              {
                parent: 10033,
                route: '/bank-transactions',
                created: '2023-09-28T05:25:49.397',
                subItems: [],
                icon: 'po-icon po-icon-menu',
                link: '/financial/bank-transactions',
                modified: '2023-09-28T05:25:49.397',
                shortLabel: 'false',
                id: 10045,
                label: 'Bank Transactions',
              },
            ],
            icon: 'po-icon po-icon-finance',
            modified: '2022-01-21T12:34:13.070',
            shortLabel: 'Financial',
            id: 10033,
            label: 'Financial',
          },
        ],
      },
      meta: {
        code: 200,
        message: 'OK',
      },
    });
  }

  verificaLogado() {
    return this.httpClient
      .get<any>(`${environment.URL.APIPRIVATE}/v1/${this.relativeLink}`)
      .pipe(
        // tap((valor) => console.log(valor)),
        // map((api) => api.payload), // Tem a mesma função do pluck
        pluck('meta')
        // map((acoes) =>
        //   acoes.sort((acaoA, acaoB) => this.ordenaPorCodigo(acaoA, acaoB))
        // )
      );
  }
}
