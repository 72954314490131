import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ListaTipoOperacaoService } from 'src/app/service/lista-tipo-operacao/lista-tipo-operacao.service';


@Injectable({ providedIn: 'root' })
export class ListaTipoOperacaoResolve implements Resolve<Observable<any>> {

    constructor(private listaTipoOperacaoService: ListaTipoOperacaoService) { }
    resolve(route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): Observable<any> | Observable<Observable<any>> | Promise<Observable<any>> {
        return this.listaTipoOperacaoService.getListaTipoOperacao({procedure:true});
    }
}
