import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { StockTransferComponent } from './stock-transfer/stock-transfer.component';
import { AddTransferComponent } from './stock-transfer/add-transfer/add-transfer.component';
import { ViewCancelTransferComponent } from './stock-transfer/view-cancel-transfer/view-cancel-transfer.component';
import { ContainerComponent } from './container/container.component';
import { ContainerEditComponent } from './container/container-edit/container-edit.component';

const routes: Routes = [
  {
    path: 'stock-transfer',
    children: [
      { path: '', component: StockTransferComponent },
      { path: 'add-transfer/:armazemOrigem/:armazemDestino', component: AddTransferComponent },
      { path: 'view/:RECNO', component: ViewCancelTransferComponent },
      { path: 'reverse/:RECNO', component: ViewCancelTransferComponent }
    ]
  },
  {
    path: 'container',
    children: [
      { path: '', component: ContainerComponent },
      { path: 'add', component: ContainerEditComponent },
      { path: 'edit/:RECNO', component: ContainerEditComponent },
      { path: 'view/:RECNO', component: ContainerEditComponent },
      { path: 'delete/:RECNO', component: ContainerEditComponent }
      
    ]
  }
]

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forChild(routes),
    CommonModule
  ],
  exports: [RouterModule]
})
export class StockRoutingModule { }
