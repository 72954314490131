import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LoadedPageService {

    /** VARIAVEIS PARA AS PRIMEIRA VERSÃO DAS FUNÇÕES */
    // route: BehaviorSubject<string> = new BehaviorSubject('');
    // params: BehaviorSubject<Object> = new BehaviorSubject({});

    route: BehaviorSubject<any> = new BehaviorSubject([]);

    constructor() { }

    /** PRIMEIRA FUNÇÃO CHANGE ROUTE
     * PEGAR A ROTA E OS PARAMETERS
     */
    // changeRoute(route: string, params: Object) {
    //   this.route.next(route);
    //   this.params.next(params);
    // }

    /**
     * VERIFICA A ROUTE E RETORNA OS PARAMETERS
     * @param route RECEBE A ROTA COMPLETA ROUTE.URL
     */
    // verifyRoute(route: string) {
    //   if (route === this.route.value) {
    //     return this.params.value;
    //   } else {
    //     return false;
    //   }
    // }

    /**
     * 
     * @param obj ROUTE, PAGE, PARAMS: FORMULÁRIO
     */
    changeRoute(obj: any) {
        const currentValue = this.route.value;
        const updateValue = [...currentValue, obj];
        this.route.next(updateValue);
    }

    /**
     * 
     * @param route RECEBE A ROTA ATUAL
     * @returns RETORNA O OBJETO RECEBIDO NA changeRoute()
     */
    verifyRoute(route: string) {
        let returns: any;
        const x: any[] = this.route.value
            .filter((data: any) => {
                return data.route === route;
            })
        if (x.length <= 0) {
            return false;
        } else {
            returns = x[x.length - 1];
            return returns;
        }

    }
}
