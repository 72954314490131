import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {
  PoBreadcrumbItem,
  PoNotificationService,
  PoPageAction,
  PoTableColumn,
  PoTableLiterals,
} from '@po-ui/ng-components';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { SessionService } from 'src/app/service/session/session.service';
import { TransferEstoqueService } from 'src/app/service/transfer-estoque/transfer-estoque.service';
import { UtilService } from 'src/app/service/util/util.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-transfer',
  templateUrl: './add-transfer.component.html',
  styleUrls: ['./add-transfer.component.css'],
})
export class AddTransferComponent implements OnInit {
  constructor(
    private transferService: TransferEstoqueService,
    private excelService: ExcelService,
    private notificationService: PoNotificationService,
    private location: Location,
    private route: ActivatedRoute,
    private utilService: UtilService,
    private sessionService: SessionService
  ) {}

  public armazens = {
    codigoOrigem: '',
    codigoDestino: '',
    origem: '',
    destino: '',
  };
  private nome = '';

  page = {
    title: 'Add Transfer',
    breadcrumb: {
      items: [
        { label: 'Stock' },
        { label: 'Transfer Stock' },
        { label: 'Add Transfer' },
      ] as Array<PoBreadcrumbItem>,
    },
    action: [
      {
        label: 'Save',
        action: () => {
          this.salvarTransferencia();
        },
      },
      {
        label: 'Back',
        action: () => {
          this.location.back();
        },
      },
    ] as Array<PoPageAction>,
  };

  table = {
    columns: [] as Array<PoTableColumn>,
    items: [],
    literals: {
      noColumns: 'Import excel to show',
      noData: 'Import excel to show the data',
    } as PoTableLiterals,
    height: this.utilService.calcularHeight(innerHeight, 0.4),
  };

  public loading = false as boolean;

  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (element: ParamMap) => {
      this.loading = true;
      this.armazens.codigoOrigem = element.get('armazemOrigem') as string;
      this.armazens.codigoDestino = element.get('armazemDestino') as string;
      const obj = {
        procedure: false,
        metodo: 'listar_armazem',
        data: {},
      };
      this.transferService.getTransferEstoque(obj).subscribe((element: any) => {
        const destino = element.data['destino']
          .filter(
            (arDes: any) => arDes.NNR_CODIGO === this.armazens.codigoDestino
          )
          .pop().NNR_DESCRI;
        const origem = element.data['origem']
          .filter(
            (arOri: any) => arOri.NNR_CODIGO === this.armazens.codigoOrigem
          )
          .pop().NNR_DESCRI;
        this.armazens.destino = `${this.armazens.codigoDestino}-${destino}`;
        this.armazens.origem = `${this.armazens.codigoOrigem}-${origem}`;
        this.loading = false;
      });
    });
    this.sessionService.userInformation$.subscribe((element: any) => {
      this.nome = element.title;
    });
  }

  // private createPage() {
  //   this.page.title = this.literals.page.title;
  //   this.page.action = [
  //     {label: this.literals.page.action.salvar, action: () => {this.salvarTransferencia()}},
  //     {label: this.literals.page.action.cancelar, type: 'danger', action: () => {this.location.back()}}
  //   ];
  //   this.page.breadcrumb.items = [
  //     {label: this.literals.page.breadcrumb.estoque},
  //     {label: this.literals.page.breadcrumb.transferenciaEstoque},
  //     {label: this.literals.page.breadcrumb.incluirTransferencia},
  //   ];
  // }

  public getModeloPlanilha() {
    this.loading = true;
    let array = [];
    const obj = {
      metodo: 'listar_planilha',
      procedure: false,
      data: {
        origem: this.armazens.codigoOrigem,
        destino: this.armazens.codigoDestino,
      },
    };

    this.transferService.getTransferEstoque(obj).subscribe({
      next: (element) => {

        if (element !== null) {
          array = element.data.map((data) => {
            return {
              Code: data.Code,
              Description: data.Description,
              Total: data.Total,
              Qty_on_SO: data.Qty_on_SO,
              Allocated_Qty: data.Allocated_Qty,
              Balance: data.Balance,
              ToBeTransfered: data.ToBeTransfered,
            };
          });
          this.excelService.exportAsExcelFile(
            array,
            `From:${this.armazens.codigoOrigem}_To:${this.armazens.codigoDestino}_Model`
          );
          this.notificationService.success('Model downloaded successfully');
          this.loading = false;
        } else {
          this.notificationService.warning(
            'Download excel file failed. Please try again'
          );
          this.loading = false;
        }
      },
      error: (error) => {
        console.error(error);
        this.notificationService.error('Error while loading data');
        this.loading = false;
      }
    });
  }

  private postUploadExcel(json: any) {
    this.loading = true;
    const obj = {
      metodo: 'validar_planilha',
      procedure: false,
      data: json,
    };
    this.transferService.getTransferEstoque(obj).subscribe({
      next: (res) => {
        res.data.map((data) => {
          if (data.columns) {
            this.table.columns = data.columns;
          }
          if (data.items) {
            if (data.items.length <= 0) {
              this.notificationService.warning('No data to show');
            }
            this.table.items = data.items;
          }
        });
        this.table.columns;
        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.notificationService.error('Error in Excel upload');
        this.loading = false;
      }
    });
  }

  excelUpload(event: any) {

    if (event.target.files.length >= 1) {
      this.loading = true;
      const selectedFile = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.readAsBinaryString(selectedFile);
      fileReader.onload = (event: any) => {

        let binaryData = event.target.result;
        let workbook = XLSX.read(binaryData, { type: 'binary' });
        workbook.SheetNames.forEach(
          (sheet) => {
            const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
            this.loading = false;
            this.postUploadExcel(data);
          },
          (error: Error) => {
            this.loading = false;
            console.error(error);
            this.notificationService.error('Error in Excel import');
          }
        );
      };
    }
  }

  private salvarTransferencia() {
    this.loading = true;
    const obj = {
      metodo: 'salvar_transferencia',
      procedure: false,
      data: {
        cabec: {
          ZR0_DATA: this.utilService.dataAtual(),
          ZR0_LOCALO: this.armazens.origem,
          ZR0_LOCALD: this.armazens.destino,
          ZR0_HORA: this.utilService.horaAtual(),
          ZR0_USERNA: this.nome,
          recno: 0,
          tabela: 'ZR0',
        },
        itens: this.table.items,
        operation: '3',
      },
    };
    this.transferService.getTransferEstoque(obj).subscribe({
      next: (element) => {
        this.notificationService.success('Succefully'), this.location.back();
        this.loading = false;
      },
      error: (error) => {
        console.error(error);
        this.notificationService.error('Error');
        this.loading = false;
      }
    });
  }
}
