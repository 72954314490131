import { Component, OnInit, ViewChild } from '@angular/core';
import { PoBreadcrumb, PoTableColumnLabel, PoPageDefault, PoSelectOption, PoBreadcrumbItem, PoNotificationService } from '@po-ui/ng-components';
import { PoTableColumn, PoTableAction } from '@po-ui/ng-components';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PoPageAction, PoPageFilter } from '@po-ui/ng-components';
import { PoModalComponent, PoModalAction } from '@po-ui/ng-components';
// import { UserService } from '../../../services/user/user.service';
// import { UtilService } from '../../../services/util/util.service';
// import { RoleService } from 'src/app/services/role/role.service';
// import { MarcasService } from 'src/app/services/marcas/marcas.service';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/service/user/user.service';
import { UtilService } from 'src/app/service/util/util.service';
import { ExcelService } from 'src/app/service/excel/excel.service';
import { RoleService } from 'src/app/service/role/role.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private utilService: UtilService,
    private route: ActivatedRoute,
    private roleService: RoleService,
    private excelService: ExcelService,
    private notification: PoNotificationService,
  ) { }

  @ViewChild('alterarSenhaModal', { static: true }) alterarSenhaModal?: PoModalComponent
  public primaryAction = { label: 'Confirm', action: () => this.alteraSenha(this.alterarSenhaForm.value) } as PoModalAction
  public secondaryAction = { label: 'Close', action: () => this.alterarSenhaModal?.close() } as PoModalAction

  public alterarSenhaForm = this.fb.group({
    new: '',
    confirm: '',
    userId: '',
    adm: true
  })


  public loading: boolean = false;

  get controls() {
    return this.filtersForm.controls;
  }

  page = {
    title: 'Users',
    breadcrumb: {
      items: [
        { label: 'Home' },
        { label: 'Settings' },
        { label: 'Users' }
      ] as Array<PoBreadcrumbItem>,
    } as PoBreadcrumb,
    actions: [
      { label: 'New', icon: 'po-icon po-icon-user-add', action: () => this.router.navigate(['settings/users/add']) },
      { label: 'Excel', action: () => this.toExcel(this.table.items) },
      { label: 'Clear Filters', action: () => this.limpaFiltros() }
    ] as Array<PoPageAction>,
  };

  filterModel = '';
  table = {
    columns: [
      {
        label: 'Status', property: 'active', type: 'subtitle', width: '10%', subtitles: [
          { value: true, color: 'color-10', label: 'Active' },
          { value: false, color: 'color-07', label: 'Inactive' }
        ]
      },
      { label: 'ID', property: 'id', width: '5%' },
      { label: 'Username', property: 'username', width: '15%' },
      { label: 'Name', property: 'name', width: '15%' },
      { label: 'Role', property: 'role_name', width: '12%' },
      { label: 'Created', property: 'created', type: 'date', format: 'MM/dd/yyyy', width: '10%' },
      { label: 'Modified', property: 'modified', type: 'date', format: 'MM/dd/yyyy', width: '10%' },
      { label: 'Sales Rep', property: 'is_representante', type: 'boolean', width: '10%', boolean: { trueLabel: 'Yes', falseLabel: 'No' } },
      { label: 'Id Protheus', property: 'id_protheus', width: '10%' }
    ] as PoTableColumn[],
    items: [] as any,
    loading: false as boolean,
    height: 0,
  };



  tableActions: PoTableAction[] = [
    { label: 'View', action: this.userView.bind(this), icon: 'po-icon-device-desktop' },
    { label: 'Edit', action: this.userEdit.bind(this), icon: 'po-icon-edit' },
    { label: 'Password', action: this.openModalSenha.bind(this), icon: 'po-icon po-icon-lock' }
  ];

  representanteOptions: Array<PoSelectOption> = [
    { label: 'Yes', value: "true" },
    { label: 'No', value: "false" }
  ];

  activeOptions: any[] = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  roleOptions: any;
  /** Form User Modal */
  formUserModal: UntypedFormGroup = this.fb.group({
    username: ['', [Validators.required]],
    group_id: ['', [Validators.required]]
  });

  /* Advanced Filters Users */
  filtersForm = this.fb.group({
    // [Validators.pattern('^[ a-zA-Z á]*$')]
    name: [''],
    username: ['', [Validators.pattern('^[a-zA-Z]*$')]],
    active: [''],
    id: [''],
    role_id: [''],
    is_representante: ['']
  });


  ngOnInit() {
    this.getRegra();
    this.getUsers({ name: this.filterModel }, false);
  }

  // View
  private userView(user: any) {
    this.router.navigate(['view', user.id], { relativeTo: this.route });
  }

  // Edit
  private userEdit(user: any) {
    this.router.navigate(['edit', user.id], { relativeTo: this.route });
  }

  /**
   *
   * @param parameters parâmetros para filtrar os users
   * @returns dados dos usuarios referente ao grupo
   */
  private getUsers(parameters: any, other: boolean) {
    const isFilter = other ?? true;
    if (isFilter) {
      parameters = { name: '' }
    }
    this.loading = true;
    this.userService.getUsers(parameters)
      .subscribe({
        next: (data) => {
        if (data.data.response) {
          this.table.items = data.data.response
            .map((item) => {
              return {
                ...item,
                role_name: this.filtraRegras(item.role_id)
              };
            });
        } else {
          this.table.items = [];
        }
        this.table.height = this.utilService.calcularHeight(innerHeight, 0.45)
        this.loading = false;
      },
       error: (error: HttpErrorResponse) => {
          console.error(error);
          this.loading = false;
        }});
  }

  private getRegra() {
    this.roleService.getAllRoles()
      .subscribe({
        next: (element: any) => {
        if (element) {
          this.roleOptions = element.data;
        }
        this.getUsers({ name: this.filterModel }, false)
      },
        error: (error: HttpErrorResponse) => {
          console.error(error);
        }
      });
  }

  private filtraRegras(id: number) {
    if (id) {
      const arr = this.roleOptions.filter((ret: any) => ret.id === id);
      if (arr.length > 0) {
        return arr[0].name.toUpperCase();
      } else {
        return
      }
    }
  }

  /**
   * função que pegar os parametros do FiltersForm
   * e altera o valor da tableItems
   */
  public applyFilters() {
    this.getUsers(this.filtersForm.value, false);
  }

  public limpaFiltros() {
    this.utilService.resetForm(this.filtersForm);
    this.getUsers(this.filtersForm.value, false);
  }

  private toExcel(array: Array<any>) {
    this.loading = true;
    this.excelService.exportAsExcelFile(array, this.page.title);
    setTimeout(() => {
      this.loading = false;
    }, 1000)
  };

  private openModalSenha(linha: any) {
    if (linha) {
      this.alterarSenhaForm.controls.userId.setValue(linha.id)
      this.alterarSenhaModal?.open();
    }
  }

  private alteraSenha(model: any) {
    if (this.alterarSenhaForm.invalid) {
      return this.notification.warning('Please fill the form correct');
    } else {
      this.loading = true
      this.userService.changePassword(model).subscribe({
        next: (element: any) => {
        this.loading = false
        this.notification.success(element.meta.message)
        this.alterarSenhaModal?.close();
      }, error: (error) => {
        console.error(error);
        this.notification.error(error.error)
        this.loading = false
      }
    })
    }
  }


}
