<po-page-default
  [p-title]="page.title"
  [p-breadcrumb]="page.breadcrumb"
  [p-actions]="page.actions"
>

  <div class="po-row">
    <po-table
      class="po-md-12 po-mt-2"
      [p-columns]="table.columns"
      [p-items]="table.items"
      [p-height]="table.height"
      [p-sort]="true"
      [p-striped]="true"
      [p-hide-text-overflow]="true"
    ></po-table>
    <div class="po-row po-mt-2">
      <hr class="po-md-10" style="height:2px;border-width:0;color: #b6bdbf;background-color: #b6bdbf">
      <span class="po-md-1" style="font-size: 13px;text-align: left; color: #4a5c60; padding-top: 5px;">TOTAL: </span>
      <span class="po-md-1" style="text-align: right; color: #4a5c60; padding-top: 5px;">{{valorTotal | currency: ' '}}</span>
</div>  

    <app-pagination class="po-md-12" (totalItems)="pagination.total" [itemsPerPage]="pagination.limit" (currentPage)="pagination.page" (pageChanged)="changePageData($event)"></app-pagination>
  </div>

  <po-modal #modalParametros p-size="sm" p-title="Filters">
    <form [formGroup]="form">
      <div class="po-row">
        <po-datepicker
          p-label="Start Date*"
          p-required="true"
          class="po-md-6"
          formControlName="start"
          p-locale="en"
          p-format="MM/dd/yyyy"
        ></po-datepicker>
        <po-datepicker
          p-label="Final Date *"
          p-required="true"
          class="po-md-6"
          formControlName="end"
          p-locale="en"
          p-format="MM/dd/yyyy"
        ></po-datepicker>
        <!-- <po-select
          p-label="Marca *"
          p-required="true"
          class="po-md-12"
          formControlName="marca"
          [p-options]="selects.marcas"
        ></po-select> -->

        <po-input
          p-label="Product"
          [p-optional]="true"
          p-clean="true"
          p-readonly="true"
          class="po-md-8"
          formControlName="viewProduto"
        ></po-input>
        <po-button
          p-icon="po-icon-search"
          p-type="primary"
          class="po-md-2 po-mt-4"
          (p-click)="modalProdutos.open()"
        ></po-button>
        <po-button
          p-icon="po-icon-close"
          p-type="danger"
          class="po-md-2 po-mt-4"
          (p-click)="cleanProdutos()"
        ></po-button>
        <po-combo
        p-label="Collection"
        class="po-md-12"
        formControlName="codigoColecao"
        [p-optional]="true"
        [p-options]="selects.colecoes"
        [p-clean]="true"
      ></po-combo>
        <po-input
          p-label="Customer"
          p-readonly
          [p-optional]="true"
          p-clean
          class="po-md-8"
          formControlName="viewCliente"
        ></po-input>
        <po-button
          p-icon="po-icon-search"
          p-type="primary"
          class="po-md-2 po-mt-4"
          (p-click)="modalClientes.open()"
        ></po-button>
        <po-button
        p-icon="po-icon-close"
        p-type="danger"
        class="po-md-2 po-mt-4"
        (p-click)="cleanClientes()"
      ></po-button>
        <!-- <po-combo
          p-label="Grupo Econômico"
          class="po-md-12"
          formControlName="codigoGrupoEconomico"
          [p-optional]="true"
          [p-options]="selects.grupoEconomico"
          p-filter-mode="contains"
        ></po-combo> -->
        <!-- <po-select
        p-label="Business Type"
        class="po-md-12"
        formControlName="codigoSegmento"
        [p-optional]="true"
        [p-options]="selects.segmento"
      ></po-select> -->

        <po-combo
          p-label="State"
          class="po-md-12"
          formControlName="uf"
          [p-optional]="true"
          [p-options]="selects.uf"
          [p-clean]="true"
        ></po-combo>
        <po-button
          p-icon="po-icon-search"
          p-label="Search"
          p-type="primary"
          class="po-md-12 po-mt-4"
          (p-click)="getVendasPorProduto()"
          ></po-button>
      </div>
    </form>
  </po-modal>


  <po-modal #modalClientes p-size="sm" p-title="Customer">
      <div class="po-row" (keyup.enter)="getClientes()">
          <po-input [(ngModel)]="modalCliente.filter" class="po-md-10" p-label="Filter" p-placeholder="Enter at least 4 characters"></po-input>
          <po-button class="po-md-2 po-mt-4" p-type="primary" p-label="Search" p-icon="po-icon-search" (p-click)="getClientes()"></po-button>
      </div>
      <div class="po-row">
          <po-table class="po-md-12" [p-hide-text-overflow]="true" [p-actions]="modalCliente.table.actions" [p-columns]="modalCliente.table.columns" [p-items]="modalCliente.table.items" [p-height]="modalCliente.table.height"></po-table>
      </div>
      <app-pagination class="po-md-12" (totalItems)="modalCliente.pagination.total" [itemsPerPage]="modalCliente.pagination.limit" (currentPage)="modalCliente.pagination.page" (pageChanged)="pageChangeCliente($event)"></app-pagination>
  </po-modal>

  <po-modal #modalProdutos p-size="sm" p-title="Product">
      <div class="po-row" (keyup.enter)="getProdutos()">
          <po-input [(ngModel)]="modalProduto.filter" class="po-md-10" p-label="Filter" p-placeholder="Enter at least 4 characters"></po-input>
          <po-button class="po-md-2 po-mt-4" p-type="primary" p-label="Search" p-icon="po-icon-search" (p-click)="getProdutos()"></po-button>
      </div>
      <div class="po-row">
          <po-table class="po-md-12" [p-hide-text-overflow]="true" [p-actions]="modalProduto.table.actions" [p-columns]="modalProduto.table.columns" [p-items]="modalProduto.table.items" [p-height]="modalProduto.table.height"></po-table>
      </div>
      <app-pagination class="po-md-12" (totalItems)="modalProduto.pagination.total" [itemsPerPage]="modalProduto.pagination.limit" (currentPage)="modalProduto.pagination.page" (pageChanged)="pageChangeProduto($event)"></app-pagination>
  </po-modal>

</po-page-default>

<po-loading-overlay *ngIf="loading" [p-screen-lock]="true" p-text="Loading...">
</po-loading-overlay>
