import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  PoBreadcrumb,
  PoNotificationService,
  PoPageAction,
  PoSelectOption,
  PoTableColumn,
} from '@po-ui/ng-components';
import { RelatorioPerformanceService } from 'src/app/service/relatorio-performance/relatorio-performance.service';
import { RepresentantesService } from 'src/app/service/representantes/representantes.service';
import { UtilService } from 'src/app/service/util/util.service';


interface RetornoRelatorio {
  anoMes: string;
  categorias: Array<{
    categoria: string;
    descricao: string;
    percentual: number;
    valorAtingido: number;
    valorDesafio: number;
  }>;
  percentual: number;
  valorAtingido: number;
  valorDesafio: number;
}
interface ItemTablePerformance {
  legenda: string;
  anoMes: string;
  indicador: string;
  total: number | string;
  [key: string]: string | {} | number;
}

@Component({
  selector: 'app-performance-report',
  templateUrl: './performance-report.component.html',
  styleUrls: ['./performance-report.component.css'],
})
export class PerformanceReportComponent implements OnInit {
  constructor(
    private utilService: UtilService,
    private representantesService: RepresentantesService,
    private fb: UntypedFormBuilder,
    private relatorioPerformanceService: RelatorioPerformanceService,
    private notification: PoNotificationService
  ) {}

  public loading = false;

  public page = {
    title: 'Performance Report',
    breadcrumb: {
      items: [
        { label: 'Home' },
        { label: 'Commercial' },
        { label: 'Performance Report ' },
      ],
    } as PoBreadcrumb,
    actions: [] as Array<PoPageAction>,
  };

  public table = {
    columns: [] as Array<PoTableColumn>,
    items: [] as Array<any>,
    height: this.utilService.calcularHeight(innerHeight, 0.5),
  };

  public form = this.fb.group({
    start: ['', [Validators.required]],
    end: ['', [Validators.required]],
    codigoRepresentante: [''],
  });

  public optionsRepresentantes = [] as Array<PoSelectOption>;

  ngOnInit(): void {
    this.getRepresentantes();
  }

  public getRepresentantes() {
    this.loading = true;
    this.representantesService.getRepresentantes().subscribe({
      next: (element: any) => {
        this.optionsRepresentantes = element.response.data.map((item: any) => {
          return {
            label: item.nomeFantasia,
            value: item.codigo,
          } as PoSelectOption;
        });
        this.loading = false;
      },
      error: (error: HttpErrorResponse) => {
        console.error(error.error);
        this.loading = false;
      },
    });
  }

  public getRelatorio() {
    if (!this.form.valid) {
      this.notification.warning('Please fill the form correctly');
    } else {
      this.loading = true;
      this.relatorioPerformanceService
        .getPeformance(this.form.value)
        .subscribe({
          next: (data: any) => {
            if (data.length <= 0) {
              this.table.items = [];
              this.loading = false;
              this.page.actions[0].disabled = false;
              this.notification.warning('No Data found');
              return;
            } else {
              this.table.items = [];
              this.table.columns = [
                {
                  property: 'legenda',
                  label: 'Status',
                  type: 'label',
                  width: '10%',
                  labels: [
                    {
                      value: 'Not Reached',
                      color: 'color-07',
                      label: 'Not Reached',
                    },
                    { value: 'Sub Goal', color: 'color-08', label: 'Sub Goal' },
                    { value: 'Goal', color: 'color-10', label: 'Goal' },
                    {
                      value: 'Super Goal',
                      color: 'color-01',
                      label: 'Super Goal',
                    },
                    {
                      value: 'Hiper Goal',
                      color: 'color-04',
                      label: 'Hiper Goal',
                    },
                  ],
                },
                { label: 'Month/Year', property: 'anoMes', width: '20%' },
                { label: 'Indicator', property: 'indicador', width: '20%' },
                { label: 'Total', property: 'total', width: '20%' },
              ];
              data.response.map((item: RetornoRelatorio) => {
                var x = 1;
                const anoMes = item.anoMes;
                while (x <= 3) {
                  let obj = {} as ItemTablePerformance;
                  item.categorias.map((cat: any) => {
                    let column = {} as PoTableColumn;
                    if (x === 1) {
                      obj.anoMes = `${anoMes.substring(
                        6,
                        4
                      )}/${anoMes.substring(0, 4)}`;
                      obj.legenda = this.devolveLegenda(item.percentual, false);
                      obj.total = item.valorDesafio.toLocaleString(
                        'en-US',
                        { style: 'currency', currency: 'USD' }
                      );
                      obj.indicador = 'Total Goal';
                      obj[cat.categoria] = cat.valorDesafio.toLocaleString(
                        'en-US',
                        { style: 'currency', currency: 'USD' }
                      );
                    } else if (x === 2) {
                      obj.legenda = this.devolveLegenda(0, true);
                      obj.total = item.valorAtingido.toLocaleString(
                        'en-US',
                        { style: 'currency', currency: 'USD' }
                      );;
                      obj.indicador = 'Total Reached';
                      obj[cat.categoria] = cat.valorAtingido.toLocaleString(
                        'en-US',
                        { style: 'currency', currency: 'USD' }
                      );
                    } else {
                      obj.legenda = this.devolveLegenda(0, true);
                      obj.total = (item.percentual * 100).toFixed(2) + '%';
                      obj.indicador = '% Reached';
                      obj[cat.categoria] =
                        (cat.percentual * 100).toFixed(2) + '%';
                    }
                    var pos = this.table.columns
                      .map(function (e) {
                        return e.property;
                      })
                      .indexOf(cat.categoria);
                    if (pos === -1) {
                      column.label = this.capitalizeFirstLetter(cat.descricao.toLowerCase());
                      column.property = cat.categoria;
                      column.width = '15%';
                      this.table.columns = this.utilService.spreadArrayToProperty(this.table.columns,column);
                    }
                  });
                  this.table.items = this.utilService.spreadArrayToProperty(this.table.items,obj);
                  x++;
                }
                this.table.items.push({});
              });
            }
            this.loading = false;
          },
          error: (error: HttpErrorResponse) => {
            return;
          },
        });
    }
  }

  private capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }

  private devolveLegenda(percentual: any, nada?: boolean) {
    if (nada) {
      return ' ';
    } else {
      if (percentual < 0.8) {
        return 'Not Reached';
      } else if (percentual >= 0.8 && percentual < 1) {
        return 'Sub Goal';
      } else if (percentual >= 1 && percentual < 1.2) {
        return 'Goal';
      } else if (percentual >= 1.2 && percentual < 2) {
        return 'Super Goal';
      } else {
        return 'Hiper Goal';
      }
    }
  }
}
