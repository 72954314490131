import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvioPadraoBackend } from '../envio-padrao-backend';

@Injectable({
  providedIn: 'root'
})
export class ConsultaReservasService {

  constructor(
    private http: HttpClient
  ) { }

  private readonly ip = 'consultas-reservas';

  public getReservas(parameters: EnvioPadraoBackend) {
    parameters.homologacao = !environment.production;
    return this.http.post(`${environment.URL.APIPRIVATE}/v1/${this.ip}`, parameters);
  }
}
