import { Component, OnInit, ViewChild } from '@angular/core';
import { PoPageAction } from '@po-ui/ng-components';
import { PoModalComponent, PoModalAction } from '@po-ui/ng-components';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { PoTableColumn, PoTableAction } from '@po-ui/ng-components';
import { PoNotificationService } from '@po-ui/ng-components';
import { PoBreadcrumb } from '@po-ui/ng-components';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { RoleService } from 'src/app/service/role/role.service';

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css'],
})
export class RoleListComponent implements OnInit {
  constructor(
    private roleService: RoleService,
    private router: Router,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private notification: PoNotificationService
  ) {}

  title = 'Roles';
  filterModel: string = '';
  public loading = true as boolean;

  /* Realizar Links */
  private relativeLink = 'settings/roles';

  /** Breadcrumb */
  readonly breadcrumb: PoBreadcrumb = {
    items: [{ label: 'Home' }, { label: 'Settings' }, { label: 'Roles' }],
  };

  /** Filter */
  filtersForm: UntypedFormGroup = this.fb.group({
    name: ['', [Validators.pattern('^[ a-zA-Z á]*$')]],
    active: [true],
  });
  selectOptions: any[] = [
    { label: 'True', value: true },
    { label: 'False', value: false },
  ];

  public table = {
    items: [] as Array<any>,
    columns: [
      { label: 'ID', property: 'id' },
      { label: 'Name', property: 'name' },
      {
        label: 'Created',
        property: 'created',
        type: 'date',
        format: 'dd/MM/yyyy',
      },
      {
        label: 'Modified',
        property: 'modified',
        type: 'date',
        format: 'dd/MM/yyyy',
      },
      { label: 'Active', property: 'active',type: 'boolean', width: '10%', boolean: { trueLabel: 'Yes', falseLabel: 'No' } },
    ] as Array<PoTableColumn>,
    actions: [
      { label: 'View', action: this.roleView.bind(this), icon: 'po-icon-device-desktop' },
      { label: 'Edit', action: this.roleEdit.bind(this),icon: 'po-icon-edit' },
      {
        label: 'Remove',
        type: 'danger',
        separator: true,
        action: this.deleteRole.bind(this),
        icon: 'po-icon po-icon-lock'
      },
    ] as Array<PoTableAction>,
    literals: {
      noData: 'No Data Found'
    }
  };

  /** Filter Settings */
  readonly actions: PoPageAction[] = [
    {
      label: 'New',
      icon: 'po-icon po-icon-folder',
      action: () => {
        this.router.navigate(['add'], { relativeTo: this.route });
      },
    },
  ];


  /** Advanced Filter Modal  */
  @ViewChild('advancedFilterActionModal', { static: true })
  advancedFilterModa!: PoModalComponent;

  public readonly advancedFilterPrimaryAction: PoModalAction = {
    label: 'Apply Filters',
    action: () => {
      // this.tableItems$ = this.loadRoles$(this.filtersForm.value);
    },
  };

  public readonly clearFilterSecundaryAction: PoModalAction = {
    label: 'Clear Filters',
    action: () => {
      this.filtersForm.reset();
      // this.tableItems$ = this.loadRoles$();
    },
  };

  ngOnInit() {
    this.getRoles();
    // popula a tabela
    // this.tableItems = this.loadRoles$();
    /* Advanced Filters*/
    // this.filtersForm = this.fb.group({
    //   name: ['', [Validators.pattern('^[ a-zA-Z á]*$')]],
    //   active: [true]
    // });
  }

  private roleEdit(role: any) {
    this.router.navigate([this.relativeLink, 'edit', role.id]);
  }

  private roleView(role: any) {
    this.router.navigate([this.relativeLink, 'view', role.id]);
  }

  advancedFilterActionModal() {
    // this.advancedFilterModal.open();
  }

  // private loadRoles$ = (params = '') =>
  //   this.roleService.getAllRoles().pipe(
  //     map((response: any) => {
  //       this.loading = false;
  //       return response.data
  //     })
  //   )

  private getRoles() {
    this.roleService.getAllRoles().subscribe((element: any) => {
      this.table.items = element.data;
      this.loading = false;
    });
  }

  private deleteRole(param: any) {
    this.loading = true;
    const id = param.id;
    this.roleService.deleteRole(id).subscribe({
      next: (element: any) => {
        this.notification.success(element.data.mensagem);
        this.getRoles();
      },
      error: (error) => {
        this.notification.error(error.error.meta.message);
        this.loading = false;
      }
    });
  }
}
